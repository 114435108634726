import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import FbosApiUtils from '../utils/FbosApiUtils'

const FboAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.LOAD_FBOS
    });

    FbosApiUtils.getAll('/api/fbos');
  },

  getFboById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.LOAD_FBO
    });

    FbosApiUtils.getOne('/api/fbos/' + id);
  },

  create(fbo) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.CREATE_FBO,
      fbo:        fbo
    });

    FbosApiUtils.create('/api/fbos', fbo);
  },

  update(fbo) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.UPDATE_FBO,
      fbo:        fbo
    });

    FbosApiUtils.update('/api/fbos/' + fbo.id, fbo);
  },

  delete(fboId, isImport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.DELETE_FBO,
      fboId:      fboId
    });

    FbosApiUtils.delete('/api/fbos/' + fboId, isImport);
  },

  addFboAirport(fboId, fboAirport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.CREATE_FBO_AIRPORT,
      fboId:      fboId,
      fboAirport: fboAirport
    });

    FbosApiUtils.addFboAirport('/api/fbos/' + fboId + '/airports', fboAirport);
  },

  deleteFboAirport(fboId, fboAirport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.DELETE_FBO_AIRPORT,
      fboId:      fboId,
      fboAirport: fboAirport
    });

    FbosApiUtils.deleteFboAirport('/api/fbos/' + fboId + '/airports/' + fboAirport.id, fboAirport);
  },

  updateFboAirport(fboId, fboAirport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.UPDATE_FBO_AIRPORT,
      fboId:      fboId,
      fboAirport: fboAirport
    });

    FbosApiUtils.updateFboAirport('/api/fbos/' + fboId + '/airports/' + fboAirport.id, fboAirport);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFbos.SEARCH_FBOS,
      field:      field,
      value:      value
    })
  }
};

export default FboAction
