import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import {arrayOf, normalize, Schema} from 'normalizr';
import _ from 'lodash'

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const supplier = new Schema('suppliers');
  const supplierAirport = new Schema('supplierAirports');
  const airport = new Schema('airports', {idAttribute: 'icao'});

  supplier.define({
    SupplierAirports: arrayOf(supplierAirport)
  });

  supplierAirport.define({
    Airport: airport
  });

  return normalize(data, {
    suppliers:        _.isArray(data.suppliers) ? arrayOf(supplier) : supplier,
    supplierAirports: _.isArray(data.supplierAirport) ? arrayOf(supplierAirport) : supplierAirport
  });
};

const SuppliersApiUtils = {
  getAll: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.LOAD_SUPPLIERS_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.LOAD_SUPPLIERS_ERROR,
          err:        err
        });
      })
  },

  getOne: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.LOAD_SUPPLIER_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.LOAD_SUPPLIER_ERROR,
          err:        err
        });
      })
  },

  create: function (url, supplier) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'POST',
        data:       supplier,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.CREATE_SUPPLIER_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.CREATE_SUPPLIER_ERROR,
          err:        err
        });
      })
  },

  update: function (url, supplier) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'PUT',
        data:       supplier,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.UPDATE_SUPPLIER_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.UPDATE_SUPPLIER_ERROR,
          err:        err
        });
      })
  },

  delete: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'DELETE',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.DELETE_SUPPLIER_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.DELETE_SUPPLIER_ERROR,
          err:        err
        });
      })
  },

  addSupplierAirport: function (url, supplierAirport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       supplierAirport,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT_ERROR,
          err:        err
        });
      })
  },

  updateSupplierAirport: function (url, supplierAirport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'PUT',
      data:       supplierAirport,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT_ERROR,
          err:        err
        });
      })
  },

  deleteSupplierAirport: function (url) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'DELETE',
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.DELETE_SUPPLIER_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionSuppliers.DELETE_SUPPLIER_AIRPORT_ERROR,
          err:        err
        });
      })
  }
}

export default SuppliersApiUtils
