import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import ImportApiUtils from '../utils/ImportApiUtils'

const ImportAction = {
  importContractAirports(contractId, data) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImport.IMPORT,
      contractId: contractId,
      data:       data
    });

    ImportApiUtils.import('/api/import/contracts/' + contractId + '/airports/csv', data);
  },

  importTaxes(data) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImport.IMPORT,
      data:       data
    });

    ImportApiUtils.import('/api/import/taxes/csv', data);
  },

  /*
  * We always import data for a specific supplier. We can import in PDF or in CSV.
  * */
  importFuelPrice(supplierId, format, data, rowsToSkip, effectiveDate, validUntil, importType) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImport.IMPORT,
      data:       data
    });

    ImportApiUtils.importFile('/api/import/fuelSupplierPrices/' + format + '/' + supplierId + '?rowsToSkip=' + rowsToSkip + '&effectiveDate=' + (effectiveDate ? effectiveDate : '') + '&validUntil=' + (validUntil ? validUntil : '') + '&importType=' + importType, data);
  },

  changeSupplier(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImport.IMPORT_FORM_CHANGE_SUPPLIER,
      field:      field,
      value:      value
    })
  },

  change(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImport.IMPORT_FORM_CHANGE,
      field:      field,
      value:      value
    })
  },

  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImports.LOAD_IMPORTS
    });

    ImportApiUtils.getAll('/api/contracts?public=true');
  },

  getImportById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImports.LOAD_IMPORT
    });

    ImportApiUtils.getOne('/api/airports/' + id);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionImports.SEARCH_IMPORTS,
      field:      field,
      value:      value
    })
  }
};

export default ImportAction;
