import assign from 'object-assign';
import  { Map, List, fromJS } from 'immutable';
import { EventEmitter } from 'events';
import _ from 'lodash'

import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import ValuesStore from './ValuesStore';

const PlattsStore = assign({}, BaseStore, {
  initialize() {
    PlattsStore.setState({
      platts        : Map(),
      search        : Map(),
      loadingStatus : Constants.LOADING,
      errCode       : null,
      errMessage    : null
    });
  },

  getActualValue(platt){
    return _.chain(platt.Values).filter(function(value) {
        return value.effectiveDate <= moment();
    }).first().value();
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;

    switch(action.actionType) {
      case Constants.ActionPlatts.LOAD_PLATT_ERROR:
      case Constants.ActionPlatts.LOAD_PLATTS_ERROR:
        PlattsStore.setState({ 
          loadingStatus: Constants.ERROR,
          errCode      : action.err && action.err.status ? action.err.status : '500'
        });
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.LOAD_PLATT_SUCCESS:
      case Constants.ActionPlatts.LOAD_PLATTS_SUCCESS:
        Dispatcher.waitFor([ValuesStore.dispatchToken]);

        PlattsStore.setState({
          platts        : !_.isUndefined(action.data.entities.platts) ? fromJS(action.data.entities.platts) : Map(),
          loadingStatus : Constants.LOADED
        })
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.LOAD_PLATT:
        PlattsStore.setState({ loadingStatus: Constants.LOADING });
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.LOAD_PLATT_ERROR:
        PlattsStore.setState({ loadingStatus: Constants.ERROR })
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.CREATE_PLATT_SUCCESS:
        var id       = action.data.result.platts;
        var nextData = PlattsStore
                        .get('platts')
                        .set(String(id), Map(action.data.entities.platts[id]));
        PlattsStore.setState({ platts : nextData });
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.SEARCH_PLATTS:
        var nextData = _.isUndefined(PlattsStore.get('search').get(action.field)) ? (
          PlattsStore.get('search').set(action.field , action.value)
        ) : (
          PlattsStore.get('search').update(action.field, v => v = action.value)
        );

        PlattsStore.setState({ search: nextData })
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.UPDATE_PLATT_SUCCESS:
        var id       = action.data.result.platts;
        var nextData = PlattsStore.get('platts');
        PlattsStore.setState({
          platts: nextData.update(String(id), v => v = Map(action.data.entities.platts[id]))
        })
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionPlatts.DELETE_PLATT_SUCCESS:
        var id       = action.data.result.platts;
        var nextData = PlattsStore
                        .get('platts')
                        .delete(String(id));

        PlattsStore.setState({ platts : nextData });
        PlattsStore.emitChange();
        break
      
      case Constants.ActionValues.DELETE_VALUE_SUCCESS:
        var valueId  = action.data.result.values;
        var nextData = PlattsStore
                        .get('platts')
                        .map( (platt, key) => {
                          var index = platt.get('Values').indexOf(Number(valueId));
                          return index > -1 ? platt.update('Values', v => v = v.delete(index)) : platt;
                        })
        PlattsStore.setState({ platts : nextData });
        PlattsStore.emitChange();
        break;

      case Constants.ActionValues.DELETE_PLATT_ERROR: 
        var nextData = PlattsStore
        PlattsStore.emitChange();
        break;
      
      case Constants.ActionValues.CREATE_VALUE_SUCCESS:
        Dispatcher.waitFor([ValuesStore.dispatchToken]);

        var nextData = PlattsStore
                        .get('platts')
                        .get(String(action.data.result.plattId))
                        .update('Values', v => v.push(Number(action.data.result.values)));

        PlattsStore.setState({ 
          platts: PlattsStore
                    .get('platts')
                    .update(String(nextData.get('id')), v => nextData)
        });

        PlattsStore.emitChange();
        break;
      }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default PlattsStore;