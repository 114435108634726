import React from 'react';
import _ from 'lodash';
import {FormControls, Input} from 'react-bootstrap';

import {IconButton} from '../../components';

var List = React.createClass({
  propTypes: {
    options:     React.PropTypes.array,
    onItemClick: React.PropTypes.func
  },

  getInitialState() {
    return {
      options: this.props.options
    }
  },

  getStyle() {
    return {
      padding: 0
    }
  },

  componentDidMount() {
    document.body.addEventListener('click', this.handleDocumentClick);
  },

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleDocumentClick);
  },

  handleDocumentClick(evt) {
    const area = this.refs.area;

    if (!area.contains(evt.target)) {
      this.props.onClickOutside(evt);
    }
  },

  render() {
    return (
      <div ref="area" className="shadow-z-1" style={this.getStyle()}>
        {_.map(this.props.options, function (option, key) {
          return (
            <Item
              value={option.value}
              label={option.label}
              onClick={this.props.onItemClick}/>)
        }, this)}
      </div>
    )
  }
})

var Item = React.createClass({
  propTypes: {
    label: React.PropTypes.string,
    value: React.PropTypes.string
  },

  getInitialState() {
    return {
      options: this.props.options,
      hover:   false
    }
  },

  toggleHover() {
    this.setState({hover: !this.state.hover})
  },

  getWrapperStyles() {
    return {
      position: "relative"
    }
  },

  getStyle() {
    if (this.state.hover) {
      return {
        padding:         "10px",
        backgroundColor: "#FFF3DB",
        cursor:          "pointer",
      }
    } else {
      return {
        padding:         "10px",
        backgroundColor: "#FFFFFF",
        cursor:          "pointer",
      }
    }
  },

  handleClick() {
    this.props.onClick(this.props.label, this.props.value);
  },

  render() {
    return (
      <div
        style={this.getStyle()}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={this.handleClick}>
        {this.props.label}
      </div>
    )
  }
})

export default React.createClass({
  propTypes: {
    name:             React.PropTypes.string,
    type:             React.PropTypes.string,
    defaultLabel:     React.PropTypes.string,
    defaultValue:     React.PropTypes.string,
    label:            React.PropTypes.string,
    placeholder:      React.PropTypes.string,
    onChange:         React.PropTypes.func,
    onBlur:           React.PropTypes.func,
    labelClassName:   React.PropTypes.string,
    wrapperClassName: React.PropTypes.string,
    bsStyle:          React.PropTypes.string,
    options:          React.PropTypes.array,
  },

  getInitialState() {
    let obj = {
      options:      [],
      defaultLabel: "",
      fieldName:    this.props.value && this.props.value.label ? this.props.value.label : null
    };
    obj[this.props.name] = this.props.value && this.props.value.value ? this.props.value.value : null;
    //Set initial state from value
    return obj
  },

  componentDidMount() {
    document.body.addEventListener('keyup', this.handleKeyPress, false);
  },

  componentWillUnmount() {
    document.body.removeEventListener('keyup', this.handleKeyPress);
  },

  render() {
    return (
      <div>
        {this.state[this.props.name] ? this.getStaticItem() : this.getItemSearch()}
      </div>
    )
  },

  filterOptions(searchStr) {
    return _.filter(this.props.options, (item) => {
      return item.label.toLowerCase().indexOf(searchStr.toLowerCase()) > -1;
    })
  },

  getListStyle() {
    return {
      position: "absolute",
      width:    "100%",
      top:      "27px",
      zIndex:   "2"
    }
  },

  getItemSearch() {
    return (
      <div style={this.getWrapperStyles()}>
        <Input
          name={this.props.name}
          type="text"
          value={this.state.defaultLabel}
          label={this.props.label}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          labelClassName={this.props.labelClassName}
          wrapperClassName={this.props.wrapperClassName}
          bsStyle={this.props.bsStyle}
          onFocus={this.handleFocus}
          hasFeedback
        />
        <div style={this.getListStyle()}>
          <div className={this.props.labelClassName}></div>
          <div
            className={this.props.wrapperClassName}>
            <List
              options={this.state.defaultLabel.length > 1 ? this.state.options : []}
              onItemClick={this.handleClick}
              onClickOutside={this.handleClickOutside}/>
          </div>
        </div>
      </div>);
  },

  handleFocus() {
    this.setState({defaultLabel: ""});
  },

  getStaticItem() {
    const value = (
      <span>
        {this.state.fieldName}
        <IconButton style={{
          position: "absolute",
          right:    15,
          top:      10
        }} onClick={this.handleClickRemove} fa="remove"/>
      </span>
    );

    return (
      <div style={{
        position:     "relative",
        marginBottom: "-7px"
      }}>
        <FormControls.Static
          label="Airport"
          labelClassName={this.props.labelClassName}
          wrapperClassName={this.props.wrapperClassName}
          bsStyle={this.props.bsStyle}
          value={value}
        />
        <span style={{
          position: "absolute",
          right:    "25px",
          top:      "8px"
        }}>
            
          </span>
      </div>
    );
  },

  getWrapperStyles() {
    return {
      position: "relative"
    }
  },

  handleClickRemove() {
    let obj = {
      fieldName:    null,
      defaultLabel: ""
    };
    obj[this.props.name] = null

    this.setState(obj);

    this.props.onChange({
      target: {
        name:  this.props.name,
        value: null
      }
    });
  },

  handleClick(label, value) {
    let obj = {
      fieldName: label,
      options:   []
    };
    obj[this.props.name] = value;
    this.setState(obj);

    this.props.onChange({
      target: {
        name:  this.props.name,
        value: value
      }
    });
  },

  handleClickOutside() {
    if (this.state.options.length === 1) {
      let obj = {
        fieldName: this.state.options[0].label
      };
      obj[this.props.name] = this.state.options[0].value;
      this.setState(obj);

      this.props.onChange({
        target: {
          name:  this.props.name,
          value: this.state.options[0].value
        }
      });
    }

    this.setState({
      options: []
    });
  },

  handleKeyPress(e) {
    if (e.keyCode === 9) {
      if (this.state.options.length === 1) {
        let obj = {
          fieldName: this.state.options[0].label
        };
        obj[this.props.name] = this.state.options[0].value;
        this.setState(obj);

        this.props.onChange({
          target: {
            name:  this.props.name,
            value: this.state.options[0].value
          }
        });
      }

      this.setState({options: []});
    }
  },

  handleChange(e) {
    var searchStr = e.target.value;
    var filteredOptions = this.filterOptions(searchStr);
    var lastOptions = filteredOptions.splice(0, this.props.itemsNumber);

    this.setState({
      options:      lastOptions,
      defaultLabel: e.target.value,
    })
  }
});
