import React from 'react';
import  { Map } from 'immutable';

import { LoginStore } from '../../stores';
import { Constants } from '../../constants';
import Welcome from './Welcome';

import commonUtils from '../../utils/commonUtils';

export default React.createClass({
  getInitialState() {
    return {
      user                : null,
    }
  },

  componentDidMount() {

    LoginStore.addChangeListener(this.handleLoginStoreChange);

  },

  componentWillUnmount() {

    LoginStore.removeChangeListener(this.handleLoginStoreChange);
  },

  render() {
    return(<Welcome {...this.state} />)
  },


  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleImportFormStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    });
  },
});
