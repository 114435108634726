import React from 'react';
import ReactDom from 'react-dom';
import { Badge } from 'react-bootstrap';
import { Palette } from '../../../constants';
import { legendTitleStyle } from '../Calculator.styles';

export default React.createClass({
  render(){
    return (
      <div style={legendTitleStyle.wrapper}>
        <div style={legendTitleStyle.title} className="text-center">
          {this.props.children}
        </div>
        <div style={legendTitleStyle.line}></div>
      </div>
    )
  }
})

