import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';

//Token
let jwt = localStorage.getItem('jwt');

const CalculatorApiUtils = {
  compute: function(url, data) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        data: data,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionCalculator.LOAD_FUEL_INDICES_SUCCESS,
          data: data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionCalculator.LOAD_FUEL_INDICES_ERROR,
          err: err
        });
      })
  }
}

export default CalculatorApiUtils
