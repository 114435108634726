import React, {PropTypes} from 'react';
import { Icon } from '../../components';

export default class PageTitle extends React.Component {
  render() {
    return (
      <h1 className="text-center">
        <Icon fa={this.props.icon} />
        &nbsp;{this.props.children}
      </h1>
    );
  }
}
