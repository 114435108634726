import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { Icon } from '../../components';

export default React.createClass({
  mixins: [PureRenderMixin],

  getContent(){
    if(!this.props.children) return null

    switch(this.props.bsStyle){
      case "warning":
        return <span className="text-danger"><Icon fa="exclamation-triangle" /> {this.props.children}</span>
        break;
      case "info":
        return <span className="text-info"><Icon fa="info" /> {this.props.children}</span>
        break;
      case "success":
        return <span className="text-success"><Icon fa="check" /> {this.props.children}</span>
        break;
      case "default":
      default:
        return <span>{this.props.children}</span>
        break;
    }
  },

  render() {
    return <div className="text-center">{this.getContent()}</div>
  }
});
