import React from 'react';
import moment from 'moment';
import {Map} from 'immutable';
import Griddle from 'griddle-react';
import {Button} from 'react-bootstrap';
import {CellFormat, Icon, PermissionRequired} from '../../components';
import ValueListActions from './ValueListActions';
import {ModalsAction} from '../../actions';
import {Activities} from '../../constants';

var ValueItem = React.createClass({
  propTypes: {
    value: React.PropTypes.object
  },

  getDefaultProps: function () {
    return {
      value: Map()
    }
  },

  render() {
    return (
      <li style={this.props.style} className={this.getClass()} key={this.props.value.get('id')}>
          <span style={{lineHeight: "32px"}}>
            {this.getValue()}
          </span>
        <PermissionRequired requiredRoles={Activities.WRITE_PLATTS_VALUES} user={this.props.user}>
            <span className="pull-right lpad-10">
              <Button style={{
                padding: "6px 12px",
                margin:  "0",
                color:   "#C34040"
              }} onClick={this.handleDeleteValueClick}><Icon fa="remove"/></Button>
            </span>
        </PermissionRequired>
        <span className="pull-right" style={{lineHeight: "32px"}}>
            {moment(this.props.value.get('effectiveDate')).format('YYYY-MM-DD')}
          </span>
      </li>)
  },

  getValue() {
    return this.props.value && (this.props.value.get('value') + ' ' + this.props.value.get('currencyCode') + ' per ' + this.props.value.get('volumeUnitCode'));
  },

  getClass() {
    return this.props.isCurrentValue ? "shadow-z-1 value-item value-current" : "shadow-z-1 value-item";
  },

  handleDeleteValueClick() {
    ModalsAction.show(this.props.valueDeleteDialogId, {
      id:      this.props.value.get('id'),
      message: 'Are you sure you want to delete the value of the ' + moment(this.props.value.get('effectiveDate')).format('YYYY-MM-DD') + ' ?'
    });
  }
});

export default React.createClass({
  propTypes: {
    values: React.PropTypes.array,
    user:   React.PropTypes.object
  },

  getColumnMetadata() {
    return [{
      columnName:      "value",
      displayName:     "Value",
      searchInput:     false,
      customComponent: props => <CellFormat.Decimal value={props.rowData.value}/>
    }, {
      columnName:  "currencyCode",
      displayName: "Currency",
      searchInput: false
    }, {
      columnName:  "volumeUnitCode",
      displayName: "Unit",
      searchInput: false
    }, {
      columnName:      "effectiveDate",
      displayName:     "Effective Date",
      searchInput:     false,
      customComponent: props => <CellFormat.ISODate format="YYYY-MM-DD" value={props.rowData.effectiveDate}/>
    }, {
      columnName:      "validUntil",
      displayName:     "Valid Until",
      searchInput:     false,
      customComponent: props => {
        return props.rowData.validUntil ?
          (<CellFormat.ISODate format="YYYY-MM-DD" value={props.rowData.validUntil}/>)
          :
          (<span>UFN</span>)
      }
    }, {
      columnName:          "id",
      displayName:         "",
      cssClassName:        "actionsColumn",
      valueDeleteDialogId: this.props.valueDeleteDialogId,
      customComponent:     ValueListActions,
      searchInput:         'actions',
      user:                this.props.user
    }];
  },

  getColumnNames() {
    return ['value', 'currencyCode', 'volumeUnitCode', 'effectiveDate', 'validUntil', 'id'];
  },

  render() {
    return (
      <Griddle
        initialSort="effectiveDate"
        initialSortAscending={false}
        results={this.props.values}
        tableClassName="table middle-td"
        resultsPerPage={20}
        filterPlaceholderText="Search"
        columnMetadata={this.getColumnMetadata()}
        columns={this.getColumnNames()}/>
    );
  }
});
