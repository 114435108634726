import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import VolumeUnitsApiUtils from '../utils/VolumeUnitsApiUtils'

export default {
  fetchAll: function() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionVolumeUnits.LOAD_VOLUME_UNITS
    })

    VolumeUnitsApiUtils.get('/api/units');
  }
};