import AirportFormModule from './settings/AirportForm';
import LegendTitleModule from './settings/LegendTitle';
import OperationTypeSliderModule from './settings/OperationTypeSlider';
import AirportModule from './settings/Airport';
import TripModule from './settings/Trip';

export const AirportForm         = AirportFormModule;
export const LegendTitle         = LegendTitleModule;
export const OperationTypeSlider = OperationTypeSliderModule;
export const Airport             = AirportModule;
export const Trip                = TripModule;