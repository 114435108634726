import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';

const ModalsAction = {
  show(modalId, payload) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_MODAL,
      modalId:    modalId,
      payload:    payload
    })
  },

  hide(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_MODAL,
      modalId:    modalId
    })
  },

  add(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.ADD_MODAL,
      modalId:    modalId
    })
  },

  showTaxForm(modalId, tax) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_TAX_FORM_MODAL,
      modalId:    modalId,
      tax:        tax
    })
  },

  hideTaxForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_TAX_FORM_MODAL,
      modalId:    modalId
    })
  },

  showSupplierForm(modalId, supplier) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_SUPPLIER_FORM_MODAL,
      modalId:    modalId,
      supplier:   supplier
    })
  },

  hideSupplierForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_SUPPLIER_FORM_MODAL,
      modalId:    modalId
    })
  },

  showHistoryDetails(modalId, history) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_HISTORY_DETAILS_MODAL,
      modalId:    modalId,
      history:    history
    })
  },

  hideHistoryDetails(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_HISTORY_DETAILS_MODAL,
      modalId:    modalId
    })
  },

  showPlattForm(modalId, platt) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_PLATT_FORM_MODAL,
      modalId:    modalId,
      platt:      platt
    })
  },

  hidePlattForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_PLATT_FORM_MODAL,
      modalId:    modalId
    })
  },

  showValueForm(modalId, platt) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_VALUE_FORM_MODAL,
      modalId:    modalId,
      platt:      platt
    })
  },

  hideValueForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_VALUE_FORM_MODAL,
      modalId:    modalId
    })
  },

  showFboForm(modalId, fbo) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_FBO_FORM_MODAL,
      modalId:    modalId,
      fbo:        fbo
    })
  },

  hideFboForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_FBO_FORM_MODAL,
      modalId:    modalId
    })
  },

  showImportResults(modalId, results) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_IMPORT_RESULTS_MODAL,
      modalId:    modalId
    })
  },

  hideImportResults(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_IMPORT_RESULTS_MODAL,
      modalId:    modalId
    })
  },

  showFboAirportForm(modalId, fboAirport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_FBO_AIRPORT_FORM_MODAL,
      modalId:    modalId,
      fboAirport: fboAirport
    })
  },

  hideFboAirportForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_FBO_AIRPORT_FORM_MODAL,
      modalId:    modalId
    })
  },

  showSupplierAirportForm(modalId, supplierAirport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_SUPPLIER_AIRPORT_FORM_MODAL,
      modalId:    modalId,
      supplierAirport: supplierAirport
    })
  },

  hideSupplierAirportForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_SUPPLIER_AIRPORT_FORM_MODAL,
      modalId:    modalId
    })
  },

  showContractForm(modalId, contract) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_CONTRACT_FORM_MODAL,
      modalId:    modalId,
      contract:   contract
    })
  },

  hideContractForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_CONTRACT_FORM_MODAL,
      modalId:    modalId
    })
  },

  showContractAirportForm(modalId, contractAirport) {
    Dispatcher.handleViewAction({
      actionType:      Constants.ActionModals.SHOW_CONTRACT_AIRPORT_FORM_MODAL,
      modalId:         modalId,
      contractAirport: contractAirport
    })
  },

  hideContractAirportForm(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_CONTRACT_AIRPORT_FORM_MODAL,
      modalId:    modalId
    })
  },

  showCalculatorAirportModal(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_CALCULATOR_AIRPORT_MODAL,
      modalId:    modalId
    })
  },

  hideCalculatorAirportModal(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_CALCULATOR_AIRPORT_MODAL,
      modalId:    modalId
    })
  },

  showImportModal(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.SHOW_IMPORT_MODAL,
      modalId:    modalId
    })
  },

  hideImportModal(modalId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionModals.HIDE_IMPORT_MODAL,
      modalId:    modalId
    })
  },
};

export default ModalsAction
