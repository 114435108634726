import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import ContractsStore from './ContractsStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';
import _ from 'lodash';

const SuppliersStore = assign({}, BaseStore, {
  initialize() {
    SuppliersStore.setState({
      suppliers:     Map(),
      search:        Map(),
      errCode:       null,
      loadingStatus: Constants.LOADING
    });
  },
  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;
    var text;

    switch (action.actionType) {
      case Constants.ActionSuppliers.LOAD_SUPPLIER_ERROR:
      case Constants.ActionSuppliers.LOAD_SUPPLIERS_ERROR:
        SuppliersStore.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        SuppliersStore.emitChange();
        break;

      case Constants.ActionSuppliers.LOAD_SUPPLIER_SUCCESS:
        SuppliersStore.setState({
          suppliers:     fromJS(_.isUndefined(action.data.entities.suppliers) ? {} : action.data.entities.suppliers),
          loadingStatus: Constants.LOADED
        });
        SuppliersStore.emitChange();
        break;

      case Constants.ActionContracts.LOAD_CONTRACT_SUCCESS:
      case Constants.ActionSuppliers.LOAD_SUPPLIERS_SUCCESS:
        Dispatcher.waitFor([ContractsStore.dispatchToken]);
        SuppliersStore.setState({
          suppliers:     fromJS(_.isUndefined(action.data.entities.suppliers) ? {} : action.data.entities.suppliers),
          loadingStatus: Constants.LOADED
        });
        SuppliersStore.emitChange();
        break;

      case Constants.ActionSuppliers.CREATE_SUPPLIER_SUCCESS:
        var id = action.data.result.suppliers;
        var nextData = SuppliersStore.get('suppliers').set(String(id), Map(action.data.entities.suppliers[id]));
        SuppliersStore.setState({suppliers: nextData});
        SuppliersStore.emitChange();
        break;

      case Constants.ActionSuppliers.UPDATE_SUPPLIER_SUCCESS:
        var id = action.data.result.suppliers;
        var nextData = SuppliersStore.get('suppliers');
        SuppliersStore.setState({
          suppliers: nextData.update(String(id), v => v = Map(action.data.entities.suppliers[id]))
        })
        SuppliersStore.emitChange();
        break;

      case Constants.ActionSuppliers.DELETE_SUPPLIER_SUCCESS:
        var id = action.data.result.suppliers;
        var nextData = SuppliersStore.get('suppliers').delete(String(id));
        SuppliersStore.setState({suppliers: nextData});
        SuppliersStore.emitChange();
        break;

      case Constants.ActionSuppliers.SEARCH_SUPPLIER_AIRPORT:
        if (_.isUndefined(SuppliersStore.get('search').get(action.field))) {
          var nextData = SuppliersStore.get('search').set(action.field, action.value);
        } else {
          var nextData = SuppliersStore.get('search').update(action.field, v => v = action.value);
        }
        SuppliersStore.setState({search: nextData});
        SuppliersStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default SuppliersStore;
