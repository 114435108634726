import React from 'react';
import ErrorPage from '../ErrorPage';

export default React.createClass({
  render() {
    return(
      <ErrorPage code="404">
        Page Not Found
      </ErrorPage>
    )
  }
});