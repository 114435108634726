import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import {fromJS, Iterable, Map} from 'immutable';

const SupplierFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    SupplierFormStore.setState({
      id:                 Map({
        value:   null,
        isValid: null
      }),
      name:               Map({
        value:   null,
        isValid: null
      }),
      email:               Map({
        value:   null,
        isValid: null
      }),
      logo:               Map({
        value:   null,
        isValid: null
      }),
      isSavable:          true,
      error:              false
    })
  },

  isValid(data) {
    var isValid = false;
    switch (data.field) {
      case 'name':
        isValid = data.value !== null && data.value !== '';
        break;
      default:
        isValid = true;
    }

    return isValid;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionSuppliers.UPDATE_SUPPLIER:
      case Constants.ActionSuppliers.CREATE_SUPPLIER:
        SupplierFormStore.setState({isSavable: false})
        SupplierFormStore.emitChange();
        break;

      case Constants.ActionSuppliers.CREATE_SUPPLIER_SUCCESS:
      case Constants.ActionSuppliers.UPDATE_SUPPLIER_SUCCESS:
        SupplierFormStore.setState({
          isSavable: true,
          error:     false
        })
        SupplierFormStore.emitChange();
        break;

      case Constants.ActionSuppliers.UPDATE_SUPPLIER_ERROR:
      case Constants.ActionSuppliers.CREATE_SUPPLIER_ERROR:
        var nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        };

        _.each(SupplierFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(SupplierFormStore.get(key))) {
            nextState[key] = SupplierFormStore.get(key).update('isValid', v => v = SupplierFormStore.isValid({
              field: key,
              value: SupplierFormStore.get(key).get('value')
            }));
          }
        })

        SupplierFormStore.setState(nextState)
        SupplierFormStore.emitChange();
        break;

      case Constants.ActionSupplierForm.SUPPLIER_FORM_CHANGE:
        var nextState = {};
        var isValid = SupplierFormStore.isValid(action)

        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        SupplierFormStore.setState(nextState)
        SupplierFormStore.emitChange();
        break;

      case Constants.ActionModals.SHOW_SUPPLIER_FORM_MODAL:
        SupplierFormStore.initialize();

        if (action.supplier) {
          var nextState = {};
          _.each(SupplierFormStore.state.toJS(), function (item, key) {
            if (!Iterable.isIterable(SupplierFormStore.get(key))) {
              return;
            }

            nextState[key] = SupplierFormStore.get(key).update('value', v => v = action.supplier[key] ? fromJS(action.supplier[key]) : null);
          })
          SupplierFormStore.setState(nextState);
        }

        SupplierFormStore.emitChange();
        break;

      case Constants.ActionModals.HIDE_SUPPLIER_FORM_MODAL:
        SupplierFormStore.initialize()
        SupplierFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default SupplierFormStore;
