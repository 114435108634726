import React from 'react';
import _ from 'lodash';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { Map, List } from 'immutable';
import moment from 'moment';

import { Loader, NoItemHelper, Oops, PageTitle, DefaultModal, YesNoDialog } from '../../components';
import { ModalsAction, PlattAction } from '../../actions';
import { Errors } from '../../constants';

import {PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded} from '../PageLifecycle';
import commonUtils from '../../utils/commonUtils';
import PlattsList from './PlattsList';
import PlattsListActions from './PlattsListActions';
import PlattForm from '../platt/PlattForm';


var effectiveDate = React.createClass({
  render() {
    if(!this.props.rowData.effectiveDate)
      return null

    return (
      <span>{moment(this.props.rowData.effectiveDate).format('YYYY-MM-DD')}</span>
    )
  }
});

var lastValue = React.createClass({
  render() {
    if(!this.props.rowData.lastValue)
      return null;

    let {value, currencyCode, volumeUnitCode} = this.props.rowData.lastValue;
    return (
      <span>{value + ' ' + currencyCode + '/' + volumeUnitCode}</span>
    )
  }
});

export default React.createClass({
  propTypes: {
    platts              : React.PropTypes.object,
    plattDataForm       : React.PropTypes.object,
    searchParams        : React.PropTypes.object,
    plattFormModalId    : React.PropTypes.number,
    plattDeleteDialogId : React.PropTypes.number
  },

  getDefaultProps: function() {
    return {
      platts              : List(),
      plattDataForm       : Map(),
      searchParams        : Map(),
      loadingPlattsStatus : Map({
        status: 'loading',
        message: 'Fetching data from server. Please wait.',
      })
    }
  },

  render() {
    return (
      <div className="data-table">
        <PageTitle icon="line-chart">List of platts</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isPlattsLoading}>
            <Loader>
              Fetching platts from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isPlattsError}>
            <Oops errorCode ={this.props.errCode} />
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed ={!this.props.isPlattsError && !this.props.isPlattsLoading && this.props.platts.size === 0}>
            <NoItemHelper onNew={this.handleNewClick} buttonLabel="New platts">
              <p>You are currently in the platts list. There is no platts registered yet.</p>
              <p>To get you started, you can create a new platts. You can add as many platts as you like.</p>
            </NoItemHelper>
          </PageHelper>

        {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isPlattsError && !this.props.isPlattsLoading && !(this.props.platts.size === 0)}>
            <PlattsList
              user           ={this.props.user}
              platts         ={this.props.platts}
              searchParams   ={this.props.searchParams}
              columnMetadata ={this.getColumnMetadata()}
              columnNames    ={this.getColumnNames()}
              isNewButton    ={true}
              onNew          ={this.handleNewClick}
            />
          </PageLoaded>
        </PageLifcycle>

        <DefaultModal id={this.props.plattFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <PlattForm
            onSave        ={this.handleSavePlatt}
            onCancel      ={this.closeModal}
            data          ={this.props.plattDataForm}
            dropdownsData = {this.props.dropdownsData} />
        </DefaultModal>

        <YesNoDialog
          id    = {this.props.plattDeleteDialogId}
          title = "Please confirm"
          onYes = {this.handleConfirmDeleteClick}
          onNo  = {this.handleCancelDeleteClick} />
      </div>
    )
  },

  getColumnMetadata(){
    return [{
      "columnName"  : "code",
      "displayName" : "Code",
      "searchInput" : "text"
    },{
      "columnName"  : "name",
      "displayName" : "Name",
      "searchInput" : "text"
    },{
      "columnName"  : "baseOrigin",
      "displayName" : "Base Origin",
      "searchInput" : "text"
    },{
      "columnName"  : "baseReference",
      "displayName" : "Base Reference",
      "searchInput" : "text"
    },{
      "columnName"  : "reviewPeriod",
      "displayName" : "Review Period",
      "searchInput" : "select",
      "options"     : { data: this.props.dropdownsData.get('reviewPeriodAll'), key: "id", label: "id" }
    },{
      columnName      : "lastValue",
      displayName     : "Last Value",
      customComponent : lastValue,
    },{
      columnName      : "effectiveDate",
      displayName     : "Effective Date",
      customComponent : effectiveDate,
    },{
      columnName      : "nbrValues",
      displayName     : "nbr. Values"
    },{
      columnName          : "id",
      displayName         : "",
      cssClassName        : "actionsColumn",
      plattDeleteDialogId : this.props.plattDeleteDialogId,
      plattFormModalId    : this.props.plattFormModalId,
      customComponent     : PlattsListActions,
      searchInput         : 'actions',
      user                : this.props.user
    }];
  },

  getColumnNames(){
    return ['code', 'name', 'baseOrigin', 'baseReference', 'reviewPeriod', 'lastValue', 'effectiveDate', 'nbrValues', 'id'];
  },

  getModalTitle(){
    return this.props.plattDataForm.get('id') && this.props.plattDataForm.get('id').get('value') ? "Edit Platts" : "New Platts";
  },

  closeModal() {
    ModalsAction.hidePlattForm(this.props.plattFormModalId);
  },

  handleSavePlatt(platt) {
    if(platt.id)
      PlattAction.update(platt);
    else
      PlattAction.create(platt);
  },

  handleConfirmDeleteClick(platt){
    PlattAction.delete(platt.get('id'))
  },

  handleCancelDeleteClick(){
    ModalsAction.hide(this.props.plattDeleteDialogId);
  },

  handleNewClick() {
    ModalsAction.showPlattForm(this.props.plattFormModalId);
  }
});
