import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import {fromJS, Iterable, Map} from 'immutable';
import validator from '../utils/validator';
import formatter from '../utils/formatter';
import ValuesStore from './ValuesStore';

const ContractAirportFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    ContractAirportFormStore.setState({
      id:                 Map({
        value:   null,
        isValid: null
      }),
      deliveryBy:         Map({
        value:   null,
        isValid: null
      }),
      hasPreNotification: Map({
        value:   null,
        isValid: null
      }),
      payment:            Map({
        value:   null,
        isValid: null
      }),
      plattId:            Map({
        value:   null,
        isValid: null
      }),
      airportIcao:        Map({
        value:   null,
        isValid: null
      }),
      valueId:            Map({
        value:   null,
        isValid: null
      }),
      value:              Map({
        value:   null,
        isValid: null
      }),
      effectiveDate:      Map({
        value:   null,
        isValid: null
      }),
      validUntil:         Map({
        value:   null,
        isValid: null
      }),
      currencyCode:       Map({
        value:   'EUR',
        isValid: null
      }),
      volumeUnitCode:     Map({
        value:   'L',
        isValid: null
      }),
      isSavable:          true,
      error:              ''
    })
  },

  isValid(data) {
    let isValid = false;
    switch (data.field) {
      case 'name':
      case 'plattId':
      case 'airportIcao':
      case 'deliveryBy':
      case 'payment':
        isValid = validator.required(data.value);
        break;
      case 'value':
        isValid = validator.isNumericValid(data.value) && validator.required(data.value);
        break;
      case 'effectiveDate':
        isValid = validator.isDateValid(data.value) && validator.required(data.value);
        break;
      case 'validUntil':
        isValid = validator.isDateValid(data.value);
        break;
      default:
        isValid = true;
        break
    }

    return isValid;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    let action = payload.action;
    let nextState = {};

    switch (action.actionType) {
      case Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT:
      case Constants.ActionContracts.CREATE_CONTRACT_AIRPORT:
        ContractAirportFormStore.setState({isSavable: false})
        ContractAirportFormStore.emitChange();
        break;

      case Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_SUCCESS:
      case Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT_SUCCESS:
        ContractAirportFormStore.setState({
          isSavable: true,
          error:     false
        })
        ContractAirportFormStore.emitChange();
        break;

      case Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT_ERROR:
      case Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_ERROR:
        nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        }
        _.each(ContractAirportFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(ContractAirportFormStore.get(key))) {
            nextState[key] = ContractAirportFormStore.get(key).update('isValid', v => v = ContractAirportFormStore.isValid({
              field: key,
              value: ContractAirportFormStore.get(key).get('value')
            }));
          }
        })
        ContractAirportFormStore.setState(nextState)
        ContractAirportFormStore.emitChange();
        break;

      case Constants.ActionContractAirportForm.CONTRACT_AIRPORT_FORM_CHANGE:
        let isValid = ContractAirportFormStore.isValid(action)

        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        ContractAirportFormStore.setState(nextState)
        ContractAirportFormStore.emitChange();
        break;
      case Constants.ActionModals.SHOW_CONTRACT_AIRPORT_FORM_MODAL:
        if (action.contractAirport) {
          nextState["id"] = ContractAirportFormStore.get("id").update('value', v => v = action.contractAirport.id ? fromJS(action.contractAirport.id) : null);
          nextState["plattId"] = ContractAirportFormStore.get("plattId").update('value', v => v = action.contractAirport.plattId ? fromJS(action.contractAirport.plattId) : null);
          nextState["airportIcao"] = ContractAirportFormStore.get("airportIcao").update('value', v => v = action.contractAirport.airportIcao ? fromJS(action.contractAirport.airportIcao) : null);
          nextState["deliveryBy"] = ContractAirportFormStore.get("deliveryBy").update('value', v => v = action.contractAirport.deliveryBy ? fromJS(action.contractAirport.deliveryBy) : null);
          nextState["payment"] = ContractAirportFormStore.get("payment").update('value', v => v = action.contractAirport.payment ? fromJS(action.contractAirport.payment) : null);
          // nextState["hasPreNotification"] = ContractAirportFormStore.get("hasPreNotification").update('value', v => v = action.contractAirport.hasPreNotification ? fromJS(action.contractAirport.hasPreNotification) : false);

          //SEARCH THE ACTIVE VALUE
          if (action.contractAirport.Values && action.contractAirport.Values.length > 0) {
            let value = _.chain(action.contractAirport.Values)
              .map((valueId) => {
                return ValuesStore.get("values").get(String(valueId)).toJS()
              })
              .sortBy('-effectiveDate')
              .first()
              .value();

            nextState.valueId = ContractAirportFormStore.get("valueId").update('value', v => v = value.id);
            nextState.value = ContractAirportFormStore.get("value").update('value', v => v = value.value);
            nextState.volumeUnitCode = ContractAirportFormStore.get("volumeUnitCode").update('value', v => v = value.volumeUnitCode);
            nextState.currencyCode = ContractAirportFormStore.get("currencyCode").update('value', v => v = value.currencyCode);
            nextState.effectiveDate = ContractAirportFormStore.get("effectiveDate").update('value', v => v = formatter.format.date(value.effectiveDate));
            nextState.validUntil = ContractAirportFormStore.get("validUntil").update('value', v => v = formatter.format.date(value.validUntil));
          } else if (action.contractAirport.effectiveDate) {
            nextState.effectiveDate = ContractAirportFormStore.get("effectiveDate").update('value', v => v = formatter.format.date(action.contractAirport.effectiveDate));

            if (action.contractAirport.validUntil) {
              nextState.validUntil = ContractAirportFormStore.get("validUntil").update('value', v => v = formatter.format.date(action.contractAirport.validUntil));
            }
          }
          ContractAirportFormStore.setState(nextState);
        }
        ContractAirportFormStore.emitChange();
        break;
      case Constants.ActionModals.HIDE_CONTRACT_AIRPORT_FORM_MODAL:
        ContractAirportFormStore.initialize()
        ContractAirportFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ContractAirportFormStore;
