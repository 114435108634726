import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {CSVForm, DefaultModal, Icon, Loader, Oops, PageTitle, PermissionRequired, YesNoDialog} from '../../components';
import {ContractsAction, ImportAction, ModalsAction, ValuesAction} from '../../actions';
import {PageError, PageLifcycle, PageLoaded, PageLoading} from '../PageLifecycle';
import ContractStaticForm from './ContractStaticForm';
import ContractAirports from './ContractAirports';
import PlattValues from './PlattValues';
import ContractAirportForm from './ContractAirportForm';
import {Activities} from "../../constants";
import ValueForm from "../values/ValueForm";

export default React.createClass({
  propTypes: {
    contract:  React.PropTypes.object,
    suppliers: React.PropTypes.object
  },

  getInitialState() {
    return {
      isContractAirportsLoading: true,
      isContractAirportsError:   false
    }
  },

  componentWillReceiveProps(nextProps) {
    //If some of theses stores loading, then we wait until everything is finish.
    let isContractAirportsLoading = (
      nextProps.isAirportsLoading ||
      nextProps.isContractAirportsLoading ||
      nextProps.isContractLoading ||
      nextProps.isValuesLoading ||
      nextProps.isPlattsLoading ||
      nextProps.isVolumeUnitsLoading ||
      nextProps.isCurrenciesLoading);

    let isContractAirportsError = (
      nextProps.isAirportsError ||
      nextProps.isContractAirportsError ||
      nextProps.isValuesError ||
      nextProps.isContractError ||
      nextProps.isPlattsError ||
      nextProps.isVolumeUnitsError ||
      nextProps.isCurrenciesError);

    this.setState({
      isContractAirportsLoading: isContractAirportsLoading,
      isContractAirportsError:   isContractAirportsError
    })
  },

  render() {
    return (
      <div className="data-table">
        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.state.isContractAirportsLoading && !this.state.isContractAirportsError}>
            <Loader>
              Fetching airport from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.state.isContractAirportsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.state.isContractAirportsError && !this.state.isContractAirportsLoading}>
            <PageTitle icon="file-text">{this.props.contract.get('isImport') ? 'Import' : 'Contract'} : {this.props.contract.get('reference')}</PageTitle>
            <Row>
              <Col xs={12}>
                <ContractStaticForm
                  contract={this.props.contract}
                  suppliers={this.props.suppliers}
                  importResultsModalId={this.props.importResultsModalId}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PermissionRequired requiredRoles={Activities.WRITE_CONTRACTS} user={this.props.user}>
                  {this.props.contract.get('isImport') && !this.props.contract.get('isPublic') ? '' :
                    (<Button style={{float: 'right'}} bsStyle="success" onClick={this.handleAddClick}>
                      <Icon fa="plus"/> Add Airport
                    </Button>)}
                </PermissionRequired>

                <PermissionRequired requiredRoles={Activities.WRITE_CONTRACTS} user={this.props.user}>
                  {this.props.contract.get('isImport') && !this.props.contract.get('isPublic') ? '' :
                    (<Button style={{
                      float:   'right',
                      display: this.props.contract.get('isImport') ? 'none' : 'inline'
                    }} bsStyle="info" onClick={this.handleImportClick}>
                      <Icon fa="upload"/>
                    </Button>)}
                </PermissionRequired>

              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {this.props.contract.get('isImport') && !this.props.contract.get('isPublic') ?
                  (<PlattValues
                    user={this.props.user}
                    import={this.props.import}
                    errCode={this.props.errCode}
                    currencies={this.props.currencies}
                    volumeUnits={this.props.volumeUnits}
                    values={this.props.values}
                    contract={this.props.contract}
                    searchParams={this.props.searchParams}
                    plattValues={this.props.plattValues}
                    airportsOptions={this.props.airportsOptions}
                    plattsOptions={this.props.plattsOptions}
                    isContractAirportsLoading={this.state.isContractAirportsLoading}
                    isContractAirportsError={this.state.isContractAirportsError}
                    isImportLoading={this.state.isImportLoading}
                    isImportError={this.state.isImportError}
                    airports={this.props.airports}
                    platts={this.props.platts}
                    valueDeleteDialogId={this.props.valueDeleteDialogId}
                    valueFormModalId={this.props.valueFormModalId}
                    onAdd={this.handleAddClick}
                    onDelete={this.handleDeleteClick}
                    onEdit={this.handleEditClick}
                  />)
                  :
                  (<ContractAirports
                    user={this.props.user}
                    import={this.props.import}
                    errCode={this.props.errCode}
                    currencies={this.props.currencies}
                    volumeUnits={this.props.volumeUnits}
                    values={this.props.values}
                    contract={this.props.contract}
                    searchParams={this.props.searchParams}
                    contractAirports={this.props.contractAirports}
                    airportsOptions={this.props.airportsOptions}
                    plattsOptions={this.props.plattsOptions}
                    isContractAirportsLoading={this.state.isContractAirportsLoading}
                    isContractAirportsError={this.state.isContractAirportsError}
                    isImportLoading={this.state.isImportLoading}
                    isImportError={this.state.isImportError}
                    airports={this.props.airports}
                    platts={this.props.platts}
                    contractAirportFormModalId={this.props.contractAirportFormModalId}
                    contractAirportDeleteDialogId={this.props.contractAirportDeleteDialogId}
                    contractAirportImportDialogId={this.props.contractAirportImportDialogId}
                    onAdd={this.handleAddClick}
                    onDelete={this.handleDeleteClick}
                    onEdit={this.handleEditClick}
                    onImport={this.handleImportClick}
                  />)}
              </Col>
            </Row>
          </PageLoaded>
        </PageLifcycle>
        <DefaultModal
          id={this.props.contractAirportFormModalId}
          bsSize="medium"
          onHide={this.closeModal}
          title='Add airport to the contract'>
          <ContractAirportForm
            onSave={this.handleSave}
            onCancel={this.closeModal}
            data={this.props.contractAirportForm}
            platts={this.props.platts}
            values={this.props.values}
            currencies={this.props.currencies}
            volumeUnits={this.props.volumeUnits}
            airports={this.props.airports}
            airportsOptions={this.props.airportsOptions}
            plattsOptions={this.props.plattsOptions}
          />
        </DefaultModal>
        <DefaultModal
          id={this.props.contractAirportImportDialogId}
          bsSize="large"
          onHide={this.handleCancelImportClick}
          title={'Import CSV'}>
          <CSVForm
            format="Icao;Platt Code;Price;Currency Code;Volume Unit;Delivery By;Payment Via;Effective Date"
            data={this.props.import}
            onSave={this.handleImportCSVClick}
            onCancel={this.handleCancelImportClick}
            onContinue={this.handleContinueClick}/>
        </DefaultModal>
        <YesNoDialog
          id={this.props.contractAirportDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick.bind(this, 'contractAirportDeleteDialogId')}/>
        <YesNoDialog
          id={this.props.valueDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeletePlattValueClick}
          onNo={this.handleCancelDeleteClick.bind(this, 'valueDeleteDialogId')}/>

        <DefaultModal id={this.props.valueFormModalId} onHide={this.closeModal} title={this.getValueModalTitle()}>
          <ValueForm
            onSave={this.handleSaveValue}
            onCancel={this.closeModal}
            data={this.props.valueDataForm}
            volumeUnits={this.props.volumeUnits}
            currencies={this.props.currencies}/>
        </DefaultModal>
      </div>
    )
  },

  getValueModalTitle() {
    return "Manage Price";
  },

  handleSaveValue(value) {
    // if(value.id)
    ValuesAction.update(value);
    ModalsAction.hideValueForm(this.props.valueFormModalId);
    // else
    // ValuesAction.create(this.props.platt.get('id'), value);
  },

  handleContinueClick() {
    ContractsAction.getContractById(this.props.contract.get('id'))
  },

  closeModal() {
    ModalsAction.hideContractAirportForm(this.props.contractAirportFormModalId);
    ModalsAction.hideValueForm(this.props.valueFormModalId);
  },

  handleImportCSVClick() {
    console.log('Current contract is ' + this.props.contract.get('id'));
    console.log('Import data is ' + this.props.import.get('data'));
    let textData = this.props.import.get('data') && this.props.import.get('data').get ? this.props.import.get('data').get('value') : this.props.import.get('data');
    ImportAction.importContractAirports(this.props.contract.get('id'), {data: textData})
  },

  handleCancelImportClick() {
    ModalsAction.hideImportModal(this.props.contractAirportImportDialogId)
  },

  handleImportClick() {
    ModalsAction.showImportModal(this.props.contractAirportImportDialogId);
  },

  handleSave(contractAirport) {
    if (contractAirport.id) {
      ContractsAction.updateContractAirport(this.props.contract.get('id'), contractAirport);
    } else {
      ContractsAction.addContractAirport(this.props.contract.get('id'), contractAirport);
    }
  },

  handleConfirmDeletePlattValueClick(payload) {
    ValuesAction.delete(payload.get('id'));
  },

  handleConfirmDeleteClick(payload) {
    ContractsAction.deleteContractAirport(this.props.contract.get('id'), payload.get('contractAirport').toJS());
  },

  handleCancelDeleteClick(str) {
    ModalsAction.hide(this.props[str]);
  },

  handleAddClick() {
    ModalsAction.showContractAirportForm(this.props.contractAirportFormModalId, {
      currencyCode:   'EUR',
      volumeUnitCode: 'L',
      effectiveDate:  this.props.contract.get('validFrom')
    });
  },

  handleEditClick(contractAirport) {
    ModalsAction.showContractAirportForm(this.props.contractAirportFormModalId, contractAirport)
  },

  handleDeleteClick(contractAirport) {
    ModalsAction.show(this.props.contractAirportDeleteDialogId, {
      contractAirport: contractAirport,
      message:         "Are you sure you want to delete this airport (" + contractAirport.airportIcao + ") from the contract ?"
    })
  },

});
