import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import moment from 'moment';
import {fromJS, Iterable, Map} from 'immutable';
import validator from '../utils/validator';

const FboFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    FboFormStore.setState({
      id:         Map({
        value:   null,
        isValid: null
      }),
      name:  Map({
        value:   null,
        isValid: null
      }),
      isSavable:  true,
      error:      ''
    })
  },

  isValid(data) {
    var isValid = false;
    switch (data.field) {
      case 'name':
        isValid = validator.required(data.value);
        break;
      default:
        isValid = true;
        break
    }
    return isValid;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionFbos.UPDATE_FBO:
      case Constants.ActionFbos.CREATE_FBO:
        FboFormStore.setState({isSavable: false});
        FboFormStore.emitChange();
        break;

      case Constants.ActionFbos.CREATE_FBO_SUCCESS:
      case Constants.ActionFbos.UPDATE_FBO_SUCCESS:
        FboFormStore.setState({
          isSavable: true,
          error:     false
        });
        FboFormStore.emitChange();
        break;

      case Constants.ActionFbos.UPDATE_FBO_ERROR:
      case Constants.ActionFbos.CREATE_FBO_ERROR:
        nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        };
        _.each(FboFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(FboFormStore.get(key))) {
            nextState[key] = FboFormStore.get(key).update('isValid', v => v = FboFormStore.isValid({
              field: key,
              value: FboFormStore.get(key).get('value')
            }));
          }
        });
        FboFormStore.setState(nextState)
        FboFormStore.emitChange();
        break;

      case Constants.ActionFboForm.FBO_FORM_CHANGE:
        var nextState = {};
        var isValid = FboFormStore.isValid(action)

        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        FboFormStore.setState(nextState)
        FboFormStore.emitChange();
        break;

      case Constants.ActionModals.SHOW_FBO_FORM_MODAL:
        FboFormStore.initialize();

        if (action.fbo) {
          var nextState = {};
          _.each(FboFormStore.state.toJS(), function (item, key) {
            if (!Iterable.isIterable(FboFormStore.get(key))) {
              return;
            }

            nextState[key] = FboFormStore.get(key).update('value', v => v = action.fbo[key] ? fromJS(action.fbo[key]) : null);
          });
          FboFormStore.setState(nextState);
        }

        FboFormStore.emitChange();
        break;

      case Constants.ActionModals.HIDE_FBO_FORM_MODAL:
        FboFormStore.initialize();
        FboFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default FboFormStore;
