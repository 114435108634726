import React from 'react';
import {Map} from 'immutable';
import {Button, ButtonToolbar, Col, Input, Row} from 'react-bootstrap';
import _ from 'lodash';

import {InlineAlert, Save} from '../../components';
import {FboFormAction} from '../../actions';

export default React.createClass({
  propTypes: {
    data: React.PropTypes.object
  },

  getDefaultProps() {
    return {
      data: Map()
    }
  },

  getInitialState() {
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  },

  render() {
    return (
      <form className="form-horizontal">
        <Row>
          <Input
            name='name'
            type='text'
            value={this.state.data.get('name').get('value')}
            label='Name'
            placeholder='Name *'
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            labelClassName='col-xs-3'
            wrapperClassName='col-xs-9'
            bsStyle={this.getValidClass(this.state.data.get('name').get('isValid'))}
            hasFeedback
          />
        </Row>
        <Row>
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{marginTop: 10}}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </Row>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')}/>
        </ButtonToolbar>
      </form>
    )
  },

  handleChange(e) {
    let {name, value} = e.target;
    var nextState = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    let {name, value} = e.target;
    FboFormAction.change(name, value)
  },

  getValidClass(isValid) {
    if (isValid === null) {
      return null
    }
    return isValid ? 'success' : 'error';
  },

  handleCancelClick() {
    this.props.onCancel();
  },

  handleSaveClick() {
    var _self = this;
    var data = {};
    _.each(_self.props.data.toJS(), function (prop, key) {
      data[key] = prop.value;
    });

    this.props.onSave(data);
  },
});
