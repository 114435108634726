import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { fromJS } from 'immutable';

const TaxCalculationTypesStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    TaxCalculationTypesStore.setState({
      calculationTypes : fromJS([
        {id: 'volume-based', name: 'Volume based'},
        {id: 'percentage-based', name: 'Percentage based'},
        {id: 'flat', name: 'Flat'}
      ])
    });
  }
});

export default TaxCalculationTypesStore;
