import React from 'react';
import {Map} from 'immutable';
import {PageHelper, PageLifcycle, PageLoaded} from '../PageLifecycle'
import {NoItemHelper, SubTitle} from '../../components'
import FboAirportList from './FboAirportList';

export default React.createClass({
  propTypes: {
    fboAirports: React.PropTypes.object
  },

  getDefaultProps() {
    return {
      fboAirports: Map()
    }
  },

  render() {
    return (
      <div>
        <SubTitle>Details</SubTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isFboAirportsError && !this.props.isFboAirportsLoading && this.props.fboAirports.size === 0}>
            <NoItemHelper onNew={this.props.onAdd} buttonLabel="Add airport" onImport={this.props.onImport}>
              <p>You are currently in the <strong>fbo</strong> details. There is no registered airport yet for this fbo.
              </p>
              <p>To get you started, you can add an airport. You can add as many airport as you like.</p>
              <p>Every airport can have multiple negociated indices as long as there is only one indice for a given day.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!(this.props.fboAirports.size === 0)}>
            <FboAirportList
              user={this.props.user}
              onNew={this.props.onAdd}
              fboAirports={this.props.fboAirports}
              fbo={this.props.fbo}
              searchParams={this.props.searchParams}
              airports={this.props.airports}
              onDelete={this.props.onDelete}
              onEdit={this.props.onEdit}
              onImport={this.props.onImport}
              fboAirportFormModalId={this.props.fboAirportFormModalId}
              fboAirportDeleteDialogId={this.props.fboAirportDeleteDialogId}
              fboAirportImportDialogId={this.props.fboAirportImportDialogId}
            />
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  }
});
