import React from 'react';
import { Button } from 'react-bootstrap';

import { ModalsAction } from '../../actions';
import { Activities } from '../../constants';
import { PermissionRequired, Action, ListActions } from '../../components';

export default React.createClass({
  render(){
    return (
      <ListActions>
        <PermissionRequired requiredRoles={Activities.READ_PLATTS} user={this.props.metadata.user}>
          <Action icon="search" to={'/platts/' + this.props.rowData.id} />
        </PermissionRequired>
        <PermissionRequired requiredRoles={Activities.WRITE_PLATTS} user={this.props.metadata.user}>
          <Action icon="edit" onClick={this.handleEditPlattClick} />
          <Action icon="remove" onClick={this.handleDeletePlattClick} />
        </PermissionRequired>
      </ListActions>
    )
  },

  handleEditPlattClick(){
    ModalsAction.showPlattForm(this.props.metadata.plattFormModalId, this.props.rowData);
  },

  handleDeletePlattClick(){
    ModalsAction.show(this.props.metadata.plattDeleteDialogId, { id: this.props.rowData.id, name: this.props.rowData.name });
  }
});