import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

export default React.createClass({
  mixins: [PureRenderMixin],
  render() {
    let classString = 'fa';
    let colorString = 'dark';

    if (this.props.fa) {
      classString += ' fa-' + this.props.fa;
    }

    if (this.props.fa === 'spinner') {
      classString += ' fa-spin';
    }

    if (this.props.color) {
      colorString = this.props.color;
    }

    return <i style={{color: colorString}} className={classString}></i>;
  }
});
