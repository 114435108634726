import React from 'react';

export default React.createClass({
  render(){
    if (!this.props.message)
      return <img style={this.getStyle()} src={'data:image/png;base64,' + this.props.base64} />
    else
      return <strong>{this.props.message}</strong>
  },

  getStyle(){
    return {
      maxWidth  : this.props.maxWidth ? this.props.maxWidth + 'px'  : '200px',
      maxHeight : this.props.maxHeight ? this.props.maxHeight + 'px' : '200px',
    }
  },
});
