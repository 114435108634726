import React from 'react';
import Radium from 'radium';
import ReactDom from 'react-dom';
import { Button, Input } from 'react-bootstrap';
import LinkedStateMixin  from 'react-addons-linked-state-mixin';
import { Palette } from '../../../constants';
import { InlineAlert } from '../../../components';

export default Radium(React.createClass({
  mixins:[LinkedStateMixin],
  getInitialState(){
    return {
      icao : ''
    }
  },

  getDefaultProps(){
    return {
      airportError: ''
    }
  },

  componentDidMount(){
    ReactDom.findDOMNode(this.refs.icaoInput).getElementsByTagName('input')[0].focus();
  },

  render(){
    return (
      <div>
        <div className='form-group'>
          <Input
            ref              ='icaoInput'
            name             ='icao'
            type             ='text' 
            valueLink        ={this.linkState('icao')} 
            placeholder      ='ICAO'
            bsStyle          ={!(this.props.airportError.length > 0) ? null : 'error'}
            onKeyDown        ={this.handleKeyPress}
            hasFeedback
          />
          <InlineAlert bsStyle="warning">{this.props.airportError}</InlineAlert>
        </div>
        <div className="text-right">
          <Button bsStyle="success" onClick={this.handleClick}>Add to trip</Button>
        </div>
      </div>)
  },

  handleClick(){
    this.props.onAdd(this.state.icao)
  },

  handleKeyPress(e){
    if(e.keyCode === 13) this.props.onAdd(this.state.icao)
  }
}))
