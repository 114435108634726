import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import ContractsApiUtils from '../utils/ContractsApiUtils'

const ContractAction = {
  fetchAll(isImport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionContracts.LOAD_CONTRACTS
    })

    ContractsApiUtils.getAll('/api/contracts?import=' + isImport.toString());
  },

  getContractById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionContracts.LOAD_CONTRACT
    })

    ContractsApiUtils.getOne('/api/contracts/' + id);
  },

  create(contract) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionContracts.CREATE_CONTRACT,
      contract   : contract
    })

    ContractsApiUtils.create('/api/contracts', contract);
  },

  update(contract) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionContracts.UPDATE_CONTRACT,
      contract   : contract
    })

    ContractsApiUtils.update('/api/contracts/' + contract.id, contract);
  },

  delete(contractId, isImport) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionContracts.DELETE_CONTRACT,
      contractId: contractId
    });

    ContractsApiUtils.delete('/api/contracts/' + contractId, isImport);
  },

  addContractAirport(contractId, contractAirport) {
    Dispatcher.handleViewAction({
      actionType      : Constants.ActionContracts.CREATE_CONTRACT_AIRPORT,
      contractId      : contractId,
      contractAirport : contractAirport
    })

    ContractsApiUtils.addContractAirport('/api/contracts/' + contractId + '/airports', contractAirport);
  },

  deleteContractAirport(contractId, contractAirport) {
    Dispatcher.handleViewAction({
      actionType      : Constants.ActionContracts.DELETE_CONTRACT_AIRPORT,
      contractId      : contractId,
      contractAirport : contractAirport
    })

    ContractsApiUtils.deleteContractAirport('/api/contracts/' + contractId + '/airports/' + contractAirport.id, contractAirport);
  },

  updateContractAirport(contractId, contractAirport) {
    Dispatcher.handleViewAction({
      actionType      : Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT,
      contractId      : contractId,
      contractAirport : contractAirport
    })

    ContractsApiUtils.updateContractAirport('/api/contracts/' + contractId + '/airports/' + contractAirport.id, contractAirport);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionContracts.SEARCH_CONTRACTS,
      field      : field,
      value      : value
    })
  }
};

export default ContractAction
