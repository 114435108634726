import React from 'react';
import  { Map } from 'immutable';

import { Activities, Constants } from '../../constants';
import {
  LoginStore,
  TaxesStore,
  TaxFormStore,
  AirportsStore,
  VolumeUnitsStore,
  CurrenciesStore,
  TaxTypesStore,
  TaxCalculationTypesStore
} from '../../stores';
import {
  AirportAction,
  VolumeUnitsAction,
  CurrenciesAction,
  ModalsAction
} from '../../actions';

import commonUtils from '../../utils/commonUtils';
import Airport from './Airport';

VolumeUnitsStore.initialize();
CurrenciesStore.initialize();
TaxTypesStore.initialize();
TaxesStore.initialize();
TaxCalculationTypesStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user                : null,
      requiredReadRoles   : Activities.READ_TAXES,
      requiredWriteRoles  : Activities.WRITE_TAXES,
      airport             : Map(),
      taxes               : Map(),
      units               : Map(),
      currencies          : Map(),
      taxDataForm         : Map(),
      isAirportLoading    : true,
      isAirportError      : false,
      airportErrCode      : null,
      isTaxesLoading      : true,
      isTaxesError        : false,
      taxesErrCode        : null,
      taxFormModalId      : commonUtils.guid(),
      taxTypes            : TaxTypesStore.get('taxTypes'),
      taxCalculationTypes : TaxCalculationTypesStore.get('calculationTypes'),
    }
  },

  componentDidMount() {
    //Initialize stores
    AirportsStore.initialize();

    //Add listeners for change operating in stores
    AirportsStore.addChangeListener(this.handleAirportsStoreChange);
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    TaxesStore.addChangeListener(this.handleTaxesStoreChange);
    TaxFormStore.addChangeListener(this.handleTaxFormStoreChange);
    VolumeUnitsStore.addChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.addChangeListener(this.handleCurrenciesStoreChange);

    //Trigger actions to get data from the stores (ajax)
    AirportAction.getAirportById(this.props.params.icao);
    TaxFormStore.initialize();
    CurrenciesAction.fetchAll();
    VolumeUnitsAction.fetchAll();
  },

  componentWillUnmount() {
    AirportsStore.removeChangeListener(this.handleAirportsStoreChange);
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    TaxesStore.removeChangeListener(this.handleTaxesStoreChange);
    TaxFormStore.removeChangeListener(this.handleTaxFormStoreChange);
    VolumeUnitsStore.removeChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.removeChangeListener(this.handleCurrenciesStoreChange);
  },


  render() {
    return <Airport {...this.state} />
  },

  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleAirportsStoreChange() {
    this.setState({
      airport         : AirportsStore.get('airports').size > 0 ? AirportsStore.get('airports').get(this.props.params.icao.toUpperCase()) : Map(),
      isAirportLoading: AirportsStore.get('loadingStatus') === Constants.LOADING,
      isAirportError  : AirportsStore.get('loadingStatus') === Constants.ERROR,
      airportErrCode  : AirportsStore.get('errCode'),
      user: LoginStore.get('user')
    })
  },

  handleTaxesStoreChange() {
    ModalsAction.hideTaxForm(this.state.taxFormModalId);
    this.setState({
      taxes            : TaxesStore.get('taxes'),
      isTaxesLoading   : TaxesStore.get('loadingStatus') === Constants.LOADING,
      isTaxesError     : TaxesStore.get('loadingStatus') === Constants.ERROR,
      taxesErrCode     : TaxesStore.get('errCode'),
      user: LoginStore.get('user')
    })
  },

  handleTaxFormStoreChange() {
    this.setState({ taxDataForm: TaxFormStore.state,
      user: LoginStore.get('user') })
  },

  handleVolumeUnitsStoreChange(){
    this.setState({ units : VolumeUnitsStore.get('units'),
    user: LoginStore.get('user') })
  },

  handleCurrenciesStoreChange(){
    this.setState({ currencies : CurrenciesStore.get('currencies'),
    user: LoginStore.get('user') })
  }
});
