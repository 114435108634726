import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';
import _ from 'lodash';

const FuelRequestsStore = assign({}, BaseStore, {
  initialize() {
    FuelRequestsStore.setState({
      fuelRequests:  Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING,
      errCode:       null
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionFuelRequests.LOAD_FUEL_REQUESTS_ERROR:
        FuelRequestsStore.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        FuelRequestsStore.emitChange();
        break;
      case Constants.ActionFuelRequests.LOAD_FUEL_REQUESTS_SUCCESS:
        FuelRequestsStore.setState({
          fuelRequests:  fromJS(action.data.entities.fuelRequests),
          loadingStatus: Constants.LOADED
        });
        FuelRequestsStore.emitChange();
        break;
      case Constants.ActionFuelRequests.SEARCH_FUEL_REQUESTS:
        if (_.isUndefined(FuelRequestsStore.get('search').get(action.field))) {
          var nextData = FuelRequestsStore.get('search').set(action.field, action.value);
        } else {
          var nextData = FuelRequestsStore.get('search').update(action.field, v => v = action.value);
        }
        FuelRequestsStore.setState({search: nextData});
        FuelRequestsStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default FuelRequestsStore;
