import moment from 'moment'

const commonUtils = {
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return setTimeout(function() { // Run after dispatcher has finished
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
             s4() + '-' + s4() + s4() + s4();
    }, 0);
  },

  blobToUrl(blob) {
    return window.URL.createObjectURL(blob);
  },

  // converts blob to base64
  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function() {
      var dataUrl = reader.result;
      var base64 = dataUrl.split(',')[1];
      callback(base64);
    };
    reader.readAsDataURL(blob);
  },

  encode(bufferView) {
    var blob = new Blob([bufferView]);
    var urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL( blob );
  }
}

export default commonUtils