import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import PlattsApiUtils from '../utils/PlattsApiUtils'

const PlattAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.LOAD_PLATTS
    })

    PlattsApiUtils.getAll('/api/platts');
  },

  getPlattById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.LOAD_PLATT
    })

    PlattsApiUtils.getOne('/api/platts/' + id);
  },

  create: function(platt) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.CREATE_PLATT,
      platt: platt
    })

    PlattsApiUtils.create('/api/platts', platt);
  },

  update: function(platt) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.UPDATE_PLATT,
      platt: platt
    })

    PlattsApiUtils.update('/api/platts/' + platt.id, platt);
  },

  delete: function(plattId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.DELETE_PLATT,
      plattId: plattId
    })

    PlattsApiUtils.delete('/api/platts/' + plattId);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.SEARCH_PLATTS,
      field: field,
      value: value
    })
  }
};

export default PlattAction