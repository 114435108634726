import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import FuelRequestApiUtils from '../utils/FuelRequestApiUtils'

const FuelRequestsAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFuelRequests.LOAD_FUEL_REQUESTS
    });

    FuelRequestApiUtils.getAll('/api/fuel_request/list');
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionFuelRequests.SEARCH_FUEL_REQUESTS,
      field:      field,
      value:      value
    })
  }
};

export default FuelRequestsAction
