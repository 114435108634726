import moment from 'moment'

const formatter = {
  format: {
    date: (date) => {
      return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : date;
    },

    decimal: (decimal) => {
      return Math.round(decimal * 100) / 100
    }
  }
}

export default formatter