import Dispatcher from '../core/Dispatcher';
import { EventEmitter } from 'events';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import  { Map, fromJS } from 'immutable';

const ValuesStore = assign({}, BaseStore, {
  initialize() {
    ValuesStore.setState({
      values        : Map(),
      loadingStatus : Constants.LOADING
    });
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;
    switch(action.actionType) {
      case Constants.ActionValues.LOAD_VALUES:
        ValuesStore.setState({ loadingStatus: Constants.LOADING });
        ValuesStore.emitChange();
        break;
      case Constants.ActionValues.LOAD_VALUES_ERROR:
        ValuesStore.setState({ loadingStatus: Constants.ERROR });
        ValuesStore.emitChange();
        break;
      case Constants.ActionValues.LOAD_VALUES_SUCCESS:
      case Constants.ActionValues.LOAD_VALUE_SUCCESS:
      case Constants.ActionContracts.LOAD_CONTRACTS_SUCCESS:
      case Constants.ActionContracts.LOAD_CONTRACT_SUCCESS:
      case Constants.ActionPlatts.LOAD_PLATTS_SUCCESS:
      case Constants.ActionPlatts.LOAD_PLATT_SUCCESS:
      case Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_SUCCESS:
        //We merge the value since the values come from different sources (contract and platts)
        ValuesStore.setState({
          values        : ValuesStore.get('values').merge(fromJS(action.data.entities.values)),
          loadingStatus : Constants.LOADED
        });
        ValuesStore.emitChange();
        break;
      case Constants.ActionValues.CREATE_VALUE_SUCCESS:
        var id       = action.data.result.values;
        var nextData = ValuesStore.get('values').set(String(id), Map(action.data.entities.values[id]));
        ValuesStore.setState({ values : nextData });
        ValuesStore.emitChange();
        break;
      case Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT_SUCCESS:
        var values            = action.data.entities.values;

        _.each(values, (value, id) => {
          var nextData = ValuesStore.get('values');
          ValuesStore.setState({
            values: nextData.update(String(id), v => v = Map(value))
          })
        });

        ValuesStore.emitChange();
        break;
      case Constants.ActionValues.UPDATE_VALUE_SUCCESS:
        var values            = action.data.entities.values;

        _.each(values, (value, id) => {
          var nextData = ValuesStore.get('values');
          ValuesStore.setState({
            values: nextData.update(String(id), v => v = Map(value))
          })
        });

        ValuesStore.emitChange();
        // not ideal...
        window.location.reload();
        break;
      case Constants.ActionValues.DELETE_VALUE_SUCCESS:
        var id       = action.data.result.values;

        var nextData = ValuesStore.get('values').delete(String(id));
        ValuesStore.setState({ values : nextData });
        ValuesStore.emitChange();
        // not ideal...
        window.location.reload();
        break;
      }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ValuesStore;
