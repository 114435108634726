import React from 'react';
import { Logo } from '../../components';

export default class LogoList extends React.Component {
  constructor(props) {
    super(props);
    this.displayLogo = this.displayLogo.bind(this);
  }

  displayLogo() {
    if(this.props.rowData.logo)
      return <Logo maxHeight="70" maxWidth="70" base64={this.props.rowData.logo} />
    else
      return <span />
  }

  render(){
    return this.displayLogo();
  }
}
