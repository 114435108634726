import React from 'react';
import ReactDom from 'react-dom';
import _ from 'lodash';
import { Map } from 'immutable';
import {TransitionMotion, spring} from 'react-motion';
import { Button, Label } from 'react-bootstrap';
import { ModalsAction, CalculatorAction } from '../../../actions';
import { Icon } from '../../../components';
import AddAirport from './AddAirport';
import LegendTitle from './LegendTitle';
import Airport from './Airport'

export default React.createClass({
  getDefaultProps(){
    return {
      trip: Map({}),
      first: 0
    }
  },

  componentWillReceiveProps(nextProps){
    let keys = Object.keys(nextProps.trip);
    this.setState({
      first: _.first(keys)
    })
  },

  getStyles() {
    let configs = {};
    _.each(this.props.trip, icao => {
      configs[icao] = {
        opacity : spring(1),
        width   : spring(100),
        height  : spring(100),
        icao    : icao
      };
    });
    return configs;
  },

  willEnter(icao, style) {
    return {
      opacity : spring(0),
      width   : spring(0),
      height  : spring(0),
      icao    : icao
    };
  },

  willLeave(key, style) {
    return {
      opacity : spring(0),
      width   : spring(0),
      height  : spring(0),
      icao    : style.icao
    };
  },

  hasArrow(key){
    return Number(key) > this.state.first;
  },

  getNrLeg(){
    let nbrAirport = this.props.trip ? this.props.trip.length : 0;
    return nbrAirport > 1 ? nbrAirport - 1 : 0;
  },

  getTitle(){
    if(this.props.trip.length === 0) {
      return <span><span style={{fontWeight: 800}}>Step 2 :</span> Please add airport to the trip</span>
    } else {
      return <span>{'Trip (' + this.getNrLeg() + ' legs)'}</span>
    }
  },

  render() {
    if(this.props.isPrivate == null) return null
    return (
      <div>
        <LegendTitle>
          {this.getTitle()}
        </LegendTitle>
        <div style={{minHeight: '106px'}}>
          <TransitionMotion
            styles={this.getStyles()}
            willEnter={this.willEnter}
            willLeave={this.willLeave}>
            {interpolatedStyles =>
              <div style={{margin: 'auto'}}>
                {_.map(interpolatedStyles, (airport, key) => {
                  const { icao, ...style } = airport;
                  return (
                    <div key={key} onClick={this.removeAirport.bind(this, icao)}
                      style  ={{
                        width    : style.width + 'px',
                        height   : '100px',
                        opacity  : style.opacity,
                        display  : 'inline-block',
                        overflow : 'hidden',
                        cursor   : 'pointer'
                      }}>
                      <Airport hasArrow={this.hasArrow(key)}>{icao}</Airport>
                    </div>
                  );
                })}
                <AddAirport 
                  onAdd     ={this.addAirport}
                  isVisible ={this.props.trip.length < 6} />
              </div>
            }
          </TransitionMotion>
        </div>
        <Icon fa="info" /> Click on the plus button to add an airport to the trip. Just click on it to remove it.
      </div>
    );
  },

  addAirport(){
    ModalsAction.showCalculatorAirportModal(this.props.modalId)
  },

  removeAirport(key){
    CalculatorAction.removeAirport(key)
  }
})
