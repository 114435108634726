
export default {
  PRIMARY              : "#A59C7F",
  SECONDARY            : "#FFFDF1",
  HOVER                : "#BEB99A",
  BACKGROUND           : "#4E4941",
  BACKGROUND_SECONDARY : "#4E626F",
  DANGER               : "#C9302C",
  DANGER_LOGIN         : "#FF6161",
  LIGHT_GREY           : "#E8E8E8",
  MID_GREY             : "#B7B7B7",
  GREY_LINE            : "#DADADA",
  WHITE                : "#FFFFFF",
  MAGENTA              : "#e91e63"
}
