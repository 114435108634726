import React from 'react';
import _ from 'lodash';
import PureRenderMixin from 'react-addons-pure-render-mixin';

export const PageLifcycle = React.createClass({
  mixins: [PureRenderMixin],
  render() {
    return <div>{this.props.children}</div>
  }
});

export const PageLoading = React.createClass({
  mixins: [PureRenderMixin],
  propTypes: { isDisplayed : React.PropTypes.bool },

  getDefaultProps(){
    return { isDisplayed : false }
  },

  render() {
    return this.props.isDisplayed ? (
      <div>{this.props.children}</div>
    ) : (
      <span />
    )
  }
})

export const PageError = React.createClass({
  mixins: [PureRenderMixin],
  propTypes: { isDisplayed : React.PropTypes.bool },

  getDefaultProps(){
    return { isDisplayed : false }
  },

  render() {
    return this.props.isDisplayed ? (
      <div>{this.props.children}</div>
    ) : (
      <span />
    )
  }
})

export const PageHelper = React.createClass({
  mixins: [PureRenderMixin],
  propTypes: { isDisplayed : React.PropTypes.bool },

  getDefaultProps(){
    return { isDisplayed : false }
  },

  render() {
    return this.props.isDisplayed ? (
      <div>{this.props.children}</div>
    ) : (
      <span />
    )
  }
})

export const PageLoaded = React.createClass({
  mixins: [PureRenderMixin],
  propTypes: { isDisplayed : React.PropTypes.bool },

  getDefaultProps(){
    return { isDisplayed : false }
  },

  render() {
    return this.props.isDisplayed ? (
      <div>{this.props.children}</div>
    ) : (
      <span />
    )
  }
})