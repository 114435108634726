import React from 'react';
import _ from 'lodash';
import {List, Map} from 'immutable';
import {Constants} from '../../constants';

import {
  AirportsStore,
  FboAirportFormStore,
  FboAirportsStore,
  FbosStore,
  LoginStore,
  SupplierAirportsStore
} from '../../stores';

import {AirportAction, FbosAction, ModalsAction} from '../../actions';

import commonUtils from '../../utils/commonUtils';
import Fbo from './Fbo'

FbosStore.initialize();
AirportsStore.initialize();
FboAirportsStore.initialize();
FboAirportFormStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:                     null,
      fbo:                      Map(),
      airports:                 Map(),
      fboAirports:              Map(),
      searchParams:             Map(),
      airportsOptions:          List(),
      errCode:                  null,
      isFboAirportsLoading:     true,
      isFboAirportsError:       false,
      isFboLoading:             true,
      isFboError:               false,
      isAirportsLoading:        true,
      isAirportsError:          false,
      fboAirportForm:           Map(),
      fboAirportFormModalId:    commonUtils.guid(),
      fboAirportDeleteDialogId: commonUtils.guid(),
      fboAirportImportDialogId: commonUtils.guid(),
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    FbosStore.addChangeListener(this.handleFbosStoreChange);
    AirportsStore.addChangeListener(this.handleAirportsStoreChange);
    FboAirportsStore.addChangeListener(this.handleFboAirportsStoreChange);
    FboAirportFormStore.addChangeListener(this.handleFboAirportFormStoreChange);
    FbosAction.getFboById(this.props.params.id);
    AirportAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    FbosStore.removeChangeListener(this.handleFbosStoreChange);
    AirportsStore.removeChangeListener(this.handleAirportsStoreChange);
    FboAirportsStore.removeChangeListener(this.handleFboAirportsStoreChange);
    FboAirportFormStore.removeChangeListener(this.handleFboAirportFormStoreChange);
  },

  render() {
    return <Fbo {...this.state} />;
  },

  hideModals() {
    ModalsAction.hideFboAirportForm(this.state.fboAirportFormModalId);
    ModalsAction.hide(this.state.fboAirportDeleteDialogId);
  },

  handleFbosStoreChange() {
    console.log('handleFbosStoreChange for: ' + this.props.params.id);
    this.setState({
      fbo:          FbosStore.get('fbos').size > 0 ? FbosStore.get('fbos').get(this.props.params.id) : Map(),
      isFboLoading: FbosStore.get('loadingStatus') === Constants.LOADING,
      isFboError:   FbosStore.get('loadingStatus') === Constants.ERROR,
      errCode:      FbosStore.get('errCode'),
      searchParams: FbosStore.get('search'),
      user: LoginStore.get('user')
    });
  },

  handleAirportsStoreChange() {
    console.log('handleAirportsStoreChange - status is ' + AirportsStore.get('loadingStatus'));
    this.setState({
      airports:          AirportsStore.get('airports'),
      airportsOptions:   this.parseAirportsToOptions(AirportsStore.get('airports')),
      isAirportsLoading: AirportsStore.get('loadingStatus') === Constants.LOADING,
      isAirportsError:   AirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleFboAirportsStoreChange() {
    console.log('handleFboAirportsStoreChange - status is ' + FboAirportsStore.get('loadingStatus'));
    this.hideModals();
    this.setState({
      fboAirports:          FboAirportsStore.get('fboAirports'),
      isFboAirportsLoading: FboAirportsStore.get('loadingStatus') === Constants.LOADING,
      isFboAirportsError:   FboAirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleFboAirportFormStoreChange() {
    this.setState({fboAirportForm: FboAirportFormStore.state,
      user: LoginStore.get('user')});
  },

  parseAirportsToOptions(airports) {
    let options = List();
    _.each(airports.toJS(), (airport) => {
      options = options.push({
        value: airport.icao,
        label: airport.icao + " - " + airport.name,
        data:  airport
      })
    });

    return options
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  }
});
