import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const airport = new Schema('airports', { idAttribute: 'icao' });
  const tax     = new Schema('taxes');

  airport.define({
    Taxes: arrayOf(tax)
  })

  return normalize(data, {
    airports: _.isArray(data.airports) ? arrayOf(airport) : airport
  });
}

const AirportsApiUtils = {
  getAll: function(url) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionAirports.LOAD_AIRPORTS_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionAirports.LOAD_AIRPORTS_ERROR,
          err: err
        });
      })
  },

  getOne: function(url) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionAirports.LOAD_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionAirports.LOAD_AIRPORT_ERROR,
          err: err
        });
      })
  }
}

export default AirportsApiUtils
