import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { Button, ButtonToolbar, Row } from 'react-bootstrap';
import { FadeInAnimation } from '../../components';

export default React.createClass({
  mixins: [PureRenderMixin],
  propTypes: {
    onNew : React.PropTypes.func,
  },

  getImportButton(){
    return this.props.onImport ? <span> or <Button bsStyle='info' onClick={this.props.onImport}>Import from csv</Button></span> : null;
  },

  render() {
    return (
      <FadeInAnimation>
        <Row style={this.getStyle()}>
          <div style={{width: "70%", margin: "auto", backgroundColor: "#FFF3DB", padding: "48px", borderRadius: "10px", marginTop:"20px", border: "1px solid #E6D4AF"}}>
            {this.props.children}
            <p>
              <Button bsStyle='success' onClick={this.props.onNew}>{this.props.buttonLabel}</Button>
              {this.getImportButton()}
            </p>
          </div>
        </Row>
      </FadeInAnimation>
    )
  },

  getStyle(){
    return {
      fontSize   : "21px",
      fontWeight : "200"
    }
  }
});
