import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import {fromJS, Iterable, Map} from 'immutable';
import validator from '../utils/validator';

const SupplierAirportFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    SupplierAirportFormStore.setState({
      id:          Map({
        value:   null,
        isValid: null
      }),
      icao: Map({
        value:   null,
        isValid: null
      }),
      email:       Map({
        value:   null,
        isValid: null
      }),
      isSavable:   true,
      error:       ''
    })
  },

  isValid(data) {
    let isValid = false;
    switch (data.field) {
      case 'icao':
        isValid = validator.required(data.value);
        break;
      default:
        isValid = true;
        break
    }

    return isValid;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    let action = payload.action;
    let nextState = {};

    switch (action.actionType) {
      case Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT:
      case Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT:
        SupplierAirportFormStore.setState({isSavable: false});
        SupplierAirportFormStore.emitChange();
        break;

      case Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT_SUCCESS:
      case Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT_SUCCESS:
        SupplierAirportFormStore.setState({
          isSavable: true,
          error:     false
        });
        SupplierAirportFormStore.emitChange();
        break;

      case Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT_ERROR:
      case Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT_ERROR:
        nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        };
        _.each(SupplierAirportFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(SupplierAirportFormStore.get(key))) {
            nextState[key] = SupplierAirportFormStore.get(key).update('isValid', v => v = SupplierAirportFormStore.isValid({
              field: key,
              value: SupplierAirportFormStore.get(key).get('value')
            }));
          }
        });
        SupplierAirportFormStore.setState(nextState);
        SupplierAirportFormStore.emitChange();
        break;

      case Constants.ActionSupplierAirportForm.SUPPLIER_AIRPORT_FORM_CHANGE:
        let isValid = SupplierAirportFormStore.isValid(action);

        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        SupplierAirportFormStore.setState(nextState);
        SupplierAirportFormStore.emitChange();
        break;
      case Constants.ActionModals.SHOW_SUPPLIER_AIRPORT_FORM_MODAL:
        if (action.supplierAirport) {
          nextState["id"] = SupplierAirportFormStore.get("id").update('value', v => v = action.supplierAirport.id ? fromJS(action.supplierAirport.id) : null);
          nextState["icao"] = SupplierAirportFormStore.get("icao").update('value', v => v = action.supplierAirport.icao ? fromJS(action.supplierAirport.icao) : null);
          nextState["email"] = SupplierAirportFormStore.get("email").update('value', v => v = action.supplierAirport.email ? fromJS(action.supplierAirport.email) : null);

          SupplierAirportFormStore.setState(nextState);
        }
        SupplierAirportFormStore.emitChange();
        break;
      case Constants.ActionModals.HIDE_SUPPLIER_AIRPORT_FORM_MODAL:
        SupplierAirportFormStore.initialize();
        SupplierAirportFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default SupplierAirportFormStore;
