import React from 'react';
import moment from 'moment';

const ISODateTime = ({ value, format }) => {
  return (
    <span>
      {moment(value).format(format || 'YYYY-MM-DD HH:mm')}
    </span>
  );
};

export default ISODateTime;
