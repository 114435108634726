import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import SuppliersApiUtils from '../utils/SuppliersApiUtils'

const SupplierAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionSuppliers.LOAD_SUPPLIERS
    });

    SuppliersApiUtils.getAll('/api/suppliers');
  },

  getSupplierById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionSuppliers.LOAD_SUPPLIER
    });

    SuppliersApiUtils.getOne('/api/suppliers/' + id);
  },

  create: function (supplier) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionSuppliers.CREATE_SUPPLIER,
      supplier:   supplier
    });

    SuppliersApiUtils.create('/api/suppliers', supplier);
  },

  update: function (supplier) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionSuppliers.UPDATE_SUPPLIER,
      supplier:   supplier
    });
    SuppliersApiUtils.update('/api/suppliers/' + supplier.id, supplier);
  },

  delete: function (supplierId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionSuppliers.DELETE_SUPPLIER,
      supplierId: supplierId
    });

    SuppliersApiUtils.delete('/api/suppliers/' + supplierId);
  },

  addSupplierAirport(supplierId, supplierAirport) {
    Dispatcher.handleViewAction({
      actionType:      Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT,
      supplierId:      supplierId,
      supplierAirport: supplierAirport
    });

    SuppliersApiUtils.addSupplierAirport('/api/suppliers/' + supplierId + '/airports', supplierAirport);
  },

  deleteSupplierAirport(supplierId, supplierAirport) {
    Dispatcher.handleViewAction({
      actionType:      Constants.ActionSuppliers.DELETE_SUPPLIER_AIRPORT,
      supplierId:      supplierId,
      supplierAirport: supplierAirport
    });

    SuppliersApiUtils.deleteSupplierAirport('/api/suppliers/' + supplierId + '/airports/' + supplierAirport.id, supplierAirport);
  },

  updateSupplierAirport(supplierId, supplierAirport) {
    Dispatcher.handleViewAction({
      actionType:      Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT,
      supplierId:      supplierId,
      supplierAirport: supplierAirport
    });

    SuppliersApiUtils.updateSupplierAirport('/api/suppliers/' + supplierId + '/airports/' + supplierAirport.id, supplierAirport);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionSuppliers.SEARCH_SUPPLIER_AIRPORT,
      field:      field,
      value:      value
    })
  }
};

export default SupplierAction
