import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import AirportsStore from './AirportsStore';
import ContractAirportsStore from './ContractAirportsStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';
import _ from 'lodash'
import {ImportsStore} from "./index";

const ContractsStore = assign({}, BaseStore, {
  initialize() {
    ContractsStore.setState({
      contracts:     Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING,
      errCode:       null
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;
    switch (action.actionType) {
      case Constants.ActionContracts.LOAD_CONTRACTS_ERROR:
      case Constants.ActionContracts.LOAD_CONTRACT_ERROR:
        ContractsStore.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.LOAD_CONTRACT_SUCCESS:
      case Constants.ActionContracts.LOAD_CONTRACTS_SUCCESS:
        Dispatcher.waitFor[AirportsStore.dispatchToken];
        ContractsStore.setState({
          contracts:     fromJS(action.data.entities.contracts ? action.data.entities.contracts : {}),
          loadingStatus: Constants.LOADED
        })
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.LOAD_CONTRACT_ERROR:
        ContractsStore.setState({loadingStatus: Constants.ERROR})
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.CREATE_CONTRACT_SUCCESS:
        var id = action.data.result.contracts;
        var nextData = ContractsStore.get('contracts').set(String(id), fromJS(action.data.entities.contracts[id]));
        ContractsStore.setState({contracts: nextData});
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.SEARCH_CONTRACTS:
        if (_.isUndefined(ContractsStore.get('search').get(action.field))) {
          var nextData = ContractsStore.get('search').set(action.field, action.value);
        } else {
          var nextData = ContractsStore.get('search').update(action.field, v => v = action.value);
        }
        ContractsStore.setState({search: nextData})
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.UPDATE_CONTRACT_SUCCESS:
        var id = action.data.result.contracts;
        var nextData = ContractsStore.get('contracts');
        ContractsStore.setState({
          contracts: nextData.update(String(id), v => v = fromJS(action.data.entities.contracts[id]))
        })
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.DELETE_CONTRACT_SUCCESS:
        let id = action.data.result.contracts, nextData;
        nextData = ContractsStore.get('contracts').delete(String(id));
        ContractsStore.setState({contracts: nextData});
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_SUCCESS:
        Dispatcher.waitFor([ContractAirportsStore.dispatchToken]);
        var contractAirportId = action.data.result.contractAirports;
        var contractId = action.data.entities.contractAirports[contractAirportId].contractId;

        var nextData = ContractsStore.get('contracts').update(String(contractId), v =>
          v = v.update("ContractAirports", ca => ca = ca.push(contractAirportId))
        );

        ContractsStore.setState({contracts: nextData});
        ContractsStore.emitChange();
        break;
      case Constants.ActionContracts.DELETE_CONTRACT_AIRPORT_SUCCESS:
        var contractAirportId = action.data.result.contractAirports;
        var contractId = action.data.entities.contractAirports[contractAirportId].contractId;

        var nextData = ContractsStore.get('contracts').update(String(contractId), v =>
          v = v.update("ContractAirports", ca =>
            ca = ca.delete(ca.indexOf(contractAirportId))
          ));
        ContractsStore.setState({contracts: nextData});
        ContractsStore.emitChange();
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ContractsStore;
