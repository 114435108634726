import keyMirror from 'key-mirror';

export default {
  'RESSOURCE_NOT_FOUND' : "The ressource that you are looking for doesn't exists.",
  'UNAUTHORIZE_ACCESS'  : "You are not allowed to perform this action.",
  'SERVER_ERROR'        : "Internal server error.",
  'BAD_CREDENTIALS'     : "Password or login inccorect.",
  'REQUEST_TIMEOUT'     : "Request time out.",
  'ERROR_SERVER_PLATTS' : "Error occurred when loading platts.",
}
