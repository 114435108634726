import React from 'react';
import {Map} from 'immutable';
import {ContractFormStore, ContractsStore, ImportFormStore, LoginStore, SuppliersStore} from '../../stores';
import {ContractsAction, ModalsAction, SupplierAction} from '../../actions';
import {Constants} from '../../constants';
import commonUtils from '../../utils/commonUtils';
import createDeepEqualSelector from '../../utils/createDeepEqualSelector';
import Contracts from './Imports';

ContractsStore.initialize();

const getContracts = createDeepEqualSelector(
  props => props,
  ({contracts, suppliers}) => {
    return contracts.map(c => ({
      ...c,
      supplierName: suppliers[c.supplierId] && suppliers[c.supplierId].name,
      nbrAirports:  (c.isPublic ? c.nbrAirports : c.valuesNbr) + 0,
    }));
  }
);

export default React.createClass({
  getInitialState() {
    return {
      contracts:              Map(),
      searchParams:           Map(),
      isContractsLoading:     true,
      isContractsError:       false,
      isSuppliersLoading:     true,
      isSuppliersError:       false,
      errCode:                null,
      contractFormModalId:    commonUtils.guid(),
      contractDeleteDialogId: commonUtils.guid(),
      fuelImportDialogId:     commonUtils.guid(),
      droppedFile:            null,
      message:                "",
      contractDataForm:       Map(),
      suppliers:              Map(),
      user:                   null,
      supplierId:             null,
      importType:             null,
      newContractId:          null,
      effectiveDate:          null,
      validUntil:             null,
      rowsToSkip:             0
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    SuppliersStore.addChangeListener(this.handleSupplierStoreChange);
    ContractsStore.addChangeListener(this.handleContractStoreChange);
    ContractFormStore.addChangeListener(this.handleContractFormStoreChange);
    ImportFormStore.addChangeListener(this.handleImportFormStoreChange);
    SupplierAction.fetchAll();
    ContractsAction.fetchAll(true);
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    SuppliersStore.removeChangeListener(this.handleSupplierStoreChange);
    ContractsStore.removeChangeListener(this.handleContractStoreChange);
    ContractFormStore.removeChangeListener(this.handleContractFormStoreChange);
    ImportFormStore.removeChangeListener(this.handleImportFormStoreChange);
  },

  render() {
    const contracts = getContracts({
      contracts: Object.values(this.state.contracts.toJS()),
      suppliers: this.state.suppliers.toJS(),
    });

    return (
      <Contracts {...this.state} contracts={contracts}/>)
  },

  handleContractStoreChange() {
    ModalsAction.hideContractForm(this.state.contractFormModalId);
    ModalsAction.hide(this.state.contractDeleteDialogId);

    this.setState({
      contracts:          ContractsStore.get('contracts'),
      searchParams:       ContractsStore.get('search'),
      errCode:            ContractsStore.get('errCode'),
      isContractsLoading: ContractsStore.get('loadingStatus') === Constants.LOADING,
      isContractsError:   ContractsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    })
  },

  handleSupplierStoreChange() {
    this.setState({
      suppliers:          SuppliersStore.get('suppliers'),
      isSuppliersLoading: SuppliersStore.get('loadingStatus') === Constants.LOADING,
      isSuppliersError:   SuppliersStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    })
  },

  handleContractFormStoreChange() {
    this.setState({
      contractDataForm: ContractFormStore.state,
      user: LoginStore.get('user')
    })
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleImportFormStoreChange() {
    console.log('[Import] ImportFormStore.get(\'supplierId\') is ' + ImportFormStore.get('supplierId'));
    console.log('[Import] ImportFormStore.get(\'newContractId\') is ' + ImportFormStore.get('newContractId'));
    console.log('[Import] ImportFormStore.get(\'effectiveDate\') is ' + ImportFormStore.get('effectiveDate'));
    console.log('[Import] ImportFormStore.get(\'validUntil\') is ' + ImportFormStore.get('validUntil'));
    console.log('[Import] ImportFormStore.get(\'importType\') is ' + ImportFormStore.get('importType'));

    this.setState({
      effectiveDate:   ImportFormStore.get('effectiveDate'),
      validUntil:      ImportFormStore.get('validUntil'),
      newContractId:   ImportFormStore.get('newContractId'),
      supplierId:      ImportFormStore.get('supplierId'),
      importType:      ImportFormStore.get('importType'),
      import:          ImportFormStore.state,
      isImportLoading: ImportFormStore.get('loadingStatus') === Constants.LOADING,
      isImportError:   ImportFormStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  }

});
