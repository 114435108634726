import React from 'react';

export const PanelHeader = React.createClass({
  render(){
    return(
      <div>
        {this.props.children}
      </div>
    )
  }
});

export const PanelContent = React.createClass({
  render(){
    return(
      <div>
        {this.props.children}
      </div>
    )
  }
});

export const Panel = React.createClass({
    getInitialState() {
    return {
      options : this.props.options,
      isHover : false
    }
  },

  toggleHover(){
    this.setState({isHover: !this.state.isHover})
    this.props.onHover(this.props.id, !this.state.isHover);
  },

  getStyle(){
    return this.state.isHover ? {
      borderRadius     : "3px",
      padding          : "10px",
      borderBottom     : "6px solid #E91E63",
      marginBottom     : "20px",
      transition       : "color .5s ease-in-out, opacity .25s ease-in-out",
      MozTransition    : "color .5s ease-in-out, opacity .25s ease-in-out",
      WebkitTransition : "color .5s ease-in-out, opacity .25s ease-in-out",
      opacity          : 1
    } : {
      borderRadius     : "3px",
      padding          : "10px",
      borderBottom     : "6px solid #CACACA",
      marginBottom     : "20px",
      transition       : "color .5s ease-in-out, opacity .25s ease-in-out",
      MozTransition    : "color .5s ease-in-out, opacity .25s ease-in-out",
      WebkitTransition : "color .25s ease-in-out, opacity .25s ease-in-out",
      opacity          : 0.7
    };
  },

  getClasses(){
    return this.state.isHover ? "shadow-z-1" : "shadow-z-1"
  },

  render(){
    return(
      <div
        className    ="col-xs-6"
        style        ={{display: "inline-block"}}>
        <div
          onMouseOver  ={this.toggleHover}
          onMouseOut   ={this.toggleHover}
          className    ={this.getClasses()}
          style        ={this.getStyle()}>
          {this.props.children}
        </div>
      </div>
    )
  }
});