import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { TaxesAction, ModalsAction } from '../../actions';
import { PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded } from '../PageLifecycle'
import { PageTitle, Oops, Loader, DefaultModal }  from '../../components/'

import AirportDetails from './AirportDetails';
import AirportTaxes from './AirportTaxes';
import TaxForm from '../taxes/TaxForm'

export default React.createClass({
  propTypes: {
    airport             : React.PropTypes.object,
    taxes               : React.PropTypes.object,
    units               : React.PropTypes.object,
    currencies          : React.PropTypes.object,
    taxDataForm         : React.PropTypes.object,
    taxFormModalId      : React.PropTypes.number,
    taxTypes            : React.PropTypes.object,
    taxCalculationTypes : React.PropTypes.object
  },

  render() {
    return (
      <div className="data-table">
        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={(this.props.isAirportLoading || this.props.isTaxesLoading) && (!this.props.isAirportError && !this.props.isTaxesError)}>
            <Loader>
              Fetching airport from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isAirportError || this.props.isTaxesError}>
            <Oops errorCode={this.props.airportErrCode} />
          </PageError>

        {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isAirportError && !this.props.isTaxesError && !this.props.isAirportLoading && !this.props.isTaxesLoading && !(this.props.airport.size === 0)}>
            <PageTitle icon="road">{this.props.airport.get('name')} Details</PageTitle>
            <Row>
              <Col xs={4}>
                <AirportDetails airport={this.props.airport} />
              </Col>
              <Col xs={8}>
                <AirportTaxes
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  airport            ={this.props.airport}
                  taxes              ={this.props.taxes}
                  onNew              ={this.handleNewClick}
                />
              </Col>
            </Row>
          </PageLoaded>
        </PageLifcycle>
        <DefaultModal id={this.props.taxFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <TaxForm
            onSave           ={this.handleSaveTax}
            onCancel         ={this.closeModal}
            data             ={this.props.taxDataForm}
            currencies       ={this.props.currencies}
            volumeUnits      ={this.props.units}
            calculationTypes ={this.props.taxCalculationTypes}
            taxTypes         ={this.props.taxTypes}
          />
        </DefaultModal>
      </div>
    )
  },

  getModalTitle(){
    return this.props.taxDataForm.get('id') && this.props.taxDataForm.get('id').get('value') ? "Edit Tax" : "New Tax";
  },

  closeModal() {
    ModalsAction.hideTaxForm(this.props.taxFormModalId);
  },

  handleSaveTax(tax) {
    if(tax.id)
      TaxesAction.update(tax);
    else
      TaxesAction.create(this.props.airport.get('icao'), tax);
  },

  handleNewClick() {
    ModalsAction.showTaxForm(this.props.taxFormModalId);
  }
});
