import React, {Component, PropTypes} from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import {fromJS} from 'immutable';
import {Logo} from '../../components';
import SubTitle from "../../components/SubTitle/SubTitle";
import IconButton from "../../components/IconButton/IconButton";

require('./PricesList.css');

export default class PricesList extends Component {
  static propTypes = {
    stores: PropTypes.object,
  };

  showExpiredAlert(isExpiredTooltip) {
    alert(isExpiredTooltip);
  }

  constructor(props) {
    super(props);
  }

  render() {
    const props = fromJS(this.props).toJS();

    const {
      stores: {
                suppliers,
                pricesForm,
              }
    } = props;

    if (!pricesForm || !Object.keys(pricesForm.data).length) {
      return null;
    }

    // get all contracts
    let {contracts} = pricesForm.data.airports.length && pricesForm.data.airports[0] || {contracts: []};

    // filter based on table required
    contracts = _.filter(contracts, (c) => c.isPublic === this.props.isPublic);

    return (
      <div>
        <SubTitle>{this.props.isPublic ? 'Secondary contract' : 'Primary contract'}</SubTitle>
        <table className="prices-table">
          <thead>
          <tr>
            <td className="col-xs-3">Supplier</td>
            <td className="col-xs-2">Unit price (duty-free)</td>
            <td className="col-xs-2">Tolerance range</td>
            <td className="col-xs-2">Currency</td>
            <td className="col-xs-2">Unit</td>
            <td className="col-xs-2"></td>
          </tr>
          </thead>
          <tbody>
          {contracts.length ? (
            contracts.map(contract => {
              const supplier = suppliers.suppliers[contract.supplierId];
              const {value, unit, currency} = contract.basePrice;

              return (
                <tr key={contract.contractId}>
                  <td><Logo maxHeight="35" maxWidth="35" base64={supplier.logo}/> {supplier.name}</td>
                  <td>{numeral(value).format('0,0.0000')}</td>
                  <td>
                    {numeral(value * 0.98).format('0,0.0000') + ' - ' + numeral(value * 1.02).format('0,0.0000')}
                  </td>
                  <td>{currency}</td>
                  <td>{unit}</td>
                  <td>{contract.isExpired ?
                    <IconButton onClick={this.showExpiredAlert.bind(this, contract.isExpiredTooltip)} fa="warning"/> : ''}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              {this.props.isPublic ?
                <td colSpan="5">{pricesForm.fields.airportIcao.value ? 'No import found.' : 'Please choose an airport.'}</td> :
                <td colSpan="5">{pricesForm.fields.airportIcao.value ? 'No contract found, please check the rate in ad-hoc quotation.' : 'Please choose an airport.'}</td>}
            </tr>
          )}
          </tbody>
        </table>
      </div>
    );
  }
}
