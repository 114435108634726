import React from 'react';
import  { Map } from 'immutable';

import { AirportsStore, ImportFormStore, LoginStore } from '../../stores';
import { AirportAction, ImportAction } from '../../actions';
import { Constants } from '../../constants';

import Airports from './Airports';
import commonUtils from '../../utils/commonUtils';

AirportsStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      airports            : Map(),
      user                : null,
      isAirportsLoading   : true,
      isAirportsError     : false,
      searchParams        : Map(),
      errCode             : null,
      taxesImportDialogId : commonUtils.guid(),
    }
  },

  componentDidMount() {
    AirportsStore.addChangeListener(this.handleChange);
    ImportFormStore.addChangeListener(this.handleImportFormStoreChange);
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    AirportAction.fetchAll();
  },

  componentWillUnmount() {
    AirportsStore.removeChangeListener(this.handleChange);
    ImportFormStore.removeChangeListener(this.handleImportFormStoreChange);
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
  },

  render() {
    return(<Airports {...this.state} />)
  },

  hideModals(){
    ModalsAction.hideImportModal(this.state.taxesImportDialogId);
  },

  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleChange() {
    this.setState({
      airports          : AirportsStore.get('airports'),
      isAirportsLoading : AirportsStore.get('loadingStatus') === Constants.LOADING,
      isAirportsError   : AirportsStore.get('loadingStatus') === Constants.ERROR,
      searchParams      : AirportsStore.get('search'),
      errCode           : AirportsStore.get('errCode'),
      user: LoginStore.get('user')
    })
  },

  handleImportFormStoreChange() {
    this.setState({
      import          : ImportFormStore.state,
      isImportLoading : ImportFormStore.get('loadingStatus') === Constants.LOADING,
      isImportError   : ImportFormStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },
});
