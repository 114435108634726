import React from 'react';
import { Button } from 'react-bootstrap';
import { Action, ListActions } from '../../components';
import { ActionAirports } from '../../constants/Constants';


export default class AirportsListActions extends React.Component {
  render(){
    return (
      <ListActions>
        <Action icon="search" to={'/airports/' + this.props.rowData.icao} />
      </ListActions>
    )
  }
}