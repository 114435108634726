import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import CurrenciesApiUtils from '../utils/CurrenciesApiUtils'

export default {
  fetchAll: function() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionCurrencies.LOAD_CURRENCIES
    })
    CurrenciesApiUtils.get('/api/currencies');
  }
};