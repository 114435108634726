import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import {arrayOf, normalize, Schema} from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeDatav2 = (data) => {
  const platt = new Schema('platts', { idAttribute: (item) => `${item.icao}-${item.supplierId}`});
  const value = new Schema('values');

  platt.define({
    Values: arrayOf(value)
  });

  return normalize(data, {
    platts: _.isArray(data.platts) ? arrayOf(platt) : platt
  });
};

var formalizeData = (data) => {
  const platt = new Schema('platts');
  const value = new Schema('values');

  platt.define({
    Values: arrayOf(value)
  });

  return normalize(data, {
    platts: _.isArray(data.platts) ? arrayOf(platt) : platt
  });
};

const PlattsApiUtils = {
  getAll: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = url === '/api/plattsv2' ? formalizeDatav2(data) : formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.LOAD_PLATTS_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.LOAD_PLATTS_ERROR,
          err:        err
        });
      })
  },

  getOne: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = url.indexOf('/api/plattsv2') > -1 ? formalizeDatav2(data) : formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.LOAD_PLATT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.LOAD_PLATT_ERROR,
          err:        err
        });
      })
  },

  create: function (url, platt) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       platt,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.CREATE_PLATT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.CREATE_PLATT_ERROR,
          err:        err
        });
      })
  },

  update: function (url, platt) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'PUT',
        data:       platt,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.UPDATE_PLATT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.UPDATE_PLATT_ERROR,
          err:        err
        });
      })
  },

  delete: function (url) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'DELETE',
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.DELETE_PLATT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPlatts.DELETE_PLATT_ERROR,
          err:        err
        });
      })
  },
}

export default PlattsApiUtils
