import React from 'react';
import { Palette } from '../../constants';
import { Icon } from '../../components';

export default React.createClass({
  getStyle(){
    return {
      width      : '100%',
      textAlign  : 'center',
      color      : Palette.DANGER_LOGIN,
      fontWeight : 'bold'
    }
  },
  
  render() {
    return (
      <div style={this.getStyle()} className={this.props.className}>
        <Icon fa="exclamation-triangle" />
        {this.props.children}
      </div>)
  }
});
