import React from 'react';
import _ from 'lodash'
import  { Map, List } from 'immutable';
import { Row, Col } from 'react-bootstrap';

import { PermissionRequired, YesNoDialog } from '../../components';
import { TaxesAction, ModalsAction } from '../../actions';
import { TaxesStore } from '../../stores';

import commonUtils from '../../utils/commonUtils';
import TaxItem from './TaxItem';

export default React.createClass({
  getInitialState(){
    return {
      deleteDialogMessage : '',
      taxFocused          : Map(),
      deleteDialogId      : commonUtils.guid()
    }
  },

  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps.taxes.toJS(), this.props.taxes.toJS())
          || this.state.deleteDialogMessage  !== nextState.deleteDialogMessage
          || this.state.taxFocused  !== nextState.taxFocused
          || this.state.deleteDialogId  !== nextState.deleteDialogId
          || this.props.user !== nextProps.user
  },

  componentDidMount(){
    TaxesStore.addChangeListener(this.handleTaxesChange);
  },

  componentWillUnmount(){
    TaxesStore.removeChangeListener(this.handleTaxesChange);
  },

  render(){
    return (
      <PermissionRequired requiredRoles={this.props.requiredReadRoles} user={this.props.user}>
        <div className="taxes-list">
          <Row className="taxes-list-title">
            {this.props.name}
          </Row>
          {this.getTaxesItems()}
          <YesNoDialog
            id    = {this.state.deleteDialogId}
            title = "Please confirm"
            onYes = {this.handleConfirmDeleteClick}
            onNo  = {this.handleCancelDeleteClick}>
              {this.state.deleteDialogMessage}
          </YesNoDialog>
        </div>
      </PermissionRequired>
    )
  },

  getTaxesItems(){
    if(this.props.taxes && this.props.taxes.size > 0){
      return this.props.taxes.map(function(tax){
        return <TaxItem
                  user               ={this.props.user}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  key                ={tax.get('id')} 
                  tax                ={tax.toJS()} 
                  onDelete           ={this.handleDeleteClick} 
                  onEdit             ={this.handleEditClick} 
                />
      }, this)
    } else {
      return <Row className="text-center">{"There is no " + this.props.name.toLowerCase()}</Row>
    }
  },


  handleTaxesChange(){
    ModalsAction.hide(this.state.deleteDialogId)
  },

  handleDeleteClick(tax){
    this.setState({
      taxFocused: tax,
      deleteDialogMessage: "Are you sure you want to delete this tax : " + tax.name + " ?"
    });

    ModalsAction.show(this.state.deleteDialogId)
  },

  handleConfirmDeleteClick(){
    TaxesAction.delete(this.state.taxFocused.id)
  },

  handleCancelDeleteClick(){
    ModalsAction.hide(this.state.deleteDialogId)
  },

  handleEditClick(tax){
    ModalsAction.showTaxForm(this.props.taxFormModalId, tax)
  },
});