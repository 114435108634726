import React from 'react';
import { AppTopNav } from '../../components';
import LoginStore from '../../stores/LoginStore'

var _render = 0;

let menuItems = []

export default React.createClass({
  getInitialState() {
    return {
      user : null
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
  },

  componentWillUnmount() {
    LoginStore.addRemoveListener(this.handleLoginStoreChange);
  },

  render() {
    return (
      <AppTopNav user={this.state.user} menuItems={menuItems}>
        {this.props.children}
      </AppTopNav>
    );
  },

  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  }
});
