import Palette from '../../constants/Palette';

export const legendTitleStyle = {
  title : {
      fontSize        : '20px',
      backgroundColor : Palette.WHITE,
      margin          : 'auto',
      padding         : '0px 20px 0px 20px',
  },

  line : {
    paddingTop   : '12px',
    height       : 0,
    borderBottom : '4px solid ' + Palette.LIGHT_GREY
  },

  wrapper : {
    margin : '25px 15px 25px 15px'
  }
}

export const ButtonStyles = {
  base: {
    width: '25%',
    fontWeight: 'bold',
    color: Palette.PRIMARY
  }
}

export const IndexesStyles = {
  table: {
    width          : '100%',
    borderSpacing  : 0,
    borderCollapse : 'collapse'
  },

  td: {
    padding: 5,
    textAlign: 'center'
  },

  headTr: {
    borderBottom: '2px solid ' + Palette.MAGENTA
  },

  tr: {
    borderBottom: '1px solid ' + Palette.MID_GREY
  },

  trColor: {
    backgroundColor: '#ECEAE4'
  },

  th: {
    padding: 5,
    textAlign: 'center',
    fontWeight: 400
  },

  tfoot: {
    borderBottom: '2px solid ' + Palette.MAGENTA
  }
}