import React from 'react';
import  { Map } from 'immutable';
import { Input, Button, ButtonToolbar, Alert } from 'react-bootstrap';

import { Save, Loader, Icon } from '../../components';
import { ImportAction } from '../../actions';

import {PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded} from '../../containers/PageLifecycle';
import {importFormStyles} from '../../styles/base.styles.js';

const ResultItem = React.createClass({
  render(){
    let { data } = this.props;

    return (
      <tr className={data.get('status') === 'success' ? "text-success" : "text-danger"}>
        <td style={importFormStyles.td}>{data.get('line') + '.'}</td>
        <td style={importFormStyles.td}>{data.get('message')}</td>
      </tr>
    )
  }
})

export default React.createClass({
  getInitialState(){
    //Initialize state from props.
    return {
      data: this.props.data,
      results: null
    }
  },

  componentWillReceiveProps(nextProps){
    this.setState({ results: nextProps.data.get('results').get('value') })
  },

  render() {
    return (
      <div>
        <Alert style={importFormStyles.format}><Icon fa="info" /> Format expected: {this.props.format}</Alert>
        <form className="form-horizontal">
          {this.getContent()}
          {this.getButtons()}
        </form>
      </div>)
  },

  getButtons(){
    var results = this.state.results;
    if(!(results && results.size > 0)){
      return (
       <ButtonToolbar style={{'textAlign': 'right'}}>
        <Button
          bsStyle='danger'
          onClick={this.props.onCancel}>
          Cancel
        </Button>
        <Save onSave={this.props.onSave} disabled={!this.props.data.get('isSavable')} />
      </ButtonToolbar>)
    } else {
      return (
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button onClick={this.handleBackClick}>
            Back
          </Button>
          <Button
            bsStyle='info'
            onClick={this.props.onContinue}>
            Continue
          </Button>
        </ButtonToolbar>)
    }
  },

  handleBackClick(){
    ImportAction.change('results', null);
  },

  getContent(){
    var results = this.state.results;
    if(!(results && results.size > 0)){
      return (
        <div>
          <Input
            name        ="data"
            value       ={this.state.data.get('data').get('value')}
            type        ="textarea" 
            placeholder ="Data" 
            bsSize      ="large" 
            style       ={importFormStyles.textarea} 
            onChange    ={this.handleChange} 
            onBlur      ={this.handleBlur} />
          <div>Paste your csv content in the text area. The column separator needs to be a semi-colon.</div>
        </div>
      )
    } else {
      return (
        <div style={importFormStyles.resultsWrapper}>
          <table style={importFormStyles.table}>
            <thead>
              <tr>
                <th style={importFormStyles.td}>Line</th>
                <th style={importFormStyles.td}>Result</th>
              </tr>
            </thead>
            <tbody>
              {results.map((row, key) => {
                return <ResultItem key={key} data={row} />
              })}
            </tbody>
          </table>
        </div>
      )
    }
  },

  handleChange(e) {
    let { name, value} = e.target;
    var nextState      = this.state.data.toObject();
    nextState[name]    = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    let { name, value} = e.target;
    ImportAction.change(name, value);
  },
})