import React from 'react';
import { Map } from 'immutable';
import _ from 'lodash';

import { Constants } from '../../constants';
import { LoginStore, SuppliersStore, SupplierFormStore } from '../../stores';
import { ModalsAction, SupplierAction } from '../../actions';

import commonUtils from '../../utils/commonUtils';
import Suppliers from './Suppliers';
import createDeepEqualSelector from "../../utils/createDeepEqualSelector";

SuppliersStore.initialize();

const getSuppliers = createDeepEqualSelector(
  props => props,
  ({suppliers}) => {
    return suppliers.map(c => ({
      ...c,
      nbrAirports: c.SupplierAirports.length,
    }));
  }
);

export default React.createClass({
  getInitialState() {
    return {
      user                   : null,
      suppliers              : Map(),
      isSuppliersLoading     : true,
      isSuppliersError       : false,
      errCode                : null,
      supplierFormModalId    : commonUtils.guid(),
      supplierDeleteDialogId : commonUtils.guid(),
      supplierDataForm       : Map()
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    SuppliersStore.addChangeListener(this.handleSupplierStoreChange);
    SupplierFormStore.addChangeListener(this.handleSupplierFormStoreChange);
    SupplierAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    SuppliersStore.removeChangeListener(this.handleSupplierStoreChange);
    SupplierFormStore.removeChangeListener(this.handleSupplierFormStoreChange);
  },

  render() {
    const suppliers = getSuppliers({
      suppliers: Object.values(this.state.suppliers.toJS())
    });

    return(<Suppliers {...this.state} suppliers={suppliers} />)
  },

  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleSupplierStoreChange() {
    ModalsAction.hideSupplierForm(this.state.supplierFormModalId);
    ModalsAction.hide(this.state.supplierDeleteDialogId);
    this.setState({
      suppliers          : SuppliersStore.get('suppliers'),
      errCode            : SuppliersStore.get('errCode'),
      isSuppliersLoading : SuppliersStore.get('loadingStatus') === Constants.LOADING,
      isSuppliersError   : SuppliersStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    })
  },

  handleSupplierFormStoreChange() {
    this.setState({
      supplierDataForm: SupplierFormStore.state,
      user: LoginStore.get('user')
    })
  }
});
