import Dispatcher from '../core/Dispatcher';
import _ from 'lodash'
import {Map} from 'immutable';
import assign from 'object-assign';

import {Constants} from '../constants';
import BaseStore from './BaseStore';
import createDeepEqualSelector from "../utils/createDeepEqualSelector";

const ImportsStore = assign({}, BaseStore, {
  initialize() {
    ImportsStore.setState({
      imports:                 Map(),
      search:                  Map(),
      loadingStatus:           Constants.LOADING,
      errCode:                 null,
      showUploadFileTypeError: false
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    const action = payload.action;

    switch (action.actionType) {
      case Constants.ActionImports.LOAD_IMPORT_ERROR:
      case Constants.ActionImports.LOAD_IMPORTS_ERROR:
        ImportsStore.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        ImportsStore.emitChange();
        break;

      case Constants.ActionImports.LOAD_IMPORT_SUCCESS:
      case Constants.ActionImports.LOAD_IMPORTS_SUCCESS:
        ImportsStore.setState({
          imports:       action.data.contracts,
          loadingStatus: Constants.LOADED
        });
        // ImportsStore.setState({
        //   imports:       fromJS(getContracts({
        //     contracts: action.data.contracts,
        //     suppliers: {}/*this.state.suppliers.toJS()*/,
        //   })) /*fromJS(action.data.contracts)*/,
        //   loadingStatus: Constants.LOADED
        // });
        ImportsStore.emitChange();
        break;

      case Constants.ActionImports.DELETE_IMPORT_SUCCESS:
        let id = action.data.result.contracts;
        ImportsStore.setState({imports: ImportsStore.get('imports').filter(function(i) {
            return i.id !== id
          })});

        //let nextData = ImportsStore.get('imports').delete(String(id));
        //ImportsStore.setState({imports: nextData});
        ImportsStore.emitChange();
        break;

      case Constants.ActionImports.SEARCH_IMPORTS:
        if (_.isUndefined(ImportsStore.get('search').get(action.field))) {
          var nextData = ImportsStore.get('search').set(action.field, action.value);
        } else {
          var nextData = ImportsStore.get('search').update(action.field, v => v = action.value);
        }
        ImportsStore.setState({search: nextData});
        ImportsStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ImportsStore;
