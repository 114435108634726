import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import ContractsStore from './ContractsStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';

const ContractAirportsStore = assign({}, BaseStore, {
  initialize() {
    ContractAirportsStore.setState({
      plattValues:      Map(),
      contractAirports: Map(),
      search:           Map(),
      loadingStatus:    Constants.LOADING
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionContracts.LOAD_CONTRACT_SUCCESS:
      case Constants.ActionContracts.LOAD_CONTRACTS_SUCCESS:
        // Not ideal; but we linked everything to a contract ( for primary or secondary contract )
        ContractAirportsStore.setState({
          plattValues:      fromJS(action.data.entities.values ? action.data.entities.values : {}),
          contractAirports: fromJS(action.data.entities.contractAirports ? action.data.entities.contractAirports : {}),
          loadingStatus:    Constants.LOADED
        });

        ContractAirportsStore.emitChange();
        break;

      case Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_SUCCESS:
        var id = action.data.result.contractAirports;
        var nextData = ContractAirportsStore.get('contractAirports').set(String(id), fromJS(action.data.entities.contractAirports[id]));
        ContractAirportsStore.setState({contractAirports: nextData});
        ContractAirportsStore.emitChange();
        break;

      case Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT_SUCCESS:
        var id = action.data.result.contractAirports;
        var nextData = ContractAirportsStore.get('contractAirports');
        ContractAirportsStore.setState({
          contractAirports: nextData.update(String(id), v => v = fromJS(action.data.entities.contractAirports[id]))
        })

        ContractAirportsStore.emitChange();
        break;

      case Constants.ActionContracts.DELETE_CONTRACT_AIRPORT_SUCCESS:
        Dispatcher.waitFor([ContractsStore.dispatchToken]);
        var id = action.data.result.contractAirports;

        //Hack, for some reason, sometimes it's string, sometimes integer :/
        var nextData = ContractAirportsStore.get('contractAirports').delete(String(id));
        var nextData = nextData.delete(String(id));

        ContractAirportsStore.setState({contractAirports: nextData});
        ContractAirportsStore.emitChange();
        break
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ContractAirportsStore;
