import React from 'react';
import {Map} from 'immutable';
import {FbosStore, LoginStore, FboFormStore} from '../../stores';
import {FbosAction, ModalsAction} from '../../actions';
import {Constants} from '../../constants';
import commonUtils from '../../utils/commonUtils';
import createDeepEqualSelector from '../../utils/createDeepEqualSelector';
import Fbos from './Fbos';

FbosStore.initialize();

const getFbos = createDeepEqualSelector(
  props => props,
  ({fbos}) => {
    return fbos.map(c => ({
      ...c,
      nbrAirports: c.FboAirports.length,
    }));
  }
);

export default React.createClass({
  getInitialState() {
    return {
      fbos:              Map(),
      searchParams:           Map(),
      isFbosLoading:     true,
      isFbosError:       false,
      errCode:                null,
      fboFormModalId:    commonUtils.guid(),
      fboDeleteDialogId: commonUtils.guid(),
      fuelImportDialogId:     commonUtils.guid(),
      droppedFile:            null,
      message:                "",
      fboDataForm:       Map(),
      user:                   null,
      rowsToSkip:             0
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    FbosStore.addChangeListener(this.handleFbostoreChange);
    FboFormStore.addChangeListener(this.handleFboFormStoreChange);
    FbosAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    FbosStore.removeChangeListener(this.handleFbostoreChange);
    FboFormStore.removeChangeListener(this.handleFboFormStoreChange);
  },

  render() {

    const fbos = getFbos({
      fbos: Object.values(this.state.fbos.toJS())
    });

    return (
      <Fbos {...this.state} fbos={fbos}/>)
  },

  handleFbostoreChange() {
    ModalsAction.hideFboForm(this.state.fboFormModalId);
    ModalsAction.hide(this.state.fboDeleteDialogId);

    this.setState({
      fbos:          FbosStore.get('fbos'),
      searchParams:       FbosStore.get('search'),
      errCode:            FbosStore.get('errCode'),
      isFbosLoading: FbosStore.get('loadingStatus') === Constants.LOADING,
      isFbosError:   FbosStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });

    // console.log('Fbos retrieved size is ' + this.state.fbos);
  },

  handleFboFormStoreChange() {
    console.log('handleFboFormStoreChange');
    this.setState({
      fboDataForm: FboFormStore.state,
      user: LoginStore.get('user')
    })
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  }

});
