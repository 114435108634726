import React from 'react';
import _ from 'lodash';
import {List, Map} from 'immutable';
import {Constants} from '../../constants';

import {
  AirportsStore,
  ContractAirportFormStore,
  ContractAirportsStore,
  ContractsStore,
  CurrenciesStore,
  ImportFormStore,
  LoginStore,
  PlattsStore,
  SuppliersStore, ValueFormStore,
  ValuesStore,
  VolumeUnitsStore
} from '../../stores';

import {
  AirportAction,
  ContractsAction,
  CurrenciesAction,
  ModalsAction,
  PlattAction,
  VolumeUnitsAction
} from '../../actions';

import commonUtils from '../../utils/commonUtils';
import Contract from './Contract'

ContractsStore.initialize();
SuppliersStore.initialize();
AirportsStore.initialize();
ContractAirportsStore.initialize();
ValuesStore.initialize();
VolumeUnitsStore.initialize();
CurrenciesStore.initialize();
ContractAirportFormStore.initialize();
ImportFormStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:                          null,
      contract:                      Map(),
      import:                        Map(),
      airports:                      Map(),
      contractAirports:              Map(),
      searchParams:                  Map(),
      suppliers:                     Map(),
      airportsOptions:               List(),
      plattsOptions:                 List(),
      currencies:                    List(),
      volumeUnits:                   List(),
      errCode:                       null,
      isContractAirportsLoading:     true,
      isContractAirportsError:       false,
      isContractLoading:             true,
      isContractError:               false,
      isAirportsLoading:             true,
      isAirportsError:               false,
      isSuppliersLoading:            true,
      isSuppliersError:              false,
      isPlattsLoading:               true,
      isPlattsError:                 false,
      isValuesLoading:               true,
      isValuesError:                 false,
      isCurrenciesLoading:           true,
      isCurrenciesError:             false,
      isVolumeUnitsLoading:          true,
      isVolumeUnitsError:            false,
      isImportError:                 false,
      isImportLoading:               false,
      contractAirportForm:           Map(),
      contractAirportFormModalId:    commonUtils.guid(),
      contractAirportDeleteDialogId: commonUtils.guid(),
      contractAirportImportDialogId: commonUtils.guid(),
      valueDeleteDialogId:           commonUtils.guid(),
      valueFormModalId:              commonUtils.guid(),
      importResultsModalId:          commonUtils.guid()
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    ContractsStore.addChangeListener(this.handleContractsStoreChange);
    SuppliersStore.addChangeListener(this.handleSuppliersStoreChange);
    AirportsStore.addChangeListener(this.handleAirportsStoreChange);
    ContractAirportsStore.addChangeListener(this.handleContractAirportsStoreChange);
    ContractAirportFormStore.addChangeListener(this.handleContractAirportFormStoreChange);
    ValueFormStore.addChangeListener(this.handleValueFormStoreChange);
    ValuesStore.addChangeListener(this.handleValuesStoreChange);
    PlattsStore.addChangeListener(this.handlePlattsStoreChange);
    VolumeUnitsStore.addChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.addChangeListener(this.handleCurrenciesStoreChange);
    ImportFormStore.addChangeListener(this.handleImportFormStoreChange);
    ContractsAction.getContractById(this.props.params.id);
    AirportAction.fetchAll();
    PlattAction.fetchAll();
    CurrenciesAction.fetchAll();
    VolumeUnitsAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    ContractsStore.removeChangeListener(this.handleContractsStoreChange);
    SuppliersStore.removeChangeListener(this.handleSuppliersStoreChange);
    AirportsStore.removeChangeListener(this.handleAirportsStoreChange);
    ContractAirportsStore.removeChangeListener(this.handleContractAirportsStoreChange);
    ContractAirportFormStore.removeChangeListener(this.handleContractAirportFormStoreChange);
    VolumeUnitsStore.removeChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.removeChangeListener(this.handleCurrenciesStoreChange);
    ImportFormStore.removeChangeListener(this.handleImportFormStoreChange);
    ValueFormStore.removeChangeListener(this.handleValueFormStoreChange);
    ValuesStore.removeChangeListener(this.handleValuesStoreChange);
    PlattsStore.removeChangeListener(this.handlePlattsStoreChange);
  },

  render() {
    return <Contract {...this.state} />;
  },

  hideModals() {
    ModalsAction.hideContractAirportForm(this.state.contractAirportFormModalId);
    ModalsAction.hide(this.state.contractAirportDeleteDialogId);
    ModalsAction.hideImportModal(this.state.contractAirportImportDialogId);
    ModalsAction.hideImportResults(this.state.importResultsModalId);
  },

  handleContractsStoreChange() {
    this.setState({
      contract:          ContractsStore.get('contracts').size > 0 ? ContractsStore.get('contracts').get(this.props.params.id) : Map(),
      isContractLoading: ContractsStore.get('loadingStatus') === Constants.LOADING,
      isContractError:   ContractsStore.get('loadingStatus') === Constants.ERROR,
      errCode:           ContractsStore.get('errCode'),
      searchParams:      ContractsStore.get('search'),
      user: LoginStore.get('user')
    });
  },

  handleSuppliersStoreChange() {
    this.setState({
      suppliers:          SuppliersStore.get('suppliers'),
      isSuppliersLoading: SuppliersStore.get('loadingStatus') === Constants.LOADING,
      isSuppliersError:   SuppliersStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleAirportsStoreChange() {
    this.setState({
      airports:          AirportsStore.get('airports'),
      airportsOptions:   this.parseAirportsToOptions(AirportsStore.get('airports')),
      isAirportsLoading: AirportsStore.get('loadingStatus') === Constants.LOADING,
      isAirportsError:   AirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleContractAirportsStoreChange() {
    this.hideModals();
    this.setState({
      plattValues:               ContractAirportsStore.get('plattValues'),
      contractAirports:          ContractAirportsStore.get('contractAirports'),
      isContractAirportsLoading: ContractAirportsStore.get('loadingStatus') === Constants.LOADING,
      isContractAirportsError:   ContractAirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleValuesStoreChange() {
    this.setState({
      values:          ValuesStore.get('values'),
      isValuesLoading: ValuesStore.get('loadingStatus') === Constants.LOADING,
      isValuesError:   ValuesStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handlePlattsStoreChange() {
    this.setState({
      platts:          PlattsStore.get('platts'),
      plattsOptions:   this.parsePlattsToOptions(PlattsStore.get('platts')),
      isPlattsLoading: PlattsStore.get('loadingStatus') === Constants.LOADING,
      isPlattsError:   PlattsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleVolumeUnitsStoreChange() {
    this.setState({
      volumeUnits:          VolumeUnitsStore.get('units'),
      isVolumeUnitsLoading: VolumeUnitsStore.get('loadingStatus') === Constants.LOADING,
      isVolumeUnitsError:   VolumeUnitsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleCurrenciesStoreChange() {
    this.setState({
      currencies:          CurrenciesStore.get('currencies'),
      isCurrenciesLoading: CurrenciesStore.get('loadingStatus') === Constants.LOADING,
      isCurrenciesError:   CurrenciesStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleImportFormStoreChange() {
    this.setState({
      import:          ImportFormStore.state,
      isImportLoading: ImportFormStore.get('loadingStatus') === Constants.LOADING,
      isImportError:   ImportFormStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleContractAirportFormStoreChange() {
    this.setState({contractAirportForm: ContractAirportFormStore.state,
      user: LoginStore.get('user')});
  },

  parseAirportsToOptions(airports) {
    let options = List();
    _.each(airports.toJS(), (airport) => {
      options = options.push({
        value: airport.icao,
        label: airport.icao + " - " + airport.name,
        data:  airport
      })
    })

    return options
  },

  parsePlattsToOptions(platts) {
    let options = List();
    _.chain(platts.toJS())
      .sortBy(platt => {
        return platt.code
      })
      .each(platt => {
        var {code, baseReference, name, calculationScheme} = platt;

        options = options.push({
          value: platt.id,
          label: [code, name, baseReference, calculationScheme].join(' - '),
          data:  platt
        })
      })
      .value()

    return options
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handleValueFormStoreChange() {
    console.log('handleValueFormStoreChange');
    this.setState({
      valueDataForm: ValueFormStore.state,
      user: LoginStore.get('user')
    });
  },

});
