import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';

export default {
  change(field, value) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionValueForm.VALUE_FORM_CHANGE,
      field      : field,
      value      : value
    })
  }
};