import React from 'react';
import {List, Map} from 'immutable';
import commonUtils from '../../utils/commonUtils';
import {LoginStore, Plattsv2Store, ValuesStore} from '../../stores';
import {ModalsAction, Plattv2Action} from '../../actions';
import {Constants} from '../../constants';
import Platt from './Platt'

Plattsv2Store.initialize();
ValuesStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:                null,
      platt:               Map(),
      values              : List(),
      isPlattLoading:      true,
      isPlattError:        false,
      valueDeleteDialogId: commonUtils.guid(),
      errCode:             null
    }
  },

  componentDidMount() {
    Plattsv2Store.addChangeListener(this.handlePlattStoreChange);
    ValuesStore.addChangeListener(this.handleValuesStoreChange);
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    Plattv2Action.getPlattById(this.props.params.icao, this.props.params.supplier);
  },

  componentWillUnmount() {
    Plattsv2Store.removeChangeListener(this.handlePlattStoreChange);
    ValuesStore.removeChangeListener(this.handleValuesStoreChange);
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
  },

  render() {
    return <Platt {...this.state} />
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handlePlattStoreChange() {
    console.log('PlattStore has been changed for ' + this.props.params.icao + ' and ' + this.props.params.supplier);
    ModalsAction.hideValueForm(this.state.valueFormModalId);
    ModalsAction.hide(this.state.valueDeleteDialogId);
    this.setState({
      platt:          Plattsv2Store.get('platts').size > 0 ? Plattsv2Store.get('platts').get(this.props.params.icao + '-' + this.props.params.supplier) : Map(),
      isPlattLoading: Plattsv2Store.get('loadingStatus') === Constants.LOADING,
      isPlattError:   Plattsv2Store.get('loadingStatus') === Constants.ERROR,
      errCode:        Plattsv2Store.get('errCode'),
      user: LoginStore.get('user')
    });
  },

  handleValuesStoreChange() {
    console.log('ValueStore has been changed');
    ModalsAction.hideValueForm(this.state.valueFormModalId);
    ModalsAction.hide(this.state.valueDeleteDialogId);
    this.setState({
      platt:               Plattsv2Store.get('platts').get(this.props.params.icao + '-' + this.props.params.supplier),
      values:              ValuesStore.get('values'),
      loadingValuesStatus: ValuesStore.get('loadingStatus'),
      user: LoginStore.get('user')
    });
  },
});
