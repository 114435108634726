import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import {arrayOf, normalize, Schema} from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const contract = new Schema('contracts');
  const contractAirport = new Schema('contractAirports');
  const airport = new Schema('airports', {idAttribute: 'icao'});
  const supplier = new Schema('suppliers');
  const value = new Schema('values');
  const platt = new Schema('platts');
  const tax = new Schema('tax');

  contract.define({
    ContractAirports: arrayOf(contractAirport),
    Supplier:         supplier,
    Values:           arrayOf(value)
  });

  contractAirport.define({
    Airport: airport,
    Values:  arrayOf(value),
    Platt:   platt,
    Taxes:   arrayOf(tax)
  });

  platt.define({
    Values: arrayOf(value)
  });

  return normalize(data, {
    contracts:        _.isArray(data.contracts) ? arrayOf(contract) : contract,
    contractAirports: _.isArray(data.contractAirport) ? arrayOf(contractAirport) : contractAirport
  });
}

const ContractsApiUtils = {
  getAll: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {

        // loop on contracts to initialize reference field accordingly
        if (data && data.contracts && _.isArray(data.contracts)) {
          data.contracts.forEach(function (c) {
            if (c.isImport) {
              c.reference = c.publicReference;
            }
          });
        }

        // then formalize data
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.LOAD_CONTRACTS_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.LOAD_CONTRACTS_ERROR,
          err:        err
        });
      })
  },

  getOne: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {

        // initialize reference field accordingly
        if (data && data.contracts) {
          if (data.contracts.isImport) {
            data.contracts.reference = data.contracts.publicReference;
          }
        }

        // then formalize data
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.LOAD_CONTRACT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.LOAD_CONTRACT_ERROR,
          err:        err
        });
      })
  },

  create: function (url, contract) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       contract,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.CREATE_CONTRACT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.CREATE_CONTRACT_ERROR,
          err:        err
        });
      })
  },

  update: function (url, contract) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'PUT',
      data:       contract,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.UPDATE_CONTRACT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.UPDATE_CONTRACT_ERROR,
          err:        err
        });
      })
  },

  delete: function (url, isImport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'DELETE',
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);
        if (isImport) {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionImports.DELETE_IMPORT_SUCCESS,
            data
          });
        } else {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionContracts.DELETE_CONTRACT_SUCCESS,
            data
          });
        }
      })
      .catch(function (err) {
        if (isImport) {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionImports.DELETE_IMPORT_ERROR,
            err
          });
        } else {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionContracts.DELETE_CONTRACT_ERROR,
            err
          });
        }
      })
  },

  addContractAirport: function (url, contractAirport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       contractAirport,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.CREATE_CONTRACT_AIRPORT_ERROR,
          err:        err
        });
      })
  },

  updateContractAirport: function (url, contractAirport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'PUT',
      data:       contractAirport,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.UPDATE_CONTRACT_AIRPORT_ERROR,
          err:        err
        });
      })
  },

  deleteContractAirport: function (url) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'DELETE',
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.DELETE_CONTRACT_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionContracts.DELETE_CONTRACT_AIRPORT_ERROR,
          err:        err
        });
      })
  }
}

export default ContractsApiUtils
