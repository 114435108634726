import React from 'react';
import { Button } from 'react-bootstrap';
import { PermissionRequired } from '../../components';
import { Action, ListActions } from '../../components';
import { ActionContracts } from '../../constants/Constants';
import { ModalsAction } from '../../actions';
import { Activities } from '../../constants';

export default React.createClass({
  render(){
    return (
      <ListActions>
        <PermissionRequired requiredRoles={Activities.READ_CONTRACTS} user={this.props.metadata.user}>
          <Action icon="search" to={'/contracts/' + this.props.rowData.id} />
        </PermissionRequired>
        <PermissionRequired requiredRoles={Activities.WRITE_CONTRACTS} user={this.props.metadata.user}>
          <Action icon="edit" onClick={this.handleEditContractClick} />
          <Action icon="remove" onClick={this.handleDeleteContractClick} />
        </PermissionRequired>
      </ListActions>
    )
  },

  handleEditContractClick(){
    ModalsAction.showContractForm(this.props.metadata.contractFormModalId, this.props.rowData);
  },

  handleDeleteContractClick(){
    ModalsAction.show(this.props.metadata.contractDeleteDialogId, { id: this.props.rowData.id, name: this.props.rowData.name });
  }
});