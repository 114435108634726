import React from 'react';
import ReactDom from 'react-dom';

import { Icon } from '../../../components'
import { CalculatorAirportStyles, tripDash } from '../../../styles/base.styles';

export default React.createClass({
  getInitialState(){
    return {
      dashStyle : CalculatorAirportStyles.tripDashStart
    }
  },

  getArrow(){
    return this.props.hasArrow ? (
      <span style={{position: 'absolute', top: '40px', left: '0px', color: '#333'}}>
        <Icon fa="arrow-right" style="inline-block" />
      </span>
    ) : (
      <span />
    );
  },

  render(){
    const img = require('../../../assets/images/tower_gjl.png');

    return (
      <div style={{position: 'relative'}}>
        {this.getArrow()}
        <div style={CalculatorAirportStyles.card} >
          <img style={{ maxWidth: 50 }} src={img} />
          <span>{this.props.children}</span>
        </div>
      </div>
    )
  }
})

