import React, { PropTypes, Component } from 'react';
import _ from 'lodash';
import { Row } from 'react-bootstrap';

import { DatePicker, CurrencyDropdown, VolumeUnitDropdown, Autocomplete } from '../../components';
import { PricesFormAction, PricesAction } from '../../actions';

const validate = function(fields, field, value) {
  const errors = {};

  return errors;
}

export default class PricesForm extends Component {
  static propTypes = {
    stores: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state= {
      fields: {}
    }

    this.parseAirportsToOptions = this.parseAirportsToOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.stores.pricesForm && !!this.props.stores.pricesForm
      && !_.isEqual(nextProps.stores.pricesForm.toJS(), this.props.stores.pricesForm.toJS())) {
      const { airportIcao, date, currencyCode, volumeUnitCode } = nextProps.stores.pricesForm.toJS().fields;

      PricesAction.getPrices({
        date: date.value,
        airport: [airportIcao.value],
        currency: currencyCode.value,
        volumeUnit: volumeUnitCode.value
      });
    }
  }

  parseAirportsToOptions(airports){
    let options = [];

    _.each(airports, airport => {
      options.push({
        value: airport.icao,
        label: airport.icao + " - " + airport.name,
        data : airport
      });
    });

    return options;
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { fields } = this.state;

    this.setState({
      fields: {
        ...fields,
        [name]: {
          ...fields.name,
          value: value
        }
      }
    });
  }

  handleBlur(e) {
    const { name, value } = e.target;
    PricesFormAction.change(name, value, validate);
  }

  render() {
    const {
      stores: {
        airports,
        currencies,
        pricesForm,
        units
      }
    } = this.props;

    const actions = {
      onBlur: this.handleBlur,
      onChange: this.handleChange
    };

    const airportsOptions = this.parseAirportsToOptions(airports.get('airports').toJS());

    return (
      <form className="form-horizontal">
        <DatePicker
          { ...pricesForm.toJS().fields.date }
          { ...actions }
          type="text"
          labelClassName={4}
          wrapperClassName={8}
          hasFeedback
        />
        <div className="row">
          <Autocomplete
            { ...pricesForm.toJS().fields.airportIcao}
            onChange         ={actions.onBlur}
            labelClassName   ="col-xs-4"
            wrapperClassName ="col-xs-8"
            options          ={airportsOptions}
            itemsNumber      ="7"
            hasFeedback
          />
        </div>
        <Row>
          <CurrencyDropdown
            { ...pricesForm.toJS().fields.currencyCode }
            onChange={actions.onBlur}
            data={currencies.get('currencies')}
            defaultValue="USD"
            labelClassName="col-xs-4"
            wrapperClassName="col-xs-8"
          />
        </Row>
        <Row>
          <VolumeUnitDropdown
            { ...pricesForm.toJS().fields.volumeUnitCode }
            onChange={actions.onBlur}
            data={units.get('units')}
            defaultValue="US gal"
            labelClassName="col-xs-4"
            wrapperClassName="col-xs-8"
          />
        </Row>
      </form>
    );
  }
}

