import React from 'react';

import {DefaultModal, Dropbox, Loader, LogoControl, NoItemHelper, Oops, PageTitle, YesNoDialog} from '../../components';
import {FbosAction, ImportAction, ModalsAction} from '../../actions';

import FbosListActions from './FbosListActions';
import FbosList from './FbosList';
import FboForm from '../fbo/FboForm';
import _ from "lodash";
import {PageError, PageHelper, PageLifcycle, PageLoaded, PageLoading} from "../PageLifecycle";

var columnNames = ['name', 'nbrAirports', 'id'];

export default React.createClass({

  getColumnMetadata() {
    return [{
      columnName:  'name',
      displayName: 'Name',
      searchInput: 'text'
    }, {
      columnName:  'nbrAirports',
      displayName: 'Nbr. Airports',
      searchInput: false
    }, {
      columnName:         "id",
      displayName:        "",
      cssClassName:       "actionsColumn",
      fboDeleteDialogId:  this.props.fboDeleteDialogId,
      fboFormModalId:     this.props.fboFormModalId,
      customComponent:    FbosListActions,
      searchInput:        'actions',
      requiredReadRoles:  this.props.requiredReadRoles,
      requiredWriteRoles: this.props.requiredWriteRoles,
      user:               this.props.user
    }];
  },

  getModalTitle() {
    return this.props.fboDataForm.get('id') && this.props.fboDataForm.get('id').get('value') ? "Edit Fbo" : "New Fbo";
  },

  closeModal() {
    ModalsAction.hideFboForm(this.props.fboFormModalId);
  },

  handleSaveFbo(fbo) {
    const save = fbo.id ? FbosAction.update : FbosAction.create;
    save(fbo);
  },

  handleConfirmDeleteClick(fbo) {
    FbosAction.delete(fbo.get('id'))
  },

  handleCancelDeleteClick() {
    ModalsAction.hide(this.props.fboDeleteDialogId);
  },

  handleNewClick() {
    ModalsAction.showFboForm(this.props.fboFormModalId);
  },

  handleImportCancelClick() {
    ModalsAction.hideImportModal(this.props.fuelImportDialogId);
  },

  handleImportContinueClick() {
    // Just close modal
    this.handleImportCancelClick();
  },

  getImportPreview() {
    if (this.props.droppedFile || (this.state && this.state.droppedFile)) {
      let base64 = 'test';
      return <LogoControl base64={base64} message={this.state.message} onDelete={this.handleRemoveFile}/>
    } else {
      return <Dropbox onDrop={this.handleImportDrop}>Drag and drop file here, or click to select file to upload.</Dropbox>
    }
  },

  handleRemoveFile() {
    // save file on state
    this.setState({
      droppedFile: null,
      message:     ''
    });
  },

  handleBlur(e) {
    let {name, value} = e.target;
    ImportAction.changeSupplier(name, value);
  },

  getSuppliersOptions() {
    let options = [];
    _.chain(this.props.suppliers.toJS())
      .sortBy(supplier => {
        return supplier.name
      })
      .each(supplier => {
        options.push({
          value: supplier.id,
          label: supplier.name,
          data:  supplier
        })
      })
      .value();
    console.log('We are returning suppliers count: ' + options.length);
    return options;
  },

  render() {
    return (
      <div className="data-table">
        <PageTitle icon="file-text">List of fbos</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isFbosLoading || this.props.isSuppliersLoading}>
            <Loader>
              Fetching fbos from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isFbosError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isFbosError && !this.props.isFbosLoading && this.props.fbos.length === 0}>
            <NoItemHelper onNew={this.handleNewClick} buttonLabel="New fbo">
              <p>You are currently in the fbos list. There is no fbo registered yet.</p>
              <p>To get you started, you can create a new fbo. You can add as many fbos as you like.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isFbosError && !this.props.isFbosLoading && !(this.props.fbos.length === 0)}>
            <FbosList
              user={this.props.user}
              fbos={this.props.fbos}
              columnMetadata={this.getColumnMetadata()}
              columnNames={columnNames}
              loadingStatus={this.props.loadingFbosStatus}
              searchParams={this.props.searchParams}
              isNewButton={true}
              onNew={this.handleNewClick}
            />

          </PageLoaded>
        </PageLifcycle>

        <DefaultModal id={this.props.fboFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <FboForm
            onSave={this.handleSaveFbo}
            onCancel={this.closeModal}
            data={this.props.fboDataForm}/>
        </DefaultModal>
        <YesNoDialog
          id={this.props.fboDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick}/>
      </div>
    )
  }

});
