import assign from 'object-assign';
import {fromJS, Map} from 'immutable';
import _ from 'lodash'

import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import ValuesStore from './ValuesStore';

const Plattsv2Store = assign({}, BaseStore, {
  initialize() {
    Plattsv2Store.setState({
      platts:        Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING,
      errCode:       null,
      errMessage:    null
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionPlattsv2.LOAD_PLATT_ERROR:
      case Constants.ActionPlattsv2.LOAD_PLATTS_ERROR:
        Plattsv2Store.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        Plattsv2Store.emitChange();
        break;

      case Constants.ActionPlattsv2.LOAD_PLATT_SUCCESS:
      case Constants.ActionPlattsv2.LOAD_PLATTS_SUCCESS:
        Dispatcher.waitFor([ValuesStore.dispatchToken]);

        Plattsv2Store.setState({
          platts:        !_.isUndefined(action.data.entities.platts) ? fromJS(action.data.entities.platts) : Map(),
          loadingStatus: Constants.LOADED
        });
        Plattsv2Store.emitChange();
        break;

      case Constants.ActionPlattsv2.LOAD_PLATT:
        Plattsv2Store.setState({loadingStatus: Constants.LOADING});
        Plattsv2Store.emitChange();
        break;

													 
																
								   
			  

      case Constants.ActionPlattsv2.CREATE_PLATT_SUCCESS:
        var id = action.data.result.platts;
        var nextData = Plattsv2Store
          .get('platts')
          .set(String(id), Map(action.data.entities.platts[id]));
        Plattsv2Store.setState({platts: nextData});
        Plattsv2Store.emitChange();
        break;

      case Constants.ActionPlattsv2.SEARCH_PLATTS:
        var nextData = _.isUndefined(Plattsv2Store.get('search').get(action.field)) ? (
          Plattsv2Store.get('search').set(action.field, action.value)
        ) : (
          Plattsv2Store.get('search').update(action.field, v => v = action.value)
        );

        Plattsv2Store.setState({search: nextData});
        Plattsv2Store.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default Plattsv2Store;
