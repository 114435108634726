import React from 'react';
import { Row, Col, Input } from 'react-bootstrap';
import { Map, List } from 'immutable';

import {PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded} from '../PageLifecycle';
import { Loader, Oops, Icon, NoPrint, DefaultModal } from '../../components';
import CalculatorSettings from './CalculatorSettings';
import { CalculatorAction, ModalsAction } from '../../actions';
import { AirportForm } from './Settings'
import Indices from './Indices';

let _render = 0;

export default React.createClass({
  propTypes: {
    contract  : React.PropTypes.object,
    suppliers : React.PropTypes.object
  },

  getInitialState(){
    return {
      isLoading : true,
      isError   : false
    }
  },

  /**
   * If some of theses stores loading, then we wait until everything is finish.
   * @param  {Immutable object} nextProps
   */
  componentWillReceiveProps(nextProps){
    let isLoading = (
        //nextProps.isAirportsLoading ||
        nextProps.isVolumeUnitsLoading ||
        nextProps.isCurrenciesLoading)

    let isError = (
        //nextProps.isAirportsError ||
        nextProps.isVolumeUnitsError ||
        nextProps.isCurrenciesError)

      this.setState({
        isLoading : isLoading,
        iError   : isError
      })
  },

  render() {
    return (
      <div>
        <h2 style={{color: '#828282'}}><Icon fa="calculator" /> Fuel Simulator</h2>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.state.isLoading && !this.state.isError}>
            <Loader>
              Fetching data from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.state.isError}>
            <Oops errorCode={this.props.errCode} />
          </PageError>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.state.isError && !this.state.isLoading}>
            <NoPrint>
              <CalculatorSettings 
                modalId  ={this.props.modalId}
                settings ={this.props.calculatorSettings}
              />
            </NoPrint>
            <Indices
              suppliers ={this.props.suppliers}
              indices   ={this.props.indices}
              settings  ={this.props.calculatorSettings}
            />
          </PageLoaded>
          <DefaultModal
            id     ={this.props.modalId}
            bsSize ="medium"
            onHide ={this.closeModal}
            title  ='Add airport to the trip'
            onValid={this.handleAddAirport}>
            <AirportForm 
              onAdd        ={this.handleAddAirport}
              airportError ={this.props.calculatorSettings.get('airportFormError')}
            />
          </DefaultModal>
        </PageLifcycle>
      </div>
    )
  },

  closeModal(){
    ModalsAction.hideCalculatorAirportModal(this.props.modalId)
  },

  handleAddAirport(airportIcao){
    CalculatorAction.addAirport(airportIcao)
  }
});
