import React from 'react';
import { Row } from 'react-bootstrap';

export default React.createClass({
  render() {
    return(
      <div className="error-page">
        <Row className="code text-center">
          oops
        </Row>
        <Row className="message text-center">
          {this.props.code + ' ' + this.props.children + ' :('} 
        </Row>
      </div>
    )
  }
});