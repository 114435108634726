import keyMirror from 'key-mirror';

export default keyMirror({
  FUEL_ADMIN:       null,
  FUEL_PURCHASER:   null,
  FUEL_COORDINATOR: null,
  FUEL_OPERATOR:    null,
  FUEL_ACCOUNTANT:  null,
  FUEL_INDEX:       null,
  FUEL_OPS_GVA:     null,
  ALL:              null
})
