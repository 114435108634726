import React from 'react';
import _ from 'lodash';
import {Map} from 'immutable';
import {Button, ButtonToolbar, Col, Input, Row} from 'react-bootstrap';

import {CurrencyDropdown, DatePicker, InlineAlert, Save, VolumeUnitDropdown} from '../../components';
import {ValueFormAction} from '../../actions';

export default React.createClass({
  propTypes: {
    data:     React.PropTypes.object,
    onSave:   React.PropTypes.func,
    onCancel: React.PropTypes.func
  },

  getDefaultProps: function () {
    return {
      data: Map()
    }
  },

  getInitialState() {
    //Initialize state from props.
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  },

  render() {
    let data = this.state.data;
    return (
      <form className="form-horizontal">
        <Row className="row-form-pad">
          <Col xs={3}>
            <label className="control-label"><span>Value</span></label>
          </Col>
          <Col xs={3}>
            <Input
              name='value'
              type='text'
              value={data.get('value').get('value')}
              valueType="number"
              placeholder='Value *'
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              bsStyle={this.getValidClass(this.state.data.get('value').get('isValid'))}
              hasFeedback/>
          </Col>
          <Col xs={3}>
            <CurrencyDropdown
              defaultValue={data.get('currencyCode').get('value')}
              data={this.props.currencies}
              onChange={this.handleBlur}/>
          </Col>
          <Col xs={1} style={{
            padding:     '0',
            'textAlign': 'center'
          }}>
            <span style={{
              padding: '7px 0 0 0',
              display: 'inline-block'
            }}>per</span>
          </Col>
          <Col xs={2}>
            <VolumeUnitDropdown
              defaultValue={data.get('volumeUnitCode').get('value')}
              data={this.props.volumeUnits}
              onChange={this.handleBlur}/>
          </Col>
        </Row>
        <DatePicker
          name='effectiveDate'
          value={data.get('effectiveDate').get('value')}
          type='text'
          label="Effective Date"
          placeholder='YYYY-MM-DD *'
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          labelClassName={3}
          wrapperClassName={9}
          bsStyle={this.getValidClass(data.get('effectiveDate').get('isValid'))}
          hasFeedback
        />
        <DatePicker
          name='validUntil'
          value={data.get('validUntil').get('value')}
          type='text'
          label="Valid Until"
          placeholder='YYYY-MM-DD'
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          labelClassName={3}
          wrapperClassName={9}
          bsStyle={this.getValidClass(data.get('validUntil').get('isValid'))}
          hasFeedback
        />
        <Row>
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{marginTop: 10}}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </Row>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')}/>
        </ButtonToolbar>
      </form>
    )
  },

  handleChange(e) {
    let {name, value} = e.target;
    var nextState = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    let {name, value} = e.target;
    ValueFormAction.change(name, value)
  },

  handleCancelClick() {
    this.props.onCancel();
  },

  handleSaveClick() {
    var _self = this;
    var data = {};
    _.each(_self.props.data.toJS(), function (prop, key) {
      data[key] = prop.value;
    });

    this.props.onSave(data);
  },

  getValidClass(isValid) {
    if (isValid === null) {
      return null
    }
    return isValid ? 'success' : 'error';
  }
});
