require("babel/polyfill");
require("./assets/js/material.js");
require("./assets/js/ripples.js");
require("./assets/css/font-awesome.min.css");
require("./node_modules/bootstrap/less/bootstrap.less");
require("./assets/less/wrapper.less");
require("./assets/less/app-base.less");

import React from "react";
import ReactDOM from "react-dom";
import jwt from "jsonwebtoken";
import  { fromJS } from 'immutable';
import { normalize, arrayOf, Schema } from "normalizr";
import {
  browserHistory,
  IndexRoute,
  Redirect,
  Route,
  Router,
} from "react-router";

import AirportWrapper from "./containers/airport/AirportWrapper.js";
import PricesWrapper from "./containers/prices/PricesWrapper.js";
import AirportsWrapper from "./containers/airports/AirportsWrapper.js";
import SuppliersWrapper from "./containers/suppliers/SuppliersWrapper.js";
import SupplierWrapper from "./containers/supplier/SupplierWrapper.js";
import PlattsWrapper from "./containers/platts/PlattsWrapper.js";
import Plattsv2Wrapper from "./containers/plattsv2/PlattsWrapper.js";
import PlattWrapper from "./containers/platt/PlattWrapper.js";
import Plattv2Wrapper from "./containers/plattv2/PlattWrapper.js";
import ContractsWrapper from "./containers/contracts/ContractsWrapper.js";
import FuelRequestsWrapper from './containers/fuelrequests/FuelRequestsWrapper.js';
import FbosWrapper from "./containers/fbos/FbosWrapper.js";
import FboWrapper from "./containers/fbo/FboWrapper.js";
import ContractWrapper from "./containers/contract/ContractWrapper.js";
import CalculatorWrapper from "./containers/calculator/CalculatorWrapper.js";
import UnauthorizedAccess from "./containers/errors/UnauthorizedAccess";
import PageNotFound from "./containers/errors/PageNotFound";
import ImportsWrapper from "./containers/import/ImportsWrapper.js";
import WelcomeWrapper from "./containers/welcome/WelcomeWrapper.js";

import AuthApiUtils from "./utils/AuthApiUtils";

import { App, FrontEndApp } from "./components";
import { Activities } from "./constants";
import { LoginStore } from "./stores";
import { LoginAction } from "./actions";

console.log("mode: " + process.env.NODE_ENV);

if (process.env.NODE_ENV !== "production") {
  // var Perf = require('react-addons-perf');
  // window.Perf = Perf;
  // Perf.start();
}

//Define ajax params
$.ajaxSetup({
  error: function (jqXHR, textStatus, errorThrown) {
    if (jqXHR.status == 419) {
      LoginAction.timeout();
    } else {
      console.log("XHR Error", textStatus);
    }
  },
});

LoginStore.initialize();

// We check if there is a json token.
// if it's the case, we register the user before displaying the page.
acquireToken();

function acquireToken() {
  console.log("Acquiring token...");
  Promise.resolve($.ajax({ url: "/.auth/me", type: "GET" }))
    .then(function (data) {
      data = formalizeData(data).result[0].id_token;
      const decoded = jwt.decode(data);
      if (LoginStore.isExpired(decoded)) {
        console.log("Token expired. Refreshing...");
        refreshToken();
      } else {
        console.log("Token acquired");
        LoginAction.register(data);
        //10 seconds before expiration: refresh
        const expirationDelay =
          decoded.exp - Math.floor(new Date().getTime() / 1000) - 10;
        console.log("The token expires in " + expirationDelay + " seconds");
        setTimeout(refreshToken, expirationDelay * 1000);

      }
    })
    .then(function(){
      renderDOM();
    })
    .catch(function (err) {
      if(err.status == 401) {
          window.location =
                "/.auth/login/aad?post_login_redirect_uri=" +
                encodeURIComponent(window.location.pathname + window.location.search);
      } else {
        console.log("Error "+ err.status +" : " + err.statusText);
      }

    });
}

function refreshToken() {
  Promise.resolve($.ajax({ url: "/.auth/refresh", type: "GET" })).then(
    function () {
      acquireToken();
    }
  );
}

var formalizeData = (data) => {
  return normalize(data, {});
};

function requireAuth(activity, nextState, replaceState) {
  if (!LoginStore.isLoggedIn()) {
    AuthApiUtils.login("/.auth/me");
  } else if (!LoginStore.isAuthorized(activity)) {
    replaceState({ nextPathname: nextState.location.pathname }, "/403");
  }
}

/**
 * Check if the user logged in is not an operator (pilot) before to continue
 * If he is, we redirect to 403
 */
function requireBackEndAuth(nextState, replaceState) {
  if (!LoginStore.isLoggedIn()) {
    AuthApiUtils.login("/.auth/me");
  } else if (LoginStore.isOperator()) {
    replaceState({ nextPathname: nextState.location.pathname }, "/403");
  }
}

function renderDOM() {
  console.log("RenderDOM");
  ReactDOM.render(
    <Router history={browserHistory}>
      <Route path="403" component={UnauthorizedAccess} />
      <Route path="404" component={PageNotFound} />
      <Route path="/" component={App}>
        <IndexRoute component={WelcomeWrapper} />

        <Route
        path="fuelrequests"
        component={FuelRequestsWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_FUEL_REQUESTS)}/>

      <Route
        path="suppliers"
        component={SuppliersWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_SUPPLIERS)}/>

      <Route
        path="suppliers/:id"
        component={SupplierWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_SUPPLIERS)}/>

      <Route
        name="airports"
        path="airports"
        component={AirportsWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_AIRPORTS)}/>

      <Route
        path="airports/:icao"
        component={AirportWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_AIRPORTS)}/>

      <Route
        path="platts"
        component={PlattsWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_PLATTS)}/>

      <Route
        path="plattsv2"
        component={Plattsv2Wrapper}
        onEnter={requireAuth.bind(this, Activities.READ_FUEL_PRICES)}/>

      <Route
        path="platts/:id"
        component={PlattWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_PLATTS)}/>

      <Route
        path="plattsv2/:icao/:supplier"
        component={Plattv2Wrapper}
        onEnter={requireAuth.bind(this, Activities.READ_PLATTS)}/>

      <Route
        path="contracts"
        component={ContractsWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_CONTRACTS)}/>

      <Route
        path="contracts/:id"
        component={ContractWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_CONTRACTS)}/>

      <Route
        path="fbos"
        component={FbosWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_FBOS)}/>

      <Route
        path="fbos/:id"
        component={FboWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_FBOS)}/>

      <Route
        path="/indexes"
        component={CalculatorWrapper}
        onEnter={requireAuth.bind(this, Activities.USE_FUEL_SIMULATOR)}/>

      <Route
        path="/prices"
        component={PricesWrapper}
        onEnter={requireAuth.bind(this, Activities.READ_FUEL_PRICES)}/>

      <Route
        path="/import"
        component={ImportsWrapper}
        onEnter={requireAuth.bind(this, Activities.IMPORT_CONTRACTS)}/>

      </Route>
      <Route
        path="/simulator"
        component={FrontEndApp}
        onEnter={requireAuth.bind(this, Activities.USE_FUEL_SIMULATOR)}
      >
        <IndexRoute component={CalculatorWrapper} />
      </Route>
      <Redirect from="*" to="/404" />
    </Router>,
    document.getElementById("app-wrapper")
  );
}
