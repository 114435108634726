import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import AirportsApiUtils from '../utils/AirportsApiUtils'

const AirportAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionAirports.LOAD_AIRPORTS
    })

    AirportsApiUtils.getAll('/api/airports');
  },

  getAirportById(icao) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionAirports.LOAD_AIRPORT
    })

    AirportsApiUtils.getOne('/api/airports/' + icao);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionAirports.SEARCH_AIRPORTS,
      field      : field,
      value      : value
    })
  }
};

export default AirportAction