import React from 'react';
import Griddle from 'griddle-react';
import _ from 'lodash';
import { Map } from 'immutable';

import { Activities } from '../../constants';
import { PermissionRequired, TableSearch, Loader } from '../../components';
import { search } from '../../components/TableSearch/TableSearch';
import {SupplierAction} from "../../actions";

export default React.createClass({
  propTypes: {
    suppliers     : React.PropTypes.array,
    loadingStatus : React.PropTypes.object,
    newButtonText : React.PropTypes.string,
    onNew         : React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      suppliers     : [],
      loadingStatus : Map({
        status: 'loading',
        message: 'Fetching data from server. Please wait.',
      })
    }
  },

  getInitialState() {
    return {
      suppliers: this.props.suppliers
    }
  },

  componentWillReceiveProps(nextProps){
    if(nextProps.searchParams && nextProps.searchParams.size > 0) {
      let filteredSuppliers = search(this.props.suppliers, nextProps.searchParams.toJS())
      this.setState({suppliers: filteredSuppliers})
    } else {
      this.setState({suppliers: nextProps.suppliers})
    }
  },

  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps.suppliers, this.props.suppliers)
           || this.props.loadingStatus.get('status') !== nextProps.loadingStatus.get('status');
  },

  render() {
    return (
      <PermissionRequired requiredRoles={Activities.READ_SUPPLIERS} user={this.props.user}>
        <TableSearch
          user           ={this.props.user}
          requiredRoles  ={Activities.WRITE_SUPPLIERS}
          columnMetadata ={this.props.columnMetadata}
          onSearch={this.handleSearch}
          isNewButton    ={this.props.isNewButton}
          newButtonText  ="New supplier"
          onNew          ={this.props.onNew} />
        <Griddle
          initialSort    ="name"
          results        ={this.state.suppliers}
          tableClassName ="table middle-td table-logo"
          resultsPerPage ={100}
          columnMetadata ={this.props.columnMetadata}
          columns        ={this.props.columnNames}
          noDataMessage  ="" />
      </PermissionRequired>
    )
  },

  handleSearch(e) {
    let {name, value} = e.target;
    SupplierAction.search(name, value);
  }
});
