import React from 'react';
import {List, Map} from 'immutable';
import commonUtils from '../../utils/commonUtils';
import {CurrenciesStore, LoginStore, PlattsStore, ValueFormStore, ValuesStore, VolumeUnitsStore} from '../../stores';
import {CurrenciesAction, ModalsAction, PlattAction, VolumeUnitsAction} from '../../actions';
import {Constants} from '../../constants';
import Platt from './Platt'

PlattsStore.initialize();
ValuesStore.initialize();
VolumeUnitsStore.initialize();
CurrenciesStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:                null,
      platt:               Map(),
      values:              List(),
      isPlattLoading:      true,
      isPlattError:        false,
      valueFormModalId:    commonUtils.guid(),
      valueDeleteDialogId: commonUtils.guid(),
      errCode:             null,
      volumeUnits:         List(),
      currencies:          List(),
    }
  },

  componentDidMount() {
    PlattsStore.addChangeListener(this.handlePlattStoreChange);
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    ValueFormStore.addChangeListener(this.handleValueFormStoreChange);
    ValuesStore.addChangeListener(this.handleValuesStoreChange);
    VolumeUnitsStore.addChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.addChangeListener(this.handleCurrenciesStoreChange);
    PlattAction.getPlattById(this.props.params.id);
    CurrenciesAction.fetchAll();
    VolumeUnitsAction.fetchAll();
  },

  componentWillUnmount() {
    PlattsStore.removeChangeListener(this.handlePlattStoreChange);
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    ValueFormStore.removeChangeListener(this.handleValueFormStoreChange);
    ValuesStore.removeChangeListener(this.handleValuesStoreChange);
    VolumeUnitsStore.removeChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.removeChangeListener(this.handleCurrenciesStoreChange);
  },

  render() {
    return <Platt {...this.state} />
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handlePlattStoreChange() {
    ModalsAction.hideValueForm(this.state.valueFormModalId);
    ModalsAction.hide(this.state.valueDeleteDialogId);
    this.setState({
      platt:          PlattsStore.get('platts').size > 0 ? PlattsStore.get('platts').get(this.props.params.id) : Map(),
      isPlattLoading: PlattsStore.get('loadingStatus') === Constants.LOADING,
      isPlattError:   PlattsStore.get('loadingStatus') === Constants.ERROR,
      errCode:        PlattsStore.get('errCode'),
      user: LoginStore.get('user')
    });
  },

  handleValuesStoreChange() {
    ModalsAction.hideValueForm(this.state.valueFormModalId);
    ModalsAction.hide(this.state.valueDeleteDialogId);
    this.setState({
      platt:               PlattsStore.get('platts').get(this.props.params.id),
      values:              ValuesStore.get('values'),
      loadingValuesStatus: ValuesStore.get('loadingStatus'),
      user: LoginStore.get('user')
    });
  },

  handleValueFormStoreChange() {
    this.setState({
      valueDataForm: ValueFormStore.state,
      user: LoginStore.get('user')
    });
  },

  handleVolumeUnitsStoreChange() {
    this.setState({
      volumeUnits: VolumeUnitsStore.get('units')
    });
  },

  handleCurrenciesStoreChange() {
    this.setState({
      currencies: CurrenciesStore.get('currencies'),
    });
  }
});
