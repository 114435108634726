import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
let formalizeData = (data) => {
  const user = new Schema('users');

  return normalize(data, {
    users: _.isArray(data.users) ? arrayOf(user) : user
  });
};

const UsersApiUtils = {
  getAll: function(url) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionUsers.LOAD_USERS_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionUsers.LOAD_USERS_ERROR,
          err: err
        });
      })
  },

  getOne: function(url) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionUsers.LOAD_USER_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionUsers.LOAD_USER_ERROR,
          err: err
        });
      })
  }
};

export default UsersApiUtils;
