import React from 'react';
import DatePicker from 'react-date-picker';
import {Col, Input, Row} from 'react-bootstrap';

require('./DatePicker.css');

export default React.createClass({
  getInitialState() {
    //We initiate the value by props
    return {
      isDatePickerVisible: false
    }
  },

  showDatePicker() {
    this.setState({isDatePickerVisible: true})
  },

  hideDatePicker() {
    this.setState({isDatePickerVisible: false})
  },

  getDatePickerStyle() {
    return {
      position: "absolute",
      width:    "300px",
      zIndex:   10
    }
  },

  componentDidMount() {
    document.body.addEventListener('click', this.handleDocumentClick);
    document.body.addEventListener('keyup', this.handleKeyPress, false);
  },

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleDocumentClick);
    document.body.removeEventListener('keyup', this.handleKeyPress);
  },

  handleDocumentClick(evt) {
    const datePickerArea = this.refs.datePickerArea;
    const inputArea = this.refs.inputArea;

    if (!(datePickerArea === undefined) && !datePickerArea.contains(evt.target) && !inputArea.contains(evt.target)) {
      this.hideDatePicker()
    }
  },

  handleDatePickerChange(v) {
    this.props.onBlur({
      target: {
        name:  this.props.name,
        value: v
      }
    });
    this.hideDatePicker();
  },

  handleInputBlur(v) {
    this.props.onBlur(v);
  },

  handleKeyPress(e) {
    if (e.keyCode === 9 && !(e.target.name === this.props.name)) {
      this.hideDatePicker()
    }
  },

  render() {
    return (
      <Row className="row-form-pad">
        <Col xs={this.props.labelClassName}>
          {this.props.label &&
          <label className={"control-label " + this.props.bsStyle}>
            <span>{this.props.label}</span>
          </label>
          }
        </Col>
        <Col xs={this.props.wrapperClassName}>
          <div ref="inputArea">
            <Input
              type='text'
              name={this.props.name}
              value={this.props.value}
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              onBlur={this.handleInputBlur}
              onFocus={this.showDatePicker}
              bsStyle={this.props.bsStyle}
              hasFeedback={this.props.hasFeedback}
            />
          </div>
          {this.getDatePicker()}
        </Col>
      </Row>
    )
  },

  getDatePicker() {
    if (this.state.isDatePickerVisible) {
      return (
        <div className="shadow-z-2" ref="datePickerArea" style={this.getDatePickerStyle()}>
          <DatePicker onChange={this.handleDatePickerChange} date={this.props.value}/>
        </div>
      )
    }
  },
});
