import React from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { PermissionRequired } from '../../components';
import { Action, ListActions } from '../../components';
import { ModalsAction } from '../../actions';
import { Activities } from '../../constants';

export default ({ rowData, metadata }) => {
  const handleRemoveClick = () => {
    ModalsAction.show(metadata.valueDeleteDialogId, {
      id: rowData.id,
      message: 'Are you sure you want to delete the value of the ' + moment(rowData.effectiveDate).format('YYYY-MM-DD') + ' ?'
    });
  };

  return (
    <ListActions>
      <PermissionRequired requiredRoles={Activities.WRITE_PLATTS_VALUES} user={metadata.user}>
        <Action icon="remove" onClick={handleRemoveClick} />
      </PermissionRequired>
    </ListActions>
  );
};
