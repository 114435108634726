import React from 'react';
import _ from 'lodash';
import {List, Map} from 'immutable';
import Calculator from './Calculator';
import commonUtils from '../../utils/commonUtils';
import {Constants} from '../../constants';
import {
  AirportsStore,
  CalculatorStore,
  CurrenciesStore,
  IndicesStore,
  LoginStore,
  SuppliersStore,
  VolumeUnitsStore
} from '../../stores';
import {CalculatorAction, CurrenciesAction, ModalsAction, SupplierAction, VolumeUnitsAction} from '../../actions';

CalculatorStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:                 null,
      calculatorSettings:   Map(),
      indices:              Map(),
      airports:             Map(),
      isPrivate:            null,
      suppliers:            Map(),
      currencies:           List(),
      volumeUnits:          List(),
      errCode:              null,
      isAirportsLoading:    true,
      isAirportsError:      false,
      isSuppliersLoading:   true,
      isSuppliersError:     false,
      isCurrenciesLoading:  true,
      isCurrenciesError:    false,
      isVolumeUnitsLoading: true,
      isVolumeUnitsError:   false,
      modalId:              commonUtils.guid(),
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    IndicesStore.addChangeListener(this.handleIndicesStoreChange);
    CalculatorStore.addChangeListener(this.handleCalculatorStoreChange);
    AirportsStore.addChangeListener(this.handleAirportsStoreChange);
    SuppliersStore.addChangeListener(this.handleSuppliersStoreChange);
    VolumeUnitsStore.addChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.addChangeListener(this.handleCurrenciesStoreChange);
    SupplierAction.fetchAll();
    CurrenciesAction.fetchAll();
    VolumeUnitsAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    IndicesStore.removeChangeListener(this.handleIndicesStoreChange);
    CalculatorStore.removeChangeListener(this.handleCalculatorStoreChange);
    AirportsStore.removeChangeListener(this.handleAirportsStoreChange);
    SuppliersStore.removeChangeListener(this.handleSuppliersStoreChange);
    VolumeUnitsStore.removeChangeListener(this.handleVolumeUnitsStoreChange);
    CurrenciesStore.removeChangeListener(this.handleCurrenciesStoreChange);
  },

  render() {
    return <Calculator {...this.state} />;
  },

  getFuelIndices() {
    var trip = this.state.calculatorSettings.get('trip');
    var isPrivate = this.state.calculatorSettings.get('isPrivate');
    if (trip.length > 0) {
      CalculatorAction.getFuelIndices(trip, isPrivate);
    } else {
      this.setState({
        indices: Map()
      })
    }
  },

  hideModals() {
    if (!CalculatorStore.get('airportFormError')) {
      ModalsAction.hideCalculatorAirportModal(this.state.modalId);
    }
  },

  handleSuppliersStoreChange() {
    this.setState({
      suppliers:          SuppliersStore.get('suppliers'),
      isSuppliersLoading: SuppliersStore.get('loadingStatus') === Constants.LOADING,
      isSuppliersError:   SuppliersStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleCalculatorStoreChange() {
    this.hideModals();
    this.setState({calculatorSettings: CalculatorStore.state});
    // IMPORTANT: Force clean the indices to force re-rendering before getting new data
    // If this is not done, then rowspan of the indices table will bug
    this.setState({
      indices: Map(),
      user: LoginStore.get('user')
    });
    this.getFuelIndices();
  },

  handleAirportsStoreChange() {
    this.setState({
      airports:          AirportsStore.get('airports'),
      airportsOptions:   this.parseAirportsToOptions(AirportsStore.get('airports')),
      isAirportsLoading: AirportsStore.get('loadingStatus') === Constants.LOADING,
      isAirportsError:   AirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleContractAirportsStoreChange() {
    this.hideModals();
    this.setState({
      contractAirports:          ContractAirportsStore.get('contractAirports'),
      isContractAirportsLoading: ContractAirportsStore.get('loadingStatus') === Constants.LOADING,
      isContractAirportsError:   ContractAirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleVolumeUnitsStoreChange() {
    this.setState({
      volumeUnits:          VolumeUnitsStore.get('units'),
      isVolumeUnitsLoading: VolumeUnitsStore.get('loadingStatus') === Constants.LOADING,
      isVolumeUnitsError:   VolumeUnitsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleIndicesStoreChange() {
    this.setState({
      indices:         IndicesStore.get('indices'),
      isPricesLoading: IndicesStore.get('loadingStatus') === Constants.LOADING,
      isPricesError:   IndicesStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleCurrenciesStoreChange() {
    this.setState({
      currencies:          CurrenciesStore.get('currencies'),
      isCurrenciesLoading: CurrenciesStore.get('loadingStatus') === Constants.LOADING,
      isCurrenciesError:   CurrenciesStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleContractAirportFormStoreChange() {
    this.setState({contractAirportForm: ContractAirportFormStore.state,
      user: LoginStore.get('user')});
  },

  parseAirportsToOptions(airports) {
    let options = List();
    _.each(airports.toJS(), (airport) => {
      options = options.push({
        value: airport.icao,
        label: airport.icao + " - " + airport.name,
        data:  airport
      })
    })

    return options
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  }
});
