import Dispatcher from '../core/Dispatcher';
import _ from 'lodash'
import  { Map, fromJS } from 'immutable';
import assign from 'object-assign';

import { Constants } from '../constants';
import BaseStore from './BaseStore';
import TaxesStore from './TaxesStore';

const AirportsStore = assign({}, BaseStore, {
  initialize() {
    AirportsStore.setState({
      airports      : Map(),
      search        : Map(),
      loadingStatus : Constants.LOADING,
      errCode       : null
    });
  },

  dispatchToken: Dispatcher.register(function(payload) {
    const action = payload.action;
    var text;

    switch(action.actionType) {
      case Constants.ActionAirports.LOAD_AIRPORT_ERROR:
      case Constants.ActionAirports.LOAD_AIRPORTS_ERROR:
        AirportsStore.setState({ 
          loadingStatus: Constants.ERROR,
          errCode      : action.err && action.err.status ? action.err.status : '500'
        });
        AirportsStore.emitChange();
        break;

      case Constants.ActionAirports.LOAD_AIRPORT_SUCCESS:
      case Constants.ActionAirports.LOAD_AIRPORTS_SUCCESS:
        //case Constants.ActionContracts.LOAD_CONTRACT_SUCCESS:
        AirportsStore.setState({
          airports      : fromJS(action.data.entities.airports),
          loadingStatus : Constants.LOADED
        })
        AirportsStore.emitChange();
        break;

      case Constants.ActionAirports.SEARCH_AIRPORTS:
        if(_.isUndefined(AirportsStore.get('search').get(action.field))) {
          var nextData = AirportsStore.get('search').set(action.field , action.value);
        } else {
          var nextData = AirportsStore.get('search').update(action.field, v => v = action.value);
        }
        AirportsStore.setState({ search: nextData })
        AirportsStore.emitChange();
        break;

      case Constants.ActionTaxes.DELETE_TAX_SUCCESS:
        var taxId     = action.data.result.taxes;
        var airports = action.data.entities.taxes[taxId].Airports;
        var nextData = AirportsStore.get('airports').update(String(airports[0]), v =>
          v = v.update("Taxes", t =>
            t = t.delete(t.indexOf(taxId))
        ));
        AirportsStore.setState({ airports: nextData })
        AirportsStore.emitChange();
        break;

      case Constants.ActionTaxes.CREATE_TAX_SUCCESS:
        Dispatcher.waitFor([TaxesStore.dispatchToken]);
        var taxId    = action.data.result.taxes;
        var airports = action.data.entities.taxes[taxId].Airports;

        var nextData = AirportsStore.get('airports').update(String(airports[0]), v =>
          v = v.update("Taxes", ca => ca = ca.push(taxId))
        );
        AirportsStore.setState({ airports: nextData })
        AirportsStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default AirportsStore;
