import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { Map, fromJS } from 'immutable';
import _ from 'lodash'

import TaxFormStore from './TaxFormStore'
import SupplierFormStore from './SupplierFormStore'
import PlattFormStore from './PlattFormStore'
import ValueFormStore from './ValueFormStore'
import ContractFormStore from './ContractFormStore'
import ContractAirportFormStore from './ContractAirportFormStore'
import FboFormStore from './FboFormStore'
import SupplierAirportFormStore from './SupplierAirportFormStore'
import ImportFormStore from './ImportFormStore'
import FboAirportFormStore from './FboAirportFormStore';

const ModalsStore = assign({}, BaseStore, {
  initialize() {
    ModalsStore.setState({
      modals: Map()
    });
  },

  getModal(id) {
    return this.get('modals').get(id);
  },

  getAll() {
    return this.get('modals');
  },

  isModalVisible(id){
    return this.get('modals').get(id) && this.get('modals').get(id).isVisible ? this.get('modals').get(id).isVisible : false;
  },

  getPayload(id) {
    return this.get('modals').get(id) && this.get('modals').get(id).payload ? this.get('modals').get(id).payload : Map();
  },

  hideModal(id) {
    var _modals = ModalsStore.get('modals');
    if(_modals.get(id)) {
      ModalsStore.setState({
        modals: _modals.update(id, v => v = {id: id, isVisible: false, payload: Map() })
      })
      ModalsStore.emitChange();
    }
  },

  showModal(id, payload) {
    var _modals = ModalsStore.get('modals');
    if(_modals.get(id)) {
      ModalsStore.setState({
        modals: _modals.update(id, v => v = {id: id, isVisible: true, payload: !_.isUndefined(payload) ? fromJS(payload) : null })
      })
      ModalsStore.emitChange();
    }
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;

    switch(action.actionType) {
      case Constants.ActionModals.SHOW_MODAL:
        ModalsStore.showModal(action.modalId, action.payload);
        ModalsStore.emitChange();
        break;
      case Constants.ActionModals.HIDE_MODAL:
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.ADD_MODAL:
        ModalsStore.setState({
          modals: ModalsStore.get('modals').set(action.modalId, {id: action.modalId, isVisible: false})
        });
        ModalsStore.emitChange();
        break;
      case Constants.ActionModals.SHOW_TAX_FORM_MODAL:
        Dispatcher.waitFor([TaxFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_TAX_FORM_MODAL:
        Dispatcher.waitFor([TaxFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_SUPPLIER_FORM_MODAL:
        Dispatcher.waitFor([SupplierFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_SUPPLIER_FORM_MODAL:
        Dispatcher.waitFor([SupplierFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_PLATT_FORM_MODAL:
        Dispatcher.waitFor([PlattFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_PLATT_FORM_MODAL:
        Dispatcher.waitFor([PlattFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_VALUE_FORM_MODAL:
        Dispatcher.waitFor([ValueFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_VALUE_FORM_MODAL:
        Dispatcher.waitFor([ValueFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_CONTRACT_FORM_MODAL:
        Dispatcher.waitFor([ContractFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_CONTRACT_FORM_MODAL:
        Dispatcher.waitFor([ContractFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_CONTRACT_AIRPORT_FORM_MODAL:
        Dispatcher.waitFor([ContractAirportFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_CONTRACT_AIRPORT_FORM_MODAL:
        Dispatcher.waitFor([ContractAirportFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_FBO_FORM_MODAL:
        Dispatcher.waitFor([FboFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_FBO_FORM_MODAL:
        Dispatcher.waitFor([FboFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_IMPORT_RESULTS_MODAL:
        // Dispatcher.waitFor([ContractStaticFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_IMPORT_RESULTS_MODAL:
        // Dispatcher.waitFor([ContractStaticFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_FBO_AIRPORT_FORM_MODAL:
        Dispatcher.waitFor([FboAirportFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_SUPPLIER_AIRPORT_FORM_MODAL:
        Dispatcher.waitFor([SupplierAirportFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_FBO_AIRPORT_FORM_MODAL:
        Dispatcher.waitFor([FboAirportFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_SUPPLIER_AIRPORT_FORM_MODAL:
        Dispatcher.waitFor([SupplierAirportFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_CALCULATOR_AIRPORT_MODAL:
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_CALCULATOR_AIRPORT_MODAL:
        ModalsStore.hideModal(action.modalId);
        break;
      case Constants.ActionModals.SHOW_IMPORT_MODAL:
        Dispatcher.waitFor([ImportFormStore.dispatchToken]);
        ModalsStore.showModal(action.modalId);
        break;
      case Constants.ActionModals.HIDE_IMPORT_MODAL:
        Dispatcher.waitFor([ImportFormStore.dispatchToken]);
        ModalsStore.hideModal(action.modalId);
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ModalsStore;
