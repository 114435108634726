import React from 'react';
import  { Map } from 'immutable';
import { Button, Input, ButtonToolbar, Col, Row } from 'react-bootstrap';
import _ from 'lodash';

import { DatePicker, SupplierDropdown, InlineAlert, Save } from '../../components';
import { ContractFormAction } from '../../actions';
import formatter from '../../utils/formatter'

export default React.createClass({
  propTypes: {
    data : React.PropTypes.object
  },

  getDefaultProps(){
    return {
      data : Map()
    }
  },

  getInitialState(){
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  },

  render() {
    return (
      <form className="form-horizontal">
        <Row>
          <Input
            name        ='reference'
            type             ='text'
            value            ={this.state.data.get('reference').get('value')}
            label            ='Reference'
            placeholder      ='Reference *'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'
            bsStyle          ={this.getValidClass(this.state.data.get('reference').get('isValid'))}
            hasFeedback
          />
        </Row>
        <Row className="row-form-pad">
          <Col xs={3}>
            <label className="control-label">
              <span>Supplier</span>
            </label>
          </Col>
          <Col xs={9}>
            <SupplierDropdown
              value    ={this.state.data.get('supplierId').get('value')}
              options  ={this.getSuppliersOptions()}
              onChange ={this.handleBlur}
            />
          </Col>
        </Row>
        <DatePicker
          name             ='validFrom'
          value            ={this.state.data.get('validFrom').get('value')}
          type             ='text'
          label            ="Valid From"
          placeholder      ='YYYY-MM-DD *'
          onChange         ={this.handleChange}
          onBlur           ={this.handleBlur}
          labelClassName   ={3}
          wrapperClassName ={9}
          bsStyle          ={this.getValidClass(this.state.data.get('validFrom').get('isValid'))}
          hasFeedback
        />
        <DatePicker
          name             ='validUntil'
          value            ={this.state.data.get('validUntil').get('value')}
          type             ='text'
          label            ="Valid Until"
          placeholder      ='YYYY-MM-DD *'
          onChange         ={this.handleChange}
          onBlur           ={this.handleBlur}
          labelClassName   ={3}
          wrapperClassName ={9}
          bsStyle          ={this.getValidClass(this.state.data.get('validUntil').get('isValid'))}
          hasFeedback
        />
        <Row>
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{ marginTop: 10 }}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </Row>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')} />
        </ButtonToolbar>
      </form>
    )
  },

  handleChange(e) {
    let { name, value} = e.target;
    var nextState      = this.state.data.toObject();
    nextState[name]    = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    let { name, value} = e.target;
    ContractFormAction.change(name, value)
  },

  getValidClass(isValid){
    if(isValid === null) return null
    return isValid ? 'success' : 'error';
  },

  getSuppliersOptions(){
    var options = [];
    _.chain(this.props.suppliers.toJS())
      .sortBy(supplier => {
        return supplier.name
      })
      .each(supplier => {
        options.push({
          value: supplier.id,
          label: supplier.name,
          data : supplier
        })
      })
      .value();
    console.log('We are returning suppliers count: ' + options.length);
    return options;
  },

  handleCancelClick() {
    this.props.onCancel();
  },

  handleSaveClick() {
    var _self = this;
    var data = {};
    _.each(_self.props.data.toJS(), function(prop, key){
      data[key] = prop.value;
    });

    this.props.onSave(data);
  },
});
