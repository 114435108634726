import React from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '../../components';
import PureRenderMixin from 'react-addons-pure-render-mixin';

export default React.createClass({
  mixins: [PureRenderMixin],
  render() {
    return (
      <Button
        bsStyle  ='success'
        style={{ width: 100 }}
        onClick  ={this.props.onSave}
        disabled ={!!this.props.disabled}>
        {this.props.disabled ? <Icon fa="spinner" fa-spin /> : 'Save' }
      </Button>
    )
  }
});


