import React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import {Col, Row} from 'react-bootstrap';
import {DefaultModal, IconButton, SubTitle} from '../../components';
import {fromJS, List, Map} from 'immutable';
import {ModalsAction} from "../../actions";
import {importFormStyles} from "../../styles/base.styles";

const ResultItem = React.createClass({

  render() {
    let {data} = this.props;

    return (
      <tr className={data.get('status') === 'success' ? "text-success" : "text-danger"}>
        <td style={importFormStyles.td}>{data.get('line') + '.'}</td>
        <td style={importFormStyles.td}>{data.get('message')}</td>
      </tr>
    )
  }
});

export default React.createClass({
  propTypes: {
    contract:  React.PropTypes.object,
    suppliers: React.PropTypes.object,
    results:   React.PropTypes.object
  },

  getDefaultProps() {
    return {
      contract:  Map(),
      suppliers: Map(),
      results:   List(),
    }
  },

  getInitialState() {
    return {
      contract:  this.props.contract,
      suppliers: this.props.suppliers,
      results:   this.props.results
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      contract:  nextProps.contract,
      suppliers: nextProps.suppliers,
      results:   nextProps.results
    });
  },

  render() {
    return (
      <div>
        <SubTitle>{this.props.contract.get('isImport') ? 'Import' : 'Contract'}</SubTitle>

        <DefaultModal
          id={this.props.importResultsModalId}
          bsSize="medium"
          onHide={this.closeImportResultModal}
          title='Import Failures'>
          <div style={importFormStyles.resultsWrapper}>
            <table style={importFormStyles.table}>
              <thead>
              <tr>
                <th style={importFormStyles.td}>Line</th>
                <th style={importFormStyles.td}>Result</th>
              </tr>
              </thead>
              <tbody>
              {this.getResults().map((row, key) => {
                return <ResultItem key={key} data={row}/>
              })}
              </tbody>
            </table>
          </div>
        </DefaultModal>

        <form className="form-horizontal details-wrapper">
          <Row>
            <Col xs={3}>
              <label>{this.props.contract.get('isImport') ? 'Name of file' : 'Reference'}</label>
            </Col>
            <Col xs={3}>
              <label>Supplier</label>
            </Col>
            <Col xs={3}>
              <label>{this.props.contract.get('isImport') ? 'Date of load' : 'Valid From'}</label>
            </Col>
            <Col xs={3}>
              <label>{this.props.contract.get('isImport') ? 'Number of prices uploaded' : 'Valid Until'}</label>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <span>{this.props.contract.get('reference')}</span>
            </Col>
            <Col xs={3}>
              <span>{this.getSupplierName()}</span>
            </Col>
            <Col xs={3}>
              <span>{this.props.contract.get('isImport') ? this.formatDate(this.props.contract.get('createdAt')) : this.formatDate(this.props.contract.get('validFrom'))} </span>
            </Col>
            <Col xs={3}>
              <span>
                {this.props.contract.get('isImport') ?
                  this.props.contract.get('isPublic') ?
                    this.props.contract.get('ContractAirports').size : this.props.contract.get('Values').size
                  : this.formatDate(this.props.contract.get('validUntil'))}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
              <label>{this.props.contract.get('isImport') ? 'Number of failures' : ''}</label>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
            </Col>
            <Col xs={3}>
              <span>{this.props.contract.get('isImport') ? this.formatNumberOfFailures(this.props.contract.toJS()) + ' ' : ''}</span>
              <span>{this.props.contract.get('isImport') ?
                <IconButton fa="search" onClick={this.handleShowFailures.bind(this, this.props.contract.toJS())}/> : ''}</span>
            </Col>
          </Row>
        </form>
      </div>)
  },

  formatDate(date) {
    return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null;
  },

  getSupplierName() {
    if (this.props.suppliers.size > 0) {
      return this.props.suppliers.get(String(this.props.contract.get('supplierId'))).get('name')
    } else {
      return null
    }
  },

  formatNumberOfFailures(contract) {
    if (contract.importStatus && contract.importStatus.data) {
      try {
        let bufferOriginal = Buffer.from(contract.importStatus.data);
        let parsedContent = JSON.parse(bufferOriginal.toString());
        return parsedContent ? _.filter(parsedContent, (r) => !(r.status === 'success')).length : 0;
      }
      catch(ex){
        console.error(ex);
        return 'Unknown'
      }
    }
    return 0;
  },

  handleShowFailures(contract) {
    console.log('handleShowFailures has been clicked for contract ' + contract.id + ' opening modal ' + this.props.importResultsModalId);
    let results = [];
    if (contract.importStatus && contract.importStatus.data) {
      let bufferOriginal = Buffer.from(contract.importStatus.data);
      let parsedContent = JSON.parse(bufferOriginal.toString());
      results = _.filter(parsedContent, (r) => !(r.status === 'success'));
      this.setState({
        results: results
      });
      console.log('State set with ' + results.length + ' entries');
      console.log('this.props.results length is ' + this.state.results.length);
    }

    ModalsAction.showImportResults(this.props.importResultsModalId);
  },

  closeImportResultModal() {
    ModalsAction.hide(this.props.importResultsModalId);
  },

  getResults() {
    return fromJS(this.state.results);
  }
});
