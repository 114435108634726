import React from 'react';
import {List, Map} from 'immutable';

import {LoginStore, Plattsv2Store} from '../../stores';
import {Constants} from '../../constants';
import {Plattv2Action} from '../../actions';
import Platts from './Platts'

Plattsv2Store.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:            null,
      platts:          List(),
      searchParams:    Map(),
      isPlattsLoading: true,
      isPlattsError:   false,
      errCode:         null,
      plattDataForm:   Map(),
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    Plattsv2Store.addChangeListener(this.handlePlattStoreChange);
    Plattv2Action.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    Plattsv2Store.removeChangeListener(this.handlePlattStoreChange);
  },

  render() {
    return (<Platts {...this.state} />)
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handlePlattStoreChange() {
    this.setState({
      platts:          Plattsv2Store.get('platts'),
      searchParams:    Plattsv2Store.get('search'),
      isPlattsLoading: Plattsv2Store.get('loadingStatus') === Constants.LOADING,
      isPlattsError:   Plattsv2Store.get('loadingStatus') === Constants.ERROR,
      errCode:         Plattsv2Store.get('errCode'),
      user: LoginStore.get('user')
    })
  }
});
