import React from 'react';
import {fromJS} from 'immutable';
import {Col, Row} from 'react-bootstrap';
import {PageError, PageLifcycle, PageLoaded, PageLoading} from '../PageLifecycle'
														   
import {Loader, Oops, Page, PageTitle} from '../../components';
import PlattStaticForm from './PlattStaticForm';
import PlattValues from './PlattValues';

export default React.createClass({
  render() {
    const platt = this.props.platt.toJS();
    const values = this.props.values.toJS();

    const filteredValues = Object.keys(platt).length && platt.Values && platt.Values.map(v => {
      return values[v];
    });

    return (
      <Page>
        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={!!this.props.isPlattLoading}>
            <Loader>
              Fetching platts from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={!!this.props.isPlattError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!!(!this.props.isPlattError && !this.props.isPlattLoading && this.props.platt.size)}>
            <PageTitle icon="line-chart">{this.getPageTitle()}</PageTitle>
            <Row>
              <Col xs={12}>
                <PlattStaticForm data={this.props.platt}/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PlattValues
                  user={this.props.user}
                  platt={this.props.platt}
                  values={fromJS(filteredValues)}
                  valueDeleteDialogId={this.props.valueDeleteDialogId}
                />
              </Col>
            </Row>
          </PageLoaded>
        </PageLifcycle>
      </Page>
    )
  },

  getPageTitle() {
    return 'Historical Price at ' + this.props.platt.get('icao') + ' for ' + this.props.platt.get('supplier');
  }
});
