import React from 'react';

import {
  CellFormat,
  DatePicker,
  DefaultModal,
  Dropbox,
  FileImport,
  Loader,
  LogoControl,
  Oops,
  PageTitle,
  SupplierDropdown,
  YesNoDialog
} from '../../components';
import {ContractsAction, ImportAction, ModalsAction} from '../../actions';

import ContractsListActions from './ImportsListActions';
import ContractsList from './ImportsList';
import ContractForm from '../contract/ContractForm';
import _ from "lodash";
import {PageError, PageHelper, PageLifcycle, PageLoaded, PageLoading} from "../PageLifecycle";
import {ImportFormStore} from "../../stores";

var columnNames = ['reference', 'supplierName', 'isPublic', 'createdAt', 'createdBy', 'nbrAirports', 'id'];
//var columnNames = ['reference', 'supplierName', 'isPublic', 'createdAt', 'nbrAirports', 'id'];
export default React.createClass({

  getColumnMetadata() {
    return [{
      columnName:  'reference',
      displayName: 'Name of file',
      searchInput: 'text'
    }, {
      columnName:  'supplierName',
      displayName: 'Supplier',
      searchInput: 'text'
    }, {
      columnName:      'isPublic',
      displayName:     'Contract',
      customComponent: props => {
        return (<p>{props.rowData.isPublic ? 'Secondary' : 'Primary'}</p>)
      }
    }, {
      columnName:      'createdAt',
      displayName:     'Date of load',
      customComponent: props => <CellFormat.ISODate value={props.rowData.createdAt}/>,
      searchInput:     'text'
    },
    {
      columnName:      'createdBy',
      displayName:     'Imported By',
      customComponent: props => {
        return (<p>{props.rowData.createdBy}</p>)
      },
      searchInput:     'text'
    },
    {
      columnName:      'nbrAirports',
      displayName:     'Nbr. Prices',
      searchInput:     false,
      customComponent: props => {
        return (<p>{props.rowData.nbrAirports}</p>)
      }
    }, {
      columnName:             "id",
      displayName:            "",
      cssClassName:           "actionsColumn",
      contractDeleteDialogId: this.props.contractDeleteDialogId,
      contractFormModalId:    this.props.contractFormModalId,
      customComponent:        ContractsListActions,
      searchInput:            'actions',
      requiredReadRoles:      this.props.requiredReadRoles,
      requiredWriteRoles:     this.props.requiredWriteRoles,
      user:                   this.props.user
    }];
  },

  getModalTitle() {
    return this.props.contractDataForm.get('id') && this.props.contractDataForm.get('id').get('value') ? "Edit Import" : "New Import";
  },

  closeModal() {
    ModalsAction.hideContractForm(this.props.contractFormModalId);
  },

  handleSaveContract(contract) {
    const save = contract.id ? ContractsAction.update : ContractsAction.create;
    save(contract);
  },

  handleConfirmDeleteClick(contract) {
    ContractsAction.delete(contract.get('id'))
  },

  handleCancelDeleteClick() {
    ModalsAction.hide(this.props.contractDeleteDialogId);
  },

  handleImportClick() {
    ModalsAction.showImportModal(this.props.fuelImportDialogId);
  },

  handleImportCancelClick() {
    ModalsAction.hideImportModal(this.props.fuelImportDialogId);
  },

  handleImportContinueClick() {
    // Just close modal
    this.handleImportCancelClick();
    // Check if we have to redirect
    if (this.props.newContractId == null) {
      console.log('We did not found any newContractId');
    } else {
      console.log('We found a newContractId: ' + this.props.newContractId);
      window.location = 'contracts/' + this.props.newContractId;
    }
  },

  getImportPreview() {
    if (this.props.droppedFile || (this.state && this.state.droppedFile)) {
      let base64 = 'test';
      return <LogoControl base64={base64} message={this.state.message} onDelete={this.handleRemoveFile}/>
    } else {
      return <Dropbox onDrop={this.handleImportDrop}>Drag and drop file here, or click to select file to upload.</Dropbox>
    }
  },

  handleRemoveFile() {
    // save file on state
    this.setState({
      droppedFile: null,
      message:     ''
    });
  },

  handleImportDrop(files) {
    console.log('Received files: ', files);

    if (files && _.isArray(files)) {
      // save file on state
      this.setState({
        droppedFile: files[0],
        rowsToSkip:  0,
        message:     'Dropped file: ' + files[0].name
      });

      // sanitize filename to search a match in suppliers list. ( use slice to remove extension )
      let filename = (files[0].name || 'unknown').slice(0, -4).replace(/[^A-Z]/ig, '').toUpperCase();
      console.log('Filename sanitized is ' + filename);

      // workaround for known unmatch
      switch (filename) {
        case 'SBA':
          this.setState({
            rowsToSkip: 2
          });
          break;
        case 'PRICENOTIFICATION':
        case 'PRICENOTIFICATIONSHELL':
        case 'PRICENOTIFICATIONSHELLPAP':
          filename = 'SHELL';
          break;
        case 'SAMPLEPNR':
        case 'WFSCORPPRICEUSDUSG':
          filename = 'WFS';
          break;
        case 'GLOBJET':
          filename = 'AVFUEL';
          break;
        case 'ATIPRICEBOOKYYY':
          filename = 'AIRTOTAL';
          break;
        default:
          console.log('We did not match any entry for filename ' + filename);
          break;
      }

      // search for a match in suppliers list
      let autoSupplierId = _.result(_.find(this.props.suppliers.toJS(), function (obj) {
        console.log('TRACE: ' + obj.name.replace(/[^A-Z]/ig, '').toUpperCase() + '-------' + filename);
        return obj.name.replace(/[^A-Z]/ig, '').toUpperCase().indexOf(filename) > -1 || filename.indexOf(obj.name.replace(/[^A-Z]/ig, '').toUpperCase()) > -1;
      }), 'id');

      // based on filename, update supplier dropdown. ( only if nothing was selected before )
      //this.setState({supplierId: autoSupplierId});
      if (ImportFormStore.get('supplierId') == null) {
        ImportAction.changeSupplier('supplierId', autoSupplierId);
        console.log('Detected supplierId is ' + autoSupplierId);
      } else {
        console.log('We already had a supplierId');
      }

    }
  },

  handleImportSaveClick() {
    let fileType = this.state.droppedFile.type, fileName = this.state.droppedFile.name;
    $('#noSupplierSelected, #invalidFileProvided, #noTypeSelected').hide();
    if (this.props.importType) {
      if (this.state.droppedFile && (fileName.toLowerCase().indexOf('.xls') > -1 ||
        fileName.toLowerCase().indexOf('.csv') > -1 || fileType === 'text/csv' ||
        fileName.toLowerCase().indexOf('.pdf') > -1 || fileType === 'application/pdf')) {
        if (this.props.supplierId) {
          const data = new FormData();
          data.append('file', this.state.droppedFile);

          if (fileType === 'text/csv' || fileName.toLowerCase().indexOf('.csv') > -1) {
            console.log('Fine, file is a CSV for ' + this.props.supplierId);
            //NotificationManager.success('File type is fine: CSV', 'Success');
            ImportAction.importFuelPrice(this.props.supplierId, 'csv', data, null, this.props.effectiveDate, this.props.validUntil, this.props.importType);
          } else if (fileName.indexOf('.xls') > -1) {
            console.log('Fine, file is a XLS for ' + this.props.supplierId);
            //NotificationManager.success('File type is fine: PDF', 'Success');
            ImportAction.importFuelPrice(this.props.supplierId, 'xls', data, this.state.rowsToSkip, this.props.effectiveDate, this.props.validUntil, this.props.importType);
          } else if (fileType === 'application/pdf' || fileName.toLowerCase().indexOf('.pdf') > -1) {
            console.log('Fine, file is a PDF for ' + this.props.supplierId);
            //NotificationManager.success('File type is fine: PDF', 'Success');
            ImportAction.importFuelPrice(this.props.supplierId, 'pdf', data, null, this.props.effectiveDate, this.props.validUntil, this.props.importType);
          }
        } else {
          console.log('Not good, no supplier selected');
          $('#noSupplierSelected').show();
        }
      } else {
        console.log('Not good, file is unacceptable');
        $('#invalidFileProvided').show();
      }
    } else {
      console.log('Not good, no type selected');
      $('#noTypeSelected').show();
    }
  },

  handleChange(e) {
    let {name, value} = e.target;
    let nextState = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState(nextState);
  },

  handleBlur(e) {

    //We upadte the state before
    //this.handleChange(e);
    let {name, value} = e.target;

    if (name === 'supplierId') {
      ImportAction.changeSupplier(name, value);
    } else {
      ImportAction.change(name, value);
    }
  },

  getSuppliersOptions() {
    let options = [];
    _.chain(this.props.suppliers.toJS())
      .sortBy(supplier => {
        return supplier.name
      })
      .each(supplier => {
        options.push({
          value: supplier.id,
          label: supplier.name,
          data:  supplier
        })
      })
      .value();
    console.log('We are returning suppliers count: ' + options.length);
    return options;
  },

  render() {
    return (
      <div className="data-table">
        <PageTitle icon="upload">List of imports</PageTitle>
        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isContractsLoading || this.props.isSuppliersLoading}>
            <Loader>
              Fetching contracts from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isContractsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isContractsError && !this.props.isContractsLoading && this.props.contracts.size === 0}>
            <p>You are currently in the imports list. There is no import done yet.</p>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isContractsError && !this.props.isContractsLoading && !(this.props.contracts.size === 0)}>
            <ContractsList
              user={this.props.user}
              contracts={this.props.contracts}
              columnMetadata={this.getColumnMetadata()}
              columnNames={columnNames}
              loadingStatus={this.props.loadingContractsStatus}
              searchParams={this.props.searchParams}
              isImportButton={true}
              onImport={this.handleImportClick}
            />

            <DefaultModal
              id={this.props.fuelImportDialogId}
              bsSize="large"
              onHide={this.handleImportCancelClick}
              title={'Import price data from CSV/PDF files'}>

              <label>Choose Supplier (automatic parsing) </label>
              <SupplierDropdown
                value={this.props.supplierId}
                options={this.getSuppliersOptions()}
                onChange={this.handleBlur}
              />

              <label style={{marginTop: '20px'}}>Price Effective Date</label>
              <DatePicker
                name='effectiveDate'
                value={this.props.effectiveDate}
                type='text'
                placeholder='When not set, we will load date from file '
                labelClassName={12}
                wrapperClassName={12}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />

              <label style={{marginTop: '20px'}}>Price Valid Until</label>
              <DatePicker
                name='validUntil'
                value={this.props.validUntil}
                type='text'
                placeholder='When not set, we will ignore it, which means UFN'
                labelClassName={12}
                wrapperClassName={12}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />

              <label style={{marginTop: '20px'}}>Import type</label>
              <div className="radio">
                <label>
                  <input type="radio" value="contract" name='importType' onChange={this.handleBlur}/>
                  Primary Contract
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" value="noContract" name='importType' onChange={this.handleBlur}/>
                  Secondary Contract
                </label>
              </div>

              <label style={{marginTop: '20px'}}>File to import</label>
              <FileImport
                data={this.props.import}
                getPreview={this.getImportPreview}
                handleDrop={this.handleImportDrop}
                onSave={this.handleImportSaveClick}
                onCancel={this.handleImportCancelClick}
                onContinue={this.handleImportContinueClick}/>
            </DefaultModal>

          </PageLoaded>
        </PageLifcycle>

        <DefaultModal id={this.props.contractFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <ContractForm
            onSave={this.handleSaveContract}
            onCancel={this.closeModal}
            data={this.props.contractDataForm}
            suppliers={this.props.suppliers}/>
        </DefaultModal>
        <YesNoDialog
          id={this.props.contractDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick}/>
      </div>
    )
  }

});
