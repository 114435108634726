import React from 'react';
import _ from 'lodash'
import moment from 'moment'
import  { Map, List } from 'immutable';
import { Tabs, Tab, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import {PageLifcycle, PageHelper, PageLoaded} from '../PageLifecycle'

import { ActionAirports, ActionTaxes } from '../../constants/Constants';
import { PermissionRequired, NoItemHelper, SubTitle } from '../../components';

import TaxesList from '../taxes/TaxesList'

export default React.createClass({
  propTypes: {
    airport        : React.PropTypes.object,
    taxes          : React.PropTypes.object,
    taxFormModalId : React.PropTypes.number,
  },

  getDefaultProps(){
    return {
      airport : Map(),
      taxes   : Map()
    }
  },

  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps.taxes.toJS(), this.props.taxes.toJS())
          || !_.isEqual(nextProps.airport.toJS(), this.props.airport.toJS())
          || !_.isEqual(nextProps.taxFormModalId, this.props.taxFormModalId)
          || this.props.user !== nextProps.user
  },

  render() {
    return (
      <div>
        <SubTitle>Taxes</SubTitle>
         {/* Page wrapper */}
        <PageLifcycle>

          {/* Displaying when no items */}
          <PageHelper isDisplayed ={this.props.taxes.size === 0}>
            <NoItemHelper onNew={this.props.onNew} buttonLabel ="Add tax">
              <p>You are currently in the <strong>airport's</strong> details. There is no registered taxes yet for this airport.</p>
              <p>To get you started, you can add a tax. You can add as many tax as you like.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!(this.props.taxes.size === 0)}>
            <Tabs>
              <Tab eventKey={1} title='Applicable Taxes'>
                <PermissionRequired requiredRoles={this.props.requiredWriteRoles} user={this.props.user}>
                  <ButtonToolbar style={{'textAlign': 'right'}}>
                    <Button bsStyle='success' onClick={this.props.onNew}>
                      New Tax
                    </Button>
                  </ButtonToolbar>
                </PermissionRequired>
                <Row className="row-header">
                  <Col xs={2} className="text-left">Name</Col>
                  <Col xs={4}>Amount</Col>
                  <Col xs={2}>Valid From</Col>
                  <Col xs={2}>Valid Until</Col>
                  <Col xs={2}></Col>
                </Row>
                <TaxesList
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  name               ="Applicable Airport Fees"
                  taxes              ={this.filterTaxes("airport-fee", "applicable")}
                />
                <TaxesList
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  name               ="Applicable Excises"
                  taxes              ={this.filterTaxes("excise", "applicable")}
                />
                <TaxesList
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  name               ="Applicable VAT"
                  taxes              ={this.filterTaxes("vat", "applicable")}
                />
              </Tab>
              <Tab eventKey={2} title='Outdated Taxes'>
                <TaxesList
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  name               ="Outdated Airport Fees"
                  taxes              ={this.filterTaxes("airport-fee", "outdated")}
                />
                <TaxesList
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  name               ="Outdated Excises"
                  taxes              ={this.filterTaxes("excise", "outdated")}
                />
                <TaxesList
                  user               ={this.props.user}
                  requiredReadRoles  ={this.props.requiredReadRoles}
                  requiredWriteRoles ={this.props.requiredWriteRoles}
                  taxFormModalId     ={this.props.taxFormModalId}
                  name               ="Outdated VAT"
                  taxes              ={this.filterTaxes("vat", "outdated")}
                />
              </Tab>
            </Tabs>
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  },

  filterTaxes(type, status){
    let { airport, taxes } = this.props;
    let taxesFiltered = List(airport.get('Taxes').filter(taxId => {

      taxId          = String(taxId);
      // console.log(taxes.toJS(), taxId)
      let validFrom  = taxes.get(taxId).get('validFrom')  ? moment(taxes.get(taxId).get('validFrom'))  : moment('1980-01-01');
      let validUntil = taxes.get(taxId).get('validUntil') ? moment(taxes.get(taxId).get('validUntil')) : moment('2200-01-01');

      if(status === "applicable")
        return taxes.get(taxId).get('type') === type && ((moment().isAfter(validFrom, 'day') || moment().isSame(validFrom, 'day'))
                                                     && ((validUntil.isAfter(moment(), 'day')) || moment().isSame(validUntil, 'day')));
      else if(status === "outdated")
        return taxes.get(taxId).get('type') === type && (validFrom.isAfter(moment(), 'day') || moment().isAfter(validUntil, 'day'));
    }));

    return taxesFiltered.map(taxId => {
      return taxes.get(String(taxId));
    })
  }
});