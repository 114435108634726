import React from 'react';
import { Row } from 'react-bootstrap';

export default React.createClass({
  render() {
   return(
    <Row className="text-center wrapper-loader">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
      <span className="loader-message">{this.props.children}</span>
    </Row>)
  }
});