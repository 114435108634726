import React from 'react';
import ErrorPage from '../ErrorPage';

export default React.createClass({
  render() {
    return(
      <ErrorPage code="403">
        Unauthorized Access
      </ErrorPage>
    )
  }
});