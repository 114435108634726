import Palette from '../constants/Palette';

export const AppStyles = {
  baseRow: {
    height:   "100%",
    margin:   "auto",
    maxWidth: "1700px"
  },

  baseCol: {
    padding: "30px",

    '@media (max-width : 1024px)': {
      padding: "15px"
    }
  },

  a: {
    color:          Palette.MAGENTA,
    fontWeight:     400,
    textDecoration: 'underline'
  }
}

export const legendTitleStyle = {
  title: {
    fontSize:        18,
    backgroundColor: Palette.WHITE,
    margin:          'auto',
    position:        'absolute',
    width:           200,
    left:            '50%',
    marginLeft:      -100
  },

  line: {
    paddingTop:   '12px',
    height:       0,
    borderBottom: '4px solid ' + Palette.LIGHT_GREY
  },

  wrapper: {
    margin: 15
  }
};

export const ButtonStyles = {
  base: {
    width:      '25%',
    fontWeight: 'bold',
    color:      Palette.PRIMARY
  }
};

export const ShadowsStyles = {
  z1:      {boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px  6px 0 rgba(0, 0, 0, 0.12)'},
  z1Hover: {boxShadow: '0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15)'},
  z2:      {boxShadow: '0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'},
  z3:      {boxShadow: '0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19)'},
  z4:      {boxShadow: '0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21)'},
  z5:      {boxShadow: '0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22)'}
};

export const importFormStyles = {
  resultsWrapper: {
    height:     400,
    overflowY:  'scroll',
    fontFamily: 'monospace',
    fontSize:   12
  },

  textarea: {
    height:     400,
    padding:    5,
    fontFamily: 'monospace',
    fontSize:   12
  },

  td: {
    paddingLeft:  2,
    paddingRight: 2
  },

  format: {
    fontFamily: 'monospace',
    fontSize:   12
  }
};

export const CalculatorAirportStyles = {
  card: {
    fontSize:   'larger',
    color:      '#A59C7F',
    fontWeight: 'bold',
    padding:    '10px',
    width:      '80px',
    display:    'inline-block'
  },

  towerControl: {
    backgroundSize:     'contain',
    backgroundImage:    "url('../assets/images/tower_gjl.png')",
    height:             '40px',
    width:              '40px',
    borderRadius:       '3px',
    backgroundRepeat:   'no-repeat',
    backgroundPosition: 'center',
    margin:             'auto'
  },

  tripDashStart: {
    transition:   'All 1s ease-in',
    width:        '100px',
    opacity:      '0',
    height:       '25px',
    border:       'dashed 2px #A59C7F',
    borderColor:  '#000 transparent transparent transparent',
    borderRadius: '50%/22px 22px 0 0',
    display:      'inline-block'
  },

  tripDashEnd: {
    transition:   'All 1s ease-in',
    width:        '100px',
    opacity:      '1',
    height:       '25px',
    border:       'dashed 2px #A59C7F',
    borderColor:  '#000 transparent transparent transparent',
    borderRadius: '50%/22px 22px 0 0',
    display:      'inline-block'
  },

  list: {}
};