import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Logo } from '../../components';

var Option = React.createClass({
  propTypes: {
    addLabelText : React.PropTypes.string,
    className    : React.PropTypes.string,
    mouseDown    : React.PropTypes.func,
    mouseEnter   : React.PropTypes.func,
    mouseLeave   : React.PropTypes.func,
    option       : React.PropTypes.object.isRequired,
    renderFunc   : React.PropTypes.func
  },

  render () {
    return (
      <div
        className    ={this.props.className}
        onMouseEnter ={this.props.mouseEnter}
        onMouseLeave ={this.props.mouseLeave}
        onMouseDown  ={this.props.mouseDown}
        onClick      ={this.props.mouseDown}>
        {this.getOption()}
      </div>
    );
  },

  getOption() {
    let {data} = this.props.option;
    let logo = data.logo ? <Logo maxHeight="40" maxWidth="40" base64={data.logo} /> : '';

    return (
      <span style={{display: 'inline-block', height: 40}}>
        {logo} {data.name}
      </span>
    );
  }
});

var SingleValue = React.createClass({
  propTypes: {
    placeholder : React.PropTypes.string,
    value       : React.PropTypes.object
  },

  render () {
    var obj    = this.props.value;
    //var _bytes = obj && obj.data.logo && obj.data.logo.data ? new Uint8Array(obj.data.logo.data) : false;
    return (
      <div className="Select-placeholder">
        {obj ? (
          obj.data.name
        ) : (
          this.props.placeholder
        )
      }
    </div>);
  }
});

export default React.createClass({
  propTypes: {
    placeholder : React.PropTypes.string,
    value       : React.PropTypes.number
  },

  render () {
    return(
      <Select
        name                 ="supplierId"
        placeholder          ="Supplier *"
        searchable           ={true}
        value                ={this.props.value}
        options              ={this.props.options}
        optionComponent      ={Option}
        singleValueComponent ={SingleValue}
        onChange             ={this.handleChange}
      />
    )
  },

  //We simulate a very basic event.target object in response
  handleChange(value){
    this.props.onChange({ target: { name: 'supplierId', value: value}})
  }
});