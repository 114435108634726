import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';
import _ from "lodash";

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const fuelRequest     = new Schema('fuelRequests');

  return normalize(data, {
    fuelRequests: _.isArray(data.fuelRequests) ? arrayOf(fuelRequest) : fuelRequest
  });
}

const FuelRequestApiUtils = {
  getAll: function(url, params) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        data: params,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFuelRequests.LOAD_FUEL_REQUESTS_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFuelRequests.LOAD_FUEL_REQUESTS_ERROR,
          err: err
        });
      })
  },

}

export default FuelRequestApiUtils
