import jwt from 'jsonwebtoken';
import assign from 'object-assign';
import { EventEmitter } from 'events';
import  { fromJS } from 'immutable';

import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import Roles from '../constants/Roles';

const LoginStore = assign({}, BaseStore, {
  /**
   * Init
   */
  initialize() {
    LoginStore.setState({
      user          : null,
      jwt           : null,
      error         : null,
      loadingStatus : null
    })
  },

  /**
   * Check if the user is an operator or not (pilot)
   * @return {Boolean}
   */
  isOperator(){
    const roles = LoginStore.get('user').get('roles').toJS();
    return roles.indexOf('FUEL_OPERATOR') > -1 && roles.length === 1
  },

  /**
   * Check if the user is authorized
   * @param  {array}  requiredRoles
   * @return {Boolean}               [description]
   */
  isAuthorized(requiredRoles){
    const roles = LoginStore.get('user').get('roles').toJS();

    if(requiredRoles.indexOf('ALL') > -1) return true;

    return roles.some(role =>
      requiredRoles.some(rr => rr === role)
    );
  },

  /**
   * Check if the use is logged in
   * @return {Boolean}
   */
  isLoggedIn() {
    return !!LoginStore.get('user');
  },

  /**
   * Check is the user has the specified role
   * @param  {string}  role
   * @return {Boolean}
   */
  hasRole(role) {
    const roles = LoginStore.get('user').get('roles').toJS()
    return roles.indexOf(role) > -1
  },

  /**
   * User is redirected to the correct page according to his role
   * Order matters
   */
  userRedirect(){
    let endpoint = '/';
    const roles    = LoginStore.get('user').get('roles').toJS()

    if(LoginStore.hasRole('FUEL_ADMIN')) endpoint = '/contracts';
    else if(LoginStore.hasRole(Roles.FUEL_PURCHASER)) endpoint = '/contracts';
    else if(LoginStore.hasRole(Roles.FUEL_COORDINATOR)) endpoint = '/platts';
    else if(LoginStore.hasRole(Roles.FUEL_ACCOUNTANT)) endpoint = '/prices';
    else if(LoginStore.hasRole(Roles.FUEL_INDEX)) endpoint = '/indexes';

    window.location = endpoint;
  },

  /**
   * Check of the token is expired on client side
   * It's checked on server side too. Don't worry...
   * @param  {json} jwt token
   */
  isExpired(jwt) {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return currentTime >= jwt.exp
  },

  /**
   * We delete the actual token and store the new token
   * A refreshed one in theory
   */
  setRefreshedToken(token){
    localStorage.clear();
    localStorage.setItem('jwt', token);

    LoginStore.setState({
      jwt: token,
      user: fromJS(jwt.decode(token))
    })
    LoginStore.emitChange();
  },

  dispatchToken: Dispatcher.register(function(payload) {
    const action = payload.action;
    /**
     * If a token is receive, we replace the actual token by the new one.
     */
    if(action.jwt)
      LoginStore.setRefreshedToken(action.jwt)

    switch(action.actionType) {
      /**
       * User is logging in
       */
      case Constants.ActionLogin.LOGGING_USER:
        LoginStore.setState({
          loadingStatus: Constants.LOADING
        })
        LoginStore.emitChange();
        break;

      /**
       * Error happened during the registration
       */
      case Constants.ActionLogin.USER_LOGGING_ERROR:
        localStorage.clear();
        LoginStore.setState({
          error         : action.err.responseJSON.message,
          loadingStatus : Constants.ERROR
        })
        LoginStore.emitChange();
        break;

      /**
       * Login success, we redirect the user
       */
      case Constants.ActionLogin.USER_LOGGED_IN:
        LoginStore.userRedirect()
        break;

      /**
       * Token expires, we redirect user to the login page
       */
      case Constants.ActionLogin.SESSION_TIMEOUT:
        if(process.env.NODE_ENV === 'dev') debugger;

        localStorage.clear();
        window.location = '/.auth/login/aad';
        break;

      /**
       * User logged out, we just delete the token from the local storage (NB: token is still valid until he expires)
       */
      case Constants.ActionLogin.USER_LOGOUT:
        localStorage.clear();
		    window.location = '/.auth/logout?post_logout_redirect_uri=/';
        break;

      /**
       * We store the user by decoding the token
       */
      case Constants.ActionLogin.REGISTER_USER:
        const decoded = jwt.decode(action.jwt);

        if(!decoded) return;

        if(!LoginStore.isExpired(decoded)) {
          var usr = fromJS(decoded);
          LoginStore.setState({
            jwt: action.jwt,
            user: usr
          })
          LoginStore.emitChange();

        }

        break;

    }

    return true;
  })
});

export default LoginStore;
