import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const value = new Schema('values');

  return normalize(data, {
    values: _.isArray(data.values) ? arrayOf(value) : value
  });
};

const ValuesApiUtils = {
  getAll: function(url) {
    Promise.resolve($.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.LOAD_VALUES_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.LOAD_VALUES_ERROR,
          err: err
        });
      })
  },

  getOne: function(url) {
    Promise.resolve($.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.LOAD_VALUE_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.LOAD_VALUE_ERROR,
          err: err
        });
      })
  },

  create: function(url, value) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'POST',
        data: value,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.CREATE_VALUE_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.CREATE_VALUE_ERROR,
          err: err
        });
      })
  },

  update: function(url, value) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'PUT',
        data: value,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.UPDATE_VALUE_SUCCESS,
          data
        });

      })
      .catch(function(err){
        console.error(err);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.UPDATE_VALUE_ERROR,
          err: err
        });
      })
  },

  delete: function(url) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'DELETE',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.DELETE_VALUE_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionValues.DELETE_VALUE_ERROR,
          err: err
        });
      })
  },
};

export default ValuesApiUtils
