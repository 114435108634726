import React from 'react';
import  { List } from 'immutable';
import { Button, FormControls, ButtonToolbar } from 'react-bootstrap';

import { PermissionRequired, NoItemHelper, DefaultModal, YesNoDialog, SubTitle } from '../../components';
import { ModalsAction, ValuesAction } from '../../actions';
import { Activities } from '../../constants';

import {PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded} from '../PageLifecycle';
import ValueForm from '../values/ValueForm';
import ValueList from '../values/ValueList';

export default React.createClass({
  propTypes: {
    data: React.PropTypes.object
  },

  getDefaultProps: function() {
    return {
      values: List()
    }
  },

  render() {
    const platt = this.props.platt.toJS();
    const values = this.props.values.toJS();

    return (
      <div>
        <SubTitle>Values</SubTitle>
        {/* Page wrapper */}
        <PageLifcycle>

          {/* Displaying when no items */}
          <PageHelper isDisplayed ={this.props.values.size === 0}>
            <NoItemHelper onNew={this.handleNewClick} buttonLabel="New value">
              <p>You are currently in the <strong>platts</strong> details. There is no registered values yet for this platts.</p>
              <p>To get you started, you can create a new value. You can add as many values as you like as long as there is one value for a given day and the effective date isn't greater than today's date.</p>
              <p>The list only displays the last seven values.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!(this.props.values.size === 0)}>
            <PermissionRequired requiredRoles={Activities.WRITE_PLATTS_VALUES} user={this.props.user}>
              <ButtonToolbar style={{'textAlign': 'right'}}>
                <Button bsStyle='success' onClick={this.handleNewClick}>
                  New Value
                </Button>
              </ButtonToolbar>
            </PermissionRequired>
            <ValueList
              user                ={this.props.user}
              values              ={values}
              valueDeleteDialogId ={this.props.valueDeleteDialogId} />
          </PageLoaded>
        </PageLifcycle>

        <DefaultModal id={this.props.valueFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <ValueForm
            onSave        ={this.handleSaveValue}
            onCancel      ={this.closeModal}
            data          ={this.props.valueDataForm}
            volumeUnits={this.props.volumeUnits}
            currencies={this.props.currencies} />
        </DefaultModal>
        <YesNoDialog
          id    = {this.props.valueDeleteDialogId}
          title = "Please confirm"
          onYes = {this.handleConfirmDeleteClick}
          onNo  = {this.handleCancelDeleteClick} />
      </div>
    )
  },

  getModalTitle(){
    return "New Value";
  },

  closeModal() {
    ModalsAction.hideValueForm(this.props.valueFormModalId);
  },

  handleSaveValue(value) {
    if(value.id)
      ValuesAction.update(value);
    else
      ValuesAction.create(this.props.platt.get('id'), value);
  },

  handleConfirmDeleteClick(value){
    ValuesAction.delete(value.get('id'))
  },

  handleCancelDeleteClick(){
    ModalsAction.hide(this.props.valueDeleteDialogId);
  },

  handleNewClick() {
    ModalsAction.showValueForm(this.props.valueFormModalId);
  }
});
