import roles from './Roles'

export default {
  READ_PLATTS:         [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR],
  WRITE_PLATTS:        [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],
  WRITE_PLATTS_VALUES: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR],

  READ_FUEL_REQUESTS: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_ACCOUNTANT, roles.FUEL_OPS_GVA],

  READ_AIRPORTS:  [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_ACCOUNTANT],
  WRITE_AIRPORTS: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],

  READ_FBOS:  [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_OPS_GVA],
  WRITE_FBOS: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_OPS_GVA],

  READ_CONTRACTS:   [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],
  WRITE_CONTRACTS:  [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],
  IMPORT_CONTRACTS: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],

  READ_TAXES:   [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_ACCOUNTANT],
  WRITE_TAXES:  [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],
  IMPORT_TAXES: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],

  READ_SUPPLIERS:  [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR],
  WRITE_SUPPLIERS: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER],

  READ_CURRENCIES: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_ACCOUNTANT, roles.FUEL_OPS_GVA],

  READ_UNITS: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_ACCOUNTANT, roles.FUEL_OPS_GVA],

  USE_FUEL_SIMULATOR: [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_INDEX, roles.FUEL_ACCOUNTANT, roles.FUEL_OPS_GVA],
  READ_FUEL_PRICES:   [roles.FUEL_ADMIN, roles.FUEL_PURCHASER, roles.FUEL_COORDINATOR, roles.FUEL_ACCOUNTANT],
}
