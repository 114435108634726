import React from 'react';

export default React.createClass({
  getDefaultProps() {
    return {
      isHover : false
    }
  },

  getStyle(){
    let visible = {
        opacity          : 1,
        transition       : "opacity .25s ease-in-out",
        MozTransition    : "opacity .25s ease-in-out",
        WebkitTransition : "opacity .25s ease-in-out",
    }

    let hidden = {
        opacity          : 0,
        transition       : "opacity .25s ease-in-out",
        MozTransition    : "opacity .25s ease-in-out",
        WebkitTransition : "opacity .25s ease-in-out",
    }
    return this.props.isHover ? visible : hidden;
  },

  render(){
    return(
      <div style={this.getStyle()}>{this.props.children}</div>
    )
  }
});