import React from 'react';
import {Map} from 'immutable';
import {PageHelper, PageLifcycle, PageLoaded} from '../PageLifecycle'
import {NoItemHelper, SubTitle} from '../../components'
import ContractAirportList from './ContractAirportList';

export default React.createClass({
  propTypes: {
    contractAirports: React.PropTypes.object
  },

  getDefaultProps() {
    return {
      contractAirports: Map()
    }
  },

  render() {
    return (
      <div>
        <SubTitle>Details</SubTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isContractAirportsError && !this.props.isContractAirportsLoading && this.props.contractAirports.size === 0}>
            <NoItemHelper onNew={this.props.onAdd} buttonLabel="Add airport" onImport={this.props.onImport}>
              <p>You are currently in the <strong>contract</strong> details. There is no registered airport yet for this contract.
              </p>
              <p>To get you started, you can add an airport. You can add as many airport as you like.</p>
              <p>Every airport can have multiple negociated indices as long as there is only one indice for a given day.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!(this.props.contractAirports.size === 0)}>
            <ContractAirportList
              user={this.props.user}
              onNew={this.props.onAdd}
              contractAirports={this.props.contractAirports}
              contract={this.props.contract}
              searchParams={this.props.searchParams}
              airports={this.props.airports}
              platts={this.props.platts}
              values={this.props.values}
              onDelete={this.props.onDelete}
              onEdit={this.props.onEdit}
              onImport={this.props.onImport}
              contractAirportFormModalId={this.props.contractAirportFormModalId}
              contractAirportDeleteDialogId={this.props.contractAirportDeleteDialogId}
              contractAirportImportDialogId={this.props.contractAirportImportDialogId}
            />
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  }
});
