import React from 'react';
import {Activities} from '../../constants';
import {Action, ListActions, PermissionRequired} from '../../components';

export default React.createClass({
  render() {
    return (
      <ListActions>
        <PermissionRequired requiredRoles={Activities.READ_FUEL_PRICES} user={this.props.metadata.user}>
          <Action icon="search" to={'/plattsv2/' + this.props.rowData.icao + '/' + this.props.rowData.supplierId}/>
        </PermissionRequired>
      </ListActions>
    )
  }
});
