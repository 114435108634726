import React from 'react';

export default React.createClass({
  getFieldsetStyle(){
    return{
      position        : "relative",
      backgroundColor : "#FFF3DB",
      borderRadius    : "3px",
      padding         : "20px 20px 20px 20px"
    }
  },

  getLegendStyle(){
    return{
      position        : "absolute",
      color           : "#fff",
      fontSize        : "11px",
      padding         : "0px 4px 0px 4px",
      width           : "auto",
      margin          : "5px",
      backgroundColor : "#4E4941",
      top             : "-10px",
      left            : "-10px",
      borderRadius    : "15px"
    }
  },
  
  render() {
    return (<fieldset style={this.getFieldsetStyle()}>
      <legend style={this.getLegendStyle()}>{this.props.legend}</legend>
      {this.props.children}
    </fieldset>)
  }
});
