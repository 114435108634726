import React from 'react';
import { Input } from 'react-bootstrap';
import _ from 'lodash'
import { DefaultDropdown } from '../../components';

export default React.createClass({
  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps.data.toJS(), this.props.data.toJS())
      || nextProps.defaultValue !== this.props.defaultValue
      || nextProps.value !== this.props.value
  },

  render() {
    return (
      <DefaultDropdown
        { ...this.props }
        name="volumeUnitCode"
        keyName="code"
        labelName="name"
      />
    );
  }
});
