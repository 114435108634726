import React from 'react';
import {Map} from 'immutable';
import {Button, ButtonToolbar, Input} from 'react-bootstrap';
import {Save} from '../../components';
import {ImportAction} from '../../actions';
import {importFormStyles} from '../../styles/base.styles.js';

const ResultItem = React.createClass({

  render() {
    let {data} = this.props;

    return (
      <tr className={data.get('status') === 'success' ? "text-success" : "text-danger"}>
        <td style={importFormStyles.td}>{data.get('line') + '.'}</td>
        <td style={importFormStyles.td}>{data.get('message')}</td>
      </tr>
    )
  }
});

export default React.createClass({

  getInitialState() {
    //Initialize state from props.
    return {
      data:    this.props.data,
      results: null,
      status:  null,
      message: null,
      file:    null
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      results: nextProps.data.get('results') ? nextProps.data.get('results').get('value') : [],
      status:  nextProps.data.get('status') ? nextProps.data.get('status') : null,
      message: nextProps.data.get('message') ? nextProps.data.get('message') : null
    });
  },

  render() {
    return (
      <div>
        {this.props.getPreview()}

        <form className="form-horizontal">
          {this.getContent()}
          {this.getButtons()}
        </form>
      </div>)
  },

  getButtons() {
    var results = this.state.results;
    var status = this.state.status;
    var message = this.state.message;

    if (!(results && results.size > 0)) {
      return (
        <div>
          <p>&nbsp;</p>
          <p className={status === 'success' ? "text-success" : "text-warning"}>{message}</p>
          <ButtonToolbar style={{'textAlign': 'right'}}>
            <Button
              bsStyle='danger'
              onClick={this.props.onCancel}>
              Close
            </Button>
            <Save onSave={this.props.onSave} disabled={!this.props.data.get('isSavable')}/>
          </ButtonToolbar>
        </div>
      )
    } else {
      return (
        <div>
          <p>&nbsp;</p>
          <p className={status === 'success' ? "text-success" : "text-danger"}>{message}</p>
          <ButtonToolbar style={{'textAlign': 'right'}}>
            <Button onClick={this.handleBackClick}>
              Close
            </Button>
            <Button
              bsStyle='info'
              onClick={this.props.onContinue}>
              Go To Prices list
            </Button>
          </ButtonToolbar>
        </div>)
    }
  },

  handleBackClick() {
    ImportAction.change('results', null);
  },

  getContent() {
    var results = this.state.results;
    if (!(results && results.size > 0)) {
      return (
        <div>
          <Input
            name="data"
            value={this.state.data.get('data').get('value')}
            type="textarea"
            placeholder=""
            bsSize="large"
            style={importFormStyles.textarea}
            onChange={this.handleChange}
            onBlur={this.handleBlur}/>
          <div>Please drag & drop your file, then make sure selected supplier is correct, and we will take care of the rest.</div>
          <div className="text-danger" id="noSupplierSelected" style={{display: 'none'}}>Please select a supplier.</div>
          <div className="text-danger" id="noTypeSelected" style={{display: 'none'}}>Please select an import type ( contract or not contract ).</div>
          <div className="text-danger" id="invalidFileProvided" style={{display: 'none'}}>Please upload a valid file, we only support XLS(CSV) or PDF(Shell supplier only).</div>
        </div>
      )
    } else {
      return (
        <div style={importFormStyles.resultsWrapper}>
          <table style={importFormStyles.table}>
            <thead>
            <tr>
              <th style={importFormStyles.td}>Line</th>
              <th style={importFormStyles.td}>Result</th>
            </tr>
            </thead>
            <tbody>
            {results.map((row, key) => {
              return <ResultItem key={key} data={row}/>
            })}
            </tbody>
          </table>
        </div>
      )
    }
  },

  handleChange(e) {
    let {name, value} = e.target;
    var nextState = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    let {name, value} = e.target;
    ImportAction.change(name, value);
  },
})
