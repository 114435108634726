import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';
import _ from 'lodash';

//NESTING RULES
var formalizeData = (data) => {
  return normalize(data, {});
}

const AuthApiUtils = {
  login: function() {
    Promise.resolve($.ajax({ url: "/.auth/me", type: 'GET' }))
      .then(function(data){
        data = formalizeData(data).result[0].id_token;
        Dispatcher.handleViewAction({
          actionType : Constants.ActionLogin.REGISTER_USER,
          jwt : data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType : Constants.ActionLogin.USER_LOGGING_ERROR,
          err        : err
        });
      })
  },

}

export default AuthApiUtils
