import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import UsersApiUtils from '../utils/UsersApiUtils'

const UserAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionUsers.LOAD_USERS
    });

    UsersApiUtils.getAll('/api/users');
  },

  getUserById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionUsers.LOAD_USER
    });

    UsersApiUtils.getOne('/api/users/' + id);
  },
};

export default UserAction;