import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { List, fromJS } from 'immutable';

const VolumeUnitsStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    VolumeUnitsStore.setState({
      units : List(),
      loadingStatus: Constants.LOADING
    });
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;

    switch(action.actionType) {
      case Constants.ActionVolumeUnits.LOAD_VOLUME_UNITS_SUCCESS:
        VolumeUnitsStore.setState({
          units: fromJS(action.units),
          loadingStatus: Constants.LOADED
        })
        VolumeUnitsStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default VolumeUnitsStore;
