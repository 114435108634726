import React from 'react';
import _ from 'lodash'
import {fromJS, Map} from 'immutable';

import {Button} from 'react-bootstrap';
import {search} from '../../components/TableSearch/TableSearch';
import {Action, Icon, ListActions, PermissionRequired, TableSearch} from '../../components';
import {ContractsAction, ModalsAction} from '../../actions';
import {Activities} from '../../constants';
import Griddle from "griddle-react";
import formatter from "../../utils/formatter";

export default React.createClass({
  handleEditContractClick(props) {
    ModalsAction.showContractAirportForm(this.props.contractAirportFormModalId, props.rowData)
  },
  handleDeleteContractClick(props) {
    ModalsAction.show(this.props.contractAirportDeleteDialogId, {
      contractAirport: props.rowData,
      message:         "Are you sure you want to delete this airport (" + props.rowData.airportIcao + ") from the contract ?"
    })
  },
  getDetailsColumn() {
    return ["airportIcao", "platt", "deliveryBy", "payment"/*, "hasPreNotification"*/, "price", "effectiveDate", "validUntil", "id"];
  },
  getDetailsColumnMetadata() {
    return [{
      columnName:      'airportIcao',
      displayName:     'Airport',
      customComponent: props => {
        let airport = this.props.airports.get(String(props.rowData.airportIcao)).toJS();
        return (<p>{[airport.icao, airport.name].join(' - ')}</p>)
      },
      searchInput:     'text'
    }, {
      columnName:      'platt',
      displayName:     'Platt',
      customComponent: props => {
        let platt = this.props.platts.get(String(props.rowData.plattId)).toJS();
        return (<p>{[platt.code, platt.name].join(' - ')}</p>)
      },
      searchInput:     'text'
    }, {
      columnName:  'deliveryBy',
      displayName: 'Delivery By'
    }, {
      columnName:  'payment',
      displayName: 'Payment'
    }, /*{
      columnName:      'hasPreNotification',
      displayName:     'Has PreNotification Required ?',
      customComponent: props => {
        return (<p>{props.rowData.hasPreNotification ? 'Yes' : 'No'}</p>)
      }
    },*/ {
      columnName:      'price',
      displayName:     'Price',
      customComponent: props => {
        let indices = fromJS(_.map(props.rowData.Values, (valueId, key) => {
          return this.props.values.get(String(valueId));
        }));

        if (indices && indices.toArray() && indices.toArray().length > 0) {
          let items = [];
          _.map(indices.toArray(), (indice, key) => {
            items.push(
              <div key={key}>
                <span>{indice ? indice.get('value') + ' ' + indice.get('currencyCode') + '/' + indice.get('volumeUnitCode') : ''}</span>
              </div>)
          });
          return (
            <div>
              {items}
            </div>
          )
        } else {
          return (<p></p>)
        }
      }
    }, {
      columnName:      'effectiveDate',
      displayName:     'Effective Date',
      customComponent: props => {
        let indices = fromJS(_.map(props.rowData.Values, (valueId, key) => {
          return this.props.values.get(String(valueId));
        }));

        if (indices && indices.toArray() && indices.toArray().length > 0) {
          let items = [];
          _.map(indices.toArray(), (indice, key) => {
            items.push(
              <div key={key}>
                <span>{indice ? formatter.format.date(indice.get('effectiveDate')) : ''}</span>
              </div>)
          });
          return (
            <div>
              {items}
            </div>
          )
        } else {
          return (<p></p>)
        }
      }
    }, {
      columnName:      'validUntil',
      displayName:     'Valid Until',
      customComponent: props => {
        let indices = fromJS(_.map(props.rowData.Values, (valueId, key) => {
          return this.props.values.get(String(valueId));
        }));

        if (indices && indices.toArray() && indices.toArray().length > 0) {
          let items = [];
          _.map(indices.toArray(), (indice, key) => {
            items.push(
              <div key={key}>
                <span>{indice && indice.get('validUntil') ? formatter.format.date(indice.get('validUntil')) : 'UFN'}</span>
              </div>)
          });
          return (
            <div>
              {items}
            </div>
          )
        } else {
          return (<p></p>)
        }
      }
    }, {
      columnName:      "id",
      displayName:     "",
      cssClassName:    "actionsColumn",
      customComponent: props => {
        return (<ListActions>
          <PermissionRequired requiredRoles={Activities.WRITE_CONTRACTS} user={this.props.user}>
            <Action icon="edit" onClick={this.handleEditContractClick.bind(null, props)}/>
            <Action icon="remove" onClick={this.handleDeleteContractClick.bind(null, props)}/>
          </PermissionRequired>
        </ListActions>)
      }
    }];
  },

  propTypes: {
    platts:           React.PropTypes.object,
    airports:         React.PropTypes.object,
    contractAirports: React.PropTypes.object,
    values:           React.PropTypes.object,
    searchParams:     React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      platts:           Map(),
      airports:         Map(),
      contractAirports: Map(),
      values:           Map(),
      searchParams:     Map()
    }
  },

  getInitialState() {
    return {
      contractAirports: this.props.contractAirports
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredContractAirports = search(this.props.contractAirports.toJS(), nextProps.searchParams.toJS())
      this.setState({contractAirports: fromJS(filteredContractAirports)})
    } else {
      this.setState({contractAirports: nextProps.contractAirports})
    }

    this.forceUpdate();
  },

  render() {
    let {contract, platts, airports, values, contractAirports, user, onDelete, onEdit} = this.props;
    return (
      <div>
        <TableSearch
          user={this.props.user}
          requiredRoles={Activities.WRITE_FBOS}
          columnMetadata={this.getDetailsColumnMetadata()}
          onSearch={this.handleSearch}
          isNewButton={true}
          newButtonText="Add airport"
          onNew={this.props.onNew}/>

        <Griddle
          results={Object.values(this.state.contractAirports.toJS())}
          tableClassName="table"
          showSettings={true}
          resultsPerPage={20}
          columnMetadata={this.getDetailsColumnMetadata()}
          columns={this.getDetailsColumn()}/>
      </div>
    )
  },

  getImportButton() {
    return (
      <PermissionRequired requiredRoles={Activities.IMPORT_CONTRACTS} user={this.props.user}>
        <Button bsStyle="info" onClick={this.props.onImport}>
          <Icon fa="upload"/>
        </Button>
      </PermissionRequired>)
  },

  getColumnMetadata() {
    return [{
      columnName:   "airportIcao",
      displayName:  "ICAO",
      cssClassName: "",
      searchInput:  'text'
    }, {
      columnName:   "import",
      displayName:  "",
      cssClassName: "text-center col-xs-1",
      searchInput:  'component',
      component:    this.getImportButton(),
      user:         this.props.user
    }, {
      columnName:   "new",
      displayName:  "",
      cssClassName: "actionsColumn",
      searchInput:  'actions',
      user:         this.props.user
    }]
  },

  handleSearch(e) {
    let {name, value} = e.target;
    ContractsAction.search(name, value);
  }
});
