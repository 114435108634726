import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import {arrayOf, normalize, Schema} from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const fbo = new Schema('fbos');
  const fboAirport = new Schema('fboAirports');
  const airport = new Schema('airports', {idAttribute: 'icao'});

  fbo.define({
    FboAirports: arrayOf(fboAirport)
  });

  fboAirport.define({
    Airport: airport
  });

  return normalize(data, {
    fbos:        _.isArray(data.fbos) ? arrayOf(fbo) : fbo,
    fboAirports: _.isArray(data.fboAirport) ? arrayOf(fboAirport) : fboAirport
  });
};

const FbosApiUtils = {
  getAll: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {

        // then formalize data
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.LOAD_FBOS_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.LOAD_FBOS_ERROR,
          err:        err
        });
      })
  },

  getOne: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {

        // initialize reference field accordingly
        if (data && data.fbos) {
          if (data.fbos.isImport) {
            data.fbos.reference = data.fbos.publicReference;
          }
        }

        // then formalize data
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.LOAD_FBO_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.LOAD_FBO_ERROR,
          err:        err
        });
      })
  },

  create: function (url, fbo) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       fbo,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.CREATE_FBO_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.CREATE_FBO_ERROR,
          err:        err
        });
      })
  },

  update: function (url, fbo) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'PUT',
      data:       fbo,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.UPDATE_FBO_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.UPDATE_FBO_ERROR,
          err:        err
        });
      })
  },

  delete: function (url, isImport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'DELETE',
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);
        if (isImport) {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionImports.DELETE_IMPORT_SUCCESS,
            data
          });
        } else {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionFbos.DELETE_FBO_SUCCESS,
            data
          });
        }
      })
      .catch(function (err) {
        if (isImport) {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionImports.DELETE_IMPORT_ERROR,
            err
          });
        } else {
          Dispatcher.handleViewAction({
            actionType: Constants.ActionFbos.DELETE_FBO_ERROR,
            err
          });
        }
      })
  },

  addFboAirport: function (url, fboAirport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       fboAirport,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.CREATE_FBO_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.CREATE_FBO_AIRPORT_ERROR,
          err:        err
        });
      })
  },

  updateFboAirport: function (url, fboAirport) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'PUT',
      data:       fboAirport,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.UPDATE_FBO_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.UPDATE_FBO_AIRPORT_ERROR,
          err:        err
        });
      })
  },

  deleteFboAirport: function (url) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'DELETE',
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        data = formalizeData(data);

        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.DELETE_FBO_AIRPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionFbos.DELETE_FBO_AIRPORT_ERROR,
          err:        err
        });
      })
  }
};

export default FbosApiUtils;
