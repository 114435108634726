import React from 'react';

import {
  CellFormat,
  DefaultModal,
  Dropbox,
  Loader,
  LogoControl,
  NoItemHelper,
  Oops,
  PageTitle,
  YesNoDialog
} from '../../components';
import {ContractsAction, ImportAction, ModalsAction} from '../../actions';

import ContractsListActions from './ContractsListActions';
import ContractsList from './ContractsList';
import ContractForm from '../contract/ContractForm';
import _ from "lodash";
import {PageError, PageHelper, PageLifcycle, PageLoaded, PageLoading} from "../PageLifecycle";

var columnNames = ['reference', 'supplierName', 'validFrom', 'validUntil', 'nbrAirports', 'id'];

export default React.createClass({

  getColumnMetadata() {
    return [{
      columnName:  'reference',
      displayName: 'Reference',
      searchInput: 'text'
    }, {
      columnName:  'supplierName',
      displayName: 'Supplier',
      searchInput: 'text'
    }, {
      columnName:      'validFrom',
      displayName:     'Valid From',
      customComponent: props => <CellFormat.ISODate value={props.rowData.validFrom}/>,
      searchInput:     'text'
    }, {
      columnName:      'validUntil',
      displayName:     'Valid Until',
      customComponent: props => <CellFormat.ISODate value={props.rowData.validUntil}/>,
      searchInput:     'text'
    }, {
      columnName:  'nbrAirports',
      displayName: 'Nbr. Airports',
      searchInput: false
    }, {
      columnName:             "id",
      displayName:            "",
      cssClassName:           "actionsColumn",
      contractDeleteDialogId: this.props.contractDeleteDialogId,
      contractFormModalId:    this.props.contractFormModalId,
      customComponent:        ContractsListActions,
      searchInput:            'actions',
      requiredReadRoles:      this.props.requiredReadRoles,
      requiredWriteRoles:     this.props.requiredWriteRoles,
      user:                   this.props.user
    }];
  },

  getModalTitle() {
    return this.props.contractDataForm.get('id') && this.props.contractDataForm.get('id').get('value') ? "Edit Contract" : "New Contract";
  },

  closeModal() {
    ModalsAction.hideContractForm(this.props.contractFormModalId);
  },

  handleSaveContract(contract) {
    const save = contract.id ? ContractsAction.update : ContractsAction.create;
    save(contract);
  },

  handleConfirmDeleteClick(contract) {
    ContractsAction.delete(contract.get('id'))
  },

  handleCancelDeleteClick() {
    ModalsAction.hide(this.props.contractDeleteDialogId);
  },

  handleNewClick() {
    ModalsAction.showContractForm(this.props.contractFormModalId);
  },

  handleImportCancelClick() {
    ModalsAction.hideImportModal(this.props.fuelImportDialogId);
  },

  handleImportContinueClick() {
    // Just close modal
    this.handleImportCancelClick();
  },

  getImportPreview() {
    if (this.props.droppedFile || (this.state && this.state.droppedFile)) {
      let base64 = 'test';
      return <LogoControl base64={base64} message={this.state.message} onDelete={this.handleRemoveFile}/>
    } else {
      return <Dropbox onDrop={this.handleImportDrop}>Drag and drop file here, or click to select file to upload.</Dropbox>
    }
  },

  handleRemoveFile() {
    // save file on state
    this.setState({
      droppedFile: null,
      message:     ''
    });
  },

  handleBlur(e) {
    let {name, value} = e.target;
    ImportAction.changeSupplier(name, value);
  },

  getSuppliersOptions() {
    let options = [];
    _.chain(this.props.suppliers.toJS())
      .sortBy(supplier => {
        return supplier.name
      })
      .each(supplier => {
        options.push({
          value: supplier.id,
          label: supplier.name,
          data:  supplier
        })
      })
      .value();
    console.log('We are returning suppliers count: ' + options.length);
    return options;
  },

  render() {
    return (
      <div className="data-table">
        <PageTitle icon="file-text">List of primary contracts</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isContractsLoading || this.props.isSuppliersLoading}>
            <Loader>
              Fetching contracts from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isContractsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isContractsError && !this.props.isContractsLoading && this.props.contracts.size === 0}>
            <NoItemHelper onNew={this.handleNewClick} buttonLabel="New primary contract">
              <p>You are currently in the contracts list. There is no contract registered yet.</p>
              <p>To get you started, you can create a new contract. You can add as many contracts as you like.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isContractsError && !this.props.isContractsLoading && !(this.props.contracts.size === 0)}>
            <ContractsList
              user={this.props.user}
              contracts={this.props.contracts}
              columnMetadata={this.getColumnMetadata()}
              columnNames={columnNames}
              loadingStatus={this.props.loadingContractsStatus}
              searchParams={this.props.searchParams}
              isNewButton={true}
              onNew={this.handleNewClick}
            />

          </PageLoaded>
        </PageLifcycle>

        <DefaultModal id={this.props.contractFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <ContractForm
            onSave={this.handleSaveContract}
            onCancel={this.closeModal}
            data={this.props.contractDataForm}
            suppliers={this.props.suppliers}/>
        </DefaultModal>
        <YesNoDialog
          id={this.props.contractDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick}/>
      </div>
    )
  }

});
