import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { fromJS } from 'immutable';

const ReviewPeriodStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    ReviewPeriodStore.setState({
      periods : fromJS([
       {id: 'WEEKLY'},
       {id: 'MONTHLY'},
       {id: 'FORTNIGHTLY'},
       {id: 'UPON NOTICE'}
      ]),
      periodsWithAll : fromJS([
       {id: 'ALL'},
       {id: 'WEEKLY'},
       {id: 'MONTHLY'},
       {id: 'FORTNIGHTLY'},
       {id: 'UPON NOTICE'}
      ])
    });
  }
});

export default ReviewPeriodStore;
