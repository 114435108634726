import assign from 'object-assign';
import _ from 'lodash';
import moment from 'moment';
import  { List } from 'immutable';
import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import validator from '../utils/validator';
import formatter from '../utils/formatter';

const isValid = {
  'icao': function(value){
    return validator.isString(value) && validator.isLen(value, 4) && validator.required(value)
  }
}

const CalculatorStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    CalculatorStore.setState({
      trip             : [],
      isPrivate        : null,
      airportFormError : ''
    })
  },
  
  dispatchToken: Dispatcher.register(function(payload) {
    let action    = payload.action;
    let nextState = {};

    switch(action.actionType) {
      
      /**
       * User add and airport to the trip
       */
      case Constants.ActionCalculator.ADD_AIRPORT:
        /**
         * First, we check if the icao as 4 letters, then we add it to the trip
         */
        if(isValid.icao(action.icao)){
          CalculatorStore.get('trip').push(action.icao.toUpperCase());
          nextState.trip             = CalculatorStore.get('trip');
          nextState.airportFormError = '';
        } else {
          nextState.airportFormError = 'The ICAO must be 4 letters'
        }

        CalculatorStore.setState(nextState);
        CalculatorStore.emitChange();
        break;

      case Constants.ActionCalculator.OPERATION_FLIGHT_CHANGES:
        nextState.isPrivate = action.operationFlight === 'private'
        CalculatorStore.setState(nextState);
        CalculatorStore.emitChange();
        break;

      /**
       * User remove airport from the trip
       */
      case Constants.ActionCalculator.REMOVE_AIRPORT:
        nextState.trip = CalculatorStore.get('trip').filter(function(icao) {
          return icao !== action.key
        });
        CalculatorStore.setState(nextState);
        CalculatorStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default CalculatorStore;
