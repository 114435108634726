import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import PricesApiUtils from '../utils/PricesApiUtils';

export default {
  getPrices(params) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionPrices.LOAD_PRICES
    });

    PricesApiUtils.load('/api/prices', {
      ...params
    });
  }
}
