import React from 'react';
import { Map } from 'immutable';
import _ from 'lodash';
import { FormControls, Row } from 'react-bootstrap';
import { SubTitle } from '../../components';
import { PlattFormAction } from '../../actions';

export default React.createClass({
  propTypes: {
    data     : React.PropTypes.object
  },

  getDefaultProps: function() {
    return {
      data : Map()
    }
  },

  getInitialState(){
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  },

  render() {
    return (
      <div>
        <SubTitle>Platt</SubTitle>
        <form className="form-horizontal details-wrapper">
          <Row>
            <FormControls.Static
              label="Code"
              labelClassName="col-xs-4"
              wrapperClassName="col-xs-8"
              value={this.props.data.get('code')} />
          </Row>
          <Row>
            <FormControls.Static
              label="Name"
              labelClassName="col-xs-4"
              wrapperClassName="col-xs-8"
              value={this.props.data.get('name')} />
          </Row>
          <Row>
            <FormControls.Static
              label="Base Origin"
              labelClassName="col-xs-4"
              wrapperClassName="col-xs-8"
              value={this.props.data.get('baseOrigin')} />
          </Row>
          <Row>
            <FormControls.Static
              label="Base Reference"
              labelClassName="col-xs-4"
              wrapperClassName="col-xs-8"
              value={this.props.data.get('baseReference')} />
          </Row>
          <Row>
            <FormControls.Static
              label="Review Period"
              labelClassName="col-xs-4"
              wrapperClassName="col-xs-8"
              value={this.props.data.get('reviewPeriod')} />
          </Row>
          <Row>
            <FormControls.Static
              label="Calculation Scheme"
              labelClassName="col-xs-4"
              wrapperClassName="col-xs-8"
              value={this.props.data.get('calculationScheme')} />
          </Row>
        </form>
      </div>)
  }
});
