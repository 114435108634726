import React from 'react';

const Decimal = ({ value }) => {
  return (
    <span>
      {value.toFixed(2)}
    </span>
  );
};

export default Decimal;
