import React, { PropTypes } from 'react';
import { Map } from 'immutable';
import { Row, Col } from 'react-bootstrap';

import {PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded} from '../PageLifecycle';
import {PageTitle, Loader, SubTitle} from '../../components';
import { AirportsStore, CurrenciesStore, PricesFormStore, VolumeUnitsStore, SuppliersStore, LoginStore } from '../../stores';
import { AirportAction, CurrenciesAction, PricesFormAction, VolumeUnitsAction, SupplierAction } from '../../actions';
import { PricesForm, PricesList } from '../../containers/prices';

export default class PricesWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: {}
    };

    this.handleAirportsStoreChange = this.handleAirportsStoreChange.bind(this);
    this.handleCurrenciesStoreChange = this.handleCurrenciesStoreChange.bind(this);
    this.handlePricesFormStoreChange = this.handlePricesFormStoreChange.bind(this);
    this.handleVolumeUnitsStoreChange = this.handleVolumeUnitsStoreChange.bind(this);
    this.handleSuppliersStoreChange = this.handleSuppliersStoreChange.bind(this);
  }

  componentDidMount(prevProps, prevState) {
    AirportsStore.addChangeListener(this.handleAirportsStoreChange);
    CurrenciesStore.addChangeListener(this.handleCurrenciesStoreChange);
    PricesFormStore.addChangeListener(this.handlePricesFormStoreChange);
    VolumeUnitsStore.addChangeListener(this.handleVolumeUnitsStoreChange);
    SuppliersStore.addChangeListener(this.handleSuppliersStoreChange);

    AirportsStore.initialize();
    CurrenciesStore.initialize();
    PricesFormStore.initialize();
    VolumeUnitsStore.initialize();
    SuppliersStore.initialize();

    AirportAction.fetchAll();
    CurrenciesAction.fetchAll();
    VolumeUnitsAction.fetchAll();
    SupplierAction.fetchAll();
  }

  componentWillUnmount() {
    AirportsStore.removeChangeListener(this.handleAirportsStoreChange);
    CurrenciesStore.removeChangeListener(this.handleCurrenciesStoreChange);
    PricesFormStore.removeChangeListener(this.handlePricesFormStoreChange);
    VolumeUnitsStore.removeChangeListener(this.handleVolumeUnitsStoreChange);
    SuppliersStore.removeChangeListener(this.handleSuppliersStoreChange);
  }

  handleAirportsStoreChange() {
    this.setState({
      stores: {
        ...this.state.stores,
        airports: AirportsStore.getState()
      },
      user: LoginStore.get('user')
    });
  }

  handleCurrenciesStoreChange() {
    this.setState({
      stores: {
        ...this.state.stores,
        currencies: CurrenciesStore.getState()
      },
      user: LoginStore.get('user')
    });
  }

  handleVolumeUnitsStoreChange() {
    this.setState({
      stores: {
        ...this.state.stores,
        units: VolumeUnitsStore.getState()
      },
      user: LoginStore.get('user')
    });
  }

  handlePricesFormStoreChange() {
    this.setState({
      stores: {
        ...this.state.stores,
        pricesForm: PricesFormStore.getState()
      },
      user: LoginStore.get('user')
    })
  }

  handleSuppliersStoreChange() {
    this.setState({
      stores: {
        ...this.state.stores,
        suppliers: SuppliersStore.getState()
      },
      user: LoginStore.get('user')
    })
  }

  render() {
    const {
      stores: {
        airports,
        currencies,
        pricesForm,
        units
      }
    } = this.state;

    const isPageLoaded = pricesForm
      && airports
      && currencies
      && units
      && airports.get('airports').size
      && currencies.get('currencies').size
      && units.get('units').size;

    return (
      <PageLifcycle>
        <PageTitle icon="usd">Fuel prices</PageTitle>
        {/* Displaying when page loading */}
        <PageLoading isDisplayed={!isPageLoaded}>
          <Loader>
            Fetching data from server, please wait.
          </Loader>
        </PageLoading>

        {/* Displaying when page loaded */}
        <PageLoaded isDisplayed={!!isPageLoaded}>
          <Row>
            <Col xs={4}>
              <PricesForm { ...this.state } />
            </Col>
            <Col xs={8}>
              <PricesList { ...this.state } isPublic={false} />
              <PricesList { ...this.state } isPublic={true} />
            </Col>
          </Row>
        </PageLoaded>
      </PageLifcycle>
    );
  }
}
