import React from 'react';
import RoleRequiredMixin from '../../mixins/RoleRequiredMixin';

const PermissionRequired = React.createClass({
  getDefaultProps: function() {
    return {
      roles         : [],
      user          : null,
      requiredRoles : ['AZERTY']
    }
  },

  mixins: [RoleRequiredMixin],

  render(){
    //User's permissions check
    if (!this.props.user || !this.props.user.get('roles').toJS()) return null;
    if (!this.permitted(this.props.requiredRoles)) return null;
      
    return <span>{this.props.children}</span>
  }
});

export default PermissionRequired;  