import React, {PropTypes} from 'react';
import {Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { Link } from 'react-router';

import { Icon, PermissionRequired } from '../../components';
import { Roles } from '../../constants';

import AuthorizationService from '../../utils/AuthorizationService';

require('./TopNav.css');

export var NavTitle = React.createClass({
  getStyle(){
    return{
      textTransform: "uppercase",
      color: "#FFFDF1",
      letterSpacing: "-1pt",
      wordSpacing: "8pt",
      fontSize: "15px",
      paddingLeft: "8px",
      textAlign: "left",
      fontFamily: "calibri",
      lineHeight: 0,
      verticalAlign: "middle"
    }
  },

  render() {
    return <span style={this.getStyle()}>{this.props.children}</span>
  }
})

export default React.createClass({
  propTypes: {
    menuItems: React.PropTypes.array
  },
  mixins: [PureRenderMixin],


  /**
   * Check is the user has the specified role
   * @param  {string}  role
   * @return {Boolean}
   */
  isUserOperator(user) {
    let roles = user.get('roles').toJS()
    return roles.indexOf(Roles.FUEL_OPERATOR) > -1 && roles.length === 1
  },

  shouldMenuBeVisible(menu, user){
    return !(!!user && AuthorizationService.authorizeActivity(user.get('roles').toJS(), menu.activity) && !this.isUserOperator(user))
  },

  render() {
    let currentLocation = window.location.pathname;

    return (
      <div>
        <Nav className="topnav">
          {this.props.menuItems.map(function(item) {
            //If not authorized
            if(this.shouldMenuBeVisible(item, this.props.user))
              return <span key={item.route} />;

            return (
              <Link key={item.url} to={{ pathname: item.url }} className={(currentLocation === item.url ? 'active top-link btn' : 'top-link btn')}>
                <Icon fa={item.icon} />&nbsp;{item.text}
              </Link>
            );
          }, this)}
          <NavDropdown eventKey="5" className="pull-right" title={this.getUserName()} id="nav-dropdown">
            <MenuItem onClick={this.logout} eventKey="5.1">
              <Icon fa="power-off" /> Log out
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    )
  },

  logout(){
    window.location = '/.auth/logout?post_logout_redirect_uri=/';
  },

  getUserName(){
    return this.props.user ? this.props.user.get('name') : "";
  }
});
