import assign from 'object-assign';
import { Constants } from '../constants';
import {EventEmitter} from 'events';
import  { Map } from 'immutable';

export default assign({}, EventEmitter.prototype, {
  state: Map(),

  setState(state) {
    var nextState = Map(state);
    this.state    = this.state.merge(nextState);
  },

  getState() {
    return this.state;
  },

  get(v) {
    return this.state.get(v);
  },

  // Allow Controller-View to register itself with store
  addChangeListener(callback) {
    this.on(Constants.CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE_EVENT, callback);
  },

  // triggers change listener above, firing controller-view callback
  emitChange() {
    var _self = this
    setTimeout(() => { // Run after dispatcher has finished
      _self.emit(Constants.CHANGE_EVENT);
    }, 0);
  }
});
