import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';

export default {
  change(field, value, validate) {
    Dispatcher.handleViewAction({
      actionType : Constants.ActionPricesForm.PRICES_FORM_CHANGE,
      field,
      value,
      validate
    })
  }
};