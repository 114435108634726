import React from 'react';

import {CellFormat, Loader, Oops, PageTitle} from '../../components';
import {PageError, PageHelper, PageLifcycle, PageLoaded, PageLoading} from "../PageLifecycle";
import FuelRequestsList from "./FuelRequestsList";

var columnNames = ['aircraft', 'icao', 'vendor', 'typeOfFlight', 'skedIn', 'skedOut', 'amount', 'createdAt'];

export default React.createClass({

  getColumnMetadata() {
    return [{
      columnName:  'aircraft',
      displayName: 'Aircraft',
      searchInput: 'text'
    }, {
      columnName:  'icao',
      displayName: 'Airport',
      searchInput: 'text'
    }, {
      columnName:  'vendor',
      displayName: 'Vendor',
      searchInput: 'text'
    }, {
      columnName:  'typeOfFlight',
      displayName: 'Type Of flight',
      searchInput: 'text'
    }, {
      columnName:  'skedIn',
      displayName: 'SKED IN'
    }, {
      columnName:  'skedOut',
      displayName: 'SKED OUT'
    }, {
      columnName:      'amount',
      displayName:     'Amount',
      customComponent: props => {
        return (<p>{props.rowData.amount} {props.rowData.amountUnit}</p>)
      }
    }, {
      columnName:      'createdAt',
      sortDirectionCycle: ['desc', 'asc'],
      displayName:     'Sending Date',
      customComponent: props => <CellFormat.ISODate value={props.rowData.createdAt}/>,
      searchInput:     'text'
    }
    ];
  },

  render() {
    return (
      <div className="data-table">
        <PageTitle icon="file-text">List of fuel requests</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isFuelRequestsLoading}>
            <Loader>
              Fetching fuel requests from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isFuelRequestsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isFuelRequestsError && !this.props.isFuelRequestsLoading && this.props.fuelRequests.size === 0}>
            <p>You are currently in the fuel request list. There is no fuel request yet.</p>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isFuelRequestsError && !this.props.isFuelRequestsLoading && !(this.props.fuelRequests.size === 0)}>
            <FuelRequestsList
              user={this.props.user}
              fuelRequests={this.props.fuelRequests}
              columnMetadata={this.getColumnMetadata()}
              columnNames={columnNames}
              loadingStatus={this.props.isFuelRequestsLoading}
              searchParams={this.props.searchParams}
              isNewButton={false}
            />

          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  }

});
