import React from 'react';
import {Map} from 'immutable';
import {FuelRequestsStore, LoginStore} from '../../stores';
import {FuelRequestsAction} from '../../actions';
import {Constants} from '../../constants';
import FuelRequests from './FuelRequests';

FuelRequestsStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      fuelRequests:          Map(),
      searchParams:          Map(),
      isFuelRequestsLoading: true,
      isFuelRequestsError:   false,
      errCode:               null,
      user:                  null,
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    FuelRequestsStore.addChangeListener(this.handleFuelRequestStoreChange);
    FuelRequestsAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    FuelRequestsStore.removeChangeListener(this.handleFuelRequestStoreChange);
  },

  render() {
    return (
      <FuelRequests {...this.state} />)
  },

  handleFuelRequestStoreChange() {
    this.setState({
      fuelRequests:          FuelRequestsStore.get('fuelRequests'),
      searchParams:          FuelRequestsStore.get('search'),
      errCode:               FuelRequestsStore.get('errCode'),
      isFuelRequestsLoading: FuelRequestsStore.get('loadingStatus') === Constants.LOADING,
      isFuelRequestsError:   FuelRequestsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });

    // console.log('fuelRequests retrieved size is ' + this.state.fuelRequests);
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  }

});
