import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import PureRenderMixin from 'react-addons-pure-render-mixin';

export default React.createClass({
  propTypes: {
    placeholder : React.PropTypes.string,
    value       : React.PropTypes.number
  },
  mixins: [PureRenderMixin],

  render () {
    return(
      <Select
        name                 ="plattId"
        placeholder          ="Platts *"
        searchable           ={false}
        value                ={this.props.value}
        options              ={this.props.options}
        onChange             ={this.handleChange}
      />
    )
  },

  //We simulate a very basic event.target object in response
  //Since this react-select is crap and just send the value in response ('joking mate, hope you're enjoying reviewing my code).
  handleChange(value){
    this.props.onChange({ target: { name: 'plattId', value: value}})
  }
});