import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {DefaultModal, Loader, Oops, PageTitle, YesNoDialog} from '../../components';
import {SupplierAction, ModalsAction} from '../../actions';
import {PageError, PageLifcycle, PageLoaded, PageLoading} from '../PageLifecycle';
import SupplierAirports from './SupplierAirports';
import SupplierAirportForm from './SupplierAirportForm';

export default React.createClass({
  propTypes: {
    supplier: React.PropTypes.object
  },

  getInitialState() {
    return {
      isSupplierAirportsLoading: true,
      isSupplierAirportsError:   false
    }
  },

  componentWillReceiveProps(nextProps) {
    //If some of theses stores loading, then we wait until everything is finish.
    let isSupplierAirportsLoading = (
      nextProps.isAirportsLoading ||
      nextProps.isSupplierAirportsLoading ||
      nextProps.isSupplierLoading);

    let isSupplierAirportsError = (
      nextProps.isAirportsError ||
      nextProps.isSupplierAirportsError ||
      nextProps.isSupplierError);

    this.setState({
      isSupplierAirportsLoading: isSupplierAirportsLoading,
      isSupplierAirportsError:   isSupplierAirportsError
    });
  },

  render() {
    return (
      <div className="data-table">
        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.state.isSupplierAirportsLoading && !this.state.isSupplierAirportsError}>
            <Loader>
              Fetching data from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.state.isSupplierAirportsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.state.isSupplierAirportsError && !this.state.isSupplierAirportsLoading}>
            <PageTitle icon="file-text">SUPPLIER Airport</PageTitle>
            <Row>
              <Col xs={12}>
                <SupplierAirports
                  user={this.props.user}
                  errCode={this.props.errCode}
                  supplier={this.props.supplier}
                  searchParams={this.props.searchParams}
                  supplierAirports={this.props.supplierAirports}
                  airportsOptions={this.props.airportsOptions}
                  isSupplierAirportsLoading={this.state.isSupplierAirportsLoading}
                  isSupplierAirportsError={this.state.isSupplierAirportsError}
                  airports={this.props.airports}
                  supplierAirportFormModalId={this.props.supplierAirportFormModalId}
                  supplierAirportDeleteDialogId={this.props.supplierAirportDeleteDialogId}
                  supplierAirportImportDialogId={this.props.supplierAirportImportDialogId}
                  onAdd={this.handleAddClick}
                  onDelete={this.handleDeleteClick}
                  onEdit={this.handleEditClick}
                />
              </Col>
            </Row>
          </PageLoaded>
        </PageLifcycle>
        <DefaultModal
          id={this.props.supplierAirportFormModalId}
          bsSize="medium"
          onHide={this.closeModal}
          title='Add airport to the supplier'>
          <SupplierAirportForm
            onSave={this.handleSave}
            onCancel={this.closeModal}
            data={this.props.supplierAirportForm}
            airports={this.props.airports}
            airportsOptions={this.props.airportsOptions}
          />
        </DefaultModal>
        <YesNoDialog
          id={this.props.supplierAirportDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick}/>
      </div>
    )
  },

  closeModal() {
    ModalsAction.hideSupplierAirportForm(this.props.supplierAirportFormModalId);
  },

  handleSave(supplierAirport) {
    if (supplierAirport.id) {
      SupplierAction.updateSupplierAirport(this.props.supplier.get('id'), supplierAirport);
    } else {
      SupplierAction.addSupplierAirport(this.props.supplier.get('id'), supplierAirport);
    }
  },

  handleConfirmDeleteClick(payload) {
    SupplierAction.deleteSupplierAirport(this.props.supplier.get('id'), payload.get('supplierAirport').toJS())
  },

  handleCancelDeleteClick() {
    ModalsAction.hide(this.props.supplierAirportDeleteDialogId);
  },

  handleAddClick() {
    ModalsAction.showSupplierAirportForm(this.props.supplierAirportFormModalId, {});
  },

  handleEditClick(supplierAirport) {
    console.log('handleEditClick for supplier');
    ModalsAction.showSupplierAirportForm(this.props.supplierAirportFormModalId, supplierAirport)
  },

  handleDeleteClick(supplierAirport) {
    ModalsAction.show(this.props.supplierAirportDeleteDialogId, {
      supplierAirport: supplierAirport,
      message:    "Are you sure you want to delete this airport (" + supplierAirport.airportIcao + ") from the supplier ?"
    })
  }
});
