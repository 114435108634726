import keyMirror from 'key-mirror';

export default {
  // MODAL
  Modals: keyMirror({
    YES_NO_DIALOG:   null,
    ERROR_DIALOG:    null,
    TAX_FROM_DIALOG: null
  }),

  // event name triggered from store, listened to by views
  CHANGE_EVENT: 'change',

  //Loading status
  LOADING: 'loading',
  LOADED:  'loaded',
  ERROR:   'error',

  List: keyMirror({
    FETCHING:    null,
    FETCHED:     null,
    FETCH_ERROR: null
  }),

  ActionLogin: keyMirror({
    LOGGING_USER:       null,
    USER_LOGOUT:        null,
    USER_LOGGED_IN:     null,
    USER_LOGGING_ERROR: null,
    REGISTER_USER:      null,
    SESSION_TIMEOUT:    null
  }),

  ActionSources: keyMirror({
    SERVER_ACTION: null,
    VIEW_ACTION:   null
  }),

  ActionFuelRequests: keyMirror({
    LOAD_FUEL_REQUESTS:         null,
    LOAD_FUEL_REQUESTS_SUCCESS: null,
    LOAD_FUEL_REQUESTS_ERROR:   null,
    SEARCH_FUEL_REQUESTS: null
  }),

  ActionPrices: keyMirror({
    LOAD_PRICES:         null,
    LOAD_PRICES_SUCCESS: null,
    LOAD_PRICES_ERROR:   null,
  }),

  ActionImports: keyMirror({
    LOAD_IMPORTS:         null,
    LOAD_IMPORTS_SUCCESS: null,
    LOAD_IMPORTS_ERROR:   null,

    LOAD_IMPORT:         null,
    LOAD_IMPORT_SUCCESS: null,
    LOAD_IMPORT_ERROR:   null,

    DELETE_IMPORT:         null,
    DELETE_IMPORT_SUCCESS: null,
    DELETE_IMPORT_ERROR:   null,

    SEARCH_IMPORTS: null
  }),

  ActionPricesForm: keyMirror({
    PRICES_FORM_CHANGE: null
  }),

  ActionAirports: keyMirror({
    LOAD_AIRPORTS:         null,
    LOAD_AIRPORTS_SUCCESS: null,
    LOAD_AIRPORTS_ERROR:   null,

    LOAD_AIRPORT:         null,
    LOAD_AIRPORT_SUCCESS: null,
    LOAD_AIRPORT_ERROR:   null,

    SEARCH_AIRPORTS: null
  }),

  ActionCurrencies: keyMirror({
    LOAD_CURRENCIES:         null,
    LOAD_CURRENCIES_SUCCESS: null,
    LOAD_CURRENCIES_ERROR:   null,
  }),

  ActionVolumeUnits: keyMirror({
    LOAD_VOLUME_UNITS:         null,
    LOAD_VOLUME_UNITS_SUCCESS: null,
    LOAD_VOLUME_UNITS_ERROR:   null,
  }),

  ActionModals: keyMirror({
    SHOW_MODAL:                       null,
    HIDE_MODAL:                       null,
    ADD_MODAL:                        null,
    SHOW_TAX_FORM_MODAL:              null,
    HIDE_TAX_FORM_MODAL:              null,
    SHOW_SUPPLIER_FORM_MODAL:         null,
    HIDE_SUPPLIER_FORM_MODAL:         null,
    SHOW_SUPPLIER_AIRPORT_FORM_MODAL: null,
    HIDE_SUPPLIER_AIRPORT_FORM_MODAL: null,
    SHOW_HISTORY_DETAILS_MODAL:       null,
    HIDE_HISTORY_DETAILS_MODAL:       null,
    SHOW_PLATT_FORM_MODAL:            null,
    HIDE_PLATT_FORM_MODAL:            null,
    SHOW_VALUE_FORM_MODAL:            null,
    HIDE_VALUE_FORM_MODAL:            null,
    SHOW_CONTRACT_FORM_MODAL:         null,
    HIDE_CONTRACT_FORM_MODAL:         null,
    SHOW_CONTRACT_AIRPORT_FORM_MODAL: null,
    HIDE_CONTRACT_AIRPORT_FORM_MODAL: null,
    SHOW_FBO_FORM_MODAL:              null,
    HIDE_FBO_FORM_MODAL:              null,
    SHOW_FBO_AIRPORT_FORM_MODAL:      null,
    HIDE_FBO_AIRPORT_FORM_MODAL:      null,
    SHOW_CALCULATOR_AIRPORT_MODAL:    null,
    HIDE_CALCULATOR_AIRPORT_MODAL:    null,
    SHOW_IMPORT_RESULTS_MODAL:        null,
    HIDE_IMPORT_RESULTS_MODAL:        null,
    SHOW_IMPORT_MODAL:                null,
    HIDE_IMPORT_MODAL:                null,
  }),

  ActionCalculator: keyMirror({
    ADD_AIRPORT:               null,
    REMOVE_AIRPORT:            null,
    OPERATION_FLIGHT_CHANGES:  null,
    LOAD_FUEL_INDICES:         null,
    LOAD_FUEL_INDICES_SUCCESS: null,
    LOAD_FUEL_INDICES_ERROR:   null
  }),

  ActionTaxes: keyMirror({
    CREATE_TAX:         null,
    CREATE_TAX_SUCCESS: null,
    CREATE_TAX_ERROR:   null,

    DELETE_TAX:         null,
    DELETE_TAX_SUCCESS: null,
    DELETE_TAX_ERROR:   null,

    UPDATE_TAX:         null,
    UPDATE_TAX_SUCCESS: null,
    UPDATE_TAX_ERROR:   null,

    LOAD_TAX:         null,
    LOAD_TAX_SUCCESS: null,
    LOAD_TAX_ERROR:   null,

    LOAD_TAXES:         null,
    LOAD_TAXES_SUCCESS: null,
    LOAD_TAXES_ERROR:   null,
  }),

  ActionSuppliers: keyMirror({
    CREATE_SUPPLIER:                 null,
    CREATE_SUPPLIER_AIRPORT:         null,
    CREATE_SUPPLIER_SUCCESS:         null,
    CREATE_SUPPLIER_AIRPORT_SUCCESS: null,
    CREATE_SUPPLIER_AIRPORT_ERROR:   null,
    CREATE_SUPPLIER_ERROR:           null,

    DELETE_SUPPLIER:                 null,
    DELETE_SUPPLIER_SUCCESS:         null,
    DELETE_SUPPLIER_AIRPORT_SUCCESS: null,
    DELETE_SUPPLIER_AIRPORT_ERROR:   null,
    DELETE_SUPPLIER_AIRPORT:         null,
    DELETE_SUPPLIER_ERROR:           null,

    UPDATE_SUPPLIER:                 null,
    UPDATE_SUPPLIER_AIRPORT:         null,
    UPDATE_SUPPLIER_AIRPORT_ERROR:   null,
    UPDATE_SUPPLIER_SUCCESS:         null,
    UPDATE_SUPPLIER_AIRPORT_SUCCESS: null,
    UPDATE_SUPPLIER_ERROR:           null,

    LOAD_SUPPLIER:         null,
    LOAD_SUPPLIER_SUCCESS: null,
    LOAD_SUPPLIER_ERROR:   null,

    LOAD_SUPPLIERS:         null,
    LOAD_SUPPLIERS_SUCCESS: null,
    LOAD_SUPPLIERS_ERROR:   null,

    SEARCH_SUPPLIER_AIRPORT: null
  }),

  ActionPlatts: keyMirror({
    CREATE_PLATT:         null,
    CREATE_PLATT_SUCCESS: null,
    CREATE_PLATT_ERROR:   null,

    DELETE_PLATT:         null,
    DELETE_PLATT_SUCCESS: null,
    DELETE_PLATT_ERROR:   null,

    UPDATE_PLATT:         null,
    UPDATE_PLATT_SUCCESS: null,
    UPDATE_PLATT_ERROR:   null,

    LOAD_PLATT:         null,
    LOAD_PLATT_SUCCESS: null,
    LOAD_PLATT_ERROR:   null,

    LOAD_PLATTS:         null,
    LOAD_PLATTS_SUCCESS: null,
    LOAD_PLATTS_ERROR:   null,

    SEARCH_PLATTS: null
  }),

  ActionPlattsv2: keyMirror({
    CREATE_PLATT:         null,
    CREATE_PLATT_SUCCESS: null,
    CREATE_PLATT_ERROR:   null,

    LOAD_PLATT:         null,
    LOAD_PLATT_SUCCESS: null,
    LOAD_PLATT_ERROR:   null,

    LOAD_PLATTS:         null,
    LOAD_PLATTS_SUCCESS: null,
    LOAD_PLATTS_ERROR:   null,

    SEARCH_PLATTS: null
  }),

  ActionFbos: keyMirror({
    CREATE_FBO:         null,
    CREATE_FBO_SUCCESS: null,
    CREATE_FBO_ERROR:   null,

    DELETE_FBO:         null,
    DELETE_FBO_SUCCESS: null,
    DELETE_FBO_ERROR:   null,

    UPDATE_FBO:         null,
    UPDATE_FBO_SUCCESS: null,
    UPDATE_FBO_ERROR:   null,

    CREATE_FBO_AIRPORT:         null,
    CREATE_FBO_AIRPORT_SUCCESS: null,
    CREATE_FBO_AIRPORT_ERROR:   null,

    DELETE_FBO_AIRPORT:         null,
    DELETE_FBO_AIRPORT_SUCCESS: null,
    DELETE_FBO_AIRPORT_ERROR:   null,

    UPDATE_FBO_AIRPORT:         null,
    UPDATE_FBO_AIRPORT_SUCCESS: null,
    UPDATE_FBO_AIRPORT_ERROR:   null,

    LOAD_FBO:         null,
    LOAD_FBO_SUCCESS: null,
    LOAD_FBO_ERROR:   null,

    LOAD_FBOS:         null,
    LOAD_FBOS_SUCCESS: null,
    LOAD_FBOS_ERROR:   null,

    SEARCH_FBOS: null
  }),

  ActionContracts: keyMirror({
    CREATE_CONTRACT:         null,
    CREATE_CONTRACT_SUCCESS: null,
    CREATE_CONTRACT_ERROR:   null,

    DELETE_CONTRACT:         null,
    DELETE_CONTRACT_SUCCESS: null,
    DELETE_CONTRACT_ERROR:   null,

    UPDATE_CONTRACT:         null,
    UPDATE_CONTRACT_SUCCESS: null,
    UPDATE_CONTRACT_ERROR:   null,

    CREATE_CONTRACT_AIRPORT:         null,
    CREATE_CONTRACT_AIRPORT_SUCCESS: null,
    CREATE_CONTRACT_AIRPORT_ERROR:   null,

    DELETE_CONTRACT_AIRPORT:         null,
    DELETE_CONTRACT_AIRPORT_SUCCESS: null,
    DELETE_CONTRACT_AIRPORT_ERROR:   null,

    UPDATE_CONTRACT_AIRPORT:         null,
    UPDATE_CONTRACT_AIRPORT_SUCCESS: null,
    UPDATE_CONTRACT_AIRPORT_ERROR:   null,

    LOAD_CONTRACT:         null,
    LOAD_CONTRACT_SUCCESS: null,
    LOAD_CONTRACT_ERROR:   null,

    LOAD_CONTRACTS:         null,
    LOAD_CONTRACTS_SUCCESS: null,
    LOAD_CONTRACTS_ERROR:   null,

    SEARCH_CONTRACTS: null
  }),

  ActionImport: keyMirror({
    IMPORT:                      null,
    IMPORT_SUCCESS:              null,
    IMPORT_ERROR:                null,
    IMPORT_FORM_CHANGE:          null,
    IMPORT_FORM_CHANGE_SUPPLIER: null
  }),

  ActionValues: keyMirror({
    CREATE_VALUE:         null,
    CREATE_VALUE_SUCCESS: null,
    CREATE_VALUE_ERROR:   null,

    DELETE_VALUE:         null,
    DELETE_VALUE_SUCCESS: null,
    DELETE_VALUE_ERROR:   null,

    UPDATE_VALUE:         null,
    UPDATE_VALUE_SUCCESS: null,
    UPDATE_VALUE_ERROR:   null,

    LOAD_VALUE:         null,
    LOAD_VALUE_SUCCESS: null,
    LOAD_VALUE_ERROR:   null,

    LOAD_VALUES:         null,
    LOAD_VALUES_SUCCESS: null,
    LOAD_VALUES_ERROR:   null,
  }),

  ActionValueForm: keyMirror({
    VALUE_FORM_CHANGE: null
  }),

  ActionPlattForm: keyMirror({
    PLATT_FORM_CHANGE: null
  }),

  ActionContractForm: keyMirror({
    CONTRACT_FORM_CHANGE: null
  }),

  ActionFboForm: keyMirror({
    FBO_FORM_CHANGE: null
  }),

  ActionContractAirportForm: keyMirror({
    CONTRACT_AIRPORT_FORM_CHANGE: null
  }),

  ActionFboAirportForm: keyMirror({
    FBO_AIRPORT_FORM_CHANGE: null
  }),

  ActionSupplierAirportForm: keyMirror({
    SUPPLIER_AIRPORT_FORM_CHANGE: null
  }),

  ActionTaxForm: keyMirror({
    TAX_FORM_CHANGE: null
  }),

  ActionSupplierForm: keyMirror({
    SUPPLIER_FORM_CHANGE: null
  }),

  ActionApi: keyMirror({
    RECEIVE_DATA:      null,
    RECEIVE_API_ERROR: null
  }),

  ActionHistoryForm: keyMirror({
    HISTORY_FORM_CHANGE: null,
  }),

  ActionHistory: keyMirror({
    LOAD_HISTORY:         null,
    LOAD_HISTORY_ERROR:   null,
    LOAD_HISTORY_SUCCESS: null
  }),

  ActionUsers: keyMirror({
    LOAD_USERS:         null,
    LOAD_USERS_SUCCESS: null,
    LOAD_USERS_ERROR:   null,
    LOAD_USER:          null,
    LOAD_USER_SUCCESS:  null,
    LOAD_USER_ERROR:    null,
  }),
};
