import moment from 'moment';

module.exports = {
  isNumericValid: function(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  },

  isDateValid: function(value) {
    return moment(value, 'YYYY-MM-DD', true).isValid();
  },

  required: function(value) {
    return !!value;
  },

  notRequired: function(value) {
    return !value;
  },

  isNull: function(value) {
    return value === null && typeof value === "object" ? true : false;
  },

  isNotNull: function(value) {
    return !(value === null && typeof value === "object") ? true : false;
  },

  isLen(value, str){
    return value.length === str
  },

  isString(value){
    return typeof value === 'string'
  }
}