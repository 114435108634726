import React from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '../../../components';

export default React.createClass({
  getDefaultProps(){
    return {
      isVisible: true
    }
  },

  getStyle(){
    return {
      position: 'relative', 
      display: this.props.isVisible ? 'inline-block' : 'none', 
      verticalAlign: 'top'
    }
  },

  render() {
    return (
      <div 
        style   ={this.getStyle()}
        onClick ={this.handleClickAirport}>
        <Button 
          className ='shadow-z-1 button-trip' 
          onClick   ={this.props.onAdd}>
          <Icon fa="plus" />
        </Button>
      </div>
    );
  },
})
