import assign from 'object-assign';
import _ from 'lodash';
import moment from 'moment';
import  { Map, fromJS } from 'immutable';
import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import validator from '../utils/validator';

const IndicesStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    IndicesStore.setState({
      indices : Map()
    })
  },
  
  dispatchToken: Dispatcher.register(function(payload) {
    let action    = payload.action;
    let nextState = {};

    switch(action.actionType) {
      case Constants.ActionCalculator.LOAD_FUEL_INDICES_SUCCESS:
        nextState.indices = fromJS(action.data.indices)
        IndicesStore.setState(nextState);
        IndicesStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default IndicesStore;