import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';
import _ from 'lodash';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const platt = new Schema('platts');
  const value = new Schema('values');

  platt.define({
    Values: arrayOf(value)
  })

  return normalize(data, {
    platts: _.isArray(data.platts) ? arrayOf(platt) : platt
  });
}

const PricesApiUtils = {
  load: (url, params) => {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        data: params,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        //data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPrices.LOAD_PRICES_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionPrices.LOAD_PRICES_ERROR,
          err
        });
      })
  }
}

export default PricesApiUtils;
