import React from 'react';
import _ from 'lodash'
import {DefaultDropdown, Icon, PermissionRequired} from '../../components';
import {Button, Input} from 'react-bootstrap';

export const search = (collection, params) => {
  return _.reduce(params, (acc, value, field) => {
    return acc.filter(o => {
      if (!_.has(o, field) || !o[field]) {
        return false;
      }
      const propValue = o[field];

      if (value.toLowerCase().charAt(0) === '=') {
        return propValue.toLowerCase() === value.toLowerCase().substr(1, 100);
      } else {
        return propValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
      }
    });
  }, Object.values(collection));
};

//CUSTOM COMPONENT FOR GRIDDLE SEARCH
//BASED ON GRIDDLE COLUMNMETADATA
export default React.createClass({
  propTypes: {
    columnMetadata:   React.PropTypes.array,
    onSearch:         React.PropTypes.func,
    isNewButton:      React.PropTypes.bool,
    onNew:            React.PropTypes.func,
    newButtonText:    React.PropTypes.string,
    isImportButton:   React.PropTypes.bool,
    onImport:         React.PropTypes.func,
    importButtonText: React.PropTypes.string
  },

  getDefaultProps: function () {
    return {
      columnMetadata:   [],
      isNewButton:      false,
      newButtonText:    "",
      isImportButton:   false,
      importButtonText: ""
    }
  },

  shouldComponentUpdate(nextState, nextProps) {
    if (nextProps && nextProps.columnMetadata) {
      return !_.isEqual(nextProps.columnMetadata, this.props.columnMetadata)
    } else {
      return false
    }
  },

  render() {
    return (
      <table className="search-table">
        <tbody>
        <tr>
          {this.props.columnMetadata.map(function (col) {
            let style = col.component ? null : {
              padding:   '5px',
              textAlign: "right"
            };
            return (
              <td key={col.columnName} style={style} className={col.cssClassName ? col.cssClassName : ''}>
                {this.getColComponent(col)}
              </td>);
          }, this)}
        </tr>
        </tbody>
      </table>
    )
  },

  getNewButton() {
    if (this.props.isNewButton) {
      return (
        <PermissionRequired requiredRoles={this.props.requiredRoles} user={this.props.user}>
          <Button bsStyle='success' onClick={this.props.onNew}><Icon fa="plus"/> {this.props.newButtonText}</Button>
        </PermissionRequired>
      )
    } else {
      return (
        <PermissionRequired requiredRoles={this.props.requiredRoles} user={this.props.user}>
          <Button bsStyle='info' onClick={this.props.onImport}><Icon fa="upload"/> {this.props.importButtonText}
          </Button>
        </PermissionRequired>
      )
    }
  },

  getColComponent(col) {
    if (col.searchInput && col.searchInput !== null) {
      switch (col.searchInput) {
        case 'component':
          return col.component;
        case 'text':
          return (
            <div style={{position: 'relative'}}>
              {this.props.children}
              <Input
                name={col.columnName}
                type="text"
                placeholder={col.displayName}
                onChange={this.props.onSearch}/>
              <span style={{
                position: 'absolute',
                right:    '5px',
                top:      '5px',
                color:    '#D2D2D2'
              }}>
              <Icon fa="search"/>
            </span>
            </div>);
          break;
        case 'select':
          return (<div style={{position: 'relative'}}>
            <DefaultDropdown
              data={col.options.data}
              name={col.columnName}
              onChange={this.props.onSearch}
              keyName={col.options.key}
              labelName={col.options.label}/>
            <span style={{
              position: 'absolute',
              right:    '20px',
              top:      '5px',
              color:    '#D2D2D2'
            }}>
                      <Icon fa="search"/>
                    </span>
          </div>)
          break;
        case 'actions':
          return this.getNewButton();
          break;

        default:
          return <span/>;
          break;
      }
    } else {
      return <span/>;
    }
  }
})
