import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router';
import { Icon } from '../../components';
import PureRenderMixin from 'react-addons-pure-render-mixin';

export default React.createClass({
  mixins: [PureRenderMixin],
  render(){
    if(this.props.to) {
      return (
        <Link className="btn" style={{padding: "6px 12px", margin: "0"}} to={this.props.to}>
          <Icon fa={this.props.icon} />
        </Link>
      );
    }

    return (
      <Button style={{padding: "6px 12px", margin: "0"}} onClick={this.props.onClick}>
        <Icon fa={this.props.icon} />
      </Button>
    );
  }
});
