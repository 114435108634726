import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import moment from 'moment';
import {fromJS, Iterable, Map} from 'immutable';
import validator from '../utils/validator';

const ContractFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    ContractFormStore.setState({
      id:         Map({
        value:   null,
        isValid: null
      }),
      reference:  Map({
        value:   null,
        isValid: null
      }),
      validFrom:  Map({
        value:   null,
        isValid: null
      }),
      validUntil: Map({
        value:   null,
        isValid: null
      }),
      supplierId: Map({
        value:   null,
        isValid: null
      }),
      isSavable:  true,
      isPublic:   false,
      isImport:   false,
      error:      ''
    })
  },

  isValid(data) {
    var isValid = false;
    switch (data.field) {
      case 'reference':
        isValid = validator.required(data.value);
        break;
      case 'supplierId':
        isValid = validator.isNumericValid(data.value) && validator.required(data.value);
        break;
      case 'validUntil':
      case 'validFrom':
        isValid = validator.isDateValid(data.value) && validator.required(data.value);
        break;
      default:
        isValid = true;
        break
    }

    return isValid;
  },

  formatDate(date) {
    return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionContracts.UPDATE_CONTRACT:
      case Constants.ActionContracts.CREATE_CONTRACT:
        ContractFormStore.setState({isSavable: false})
        ContractFormStore.emitChange();
        break;

      case Constants.ActionContracts.CREATE_CONTRACT_SUCCESS:
      case Constants.ActionContracts.UPDATE_CONTRACT_SUCCESS:
        ContractFormStore.setState({
          isSavable: true,
          error:     false
        })
        ContractFormStore.emitChange();
        break;

      case Constants.ActionContracts.UPDATE_CONTRACT_ERROR:
      case Constants.ActionContracts.CREATE_CONTRACT_ERROR:
        nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        }
        _.each(ContractFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(ContractFormStore.get(key))) {
            nextState[key] = ContractFormStore.get(key).update('isValid', v => v = ContractFormStore.isValid({
              field: key,
              value: ContractFormStore.get(key).get('value')
            }));
          }
        })
        ContractFormStore.setState(nextState)
        ContractFormStore.emitChange();
        break;

      case Constants.ActionContractForm.CONTRACT_FORM_CHANGE:
        var nextState = {};
        var isValid = ContractFormStore.isValid(action)

        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        ContractFormStore.setState(nextState)
        ContractFormStore.emitChange();
        break;

      case Constants.ActionModals.SHOW_CONTRACT_FORM_MODAL:
        ContractFormStore.initialize();

        if (action.contract) {
          var nextState = {};
          _.each(ContractFormStore.state.toJS(), function (item, key) {
            if (!Iterable.isIterable(ContractFormStore.get(key))) {
              return;
            }
            if (key === 'validFrom' || key === 'validUntil') {
              action.contract[key] = ContractFormStore.formatDate(action.contract[key]);
            }

            nextState[key] = ContractFormStore.get(key).update('value', v => v = action.contract[key] ? fromJS(action.contract[key]) : null);
          })
          ContractFormStore.setState(nextState);
        }

        ContractFormStore.emitChange();
        break;

      case Constants.ActionModals.HIDE_CONTRACT_FORM_MODAL:
        ContractFormStore.initialize()
        ContractFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ContractFormStore;
