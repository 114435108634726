import React from 'react';
import _ from 'lodash';
import  { Map } from 'immutable';
import { Button, Input, ButtonToolbar, Col } from 'react-bootstrap';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import Select from 'react-select';

import { DatePicker, Autocomplete, PlattsDropdown, FieldSet, Save, InlineAlert, VolumeUnitDropdown, CurrencyDropdown } from '../../components';
import { ContractAirportFormAction } from '../../actions'

export default React.createClass({
  mixins: [PureRenderMixin],
  propTypes: {
    data     : React.PropTypes.object,
    onSave   : React.PropTypes.func,
    onCancel : React.PropTypes.func
  },

  getDefaultProps() {
    return {
      data : Map()
    }
  },

  getInitialState(){
    //Initialize state from props.
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  },

  render() {
    let platt           = this.getPlatts();
    let airports        = this.props.airports;
    let defaultAirport  = this.state.data.get('airportIcao').get('value') ? (
                            this.parseAirportToOption(airports.get(this.state.data.get('airportIcao').get('value')).toJS())
                          ) : (
                            null
                          );
    return (
        <form className="form-horizontal">
          <div className="row">
            <Autocomplete
              value            ={defaultAirport}
              name             ="airportIcao"
              label            ="Airport"
              placeholder      ="Search airport *"
              onChange         ={this.handleBlur}
              labelClassName   ="col-xs-3"
              wrapperClassName ="col-xs-9"
              options          ={this.props.airportsOptions.toJS()}
              itemsNumber      ="7"
              bsStyle          ={this.getValidClass(this.state.data.get('airportIcao').get('isValid'))}
              hasFeedback
            />
          </div>
          <div className="row row-form-pad">
            <Col xs={3}>
              <label className={"control-label " + this.getValidClass(this.state.data.get('plattId').get('isValid'))}>
                <span>Platts</span>
              </label>
            </Col>
            <Col xs={9}>
              <PlattsDropdown
                value    ={this.getPlatts()}
                options  ={this.props.plattsOptions.toJS()}
                onChange ={this.handleBlur}
              />
            </Col>
          </div>
          <div className="row row-form-pad">
            <Col xs={3}>
              <label className={"control-label " + this.getValidClass(this.state.data.get('value').get('isValid'))}>
                <span>Differential Price</span>
              </label>
            </Col>
            <Col xs={3}>
              <input
                name        ='valueId'
                type        ='hidden'
                value       ={this.state.data.get('valueId').get('value')} />
              <Input
                name        ='value'
                type        ='text'
                value       ={this.state.data.get('value').get('value')}
                valueType   ="number"
                placeholder ='Price *'
                onChange    ={this.handleChange}
                onBlur      ={this.handleBlur}
                bsStyle     ={this.getValidClass(this.state.data.get('value').get('isValid'))}
                hasFeedback />
            </Col>
            <Col xs={2}>
              <CurrencyDropdown
                defaultValue ={this.state.data.get('currencyCode').get('value')}
                data         ={this.props.currencies}
                onChange     ={this.handleBlur} />
            </Col>
            <Col xs={1} style={{padding: '0', 'textAlign': 'center'}}>
              <span style={{padding: '7px 0 0 0', display: 'inline-block'}}>per</span>
            </Col>
            <Col xs={3}>
              <VolumeUnitDropdown
                defaultValue ={this.state.data.get('volumeUnitCode').get('value')}
                data         ={this.props.volumeUnits}
                onChange     ={this.handleBlur} />
            </Col>
          </div>
          <div className="row row-form-pad">
            <Col xs={3}>
              <label className={"control-label " + this.getValidClass(this.state.data.get('deliveryBy').get('isValid'))}>
                <span>Delivery By</span>
              </label>
            </Col>
            <Col xs={9}>
              <Input
                name             ='deliveryBy'
                type             ='text'
                value            ={this.state.data.get('deliveryBy').get('value')}
                valueType        ='string'
                placeholder      ='Delivery By'
                onChange         ={this.handleChange}
                onBlur           ={this.handleBlur}
                bsStyle          ={this.getValidClass(this.state.data.get('deliveryBy').get('isValid'))}
                hasFeedback
              />
            </Col>
          </div>
          <div className="row row-form-pad">
            <Col xs={3}>
              <label className={"control-label " + this.getValidClass(this.state.data.get('payment').get('isValid'))}>
                <span>Payment via</span>
              </label>
            </Col>
            <Col xs={9}>
              <Input
                name             ='payment'
                type             ='text'
                value            ={this.state.data.get('payment').get('value')}
                valueType        ='string'
                placeholder      ='Payment to be used'
                onChange         ={this.handleChange}
                onBlur           ={this.handleBlur}
                bsStyle          ={this.getValidClass(this.state.data.get('payment').get('isValid'))}
                hasFeedback
              />
            </Col>
          </div>
          <DatePicker
            name             ='effectiveDate'
            value            ={this.state.data.get('effectiveDate').get('value')}
            type             ='text'
            label            ="Effective Date"
            placeholder      ='YYYY-MM-DD *'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ={3}
            wrapperClassName ={9}
            bsStyle          ={this.getValidClass(this.state.data.get('effectiveDate').get('isValid'))}
            hasFeedback
          />
          <DatePicker
            name             ='validUntil'
            value            ={this.state.data.get('validUntil').get('value')}
            type             ='text'
            label            ="Valid Until"
            placeholder      ='YYYY-MM-DD'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ={3}
            wrapperClassName ={9}
            bsStyle          ={this.getValidClass(this.state.data.get('validUntil').get('isValid'))}
            hasFeedback
          />
          <div className="row">
            <Col xs={3} className="text-right"></Col>
            <Col xs={9} className="text-center" style={{ marginTop: 10 }}>
              <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
            </Col>
          </div>
          <ButtonToolbar style={{'textAlign': 'right'}}>
            <Button
              bsStyle='danger'
              onClick={this.handleCancelClick}>
              Cancel
            </Button>
            <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')} />
          </ButtonToolbar>
        </form>
    )
  },

  handleChange(e) {
    let { name, value } = e.target;
    let nextState       = this.state.data.toObject();
    console.debug('handleChange for ' + name);
    nextState[name]     = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    //We upadte the state before
    this.handleChange(e);
    let { name, value } = e.target;
    ContractAirportFormAction.change(name, value)
  },

  handleCheck(e) {
    let { name, checked } = e.target;
    let value             = checked ? 1 : 0;
    let nextState         = this.state.data.toObject();
    nextState[name]       = this.state.data.get(name).update('value', v => v = value);
    
    this.setState({
      data: Map(nextState)
    })

    ContractAirportFormAction.change(name, value)
  },

  getPlatts(){
    let plattId = this.state.data.get('plattId').get('value');
    return plattId ? plattId : null;
  },

  parseAirportToOption(airport){
    return {
      value: airport.icao,
      label: airport.icao + " - " + airport.name,
      data : airport
    }
  },

  handleCancelClick() {
    this.props.onCancel();
  },

  handleSaveClick() {
    let data = {};
    _.each(this.props.data.toJS(), function(prop, key){
      data[key] = prop.value;
    }, this);

    this.props.onSave(data);
  },

  getValidClass(isValid){
    if(isValid === null) return null
    return isValid ? 'success' : 'error';
  }
});
