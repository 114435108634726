import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import TaxesApiUtils from '../utils/TaxesApiUtils'

export default {
  fetchAll: function(airportId, params) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionTaxes.LOAD_TAXES
    })

    TaxesApiUtils.get('/api/airports/' + airportId + '/taxes', params);
  },

  create: function(airportId, tax) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionTaxes.CREATE_TAX,
      airportId: airportId,
      tax: tax
    })

    TaxesApiUtils.create('/api/airports/' + airportId  + '/taxes', tax);
  },

  delete: function(taxId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionTaxes.DELETE_TAX,
      taxId: taxId
    })

    TaxesApiUtils.delete('/api/taxes/' + taxId);
  },

  update: function(tax) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionTaxes.UPDATE_TAX,
      tax: tax
    })

    TaxesApiUtils.update('/api/taxes/' + tax.id, tax);
  }
};