import React from 'react';
import {fromJS, Map} from 'immutable';
import {search} from '../../components/TableSearch/TableSearch';
import {Action, ListActions, PermissionRequired, TableSearch} from '../../components';
import {ModalsAction, SupplierAction} from '../../actions';
import {Activities} from '../../constants';
import Griddle from "griddle-react";

export default React.createClass({
  handleAddSupplierClick(props) {
    ModalsAction.showSupplierAirportForm(this.props.supplierAirportFormModalId, {})
  },
  handleEditSupplierClick(props) {
    ModalsAction.showSupplierAirportForm(this.props.supplierAirportFormModalId, props.rowData)
  },
  handleDeleteSupplierClick(props) {
    ModalsAction.show(this.props.supplierAirportDeleteDialogId, {
      supplierAirport: props.rowData,
      message:         "Are you sure you want to delete this airport (" + props.rowData.icao + ") from the supplier ?"
    })
  },
  getDetailsColumn() {
    return ["icao", "email", "id"];
  },
  getDetailsColumnMetadata() {
    return [{
      columnName:      'icao',
      displayName:     'Airport',
      customComponent: props => {
        let airport = this.props.airports.get(String(props.rowData.icao)).toJS();
        return (<p>{[airport.icao, airport.name].join(' - ')}</p>)
      },
      searchInput:     'text'
    }, {
      columnName:  'email',
      displayName: 'Email',
      searchInput: 'text'
    }, {
      columnName:      "id",
      displayName:     "",
      searchInput:     'actions',
      cssClassName:    "actionsColumn",
      customComponent: props => {
        return (<ListActions>
          <PermissionRequired requiredRoles={Activities.WRITE_AIRPORTS} user={this.props.user}>
            <Action icon="edit" onClick={this.handleEditSupplierClick.bind(null, props)}/>
            <Action icon="remove" onClick={this.handleDeleteSupplierClick.bind(null, props)}/>
          </PermissionRequired>
        </ListActions>)
      }
    }];
  },

  propTypes: {
    airports:         React.PropTypes.object,
    supplierAirports: React.PropTypes.object,
    searchParams:     React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      airports:         Map(),
      supplierAirports: Map(),
      searchParams:     Map()
    }
  },

  getInitialState() {
    return {
      supplierAirports: this.props.supplierAirports
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredSupplierAirports = search(this.props.supplierAirports.toJS(), nextProps.searchParams.toJS())
      this.setState({supplierAirports: fromJS(filteredSupplierAirports)})
    } else {
      this.setState({supplierAirports: nextProps.supplierAirports})
    }

    this.forceUpdate();
  },

  render() {
    let {supplier, platts, airports, values, supplierAirports, user, onDelete, onEdit} = this.props;
    return (
      <div>
        <TableSearch
          user={this.props.user}
          requiredRoles={Activities.WRITE_SUPPLIERS}
          columnMetadata={this.getDetailsColumnMetadata()}
          onSearch={this.handleSearch}
          isNewButton={true}
          newButtonText="Add airport"
          onNew={this.handleAddSupplierClick}/>

        <Griddle
          results={Object.values(this.state.supplierAirports.toJS())}
          tableClassName="table"
          showSettings={true}
          resultsPerPage={20}
          columnMetadata={this.getDetailsColumnMetadata()}
          columns={this.getDetailsColumn()}/>
      </div>
    )
  },

  handleSearch(e) {
    let {name, value} = e.target;
    SupplierAction.search(name, value);
  }
});
