import React from 'react';
import {fromJS, Map} from 'immutable';

import {Button} from 'react-bootstrap';
import {search} from '../../components/TableSearch/TableSearch';
import {Action, Icon, ListActions, PermissionRequired, TableSearch} from '../../components';
import {FbosAction, ModalsAction} from '../../actions';
import {Activities} from '../../constants';
import Griddle from "griddle-react";

export default React.createClass({
  handleAddFboClick(props) {
    ModalsAction.showFboAirportForm(this.props.fboAirportFormModalId, {})
  },
  handleEditFboClick(props) {
    ModalsAction.showFboAirportForm(this.props.fboAirportFormModalId, props.rowData)
  },
  handleDeleteFboClick(props) {
    ModalsAction.show(this.props.fboAirportDeleteDialogId, {
      fboAirport: props.rowData,
      message:    "Are you sure you want to delete this airport (" + props.rowData.icao + ") from the fbo ?"
    })
  },
  getDetailsColumn() {
    return ["icao", "email", "id"];
  },
  getDetailsColumnMetadata() {
    return [{
      columnName:      'icao',
      displayName:     'Airport',
      customComponent: props => {
        let airport = this.props.airports.get(String(props.rowData.icao)).toJS();
        return (<p>{[airport.icao, airport.name].join(' - ')}</p>)
      },
      searchInput:     'text'
    }, {
      columnName:  'email',
      displayName: 'Email',
      searchInput: 'text'
    }, {
      columnName:      "id",
      displayName:     "",
      searchInput:     'actions',
      cssClassName:    "actionsColumn",
      customComponent: props => {
        return (<ListActions>
          <PermissionRequired requiredRoles={Activities.WRITE_AIRPORTS} user={this.props.user}>
            <Action icon="edit" onClick={this.handleEditFboClick.bind(null, props)}/>
            <Action icon="remove" onClick={this.handleDeleteFboClick.bind(null, props)}/>
          </PermissionRequired>
        </ListActions>)
      }
    }];
  },

  propTypes: {
    airports:     React.PropTypes.object,
    fboAirports:  React.PropTypes.object,
    searchParams: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      airports:     Map(),
      fboAirports:  Map(),
      searchParams: Map()
    }
  },

  getInitialState() {
    return {
      fboAirports: this.props.fboAirports
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredFboAirports = search(this.props.fboAirports.toJS(), nextProps.searchParams.toJS())
      this.setState({fboAirports: fromJS(filteredFboAirports)})
    } else {
      this.setState({fboAirports: nextProps.fboAirports})
    }

    this.forceUpdate();
  },

  render() {
    let {fbo, platts, airports, values, fboAirports, user, onDelete, onEdit} = this.props;
    return (
      <div>
        <TableSearch
          user={this.props.user}
          requiredRoles={Activities.WRITE_FBOS}
          columnMetadata={this.getDetailsColumnMetadata()}
          onSearch={this.handleSearch}
          isNewButton={true}
          newButtonText="Add airport"
          onNew={this.handleAddFboClick}/>

        <Griddle
          results={Object.values(this.state.fboAirports.toJS())}
          tableClassName="table"
          showSettings={true}
          resultsPerPage={20}
          columnMetadata={this.getDetailsColumnMetadata()}
          columns={this.getDetailsColumn()}/>
      </div>
    )
  },

  handleSearch(e) {
    let {name, value} = e.target;
    FbosAction.search(name, value);
  }
});
