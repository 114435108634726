import React from 'react';
import { Map } from 'immutable';
import _ from 'lodash';
import {Col, FormControls, Row} from 'react-bootstrap';
import { SubTitle } from '../../components';
import { PlattFormAction } from '../../actions';

export default React.createClass({
  propTypes: {
    data     : React.PropTypes.object
  },

  getDefaultProps: function() {
    return {
      data : Map()
    }
  },

  getInitialState(){
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  },

  render() {
    return (
      <div>
        <SubTitle>Details</SubTitle>
        <form className="form-horizontal details-wrapper">
          <Row>
            <Col xs={3}>
              <label>ICAO</label>
            </Col>
            <Col xs={3}>
              <label>Supplier</label>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <span>{this.props.data.get('icao')}</span>
            </Col>
            <Col xs={3}>
              <span>{this.props.data.get('supplier')}</span>
            </Col>
          </Row>
        </form>
      </div>)
  }
});
