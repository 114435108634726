import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { fromJS } from 'immutable';

const TaxTypesStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    TaxTypesStore.setState({
      taxTypes : fromJS([
       {id: 'airport-fee', name: 'Airport fee'},
       {id: 'excise', name: 'Excise'},
       {id: 'vat', name: 'VAT'}
      ])
    });
  }
});

export default TaxTypesStore;
