import React from 'react';

export default React.createClass({
  componentDidMount: function(){
    var self = this;
    $('#mytable').dataTable({
      "bAutoWidth": false,
      "bDestroy": true,
      "fnDrawCallback": function() {
          self.forceUpdate();
      },
    });
  },
  componentDidUpdate: function(){
    $('#mytable').dataTable({
      "bAutoWidth": false,
      "bDestroy": true,
    });
  },
  render: function(){
    var x = this.props.initialData.map(function(d, index){
      console.log(d, index)
      return (<tr>
        <td>{index+1}</td>
        <td>{d.name}</td>
        <td>{d.city}</td>
        <td>{d.address}</td>
        <td>{d.phone}</td>
      </tr>)
    });
    return (
      <div className="table-responsive">
        <h4>Hello</h4>
        <table className="table table-bordered" id="mytable">
          <thead>
            <tr className="success">
              <td>Id</td>
              <td>Name</td>
              <td>City</td>
              <td>Address</td>
              <td>Phone</td>
            </tr>
          </thead>
          <tbody>
            {x}
          </tbody>
        </table>
      </div>
    )
  }
 });