import React from 'react';
import {List, Map} from 'immutable';
import moment from 'moment';

import {Loader, Oops, PageTitle} from '../../components';

import {PageError, PageLifcycle, PageLoaded, PageLoading} from '../PageLifecycle';
import PlattsList from './PlattsList';
import PlattsListActions from './PlattsListActions';

var effectiveDate = React.createClass({
  render() {
    if (!this.props.rowData.effectiveDate) {
      return null
    }

    return (
      <span>{moment(this.props.rowData.effectiveDate).format('YYYY-MM-DD')}</span>
    )
  }
});

var lastValue = React.createClass({
  render() {
    if (!this.props.rowData.lastValue) {
      return null;
    }

    let {value, currencyCode, volumeUnitCode} = this.props.rowData.lastValue;
    return (
      <span>{value + ' ' + currencyCode + '/' + volumeUnitCode}</span>
    )
  }
});

export default React.createClass({
  propTypes: {
    platts:              React.PropTypes.object,
    plattDataForm:       React.PropTypes.object,
    searchParams:        React.PropTypes.object,
    plattFormModalId:    React.PropTypes.number,
    plattDeleteDialogId: React.PropTypes.number
  },

  getDefaultProps: function () {
    return {
      platts:              List(),
      plattDataForm:       Map(),
      searchParams:        Map(),
      loadingPlattsStatus: Map({
        status:  'loading',
        message: 'Fetching data from server. Please wait.',
      })
    }
  },

  render() {
    return (
      <div className="data-table">
        <PageTitle icon="line-chart">List of platts & market price</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isPlattsLoading}>
            <Loader>
              Fetching platts from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isPlattsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isPlattsError && !this.props.isPlattsLoading}>
            <PlattsList
              user={this.props.user}
              platts={this.props.platts}
              searchParams={this.props.searchParams}
              columnMetadata={this.getColumnMetadata()}
              columnNames={this.getColumnNames()}
              isNewButton={false}
            />
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  },

  getColumnMetadata() {
    return [{
      "columnName":  "icao",
      "displayName": "ICAO",
      "searchInput": "text"
    }, {
      "columnName":  "supplierName",
      "displayName": "Supplier",
      "searchInput": "text"
    }/*, {
      columnName:      "lastValue",
      displayName:     "Last Value",
      customComponent: lastValue,
    }, {
      columnName:      "effectiveDate",
      displayName:     "Effective Date",
      customComponent: effectiveDate,
    }*/, {
      columnName:  "nbrValues",
      displayName: "nbr. Values"
    }, {
      columnName:      "id",
      displayName:     "",
      cssClassName:    "actionsColumn",
      customComponent: PlattsListActions,
      user:            this.props.user
    }];
  },

  getColumnNames() {
    return ['icao', 'supplierName',/* 'reviewPeriod', 'lastValue', 'effectiveDate',*/ 'nbrValues', 'id'];
  }
});
