import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

export default React.createClass({
  mixins: [PureRenderMixin],
  getInitialState() {
    return {
      options : this.props.options,
      hover   : false
    }
  },

  getStyle() {
    if (this.state.hover) {
      return {
        ...this.props.style,
        color:  'red',
        cursor: 'pointer'
      };
    } else {
      return {
        ...this.props.style,
        cursor : "pointer"
      };
    }
  },

  toggleHover() {
    this.setState({hover: !this.state.hover })
  },

  render() {
    let classString = 'fa';

    if(this.props.fa) {
      classString += ' fa-' + this.props.fa;
    }

    return <i 
      onClick      ={this.props.onClick} 
      className    ={classString}
      style        ={this.getStyle()} 
      onMouseEnter ={this.toggleHover} 
      onMouseLeave ={this.toggleHover} />;
  }
});
