import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { Map } from 'immutable';

const ErrorStore = assign({}, BaseStore, {
  initialize() {
    ErrorStore.setState({
      error: Map()
    });
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;
    switch(action.actionType) {
      case 401:
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ErrorStore;
