import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { fromJS } from 'immutable';

const CalculationSchemeStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    CalculationSchemeStore.setState({
      schemes : fromJS([
       {id: 'HIGH'},
       {id: 'MEAN'},
       {id: 'LOW'},
       {id: 'UPON NOTICE'},
       {id: 'N/A'}
      ]),
      schemesWithAll : fromJS([
       {id: 'ALL'},
       {id: 'HIGH'},
       {id: 'MEAN'},
       {id: 'LOW'},
       {id: 'UPON NOTICE'},
       {id: 'N/A'}
      ])
    });
  }
});

export default CalculationSchemeStore;
