import React from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';

import { DefaultModal, PageTitle, CSVForm, Icon, PermissionRequired, Oops, NoItemHelper, Loader } from '../../components'
import { ModalsAction, ImportAction } from '../../actions';
import { Activities } from '../../constants';

import { PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded } from '../PageLifecycle'
import AirportsList from './AirportsList'
import AirportsListActions from './AirportsListActions'

export default React.createClass({
  render() {
    return (
      <div className="data-table">
        <PageTitle icon="road">List of airports</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isAirportsLoading}>
            <Loader>
              Fetching airports from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isAirportsError}>
            <Oops errorCode={this.props.errCode} />
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed ={!this.props.isAirportsError && !this.props.isAirportsLoading && this.props.airports.size === 0}>
            <Oops>
              <p>Something is wrong with the database. Contact your administrator as soon as possible.</p>
            </Oops>
          </PageHelper>

        {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isAirportsError && !this.props.isAirportsLoading && !(this.props.airports.size === 0)}>
            <AirportsList
              user           ={this.props.user}
              airports       ={this.props.airports}
              import         ={this.props.import}
              onImport       ={this.handleImportClick}
              columnMetadata ={this.getColumnMetadata()}
              columnNames    ={this.getColumnNames()}
              loadingStatus  ={this.props.loadingAirportStatus}
              searchParams   ={this.props.searchParams}
            />
            <DefaultModal
              id     ={this.props.taxesImportDialogId}
              bsSize ="large"
              onHide ={this.handleCancelImportClick}
              title  ={'Import airports taxes from CSV'}>
              <CSVForm
                format     ="icao;name;type;calculation type;value;currency code;volume unit code;valid from;valid until"
                data       ={this.props.import}
                onSave     ={this.handleImportCSVClick} 
                onCancel   ={this.handleCancelImportClick}
                onContinue ={this.handleContinueClick} />
            </DefaultModal>
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  },

  getImportButton(){
    return (
       <PermissionRequired requiredRoles={Activities.IMPORT_TAXES} user={this.props.user}>
        <Button bsStyle="info" onClick={this.handleImportClick}>
          <Icon fa="upload" />
        </Button>
      </PermissionRequired>)
  },

  getColumnNames(){
    return ['icao', 'iata', 'name', 'city', 'country', 'dateCreate'];
  },

  getColumnMetadata() {
    return [{
      "columnName"  : "icao",
      "displayName" : "ICAO",
      "searchInput" : "text"
    },{
      "columnName"  : "iata",
      "displayName" : "IATA",
      "searchInput" : "text"
    },{
      "columnName"  : "name",
      "displayName" : "Name",
      "searchInput" : "text"
    },{
      "columnName"  : "city",
      "displayName" : "City",
      "searchInput" : "text"
    },{
      "columnName"  : "country",
      "displayName" : "Country",
      "searchInput" : "text"
    },{
      columnName      : "dateCreate",
      displayName     : "",
      cssClassName    : "actionsColumn",
      customComponent : AirportsListActions,
      component       : this.getImportButton(),
      searchInput     : 'component'
    }]
  },

  handleContinueClick() {
    this.handleCancelImportClick()
  },

  handleImportCSVClick() {
    ImportAction.importTaxes({data: this.props.import.get('data').get('value') })
  },

  handleCancelImportClick() {
    ModalsAction.hideImportModal(this.props.taxesImportDialogId)
  },

  handleImportClick() {
    ModalsAction.showImportModal(this.props.taxesImportDialogId);
  },
});