import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import FbosStore from './FbosStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';

const FboAirportsStore = assign({}, BaseStore, {
  initialize() {
    FboAirportsStore.setState({
      fboAirports:   Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionFbos.LOAD_FBO_SUCCESS:
      case Constants.ActionFbos.LOAD_FBOS_SUCCESS:
        FboAirportsStore.setState({
          fboAirports:   fromJS(action.data.entities.fboAirports ? action.data.entities.fboAirports : {}),
          loadingStatus: Constants.LOADED
        })

        FboAirportsStore.emitChange();
        break;

      case Constants.ActionFbos.CREATE_FBO_AIRPORT_SUCCESS:
        var id = action.data.result.fboAirports;
        var nextData = FboAirportsStore.get('fboAirports').set(String(id), fromJS(action.data.entities.fboAirports[id]));
        FboAirportsStore.setState({fboAirports: nextData});
        FboAirportsStore.emitChange();
        break;

      case Constants.ActionFbos.UPDATE_FBO_AIRPORT_SUCCESS:
        var id = action.data.result.fboAirports;
        var nextData = FboAirportsStore.get('fboAirports');
        FboAirportsStore.setState({
          fboAirports: nextData.update(String(id), v => v = fromJS(action.data.entities.fboAirports[id]))
        })

        FboAirportsStore.emitChange();
        break;

      case Constants.ActionFbos.DELETE_FBO_AIRPORT_SUCCESS:
        Dispatcher.waitFor([FbosStore.dispatchToken]);
        var id = action.data.result.fboAirports;

        //Hack, for some reason, sometimes it's string, sometimes integer :/
        var nextData = FboAirportsStore.get('fboAirports').delete(String(id));
        var nextData = nextData.delete(String(id));

        FboAirportsStore.setState({fboAirports: nextData});
        FboAirportsStore.emitChange();
        break
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default FboAirportsStore;
