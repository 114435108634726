import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import  { Map, fromJS, Iterable } from 'immutable';
import  validator from '../utils/validator';

const PlattFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    PlattFormStore.setState({
      id                : Map({value: null, isValid: null}),
      code              : Map({value: null, isValid: null}),
      name              : Map({value: null, isValid: null}),
      baseOrigin        : Map({value: null, isValid: null}),
      baseReference     : Map({value: null, isValid: null}),
      reviewPeriod      : Map({value: 'WEEKLY', isValid: null}),
      calculationScheme : Map({value: 'HIGH', isValid: null}),
      isSavable         : true,
      error             : ''
    })
  },

  _isValid(data){
    var _isValid = false;
    switch(data.field) {
      case 'code':
        _isValid = validator.required(data.value);
        break;
      case 'name':
        _isValid = validator.required(data.value);
        break;
      default:
        _isValid = true;
        break
    }

    return _isValid;
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;

    switch(action.actionType) {
      case Constants.ActionPlatts.UPDATE_PLATT:
      case Constants.ActionPlatts.CREATE_PLATT:
        PlattFormStore.setState({isSavable: false})
        PlattFormStore.emitChange();
        break;

      case Constants.ActionPlatts.CREATE_PLATT_SUCCESS:
      case Constants.ActionPlatts.UPDATE_PLATT_SUCCESS:
        PlattFormStore.setState({isSavable: true, error: false})
        PlattFormStore.emitChange();
        break;

      case Constants.ActionPlatts.UPDATE_PLATT_ERROR:
      case Constants.ActionPlatts.CREATE_PLATT_ERROR:
        var nextState = { isSavable: true, error: action.err.responseJSON.error.message };

        _.each(PlattFormStore.state.toJS(), function(item, key){
          if(!Iterable.isIterable(PlattFormStore.get(key))) return;
          nextState[key] = PlattFormStore.get(key).update('isValid', v => v = PlattFormStore._isValid({field: key, value: PlattFormStore.get(key).get('value') })  );
        })

        PlattFormStore.setState(nextState)
        PlattFormStore.emitChange();
        break;
      case Constants.ActionPlattForm.PLATT_FORM_CHANGE:
        var nextState = {};
        var _isValid  = PlattFormStore._isValid(action)

        nextState[action.field] = fromJS({ value: action.value, isValid: _isValid });
        PlattFormStore.setState(nextState)
        PlattFormStore.emitChange();
        break;
      case Constants.ActionModals.SHOW_PLATT_FORM_MODAL:
        PlattFormStore.initialize();

        if(action.platt) {
          var nextState = {};
          _.each(PlattFormStore.state.toJS(), function(item, key){
            if(!Iterable.isIterable(PlattFormStore.get(key))) return;
            nextState[key] = PlattFormStore.get(key).update('value', v => v = action.platt[key] ? fromJS(action.platt[key]) : null);
          })
          PlattFormStore.setState(nextState);
        }

        PlattFormStore.emitChange();
        break;
      case Constants.ActionModals.HIDE_PLATT_FORM_MODAL:
        PlattFormStore.initialize()
        PlattFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default PlattFormStore;