import React from 'react';
import _ from 'lodash';
import {List, Map} from 'immutable';
import {Constants} from '../../constants';

import {AirportsStore, SupplierAirportFormStore, SupplierAirportsStore, SuppliersStore, LoginStore} from '../../stores';

import {AirportAction, SupplierAction, ModalsAction} from '../../actions';

import commonUtils from '../../utils/commonUtils';
import Supplier from './Supplier'

SuppliersStore.initialize();
AirportsStore.initialize();
SupplierAirportsStore.initialize();
SupplierAirportFormStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user:                     null,
      supplier:                      Map(),
      airports:                 Map(),
      supplierAirports:              Map(),
      searchParams:             Map(),
      airportsOptions:          List(),
      errCode:                  null,
      isSupplierAirportsLoading:     true,
      isSupplierAirportsError:       false,
      isSupplierLoading:             true,
      isSupplierError:               false,
      isAirportsLoading:        true,
      isAirportsError:          false,
      supplierAirportForm:           Map(),
      supplierAirportFormModalId:    commonUtils.guid(),
      supplierAirportDeleteDialogId: commonUtils.guid(),
      supplierAirportImportDialogId: commonUtils.guid(),
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    SuppliersStore.addChangeListener(this.handleSuppliersStoreChange);
    AirportsStore.addChangeListener(this.handleAirportsStoreChange);
    SupplierAirportsStore.addChangeListener(this.handleSupplierAirportsStoreChange);
    SupplierAirportFormStore.addChangeListener(this.handleSupplierAirportFormStoreChange);
    SupplierAction.getSupplierById(this.props.params.id);
    console.log('Getting supplier ' + this.props.params.id);
    AirportAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    SuppliersStore.removeChangeListener(this.handleSuppliersStoreChange);
    AirportsStore.removeChangeListener(this.handleAirportsStoreChange);
    SupplierAirportsStore.removeChangeListener(this.handleSupplierAirportsStoreChange);
    SupplierAirportFormStore.removeChangeListener(this.handleSupplierAirportFormStoreChange);
  },

  render() {
    return <Supplier {...this.state} />;
  },

  hideModals() {
    ModalsAction.hideSupplierAirportForm(this.state.supplierAirportFormModalId);
    ModalsAction.hide(this.state.supplierAirportDeleteDialogId);
  },

  handleSuppliersStoreChange() {
    console.log('handleSuppliersStoreChange for: ' + this.props.params.id);
    this.setState({
      supplier:          SuppliersStore.get('suppliers').size > 0 ? SuppliersStore.get('suppliers').get(this.props.params.id) : Map(),
      isSupplierLoading: SuppliersStore.get('loadingStatus') === Constants.LOADING,
      isSupplierError:   SuppliersStore.get('loadingStatus') === Constants.ERROR,
      errCode:      SuppliersStore.get('errCode'),
      searchParams: SuppliersStore.get('search'),
      user: LoginStore.get('user')
    });
  },

  handleAirportsStoreChange() {
    console.log('handleAirportsStoreChange - status is ' + AirportsStore.get('loadingStatus'));
    this.setState({
      airports:          AirportsStore.get('airports'),
      airportsOptions:   this.parseAirportsToOptions(AirportsStore.get('airports')),
      isAirportsLoading: AirportsStore.get('loadingStatus') === Constants.LOADING,
      isAirportsError:   AirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleSupplierAirportsStoreChange() {
    console.log('handleSupplierAirportsStoreChange - status is ' + SupplierAirportsStore.get('loadingStatus'));
    this.hideModals();
    this.setState({
      supplierAirports:          SupplierAirportsStore.get('supplierAirports'),
      isSupplierAirportsLoading: SupplierAirportsStore.get('loadingStatus') === Constants.LOADING,
      isSupplierAirportsError:   SupplierAirportsStore.get('loadingStatus') === Constants.ERROR,
      user: LoginStore.get('user')
    });
  },

  handleSupplierAirportFormStoreChange() {
    this.setState({supplierAirportForm: SupplierAirportFormStore.state,
      user: LoginStore.get('user')});
  },

  parseAirportsToOptions(airports) {
    let options = List();
    _.each(airports.toJS(), (airport) => {
      options = options.push({
        value: airport.icao,
        label: airport.icao + " - " + airport.name,
        data:  airport
      })
    });

    return options
  },

  handleLoginStoreChange() {
    this.setState({
      user: LoginStore.get('user')
    })
  }
});
