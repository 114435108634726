import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import  { fromJS, List } from 'immutable';

const CurrenciesStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    CurrenciesStore.setState({
      currencies : List(),
      loadingStatus : Constants.LOADING
    });
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;

    switch(action.actionType) {
      case Constants.ActionCurrencies.LOAD_CURRENCIES_SUCCESS:
        CurrenciesStore.setState({
          currencies: fromJS(action.currencies),
          loadingStatus : Constants.LOADED
        })
        CurrenciesStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default CurrenciesStore;
