import React from 'react';
import  { Map, List } from 'immutable';

import { PlattFormStore, PlattsStore, ReviewPeriodStore, CalculationSchemeStore, LoginStore } from '../../stores';
import { Constants } from '../../constants';
import { PlattAction, ModalsAction } from '../../actions';

import commonUtils from '../../utils/commonUtils';
import Platts from './Platts'

PlattsStore.initialize();
ReviewPeriodStore.initialize();
CalculationSchemeStore.initialize();

export default React.createClass({
  getInitialState() {
    return {
      user                : null,
      platts              : List(),
      searchParams        : Map(),
      isPlattsLoading     : true,
      isPlattsError       : false,
      errCode             : null,
      plattFormModalId    : commonUtils.guid(),
      plattDeleteDialogId : commonUtils.guid(),
      plattDataForm       : Map(),
      dropdownsData       : Map({
        reviewPeriod         : ReviewPeriodStore.get('periods'),
        calculationScheme    : CalculationSchemeStore.get('schemes'),
        reviewPeriodAll      : ReviewPeriodStore.get('periodsWithAll'),
        calculationSchemeAll : CalculationSchemeStore.get('schemesWithAll')
      })
    }
  },

  componentDidMount() {
    LoginStore.addChangeListener(this.handleLoginStoreChange);
    PlattsStore.addChangeListener(this.handlePlattStoreChange);
    PlattFormStore.addChangeListener(this.handlePlattFormStoreChange);
    PlattAction.fetchAll();
  },

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
    PlattsStore.removeChangeListener(this.handlePlattStoreChange);
    PlattFormStore.removeChangeListener(this.handlePlattFormStoreChange);
  },

  render() {
    var data = this.state;
    return(<Platts {...this.state} />)
  },

  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  },

  handlePlattStoreChange() {
    ModalsAction.hidePlattForm(this.state.plattFormModalId);
    ModalsAction.hide(this.state.plattDeleteDialogId);

    this.setState({
      platts          : PlattsStore.get('platts'),
      searchParams    : PlattsStore.get('search'),
      isPlattsLoading : PlattsStore.get('loadingStatus') === Constants.LOADING,
      isPlattsError   : PlattsStore.get('loadingStatus') === Constants.ERROR,
      errCode         : PlattsStore.get('errCode'),
      user: LoginStore.get('user')
    })
  },

  handlePlattFormStoreChange() {
    this.setState({
      plattDataForm: PlattFormStore.state,
      user: LoginStore.get('user')
    })
  }
});
