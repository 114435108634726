import React from 'react';
import { Map } from 'immutable';
import { Button, Input, ButtonToolbar, Col, Row } from 'react-bootstrap'; 
import { CalculatorAction } from '../../actions';
import { OperationTypeSlider, LegendTitle, Trip } from './Settings'
import { ButtonStyles } from './Calculator.styles';
import Indices from './Indices';

export default React.createClass({
  propTypes: {
    trip : React.PropTypes.object
  },

  getDefaultProps(){
    return {
      trip : Map()
    }
  },

  getTitle(){
    if(this.props.settings.get('isPrivate') == null) {
      return <span><span style={{fontWeight: 800}}>Step 1 :</span> Please choose the type of the operation</span>
    } else {
      return <span>Type of operation</span>
    }
  },

  render() {
    return (
      <Row>
        <Col className="text-center">
          <LegendTitle>{this.getTitle()}</LegendTitle>
          <div>
            <OperationTypeSlider />
          </div>
          <Trip 
            modalId   ={this.props.modalId} 
            trip      ={this.props.settings.get('trip')}
            isPrivate ={this.props.settings.get('isPrivate')}
          />
        </Col>
      </Row>
    )
  },
});
