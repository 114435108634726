import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';

//Token
let jwt = localStorage.getItem('jwt');

const ImportApiUtils = {
  importFile: function (url, data) {
    Promise.resolve($.ajax({
      url:         url,
      type:        'POST',
      data:        data,
      processData: false,
      contentType: false,
      beforeSend:  (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        Dispatcher.handleViewAction({
          actionType:    Constants.ActionImport.IMPORT_SUCCESS,
          newContractId: data.contract,
          message:       data.message,
          status:        data.status,
          data:          data.results
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImport.IMPORT_ERROR,
          err:        err.results
        });
      })
  },

  import: function (url, data) {
    Promise.resolve($.ajax({
      url:        url,
      type:       'POST',
      data:       data,
      beforeSend: (req) => {
        req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
      }
    }))
      .then(function (data) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImport.IMPORT_SUCCESS,
          data:       data.results
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImport.IMPORT_ERROR,
          err:        err.results
        });
      })
  },

  getAll: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        //data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImports.LOAD_IMPORTS_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImports.LOAD_IMPORTS_ERROR,
          err:        err
        });
      })
  },

  getOne: function (url) {
    Promise.resolve(
      $.ajax({
        url:        url,
        type:       'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function (data) {
        //data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImports.LOAD_IMPORT_SUCCESS,
          data
        });
      })
      .catch(function (err) {
        Dispatcher.handleViewAction({
          actionType: Constants.ActionImports.LOAD_IMPORT_ERROR,
          err:        err
        });
      })
  }

};

export default ImportApiUtils;
