import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import AirportsStore from './AirportsStore';
import FboAirportsStore from './FboAirportsStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';
import _ from 'lodash';

const FbosStore = assign({}, BaseStore, {
  initialize() {
    FbosStore.setState({
      fbos:     Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING,
      errCode:       null
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionFbos.LOAD_FBOS_ERROR:
      case Constants.ActionFbos.LOAD_FBO_ERROR:
        FbosStore.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.LOAD_FBO_SUCCESS:
      case Constants.ActionFbos.LOAD_FBOS_SUCCESS:
        Dispatcher.waitFor[AirportsStore.dispatchToken];
        FbosStore.setState({
          fbos:     fromJS(action.data.entities.fbos ? action.data.entities.fbos : {}),
          loadingStatus: Constants.LOADED
        });
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.CREATE_FBO_SUCCESS:
        var id = action.data.result.fbos;
        var nextData = FbosStore.get('fbos').set(String(id), fromJS(action.data.entities.fbos[id]));
        FbosStore.setState({fbos: nextData});
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.SEARCH_FBOS:
        if (_.isUndefined(FbosStore.get('search').get(action.field))) {
          var nextData = FbosStore.get('search').set(action.field, action.value);
        } else {
          var nextData = FbosStore.get('search').update(action.field, v => v = action.value);
        }
        FbosStore.setState({search: nextData});
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.UPDATE_FBO_SUCCESS:
        var id = action.data.result.fbos;
        var nextData = FbosStore.get('fbos');
        FbosStore.setState({
          fbos: nextData.update(String(id), v => v = fromJS(action.data.entities.fbos[id]))
        });
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.DELETE_FBO_SUCCESS:
        let id = action.data.result.fbos, nextData;
        nextData = FbosStore.get('fbos').delete(String(id));
        FbosStore.setState({fbos: nextData});
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.CREATE_FBO_AIRPORT_SUCCESS:
        Dispatcher.waitFor([FboAirportsStore.dispatchToken]);
        var fboAirportId = action.data.result.fboAirports;
        var fboId = action.data.entities.fboAirports[fboAirportId].fboId;

        var nextData = FbosStore.get('fbos').update(String(fboId), v =>
          v = v.update("FboAirports", ca => ca = ca.push(fboAirportId))
        );

        FbosStore.setState({fbos: nextData});
        FbosStore.emitChange();
        break;
      case Constants.ActionFbos.DELETE_FBO_AIRPORT_SUCCESS:
        var fboAirportId = action.data.result.fboAirports;
        var fboId = action.data.entities.fboAirports[fboAirportId].fboId;

        var nextData = FbosStore.get('fbos').update(String(fboId), v =>
          v = v.update("FboAirports", ca =>
            ca = ca.delete(ca.indexOf(fboAirportId))
          ));
        FbosStore.setState({fbos: nextData});
        FbosStore.emitChange();
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default FbosStore;
