import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { Icon, Logo } from '../../components';

export default React.createClass({
  render(){
    return (
      <div style={this.getStyleContainer()} >
          <Button style={this.getStyleButton()} bsStyle="link" onClick={this.props.onDelete}>
            <Icon fa="close" />
          </Button>
        <Logo base64={this.props.base64} message={this.props.message}></Logo>
      </div>
    )
  },

  getStyleButton(){
    return {
      position : 'absolute',
      top      : '-20px',
      right    : '-26px',
      fontSize : '20px',
      color    : '#EC6A6A'
    }
  },

  getStyleContainer(){
    return {
      position : 'relative',
      width    : '200px',
      border   : '1px dashed #9E9E9E'
    }
  }
});
