import React from 'react';

export default React.createClass({
  render(){
    return (
      <div className="page">
        {this.props.children}
      </div>
      )
  }
});
