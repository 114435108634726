import React from 'react';
import _ from 'lodash';
import { Map, List } from 'immutable';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { Modal, Button, ButtonToolbar, TabbedArea, TabPane } from 'react-bootstrap';

import { SubTitle } from '../../components';
import { ModalsStore } from '../../stores';
import { ModalsAction } from '../../actions';

ModalsStore.initialize();

export default class ErrorDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;

    ModalsAction.add(this.props.id)
    this.handleChange = this.handleChange.bind(this)
    this._close = this._close.bind(this)

    this.keyPress = this.keyPress.bind(this);
  }

  componentDidMount() {
    ModalsStore.addChangeListener(this.handleChange);
    document.addEventListener('keyup', this.keyPress, false);
  }

  componentWillUnmount() {
    ModalsStore.removeChangeListener(this.handleChange);
    document.removeEventListener('keyup', this.keyPress);
  }

  keyPress(e){
    if(this.state.isVisible) {
      if(e.keyCode === 13)
        this.props.onYes()
    }
  }

  render(){
    return (<Modal bsSize="small" show={this.state.isVisible} payload={this.state.payload} onHide={this._close} icon={this.props.icon}>
      <Modal.Header closeButton>
        <div className="modal-error">
          <SubTitle>{this.props.title}</SubTitle>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {this.props.children}
        </div>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button onClick={this.props.onYes}>Close</Button>
        </ButtonToolbar>
      </Modal.Body>
    </Modal>)
  }

  handleChange(){
    this.setState({
      isVisible: ModalsStore.isModalVisible(this.props.id),
      payload  : ModalsStore.getPayload(this.props.id)
    })
  }

  _close(){
    ModalsAction.hide(this.props.id)
  }
}
ErrorDialog.propTypes    = {
  id        : React.PropTypes.number,
  isVisible : React.PropTypes.bool,
  payload   : React.PropTypes.object,
  icon      : React.PropTypes.string,
}
ErrorDialog.defaultProps    = {
  isVisible  : false,
  icon       : null,
  payload    : {}
}

