import React from 'react';
import _ from 'lodash'
import Griddle from 'griddle-react'
import  { Map, fromJS } from 'immutable';

import { AirportAction } from '../../actions'
import { Loader, TableSearch } from '../../components'
import { search } from '../../components/TableSearch/TableSearch';

export default React.createClass({
  propTypes: {
    airports      : React.PropTypes.object,
    loadingStatus :React.PropTypes.object
  },

  getDefaultProps: function() {
    return {
      airports     : Map(),
      loadingStatus : Map({
        status: 'loading',
        message: 'Fetching data from server. Please wait.',
      })
    }
  },

  getInitialState(){
    return {
      airports: this.props.airports
    }
  },

  componentWillReceiveProps(nextProps){
    if(nextProps.searchParams.size > 0) {
      let filteredAirports = search(this.props.airports.toJS(), nextProps.searchParams.toJS())
      this.setState({airports: fromJS(filteredAirports)})
    } else {
      this.setState({airports: nextProps.airports})
    }
  },

  render() {
    return (
      <div>
        <TableSearch columnMetadata={this.props.columnMetadata} onSearch={this.handleSearch}/>
        <Griddle
          initialSort           ="icao"
          results               ={this.getAirports()}
          tableClassName        ="table middle-td"
          resultsPerPage        ={20}
          filterPlaceholderText ="Search"
          columnMetadata        ={this.props.columnMetadata}
          columns               ={this.props.columnNames}
          noDataMessage         =""
        />
      </div>
    )
  },

  handleSearch(e){
    let { name, value } = e.target;
    if(value.length > 1 || value.length === 0)
      AirportAction.search(name, value);
  },

  getAirports(){
    return _.map(this.state.airports.toJS(), (a) => {return a;});
  }
});
