import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import moment from 'moment';

function processErrors(state, errors) {
  var nextState = { ...state };

  _.each(errors, (error, key) => {
    nextState.fields[key].error = error;
  });

  return nextState;
}

const PriceFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    PriceFormStore.setState({
      fields: {
        date: {
          label: 'Uplift date',
          name: 'date',
          placeholder: 'YYYY-MM-DD',
          value: moment().format('YYYY-MM-DD'),
          isValid: false
        },
        airportIcao: {
          label: 'Airport',
          name: 'airportIcao',
          placeholder: 'Airport',
          value: null
        },
        currencyCode: {
          label: 'Currency',
          name: 'currencyCode',
          value: 'USD'
        },
        volumeUnitCode: {
          label: 'Volume Unit',
          name: 'volumeUnitCode',
          value: 'US gal'
        }
      },
      data: {}
    });

    PriceFormStore.emitChange();
  },

  dispatchToken: Dispatcher.register(function(payload) {
    const action = payload.action;
    const state = PriceFormStore.getState().toJS();
    const { fields } = state;

    switch(action.actionType) {
      case Constants.ActionPricesForm.PRICES_FORM_CHANGE:
        var { value, field } = action;
        PriceFormStore.setState({
          ...state,
          fields: {
            ...fields,
            [field]: {
              ...fields[field],
              value: value
            }
          }
        });

        PriceFormStore.emitChange();
        break;
      case Constants.ActionPrices.LOAD_PRICES_SUCCESS:
        const { data } = action;
        PriceFormStore.setState({
          ...state,
          data
        })

        PriceFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default PriceFormStore;