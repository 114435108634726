import React from 'react';
//import {NotificationContainer} from 'react-notification';
import Radium from 'radium';
import {RouteHandler} from 'react-router';
import {Grid, Row, Col, Nav, Navbar, NavBrand} from 'react-bootstrap';
import LogoGlobal from '../LogoGlobal/LogoGlobal';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import TopNav, { NavTitle } from '../TopNav/TopNav';
import { ModalsAction } from '../../actions';
import { Activities } from '../../constants';
import { ErrorStore, LoginStore } from '../../stores';
import commonUtils from '../../utils/commonUtils';

let menuItems = [
  { route: 'Contracts', text: 'Primary Contracts', icon: 'file-text', url: '/contracts', activity: Activities.READ_CONTRACTS },
  { route: 'Fbos', text: 'Fbos', icon: 'users', url: '/fbos', activity: Activities.READ_FBOS },
  { route: 'Platts', text: 'Platts', icon: 'line-chart', url:'/platts', activity: Activities.READ_PLATTS },
  { route: 'PlattsV2', text: 'List of Platts & Market price', icon: 'line-chart', url:'/plattsV2', activity: Activities.READ_PLATTS },
  { route: 'Airports', text: 'Airports', icon: 'road', url: '/airports', activity: Activities.READ_AIRPORTS },
  { route: 'Suppliers', text: 'Suppliers', icon: 'industry', url: '/suppliers', activity: Activities.READ_SUPPLIERS },
  { route: 'Fuel prices', text: 'Fuel prices', icon: 'usd', url: '/prices', activity: Activities.READ_FUEL_PRICES },
  { route: 'Fuel indexes', text: 'Fuel indexes', icon: 'calculator', url: '/indexes', activity: Activities.USE_FUEL_SIMULATOR },
  { route: 'Import', text: 'Fuel Imports', icon: 'upload', url: '/import', activity: Activities.IMPORT_CONTRACTS },
  { route: 'Fuel requests', text: 'Sent Fuel Requests', icon: 'send', url: '/fuelrequests', activity: Activities.READ_FUEL_REQUESTS }
];

ErrorStore.initialize();

const App = React.createClass({
  getInitialState() {
    return {
      error  : {},
      user: null,
      errorModalId: commonUtils.guid()
    }
  },

  componentDidMount() {
    $.material.init();
    ErrorStore.addChangeListener(this.onHandleError);
    LoginStore.addChangeListener(this.handleLoginStoreChange);
  },

  componentWillUnmount() {
    ErrorStore.removeChangeListener(this.onHandleError);
    LoginStore.removeChangeListener(this.handleLoginStoreChange);
  },

  onHandleError() {
    if(ErrorStore.get('error')) {
      this.setState({error: ErrorStore.get('error').toJS()});
      ModalsAction.show(this.state.errorModalId);
    }
  },

  handleYesClick() {
    ModalsAction.hide(this.state.errorModalId);
  },

  getErrorMessage() {
    if(this.state.error.message)
      return this.state.error.message;
    else
      return "Unknown error";
  },

  render() {
    return (
      <div id="app-background">
        <div className="container-fluid" style={{height: "100%"}}>
          <Row className='show-grid'>
              <Navbar style={{margin: 0}} className="shadow-z-2 topnav">
                <NavBrand style={{paddingTop: 12}}>
                  <LogoGlobal/><NavTitle>Fuel</NavTitle>
                </NavBrand>
                <TopNav user={this.state.user} menuItems={menuItems} />
              </Navbar>
          </Row>
          <Row className='show-grid app-wrapper'>
            <Col md={12} className='app-content'>
              <div style={{padding: "30px", height: "100%", 'backgroundColor': "#fff", borderRadius: "3px"}} className="shadow-z-2">
                {this.props.children}
              </div>
            </Col>
          </Row>
          <ErrorDialog
              id    = {this.state.errorModalId}
              title ="An error occured"
              onYes ={this.handleYesClick}>
            {this.state.error.message}
          </ErrorDialog>
        </div>

      </div>
    );
  },
//<NotificationContainer/> was here

  handleLoginStoreChange(){
    this.setState({
      user: LoginStore.get('user')
    })
  }
});

export default Radium(App);
