import React from 'react';
import _ from 'lodash';
import Griddle from 'griddle-react';
import {Map} from 'immutable';
import {PermissionRequired, TableSearch} from '../../components';
import {search} from '../../components/TableSearch/TableSearch';
import {ContractsAction} from '../../actions';
import {Activities} from '../../constants';

export default React.createClass({
  propTypes: {
    contracts:     React.PropTypes.array,
    loadingStatus: React.PropTypes.object
  },

  getDefaultProps: function () {
    return {
      contracts:     [],
      loadingStatus: Map({
        status:  'loading',
        message: 'Fetching data from server. Please wait.',
      })
    }
  },

  getInitialState() {
    return {
      contracts: this.props.contracts
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps.contracts, this.props.contracts)
      || this.props.loadingStatus.get('status') !== nextProps.loadingStatus.get('status');
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredContracts = search([...this.props.contracts], nextProps.searchParams.toJS())
      this.setState({contracts: filteredContracts})
    } else {
      this.setState({contracts: nextProps.contracts})
    }

    this.forceUpdate();
  },

  render() {
    return (

      <PermissionRequired requiredRoles={Activities.READ_CONTRACTS} user={this.props.user}>

        <TableSearch
          user={this.props.user}
          requiredRoles={Activities.WRITE_CONTRACTS}
          columnMetadata={this.props.columnMetadata}
          onSearch={this.handleSearch}
          isNewButton={this.props.isNewButton}
          newButtonText="New contract"
          onNew={this.props.onNew}
        />
        <Griddle
          initialSort="reference"
          initialSortAscending={false}
          results={this.state.contracts}
          tableClassName="table middle-td"
          resultsPerPage={20}
          filterPlaceholderText="Search"
          columnMetadata={this.props.columnMetadata}
          columns={this.props.columnNames}
        />
      </PermissionRequired>
    )
  },

  handleSearch(e) {
    let {name, value} = e.target;
    ContractsAction.search(name, value);
  }
});
