import React from 'react';
import {Map} from 'immutable';
import {Button, ButtonToolbar, Col, Input, Row} from 'react-bootstrap';
import {Dropbox, InlineAlert, LogoControl, Save} from '../../components';
import {SupplierFormAction} from '../../actions';
import ImageTools from '../../utils/ImageTools';

export default class SupplierForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;

    this.handleDrop = this.handleDrop.bind(this);
    this.getLogo = this.getLogo.bind(this);
    this.handleDeleteLogo = this.handleDeleteLogo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  }

  render() {
    let data = this.state.data;
    return (
      <form className="form-horizontal">
        <Row>
          <Input
            name='name'
            value={data.get('name').get('value')}
            type='text'
            label="Name"
            placeholder='Supplier Name'
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            labelClassName='col-xs-3'
            wrapperClassName='col-xs-9'
            bsStyle={this.getValidClass(data.get('name').get('isValid'))}
            hasFeedback/>
        </Row>
        <Row>
          <Input
            name='email'
            value={data.get('email').get('value')}
            type='text'
            label="Email"
            placeholder='Supplier Email'
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            labelClassName='col-xs-3'
            wrapperClassName='col-xs-9'
            bsStyle={this.getValidClass(data.get('email').get('isValid'))}
            hasFeedback/>
        </Row>
        <Row>
          <Col xs={3} className="text-right">
            <label>Logo</label>
          </Col>
          <Col xs={9} className="text-center">
            {this.getLogo()}
          </Col>
        </Row>
        <Row>
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{marginTop: 10}}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </Row>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick.bind(this)}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick.bind(this)} disabled={!this.props.data.get('isSavable')}/>
        </ButtonToolbar>
      </form>
    )
  }

  handleChange(e) {
    let {name, value} = e.target;
    var nextState = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    });
  }

  handleBlur(e) {
    let {name, value} = e.target;
    SupplierFormAction.change(name, value)
  }

  handleDeleteLogo() {
    SupplierFormAction.change("logo", null)
  }

  getLogo() {
    if (this.props.data.get('logo') && this.props.data.get('logo').get('value')) {
      let base64 = this.props.data.get('logo').get('value');
      return <LogoControl base64={base64} onDelete={this.handleDeleteLogo}/>
    } else {
      return <Dropbox onDrop={this.handleDrop}>Drag and drop files here, or click to select logo to upload.</Dropbox>
    }
  }

  handleDrop(files) {
    var _self = this;

    ImageTools.resize(files[0], {
      width:  200,
      height: 200
    }, function (blob, didItResize) {
      _self.blobToBuffer(blob, function (url) {
        var base64 = url.replace(/^data:image\/(png|jpg|gif|jpeg);base64,/, "");
        SupplierFormAction.change('logo', base64)
      })
    });
  }

  blobToBuffer(blob, callback) {
    var fileReader = new FileReader();

    fileReader.onload = function () {
      callback(this.result)
    };

    fileReader.readAsDataURL(blob);
  }

  handleCancelClick() {
    this.props.onCancel();
  }

  handleSaveClick() {
    var _data = this.props.data.toJS();

    var data = {
      name:  _data.name.value,
      email: _data.email.value,
      logo:  _data.logo.value
    };

    if (_data.id.value) {
      data['id'] = _data.id.value;
    }

    this.props.onSave(data);
  }

  getValidClass(isValid) {
    if (isValid === null) {
      return null
    }
    return isValid ? 'success' : 'error';
  }
}
