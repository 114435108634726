import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export default React.createClass({
  render() {
    return (
      <ReactCSSTransitionGroup 
        transitionName="fadeInAnimation" 
        transitionAppear={true} 
        transitionAppearTimeout={!this.props.delay ? 500 : this.props.delay}
        transitionEnterTimeout={!this.props.delay ? 500 : this.props.delay} 
        transitionLeaveTimeout={300}>
        {this.props.children}
      </ReactCSSTransitionGroup>
    )
  }
});
