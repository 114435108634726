import React from 'react';
import Dropzone from 'react-dropzone';

export default React.createClass({
  render(){
    return (
      <div>
        <span style={{color: '#BFAE8B'}} >{this.props.children}</span>
        <Dropzone
          multiple={false}
          onDrop={this.props.onDrop}
          style={this.getDropzoneStyle()}
          activeStyle={this.getDropzoneActiveStyle()} >
        </Dropzone>
      </div>
    )
  },

   getDropzoneStyle(){
    return {
      width           : '100%',
      height          : '100px',
      padding         : '10px',
      backgroundColor : '#FFF1D5',
      border          : '1px dashed #9E9074',
      textAlign       : 'center',
    }
  },

  getDropzoneActiveStyle(){
     return {
      backgroundColor : '#fff8ed',
    }
  }
});
