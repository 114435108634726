import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import PlattsApiUtils from '../utils/PlattsApiUtils'

const PlattAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.LOAD_PLATTS
    });

    PlattsApiUtils.getAll('/api/plattsv2');
  },

  getPlattById(icao, supplier) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.LOAD_PLATT
    });

    PlattsApiUtils.getOne('/api/plattsv2/' + icao + '/' + supplier);
  },

  search(field, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionPlatts.SEARCH_PLATTS,
      field:      field,
      value:      value
    })
  }
};

export default PlattAction
