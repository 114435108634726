import React from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';

import { DefaultModal, PageTitle, CSVForm, Icon, PermissionRequired, Oops, NoItemHelper, Loader } from '../../components'
import { ModalsAction, ImportAction } from '../../actions';
import { Activities } from '../../constants';

import { PageLifcycle, PageLoading, PageError, PageHelper, PageLoaded } from '../PageLifecycle'


export default React.createClass({
  render() {
    return (
      <div className="data-table">
        <PageTitle icon="dashboard">Welcome to the Fuel App</PageTitle>

        Welcome to the Fuel App
      </div>
    )
  },


});
