import Dispatcher from '../core/Dispatcher';
import { EventEmitter } from 'events';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import AirportsStore from './AirportsStore';
import assign from 'object-assign';
import _ from 'lodash';
import  { Map, fromJS } from 'immutable';

const TaxesStore = assign({}, BaseStore, {
  initialize(airportId) {
    TaxesStore.setState({
      taxes         : Map(),
      loadingStatus : Constants.LOADING
    });
  },

  // Allow Controller-View to register itself with store
  addChangeListener(callback) {
    this.on(Constants.CHANGE_EVENT, callback);
  },

  dispatchToken: Dispatcher.register(function(payload) {
    var action = payload.action;

    switch(action.actionType) {
      case Constants.ActionAirports.LOAD_AIRPORT_SUCCESS:
      case Constants.ActionTaxes.LOAD_TAXES_SUCCESS:
        TaxesStore.setState({
          taxes         : fromJS(action.data.entities.taxes ? action.data.entities.taxes : {}),
          loadingStatus : Constants.LOADED
        });
        TaxesStore.emitChange();
        break;

      case Constants.ActionTaxes.CREATE_TAX_SUCCESS:
        var id       = action.data.result.taxes;
        var nextData = TaxesStore.get('taxes').set(String(id), Map(action.data.entities.taxes[id]));
        TaxesStore.setState({ taxes : nextData });
        TaxesStore.emitChange();
        break;

      case Constants.ActionTaxes.UPDATE_TAX_SUCCESS:
        var id       = action.data.result.taxes;
        var nextData = TaxesStore.get('taxes');
        TaxesStore.setState({
          taxes: nextData.update(String(id), v => v = Map(action.data.entities.taxes[id]))
        })
        TaxesStore.emitChange();
        break;

      case Constants.ActionTaxes.DELETE_TAX_SUCCESS:
        Dispatcher.waitFor([AirportsStore.dispatchToken])

        var id       = action.data.result.taxes;
        var nextData = TaxesStore.get('taxes').delete(String(id));

        TaxesStore.setState({ taxes : nextData });
        TaxesStore.emitChange();
        break;

    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default TaxesStore;