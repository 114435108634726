import React from 'react';
import * as _ from 'lodash';
import {LegendTitle} from './Settings';
import {Badge, Button} from 'react-bootstrap';
import {IndexesStyles} from './Calculator.styles';
import {Icon, IconButton, InlineAlert, Logo, NoPrint, Pull} from '../../components';
import formatter from "../../utils/formatter";

require('./Indices.css');

export default React.createClass({
  propTypes: {
    indices: React.PropTypes.object,
  },

  showExpiredAlert(isExpiredTooltip) {
    alert(isExpiredTooltip);
  },

  getColor(value) {
    if (value > 80) {
      return '#FF0707';
    }

    if (value > 65) {
      return '#FF8302';
    }

    if (value > 55) {
      return '#FFC107';
    }

    return '#8BC34A';

  },

  getCheckIcon(isCheck) {
    return isCheck ? <Icon fa="check"/> : null;
  },

  getLogo(supplier) {
    if (!!supplier.get('logo')) {
      return <Logo base64={supplier.get('logo')} maxHeight={20}/>
    }
  },

  getAirportName(airport) {
    return airport.icao + ' - ' + (airport.name ? airport.name : 'Unknown');
  },

  handlePrintClick() {
    window.print();
  },

  rowError(i, airport) {
    return (
      <tr style={_.merge(i % 2 === 0 ? {} : IndexesStyles.trColor, IndexesStyles.tr)} key={i * 100}>
        <td style={IndexesStyles.td}>{this.getAirportName(airport)}</td>
        <td colSpan='10' style={IndexesStyles.td}><InlineAlert bsStyle="warning">{airport.error.message}</InlineAlert>
        </td>
      </tr>
    )
  },

  handleToggle(key, icao) {
    console.log('Handling toggle for key ' + key + ', icao ' + icao );
    // show all hidden records
    $('#indices tbody tr[data-record^="'+icao+'_"]').removeClass('hidden');
    // hide button to avoid managing opposite way
    $('#' + icao + '_btn').hide();
    // refresh rowspan
    let $rowSpanSelector = $('#' + icao + '_rowspan');
    let originalRowSpan = $rowSpanSelector.attr('data-originalrowspan');

    console.log('originalRowSpan is ' + originalRowSpan);

    $rowSpanSelector.attr('rowspan', originalRowSpan);
  },

  render() {
    console.log('Rendering indices');
    let {indices, settings, suppliers} = this.props;
    let i = 1;
    if (indices.size === 0) {
      return null
    }

    return (
      <div>
        <LegendTitle>
          <span>
            <NoPrint><span style={{fontWeight: 800}}>Step 3 :</span></NoPrint> Fuel indices for a <span style={{fontWeight: 'bold'}}>{settings.get('isPrivate') ? 'private' : 'commercial'}</span> flight.
          </span>
        </LegendTitle>
        <NoPrint>
          <Pull to="right">
            <IconButton fa="print" onClick={this.handlePrintClick}/>
          </Pull>
        </NoPrint>
        <div id="indices">
          <table style={IndexesStyles.table}>
            <thead>
            <tr style={IndexesStyles.headTr}>
              <th style={IndexesStyles.th}>
                Airport
              </th>
              <th style={IndexesStyles.th}>
                Domestic
              </th>
              <th style={IndexesStyles.th}>
                International
              </th>
              <th style={IndexesStyles.th}>
                Vendor
              </th>
              <th style={IndexesStyles.th}>
                Local Delivery via
              </th>
              <th style={IndexesStyles.th}>
                Payment to be used
              </th>
              <th style={IndexesStyles.th}>
                Contract
              </th>
              <th style={IndexesStyles.th}></th>
            </tr>
            </thead>
            <tbody>
            {_.map(indices.toJS(), (airport) => {
              i++;

              if (airport.error) {
                return this.rowError(i, airport)
              }

              if (airport.contracts.length === 0) {
                airport.error = {message: 'Not covered by a contract'};
                return this.rowError(i, airport)
              }

              return _.map(airport.contracts, (contract, key) => {
                console.log('Generating for ' + airport.icao + ' with ' + airport.contracts.length + ' contracts');

                let indices = contract.indices;
                let domesticIndex = settings.get('isPrivate') ? Math.round(indices.private.domestic) : Math.round(indices.commercial.domestic);
                let internationalIndex = settings.get('isPrivate') ? Math.round(indices.private.international) : Math.round(indices.commercial.international);

                return (
                  <tr
                    data-record={airport.icao + '_' + key}
                    data-debug={airport.icao + '_' + key + '_' + airport.contracts.length}
                    className={(airport.contracts.length > 1 && key!==0) ? 'hidden' : ''}
                    style={_.merge(i % 2 === 0 ? {} : IndexesStyles.trColor, IndexesStyles.tr)}
                    key={key}>
                    {key === 0 ?
                      <td id={airport.icao + '_rowspan'} data-originalrowspan={airport.contracts.length} style={IndexesStyles.td}>{this.getAirportName(airport)}</td> : null}
                    <td style={IndexesStyles.td}>
                      <Badge>
                        {domesticIndex}
                      </Badge>
                    </td>
                    <td style={IndexesStyles.td}>
                      <Badge>
                        {internationalIndex}
                      </Badge>
                    </td>
                    <td style={IndexesStyles.td}>
                      {this.getLogo(suppliers.get(String(contract.supplierId)))}
                      &nbsp;{suppliers.get(String(contract.supplierId)).get('name')}
                    </td>
                    <td style={IndexesStyles.td}>{contract.deliveryBy}</td>
                    <td style={IndexesStyles.td}>{contract.payment ? contract.payment : 'N/A'}</td>
                    <td style={IndexesStyles.td}>
                      {contract.isPublic ? <span className="badge btn-warning">Secondary</span> :
                        <span className="badge btn-success">Primary</span>}

                      {contract.isExpired === 1 ?
                        <IconButton onClick={this.showExpiredAlert.bind(this, 'This indice is shown but expired since ' + formatter.format.date(contract.indiceDate))} fa="warning"/> : ''}
                    </td>
                    <td style={IndexesStyles.td}>
                      {airport.contracts.length > 1 && key===0 ?
                        <Button
                          id={airport.icao + '_btn'}
                          bsStyle='btn-indices-sm btn-primary'
                          className ='shadow-z-1'
                          onClick   ={this.handleToggle.bind(this, key, airport.icao)}>
                          <Icon fa="plus" />
                        </Button> : ''}
                    </td>
                  </tr>
                )
              })
            })}
            </tbody>
            <tfoot>
            <tr style={IndexesStyles.tfoot}>
              <td colSpan="10"></td>
            </tr>
            </tfoot>
          </table>
          <div style={{marginTop: 15}}>
            <p>
              <Icon fa="info"/>
              &nbsp; Important Notice : Each destination needs a Fuel Release. Prioritize always “primary contract”.
            </p>
            <p>
              For further information or issue, please contact immediately <strong>ops@globaljetconcept.com </strong>
            </p>
          </div>
        </div>
      </div>
    )
  }
});
