import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import {fromJS, Iterable, Map} from 'immutable';
import validator from '../utils/validator';

const FboAirportFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    FboAirportFormStore.setState({
      id:          Map({
        value:   null,
        isValid: null
      }),
      icao: Map({
        value:   null,
        isValid: null
      }),
      email:       Map({
        value:   null,
        isValid: null
      }),
      isSavable:   true,
      error:       ''
    })
  },

  isValid(data) {
    let isValid = false;
    switch (data.field) {
      case 'icao':
        isValid = validator.required(data.value);
        break;
      default:
        isValid = true;
        break
    }

    return isValid;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    let action = payload.action;
    let nextState = {};

    switch (action.actionType) {
      case Constants.ActionFbos.UPDATE_FBO_AIRPORT:
      case Constants.ActionFbos.CREATE_FBO_AIRPORT:
        FboAirportFormStore.setState({isSavable: false});
        FboAirportFormStore.emitChange();
        break;

      case Constants.ActionFbos.CREATE_FBO_AIRPORT_SUCCESS:
      case Constants.ActionFbos.UPDATE_FBO_AIRPORT_SUCCESS:
        FboAirportFormStore.setState({
          isSavable: true,
          error:     false
        });
        FboAirportFormStore.emitChange();
        break;

      case Constants.ActionFbos.UPDATE_FBO_AIRPORT_ERROR:
      case Constants.ActionFbos.CREATE_FBO_AIRPORT_ERROR:
        nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        };
        _.each(FboAirportFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(FboAirportFormStore.get(key))) {
            nextState[key] = FboAirportFormStore.get(key).update('isValid', v => v = FboAirportFormStore.isValid({
              field: key,
              value: FboAirportFormStore.get(key).get('value')
            }));
          }
        });
        FboAirportFormStore.setState(nextState);
        FboAirportFormStore.emitChange();
        break;

      case Constants.ActionFboAirportForm.FBO_AIRPORT_FORM_CHANGE:
        let isValid = FboAirportFormStore.isValid(action);

        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        FboAirportFormStore.setState(nextState);
        FboAirportFormStore.emitChange();
        break;
      case Constants.ActionModals.SHOW_FBO_AIRPORT_FORM_MODAL:
        if (action.fboAirport) {
          nextState["id"] = FboAirportFormStore.get("id").update('value', v => v = action.fboAirport.id ? fromJS(action.fboAirport.id) : null);
          nextState["icao"] = FboAirportFormStore.get("icao").update('value', v => v = action.fboAirport.icao ? fromJS(action.fboAirport.icao) : null);
          nextState["email"] = FboAirportFormStore.get("email").update('value', v => v = action.fboAirport.email ? fromJS(action.fboAirport.email) : null);

          FboAirportFormStore.setState(nextState);
        }
        FboAirportFormStore.emitChange();
        break;
      case Constants.ActionModals.HIDE_FBO_AIRPORT_FORM_MODAL:
        FboAirportFormStore.initialize();
        FboAirportFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default FboAirportFormStore;
