import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';

const ImportFormStore = assign({}, BaseStore, {
  initialize() {
    // Set some default params to the form
    ImportFormStore.setState({
      data:          Map({value: null}),
      isSavable:     true,
      isLoading:     false,
      error:         '',
      results:       Map({value: null}),
      status:        null,
      message:       null,
      supplierId:    null,
      effectiveDate: null,
      newContractId: null,
      importType: null
    })
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionImport.IMPORT:
        ImportFormStore.setState({
          isSavable: false,
          isLoading: true
        });
        ImportFormStore.emitChange();
        break;

      case Constants.ActionImport.IMPORT_SUCCESS:
        ImportFormStore.setState({
          isSavable:     true,
          newContractId: action.newContractId,
          status:        action.status,
          message:       action.message,
          results:       Map({value: fromJS(action.data)}),
          isLoading:     false
        });
        ImportFormStore.emitChange();
        break;

      case Constants.ActionImport.IMPORT_ERROR:
        ImportFormStore.setState({
          isSavable: true,
          error:     action.err.responseJSON.error.message,
          isLoading: false
        });
        ImportFormStore.emitChange();
        break;

      case Constants.ActionModals.HIDE_IMPORT_MODAL:
      case Constants.ActionModals.SHOW_IMPORT_MODAL:
        ImportFormStore.initialize();
        ImportFormStore.emitChange();
        break;

      case Constants.ActionImport.IMPORT_FORM_CHANGE:
        var nextState = {};
        nextState[action.field] = action.value;
        ImportFormStore.setState(nextState);
        ImportFormStore.emitChange();
        console.log('IMPORT_FORM_CHANGE: ' + action.field + '--' + action.value + '--' + JSON.stringify(nextState[action.field]));
        break;

      case Constants.ActionImport.IMPORT_FORM_CHANGE_SUPPLIER:
        ImportFormStore.setState({
          supplierId: action.value
        });
        ImportFormStore.emitChange();
        console.log('IMPORT_FORM_CHANGE_SUPPLIER: ' + action.value);
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ImportFormStore;
