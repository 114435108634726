import React from 'react';
import _ from 'lodash';
import {Map} from 'immutable';
import {Button, ButtonToolbar, Col, Input} from 'react-bootstrap';
import PureRenderMixin from 'react-addons-pure-render-mixin';

import {Autocomplete, InlineAlert, Save} from '../../components';
import {SupplierAirportFormAction} from '../../actions'

export default React.createClass({
  mixins:    [PureRenderMixin],
  propTypes: {
    data:     React.PropTypes.object,
    onSave:   React.PropTypes.func,
    onCancel: React.PropTypes.func
  },

  getDefaultProps() {
    return {
      data: Map()
    }
  },

  getInitialState() {
    //Initialize state from props.
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  },

  render() {
    let airports = this.props.airports;
    let defaultAirport = this.state.data.get('icao').get('value') ? (
      this.parseAirportToOption(airports.get(this.state.data.get('icao').get('value')).toJS())
    ) : (
      null
    );
    return (
      <form className="form-horizontal">
        <div className="row">
          <Autocomplete
            value={defaultAirport}
            name="icao"
            label="Airport"
            placeholder="Choose airport *"
            onChange={this.handleBlur}
            labelClassName="col-xs-3"
            wrapperClassName="col-xs-9"
            options={this.props.airportsOptions.toJS()}
            itemsNumber="7"
            bsStyle={this.getValidClass(this.state.data.get('icao').get('isValid'))}
            hasFeedback
          />
        </div>
        <div className="row row-form-pad">
          <Col xs={3}>
            <label className={"control-label " + this.getValidClass(this.state.data.get('email').get('isValid'))}>
              <span>Email</span>
            </label>
          </Col>
          <Col xs={9}>
            <Input
              name='email'
              type='text'
              value={this.state.data.get('email').get('value')}
              valueType='string'
              placeholder='Email'
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              bsStyle={this.getValidClass(this.state.data.get('email').get('isValid'))}
              hasFeedback
            />
          </Col>
        </div>
        <div className="row">
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{marginTop: 10}}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </div>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')}/>
        </ButtonToolbar>
      </form>
    )
  },

  handleChange(e) {
    let {name, value} = e.target;
    let nextState = this.state.data.toObject();
    console.log('handleChange for ' + name + ' with ' + value);
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    //We upadte the state before
    this.handleChange(e);
    let {name, value} = e.target;
    SupplierAirportFormAction.change(name, value)
  },

  handleCheck(e) {
    let {name, checked} = e.target;
    let value = checked ? 1 : 0;
    let nextState = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);

    this.setState({
      data: Map(nextState)
    });

    SupplierAirportFormAction.change(name, value)
  },

  getPlatts() {
    let plattId = this.state.data.get('plattId').get('value');
    return plattId ? plattId : null;
  },

  parseAirportToOption(airport) {
    return {
      value: airport.icao,
      label: airport.icao + " - " + airport.name,
      data:  airport
    }
  },

  handleCancelClick() {
    this.props.onCancel();
  },

  handleSaveClick() {
    let data = {};
    _.each(this.props.data.toJS(), function (prop, key) {
      data[key] = prop.value;
    }, this);

    this.props.onSave(data);
    console.log('save clicked: ' + JSON.stringify(data));
  },

  getValidClass(isValid) {
    if (isValid === null) {
      return null
    }
    return isValid ? 'success' : 'error';
  }
});
