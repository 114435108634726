import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import CalculatorApiUtils from '../utils/CalculatorApiUtils';

const CalculatorAction = {
  addAirport(airportIcao) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionCalculator.ADD_AIRPORT,
      icao:       airportIcao
    })
  },

  removeAirport(key) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionCalculator.REMOVE_AIRPORT,
      key:        key
    })
  },

  operationFlightChanges(operationFlight) {
    Dispatcher.handleViewAction({
      actionType:      Constants.ActionCalculator.OPERATION_FLIGHT_CHANGES,
      operationFlight: operationFlight
    })
  },

  getFuelIndices(data, isPrivate) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionCalculator.LOAD_FUEL_INDICES
    });

    CalculatorApiUtils.compute('/api/indices/' + data.join(',') + '?isPrivate=' + isPrivate.toString());
  }

};

export default CalculatorAction