import React from 'react';
import {Map} from 'immutable';
import {PageHelper, PageLifcycle, PageLoaded} from '../PageLifecycle'
import {NoItemHelper, SubTitle} from '../../components'
import PlattValueList from './PlattValueList';

export default React.createClass({
  propTypes: {
    plattValues: React.PropTypes.object
  },

  getDefaultProps() {
    return {
      plattValues: Map()
    }
  },

  render() {
    return (
      <div>
        <SubTitle>Details</SubTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isContractAirportsError && !this.props.isContractAirportsLoading && this.props.plattValues.size === 0}>
            <NoItemHelper>
              <p>You are currently in the <strong>import</strong> details. There is no registered data for this import.
              </p>
              <p>It has probably failed.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!(this.props.plattValues.size === 0)}>
            <PlattValueList
              user={this.props.user}
              onNew={this.props.onAdd}
              plattValues={this.props.plattValues}
              contract={this.props.contract}
              searchParams={this.props.searchParams}
              airports={this.props.airports}
              platts={this.props.platts}
              values={this.props.values}
              onDelete={this.props.onDelete}
              onEdit={this.props.onEdit}
              onImport={this.props.onImport}
              valueDeleteDialogId={this.props.valueDeleteDialogId}
              valueFormModalId={this.props.valueFormModalId}
            />
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  }
});
