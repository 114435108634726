import React from 'react';
import moment from 'moment';

const ISODate = ({ value, format }) => {
  return (
    <span>
      {moment(value).format(format || 'YYYY-MM-DD')}
    </span>
  );
};

export default ISODate;
