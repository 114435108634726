import Dispatcher from '../core/Dispatcher';
import {fromJS, Map} from 'immutable';
import assign from 'object-assign';

import {Constants} from '../constants';
import BaseStore from './BaseStore';

const UsersStore = assign({}, BaseStore, {
  initialize() {
    UsersStore.setState({
      users:         Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING,
      errCode:       null
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    const action = payload.action;

    switch (action.actionType) {
      case Constants.ActionUsers.LOAD_USER_ERROR:
      case Constants.ActionUsers.LOAD_USERS_ERROR:
        UsersStore.setState({
          loadingStatus: Constants.ERROR,
          errCode:       action.err && action.err.status ? action.err.status : '500'
        });
        UsersStore.emitChange();
        break;

      case Constants.ActionUsers.LOAD_USER_SUCCESS:
      case Constants.ActionUsers.LOAD_USERS_SUCCESS:
        UsersStore.setState({
          users:         fromJS(action.data.entities.users),
          loadingStatus: Constants.LOADED
        });
        UsersStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default UsersStore;
