import React from 'react';
import {fromJS, Map} from 'immutable';
import {search} from '../../components/TableSearch/TableSearch';
import {Action, ListActions, PermissionRequired, TableSearch} from '../../components';
import {ContractsAction, ModalsAction} from '../../actions';
import {Activities} from '../../constants';
import Griddle from "griddle-react";
import formatter from "../../utils/formatter";
import moment from "moment";

export default React.createClass({
  handleEditPlattValueClick(props) {
    ModalsAction.showValueForm(this.props.valueFormModalId, props.rowData)
  },
  handleDeletePlattValueClick(props) {
    ModalsAction.show(this.props.valueDeleteDialogId, {
      id:      props.rowData.id,
      message: 'Are you sure you want to delete the value of the ' + moment(props.rowData.effectiveDate).format('YYYY-MM-DD') + ' ?'
    });
  },
  getDetailsColumn() {
    return ["airportIcao", "platt", "deliveryBy", "payment", "price", "effectiveDate", "validUntil", "id"];
  },
  getDetailsColumnMetadata() {
    return [{
      columnName:      'airportIcao',
      displayName:     'Airport',
      customComponent: props => {
        return (<span>N/A</span>)
      },
      searchInput:     'text'
    }, {
      columnName:      'platt',
      displayName:     'Platt',
      customComponent: props => {
        let platt = props.rowData.Platts ? props.rowData.Platts[0] : {
          code: 'N/A',
          name: 'N/A'
        };
        return (<p>{[platt.code, platt.name].join(' - ')}</p>)
      },
      searchInput:     'text'
    }, {
      columnName:      'deliveryBy',
      displayName:     'Delivery By',
      customComponent: props => {
        return (<span>N/A</span>)
      },
    }, {
      columnName:      'payment',
      displayName:     'Payment',
      customComponent: props => {
        return (<span>N/A</span>)
      },
    }, {
      columnName:      'price',
      displayName:     'Price',
      customComponent: props => {
        return (
          <span>{props.rowData.value ? props.rowData.value + ' ' + props.rowData.currencyCode + '/' + props.rowData.volumeUnitCode : ''}</span>)
      }
    }, {
      columnName:      'effectiveDate',
      displayName:     'Effective Date',
      customComponent: props => {
        return (<span>{props.rowData.effectiveDate ? formatter.format.date(props.rowData.effectiveDate) : ''}</span>)
      }
    }, {
      columnName:      'validUntil',
      displayName:     'Valid Until',
      customComponent: props => {
        return (<span>{props.rowData.validUntil ? formatter.format.date(props.rowData.validUntil) : 'UFN'}</span>)
      }
    }, {
      columnName:      "id",
      displayName:     "",
      cssClassName:    "actionsColumn",
      customComponent: props => {
        return (<ListActions>
          <PermissionRequired requiredRoles={Activities.WRITE_CONTRACTS} user={this.props.user}>
            <Action icon="edit" onClick={this.handleEditPlattValueClick.bind(null, props)}/>
            <Action icon="remove" onClick={this.handleDeletePlattValueClick.bind(null, props)}/>
          </PermissionRequired>
        </ListActions>)
      }
    }];
  },

  propTypes: {
    platts:       React.PropTypes.object,
    airports:     React.PropTypes.object,
    plattValues:  React.PropTypes.object,
    values:       React.PropTypes.object,
    searchParams: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      platts:       Map(),
      airports:     Map(),
      plattValues:  Map(),
      values:       Map(),
      searchParams: Map()
    }
  },

  getInitialState() {
    return {
      plattValues: this.props.plattValues
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredPlattValues = search(this.props.plattValues.toJS(), nextProps.searchParams.toJS())
      this.setState({plattValues: fromJS(filteredPlattValues)})
    } else {
      this.setState({plattValues: nextProps.plattValues})
    }

    this.forceUpdate();
  },

  render() {
    let {contract, platts, airports, values, plattValues, user, onDelete, onEdit} = this.props;
    return (
      <div>
        <TableSearch
          user={this.props.user}
          requiredRoles={Activities.WRITE_CONTRACTS}
          columnMetadata={this.getDetailsColumnMetadata()}
          onSearch={this.handleSearch}
          isNewButton={true}
          newButtonText="Add airport"
          onNew={this.props.onNew}/>

        <Griddle
          results={Object.values(this.state.plattValues.toJS())}
          tableClassName="table"
          showSettings={true}
          resultsPerPage={20}
          columnMetadata={this.getDetailsColumnMetadata()}
          columns={this.getDetailsColumn()}/>
      </div>
    )
  },

  handleSearch(e) {
    let {name, value} = e.target;
    ContractsAction.search(name, value);
  }
});
