import React from 'react';
import  { Map } from 'immutable';
import { FormControls } from 'react-bootstrap';
import { SubTitle } from '../../components';

export default React.createClass({
  propTypes: {
    airport: React.PropTypes.object
  },

  getDefaultProps: function() {
    return {
      airport: Map()
    }
  },

  render() {
    return (
      <div>
        <SubTitle>Details</SubTitle>
        <form className="form-horizontal details-wrapper">
          <FormControls.Static label="ICAO" labelClassName="col-xs-3" wrapperClassName="col-xs-9" value={this.props.airport.get('icao')} />
          <FormControls.Static label="IATA" labelClassName="col-xs-3" wrapperClassName="col-xs-9" value={this.props.airport.get('iata')} />
          <FormControls.Static label="Name" labelClassName="col-xs-3" wrapperClassName="col-xs-9" value={this.props.airport.get('name')} />
          <FormControls.Static label="City" labelClassName="col-xs-3" wrapperClassName="col-xs-9" value={this.props.airport.get('city')} />
          <FormControls.Static label="Country" labelClassName="col-xs-3" wrapperClassName="col-xs-9" value={this.props.airport.get('country')} />
        </form>
      </div>
    )
  }

});