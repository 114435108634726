import React from 'react';
import { Errors } from '../../constants';
import { Row, Col } from 'react-bootstrap';

export default React.createClass({
  getHeaderStyle(){
    return {
      fontWeight : "bold",
      fontSize   : "65px",
      color      : "#EA5F0A"
    }
  },

  getWrapperStyle(){
    return {
      textAlign     : "center",
      margin        : "auto",
      textTransform : "uppercase",
      width         : "440px",
      padding       : "20px",
      borderBottom  : "5px solid #EA5F0A"
    }
  },

  getMessageStyle(){
    return {
      display    : "block",
      color      : "#9E9074",
      fontSize   : "30px",
      fontWeight : "bold",
      margin     : "auto",
      marginTop  : "50px",
      lineHeight :"28px"
    }
  },

  getFooterStyle(){
    return {
      color      : "#9E9074",
      marginTop  : "-4px",
      fontWeight : "bold"
    }
  },

  getCodeStyle(){
    return {
      fontSize: "86px",
      fontWeight: 800,
      lineHeight: 0,
      marginTop: "17px",
      color: "#EA5F0A",
    }
  },

  render() {
    let errorCode = this.props.errorCode ? this.props.errorCode : '500';
    let errorMsg = {
      '500': this.props.e500 ? this.props.e500 : Errors.SERVER_ERROR,
      '403': this.props.e403 ? this.props.e403 : Errors.UNAUTHORIZE_ACCESS,
      '401': this.props.e401 ? this.props.e401 : Errors.BAD_CREDENTIALS,
      '408': this.props.e408 ? this.props.e408 : Errors.REQUEST_TIMEOUT,
      '404': this.props.e404 ? this.props.e404 : Errors.RESSOURCE_NOT_FOUND
    }[errorCode];

   return(
    <div style={this.getWrapperStyle()} className="whoops shadow-z-1">
      <p style={this.getHeaderStyle()}>
        Wooooops <span className="error-emoticon">:(</span>
      </p>
      <p style={this.getCodeStyle()}>
        Error {this.props.errorCode}
      </p>
      <span style={this.getMessageStyle()}>
        {errorMsg}
      </span>
      <p style={this.getFooterStyle()}>
        
      </p>
    </div>)
  }
});