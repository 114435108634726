import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import SuppliersStore from './SuppliersStore';
import assign from 'object-assign';
import {fromJS, Map} from 'immutable';

const SupplierAirportsStore = assign({}, BaseStore, {
  initialize() {
    SupplierAirportsStore.setState({
      supplierAirports:   Map(),
      search:        Map(),
      loadingStatus: Constants.LOADING
    });
  },

  dispatchToken: Dispatcher.register(function (payload) {
    var action = payload.action;

    switch (action.actionType) {
      case Constants.ActionSuppliers.LOAD_SUPPLIER_SUCCESS:
      case Constants.ActionSuppliers.LOAD_SUPPLIERS_SUCCESS:
        SupplierAirportsStore.setState({
          supplierAirports:   fromJS(action.data.entities.supplierAirports ? action.data.entities.supplierAirports : {}),
          loadingStatus: Constants.LOADED
        });

        SupplierAirportsStore.emitChange();
        break;

      case Constants.ActionSuppliers.CREATE_SUPPLIER_AIRPORT_SUCCESS:
        var id = action.data.result.supplierAirports;
        var nextData = SupplierAirportsStore.get('supplierAirports').set(String(id), fromJS(action.data.entities.supplierAirports[id]));
        SupplierAirportsStore.setState({supplierAirports: nextData});
        SupplierAirportsStore.emitChange();
        break;

      case Constants.ActionSuppliers.UPDATE_SUPPLIER_AIRPORT_SUCCESS:
        var id = action.data.result.supplierAirports;
        var nextData = SupplierAirportsStore.get('supplierAirports');
        SupplierAirportsStore.setState({
          supplierAirports: nextData.update(String(id), v => v = fromJS(action.data.entities.supplierAirports[id]))
        })

        SupplierAirportsStore.emitChange();
        break;

      case Constants.ActionSuppliers.DELETE_SUPPLIER_AIRPORT_SUCCESS:
        Dispatcher.waitFor([SuppliersStore.dispatchToken]);
        var id = action.data.result.supplierAirports;

        //Hack, for some reason, sometimes it's string, sometimes integer :/
        var nextData = SupplierAirportsStore.get('supplierAirports').delete(String(id));
        var nextData = nextData.delete(String(id));

        SupplierAirportsStore.setState({supplierAirports: nextData});
        SupplierAirportsStore.emitChange();
        break
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default SupplierAirportsStore;
