import React from 'react';
import {Modal} from 'react-bootstrap';

import {SubTitle} from '../../components';
import {ModalsStore} from '../../stores';
import {ModalsAction} from '../../actions';

ModalsStore.initialize();

export default class DefaultModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;

    ModalsAction.add(this.props.id);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    ModalsStore.addChangeListener(this.handleChange);
  }

  componentWillUnmount() {
    ModalsStore.removeChangeListener(this.handleChange);
  }

  render() {
    return (
      <Modal
        backdrop='static'
        bsSize={this.props.bsSize}
        show={this.state.isVisible}
        payload={this.state.payload}
        onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <SubTitle>{this.props.title}</SubTitle>
        </Modal.Header>
        <Modal.Body>
          {this.props.children}
        </Modal.Body>
      </Modal>)
  }

  handleChange() {
    this.setState({
      isVisible: ModalsStore.isModalVisible(this.props.id),
      payload:   ModalsStore.getPayload(this.props.id)
    })
  }
}
DefaultModal.propTypes = {
  id:        React.PropTypes.number,
  isVisible: React.PropTypes.bool,
  payload:   React.PropTypes.object,
  icon:      React.PropTypes.string,
}
DefaultModal.defaultProps = {
  isVisible: false,
  icon:      null,
  payload:   {}
}
