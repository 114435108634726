import React from 'react';
import _ from 'lodash';
import  { Map } from 'immutable';
import { Button, FormControls, Row, Col, Input, DropdownButton, MenuItem, ButtonToolbar, ButtonInput } from 'react-bootstrap';

import { DatePicker, Save, InlineAlert } from '../../components';
import { ActionTax } from '../../constants/Constants';
import { TaxFormAction } from '../../actions';
import { TaxFormStore } from '../../stores';

export default class TaxForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;

    this.handleSaveClick   = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChange      = this.handleChange.bind(this);
    this.handleBlur        = this.handleBlur.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  render() {
    let data = this.state.data;
    return (
      <form className="form-horizontal">
        <Input
          name             ='name'
          value            ={data.get('name').get('value')}
          type             ='text'
          label            ="Name"
          placeholder      ='Tax Name'
          onChange         ={this.handleChange}
          onBlur           ={this.handleBlur}
          labelClassName   ='col-xs-3'
          wrapperClassName ='col-xs-9'
          bsStyle          ={this.getValidClass(data.get('name').get('isValid'))}
          hasFeedback/>

        {this.getTaxTypeDropdown()}
        {this.getCalculationTypesDropdown()}
        {this.getValueInputs()}

        <DatePicker
          name             ='validFrom'
          value            ={data.get('validFrom').get('value')}
          type             ='text'
          label            ="Valid From"
          placeholder      ='YYYY-MM-DD'
          onChange         ={this.handleBlur}
          onBlur           ={this.handleBlur}
          labelClassName   ={3}
          wrapperClassName ={9}
          bsStyle          ={this.getValidClass(data.get('validFrom').get('isValid'))}
          hasFeedback
        />
        <DatePicker
          name             ='validUntil'
          value            ={data.get('validUntil').get('value')}
          type             ='text'
          label            ="Valid Until"
          placeholder      ='YYYY-MM-DD'
          onChange         ={this.handleChange}
          onBlur           ={this.handleBlur}
          labelClassName   ={3}
          wrapperClassName ={9}
          bsStyle          ={this.getValidClass(data.get('validUntil').get('isValid'))}
          hasFeedback
        />

        <Row>
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{ marginTop: 10 }}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </Row>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')} />
        </ButtonToolbar>
      </form>
    )
  }

  handleChange(e) {
    let { name, value } = e.target;
    var nextState  = this.state.data.toObject();
    nextState[name] = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  }

  handleBlur(e) {
    let { name, value } = e.target;
    TaxFormAction.change(name, value)
  }

  getValueInputs() {
    return {
      "volume-based"     : this.getUnitRow(),
      "flat"             : this.getFlatRow(),
      "percentage-based" : this.getPercentageRow(),
    }[this.props.data.get('calculation').get('value')]
  }

  getCurrencyDropdown() {
    return (
      <Input
        name      ='currencyCode'
        value     ={this.state.data.get('currencyCode').get('value')}
        type      ='select'
        className ='col-xs-3'
        onChange  ={this.handleChange}
        onBlur    ={this.handleBlur}>

        {this.props.currencies.map(function(currency){
          return <option key={currency.get('code')} value={currency.get('code')}>{currency.get('code')}</option>
        }, this)}
      </Input>)
  }

  getVolumeUnitDropdown() {
    return (
      <Input
        name     ='volumeUnitCode'
        value    ={this.state.data.get('volumeUnitCode').get('value')}
        type     ='select'
        onChange ={this.handleChange}
        onBlur   ={this.handleBlur}>

        {this.props.volumeUnits.map(function(unit){
          return <option key={unit.get('code')} value={unit.get('code')}>{unit.get('name')}</option>
        }, this)}
      </Input>)
  }

  getTaxTypeDropdown() {
    return (
      <Input
        name             ='type'
        value            ={this.state.data.get('type').get('value')}
        type             ='select'
        label            ="Type"
        labelClassName   ='col-xs-3'
        wrapperClassName ='col-xs-9'
        onChange         ={this.handleChange}
        onBlur           ={this.handleBlur}>

        {this.props.taxTypes.map(function(type){
          return <option key={type.get('id')} value={type.get('id')}>{type.get('name')}</option>
        }, this)}
      </Input>)
  }

  getCalculationTypesDropdown() {
    return (
      <Input
        name             ='calculation'
        value            ={this.state.data.get('calculation').get('value')}
        type             ='select'
        label            ="Calculation"
        labelClassName   ='col-xs-3'
        wrapperClassName ='col-xs-9'
        onChange         ={this.handleBlur}>

        {this.props.calculationTypes.map(function(calculation){
          return <option key={calculation.get('id')} value={calculation.get('id')}>{calculation.get('name')}</option>
        }, this)}
      </Input>)
  }

  getFlatRow() {
     return (
      <Row className="row-form-pad">
        <Col xs={3}>
          <label className="control-label"><span>Value</span></label>
        </Col>
        <Col xs={3}>
          <Input
            name        ='value'
            type        ='text'
            value       ={this.state.data.get('value').get('value')}
            placeholder ='Value'
            onChange    ={this.handleChange}
            onBlur      ={this.handleBlur}
            bsStyle     ={this.getValidClass(this.state.data.get('value').get('isValid'))}
            hasFeedback/>
        </Col>
        <Col xs={3}>
          {this.getCurrencyDropdown()}
        </Col>
      </Row>
    )
  }

  getUnitRow() {
     return (
      <Row className="row-form-pad">
        <Col xs={3}>
          <label className="control-label"><span>Value</span></label>
        </Col>
        <Col xs={3}>
          <Input
            name        ='value'
            type        ='text'
            value       ={this.state.data.get('value').get('value')}
            valueType   ="number"
            placeholder ='Value'
            onChange    ={this.handleChange}
            onBlur      ={this.handleBlur}
            bsStyle     ={this.getValidClass(this.state.data.get('value').get('isValid'))}
            hasFeedback />
        </Col>
        <Col xs={2}>
          {this.getCurrencyDropdown()}
        </Col>
        <Col xs={1} style={{padding: '0', 'textAlign': 'center'}}>
          <span style={{padding: '7px 0 0 0', display: 'inline-block'}}>per</span>
        </Col>
        <Col xs={3}>
          {this.getVolumeUnitDropdown()}
        </Col>
      </Row>
    )
  }

  getPercentageRow() {
    return (
      <Row className="row-form-pad">
        <Col xs={3}>
          <label className="control-label"><span>Value</span></label>
        </Col>
        <Col xs={3}>
          <Input
            name        ='value'
            type        ='text'
            value       ={this.state.data.get('value').get('value')}
            placeholder ='Value'
            onChange    ={this.handleChange}
            onBlur      ={this.handleBlur}
            bsStyle     ={this.getValidClass(this.state.data.get('value').get('isValid'))}
            hasFeedback />
        </Col>
        <Col xs={5} style={{padding: '0'}}>
          <span style={{padding: '7px 0 0 0', display: 'inline-block'}}>%</span>
        </Col>
      </Row>
    )
  }

  handleCancelClick() {
    this.props.onCancel();
  }

  handleSaveClick() {
    var _self = this;
    var data  = {};
    _.each(_self.props.data.toJS(), function(prop, key){
      data[key] = prop.value;
    });

    this.props.onSave(data);
  }

  getValidClass(isValid){
    if(isValid === null) return null
    return isValid ? 'success' : 'error';
  }
}
