import React from 'react';
import _ from 'lodash';
import Griddle from 'griddle-react';
import {fromJS, Map} from 'immutable';
import {PermissionRequired, TableSearch} from '../../components';
import {search} from '../../components/TableSearch/TableSearch';
import {FuelRequestsAction} from '../../actions';
import {Activities} from '../../constants';

export default React.createClass({
  propTypes: {
    fuelRequests:  React.PropTypes.object,
    loadingStatus: React.PropTypes.object
  },

  getDefaultProps: function () {
    return {
      fuelRequests:  Map(),
      loadingStatus: Map({
        status:  'loading',
        message: 'Fetching data from server. Please wait.',
      })
    }
  },

  getInitialState() {
    return {
      fuelRequests: this.props.fuelRequests
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps.fuelRequests, this.props.fuelRequests)
      || this.props.loadingStatus.get('status') !== nextProps.loadingStatus.get('status');
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredFuelRequests = search(this.props.fuelRequests.toJS(), nextProps.searchParams.toJS());
      this.setState({fuelRequests: fromJS(filteredFuelRequests)});
    } else {
      this.setState({fuelRequests: nextProps.fuelRequests})
    }

    this.forceUpdate();
  },

  render() {
    return (
      <PermissionRequired requiredRoles={Activities.READ_FUEL_REQUESTS} user={this.props.user}>
        <TableSearch
          columnMetadata={this.props.columnMetadata}
          onSearch={this.handleSearch}
        />
        <Griddle
          initialSort="createdAt"
          initialSortAscending={false}
          results={this.getRequests()}
          tableClassName="table middle-td"
          resultsPerPage={20}
          filterPlaceholderText="Search"
          columnMetadata={this.props.columnMetadata}
          columns={this.props.columnNames}
        />
      </PermissionRequired>
    )
  },

  handleSearch(e) {
    let {name, value} = e.target;
    FuelRequestsAction.search(name, value);
  },

  getRequests() {
    return _.map(this.state.fuelRequests.toJS(), (a) => {
      return a;
    });
  }
});
