import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {DefaultModal, Loader, Oops, PageTitle, YesNoDialog} from '../../components';
import {FbosAction, ModalsAction} from '../../actions';
import {PageError, PageLifcycle, PageLoaded, PageLoading} from '../PageLifecycle';
import FboAirports from './FboAirports';
import FboAirportForm from './FboAirportForm';

export default React.createClass({
  propTypes: {
    fbo: React.PropTypes.object
  },

  getInitialState() {
    return {
      isFboAirportsLoading: true,
      isFboAirportsError:   false
    }
  },

  componentWillReceiveProps(nextProps) {
    //If some of theses stores loading, then we wait until everything is finish.
    let isFboAirportsLoading = (
      nextProps.isAirportsLoading ||
      nextProps.isFboAirportsLoading ||
      nextProps.isFboLoading);

    let isFboAirportsError = (
      nextProps.isAirportsError ||
      nextProps.isFboAirportsError ||
      nextProps.isFboError);

    this.setState({
      isFboAirportsLoading: isFboAirportsLoading,
      isFboAirportsError:   isFboAirportsError
    })
  },

  render() {
    return (
      <div className="data-table">
        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.state.isFboAirportsLoading && !this.state.isFboAirportsError}>
            <Loader>
              Fetching airport from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.state.isFboAirportsError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.state.isFboAirportsError && !this.state.isFboAirportsLoading}>
            <PageTitle icon="file-text">FBO Airport</PageTitle>
            <Row>
              <Col xs={12}>
                <FboAirports
                  user={this.props.user}
                  errCode={this.props.errCode}
                  fbo={this.props.fbo}
                  searchParams={this.props.searchParams}
                  fboAirports={this.props.fboAirports}
                  airportsOptions={this.props.airportsOptions}
                  isFboAirportsLoading={this.state.isFboAirportsLoading}
                  isFboAirportsError={this.state.isFboAirportsError}
                  airports={this.props.airports}
                  fboAirportFormModalId={this.props.fboAirportFormModalId}
                  fboAirportDeleteDialogId={this.props.fboAirportDeleteDialogId}
                  fboAirportImportDialogId={this.props.fboAirportImportDialogId}
                  onAdd={this.handleAddClick}
                  onDelete={this.handleDeleteClick}
                  onEdit={this.handleEditClick}
                />
              </Col>
            </Row>
          </PageLoaded>
        </PageLifcycle>
        <DefaultModal
          id={this.props.fboAirportFormModalId}
          bsSize="medium"
          onHide={this.closeModal}
          title='Add airport to the fbo'>
          <FboAirportForm
            onSave={this.handleSave}
            onCancel={this.closeModal}
            data={this.props.fboAirportForm}
            airports={this.props.airports}
            airportsOptions={this.props.airportsOptions}
          />
        </DefaultModal>
        <YesNoDialog
          id={this.props.fboAirportDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick}/>
      </div>
    )
  },

  closeModal() {
    ModalsAction.hideFboAirportForm(this.props.fboAirportFormModalId);
  },

  handleSave(fboAirport) {
    if (fboAirport.id) {
      FbosAction.updateFboAirport(this.props.fbo.get('id'), fboAirport);
    } else {
      FbosAction.addFboAirport(this.props.fbo.get('id'), fboAirport);
    }
  },

  handleConfirmDeleteClick(payload) {
    FbosAction.deleteFboAirport(this.props.fbo.get('id'), payload.get('fboAirport').toJS())
  },

  handleCancelDeleteClick() {
    ModalsAction.hide(this.props.fboAirportDeleteDialogId);
  },

  handleAddClick() {
    ModalsAction.showFboAirportForm(this.props.fboAirportFormModalId, {});
  },

  handleEditClick(fboAirport) {
    console.log('handleEditClick for fbo');
    ModalsAction.showFboAirportForm(this.props.fboAirportFormModalId, fboAirport)
  },

  handleDeleteClick(fboAirport) {
    ModalsAction.show(this.props.fboAirportDeleteDialogId, {
      fboAirport: fboAirport,
      message:    "Are you sure you want to delete this airport (" + fboAirport.airportIcao + ") from the fbo ?"
    })
  }
});
