import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import ValuesApiUtils from '../utils/ValuesApiUtils'

const ValueAction = {
  fetchAll() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionValues.LOAD_VALUES
    });

    ValuesApiUtils.getAll('/api/values');
  },

  getValueById(id) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionValues.LOAD_VALUE
    });

    ValuesApiUtils.getOne('/api/values/' + id);
  },

  create: function(plattId, value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionValues.CREATE_VALUE,
      value: value
    });

    ValuesApiUtils.create('/api/platts/' + plattId + '/values', value);
  },

  update: function(value) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionValues.UPDATE_VALUE,
      value: value
    });

    ValuesApiUtils.update('/api/values/' + value.id, value);
  },

  delete: function(valueId) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionValues.DELETE_VALUE,
      valueId: valueId
    });

    ValuesApiUtils.delete('/api/values/' + valueId);
  },
};

export default ValueAction