import React from 'react';
import _ from 'lodash';
import { Map, List } from 'immutable';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import { Modal, Button, ButtonToolbar, TabbedArea, TabPane } from 'react-bootstrap';

import { SubTitle } from '../../components';
import { ModalsStore } from '../../stores';
import { ModalsAction } from '../../actions';

ModalsStore.initialize();

export default class YesNoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id        : this.props.id,
      isVisible : this.props.isVisible,
      payload   : Map()
    };

    ModalsAction.add(this.props.id)

    this.handleChange   = this.handleChange.bind(this)
    this.handleYesClick = this.handleYesClick.bind(this)
    this.handleNoClick  = this.handleNoClick.bind(this)
    this.getMessage     = this.getMessage.bind(this)
    this.keyPress       = this.keyPress.bind(this);
  }

  componentDidMount() {
    ModalsStore.addChangeListener(this.handleChange);
    document.addEventListener('keyup', this.keyPress, false);
  }

  componentWillUnmount() {
    ModalsStore.removeChangeListener(this.handleChange);
    document.removeEventListener('keyup', this.keyPress);
  }

  keyPress(e){
    if(this.state.isVisible) {
      if(e.keyCode === 13)
        this.handleYesClick()
    }
  }

  render(){
    return (<Modal
                show    ={this.state.isVisible}
                payload ={this.state.payload}
                onHide  ={this.props.onNo}
                icon    ={this.props.icon}>
      <Modal.Header closeButton>
        <SubTitle>{this.props.title}</SubTitle>
      </Modal.Header>
      <Modal.Body>
        {this.getMessage()}
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button bsStyle='danger' onClick={this.handleNoClick}>No</Button>
          <Button bsStyle='success' onClick={this.handleYesClick}>Yes</Button>
        </ButtonToolbar>
      </Modal.Body>
    </Modal>)
  }

  getMessage(){
    var _msg = "Are you sure you want to delete ";

    if(this.props.children) {
      return this.props.children;
    } else if (!_.isUndefined(this.state.payload) && !_.isUndefined(this.state.payload.get('message'))) {
      return this.state.payload.get('message');
    } else if(!_.isUndefined(this.state.payload) && !_.isUndefined(this.state.payload.get('name'))) {
      return  _msg + this.state.payload.get('name');
    } else {
      return 'Are you sure you want to continue ?'
    }
  }

  handleYesClick(){
    this.props.onYes(this.state.payload);
  }

  handleNoClick(){
    this.props.onNo(this.state.payload);
  }

  handleChange(){
    this.setState({
      isVisible: ModalsStore.isModalVisible(this.props.id),
      payload  : ModalsStore.getPayload(this.props.id)
    })
  }
}
YesNoDialog.propTypes    = {
  id         : React.PropTypes.number,
  isVisible  : React.PropTypes.bool,
  payload    : React.PropTypes.object,
  icon       : React.PropTypes.string,
}
YesNoDialog.defaultProps    = {
  isVisible  : false,
  icon       : null,
  payload    : {}
}