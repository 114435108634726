import React from 'react';
import { Input } from 'react-bootstrap';
import _ from 'lodash'
import { DefaultDropdown } from '../../components';

export default React.createClass({
  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps.data.toJS(), this.props.data.toJS())
      || nextProps.defaultValue !== this.props.defaultValue
  },

  render(){
    return (
      <DefaultDropdown
        defaultValue     ={this.props.defaultValue}
        label            ="Type"
        labelClassName   ={this.props.labelClassName}
        wrapperClassName ={this.props.wrapperClassName}
        name             = 'type'
        onChange         ={this.props.onChange}
        keyName          ='id'
        labelName        ='name'
        data             ={this.props.data} />)
  }
});
