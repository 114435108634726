import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import AuthApiUtils from '../utils/AuthApiUtils'


const LoginAction = {
  login() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionLogin.LOGGING_USER,
    });
    AuthApiUtils.login('/.auth/me');
  },

  timeout() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionLogin.SESSION_TIMEOUT
    });
  },

  logout() {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionLogin.USER_LOGOUT
    });
  },

  register(jwt) {
    Dispatcher.handleViewAction({
      actionType: Constants.ActionLogin.REGISTER_USER,
      jwt: jwt
    });
  }
};

export default LoginAction

