import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import { normalize, arrayOf, Schema } from 'normalizr';

//Token
let jwt = localStorage.getItem('jwt');

//NESTING RULES
var formalizeData = (data) => {
  const tax     = new Schema('taxes');
  const airport = new Schema('airports', { idAttribute: 'icao' });

  tax.define({
    Airports: arrayOf(airport)
  })

  return normalize(data, {
    taxes: tax
  });
}

const TaxesApiUtils = {
  get: function(url, params) {
    Promise.resolve(
      $.ajax({
        url: url,
        type: 'GET',
        data: params,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.LOAD_TAXES_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.LOAD_TAXES_ERROR,
          err: err
        });
      })
  },

  create: function(url, tax) {
    Promise.resolve($.ajax({
        url: url,
        type: 'POST',
        data: tax,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.CREATE_TAX_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.CREATE_TAX_ERROR,
          err: err
        });
      })
  },

  delete: function(url) {
    Promise.resolve($.ajax({
        url: url,
        type: 'DELETE',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.DELETE_TAX_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.DELETE_TAX_ERROR,
          err: err
        });
      })
  },

  update: function(url, tax) {
    Promise.resolve($.ajax({
        url: url,
        type: 'PUT',
        data: tax,
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        data = formalizeData(data);
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.UPDATE_TAX_SUCCESS,
          data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionTaxes.UPDATE_TAX_ERROR,
          err: err
        });
      })
  }
}

export default TaxesApiUtils
