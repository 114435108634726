import React from 'react';
import _ from 'lodash';
import  { Map } from 'immutable';
import { Button, Input, ButtonToolbar, Col, Row } from 'react-bootstrap';
import { PlattFormAction } from '../../actions';
import { Save, InlineAlert, ReviewPeriodDropdown, CalculationSchemeDropdown } from '../../components';

export default React.createClass({
  propTypes: {
    data     : React.PropTypes.object,
    onSave   : React.PropTypes.func,
    onCancel : React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      data : Map()
    }
  },

  getInitialState(){
    //Initialize state from props.
    return {
      data: this.props.data
    }
  },

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  },

  render() {
    let data = this.state.data;
    return (
      <form className="form-horizontal">
        <Row>
          <Input
            name             ='code'
            value            ={data.get('code').get('value')}
            type             ='text'
            label            ="Code"
            placeholder      ='Code *'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'
            bsStyle          ={this.getValidClass(data.get('code').get('isValid'))}
            hasFeedback/>
        </Row>
        <Row>
          <Input
            name             ='name'
            value            ={data.get('name').get('value')}
            type             ='text'
            label            ="Name"
            placeholder      ='Name *'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'
            bsStyle          ={this.getValidClass(data.get('name').get('isValid'))}
            hasFeedback/>
        </Row>
        <Row>
          <Input
            name             ='baseOrigin'
            value            ={data.get('baseOrigin').get('value')}
            type             ='text'
            label            ='Base Origin'
            placeholder      ='Base Origin'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'
            hasFeedback/>
        </Row>
        <Row>
          <Input
            name             ='baseReference'
            value            ={data.get('baseReference').get('value')}
            type             ='text'
            label            ='Base Reference'
            placeholder      ='Base Reference'
            onChange         ={this.handleChange}
            onBlur           ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'
            hasFeedback/>
        </Row>
        <Row>
          <ReviewPeriodDropdown
            defaultValue     ={data.get('reviewPeriod').get('value')}
            data             ={this.props.dropdownsData.get('reviewPeriod')}
            onChange         ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'/>
        </Row>
        <Row>
          <CalculationSchemeDropdown
            defaultValue     ={data.get('calculationScheme').get('value')}
            data             ={this.props.dropdownsData.get('calculationScheme')}
            onChange         ={this.handleBlur}
            labelClassName   ='col-xs-3'
            wrapperClassName ='col-xs-9'/>
        </Row>
        <Row>
          <Col xs={3} className="text-right"></Col>
          <Col xs={9} className="text-center" style={{ marginTop: 10 }}>
            <InlineAlert bsStyle="warning">{this.props.data.get('error')}</InlineAlert>
          </Col>
        </Row>
        <ButtonToolbar style={{'textAlign': 'right'}}>
          <Button
            bsStyle='danger'
            onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Save onSave={this.handleSaveClick} disabled={!this.props.data.get('isSavable')} />
        </ButtonToolbar>
      </form>
    )
  },

  handleChange(e) {
    let { name, value } = e.target;
    var nextState       = this.state.data.toObject();
    nextState[name]     = this.state.data.get(name).update('value', v => v = value);
    this.setState({
      data: Map(nextState)
    })
  },

  handleBlur(e) {
    let { name, value } = e.target;
    PlattFormAction.change(name, value)
  },

  handleCancelClick() {
    this.props.onCancel();
  },

  handleSaveClick() {
    var _self = this;
    var data = {};
    _.each(_self.props.data.toJS(), function(prop, key){
      data[key] = prop.value;
    });

    this.props.onSave(data);
  },

  getValidClass(isValid){
    if(isValid === null) return null
    return isValid ? 'success' : 'error';
  }
});
