import React from 'react';

import {Action, ListActions, PermissionRequired} from '../../components';
import {ModalsAction} from '../../actions';
import {Activities} from '../../constants';

export default React.createClass({
  render() {
    return (
      <ListActions>
        <PermissionRequired requiredRoles={Activities.WRITE_SUPPLIERS} user={this.props.metadata.user}>
          <Action icon="search" to={'/suppliers/' + this.props.rowData.id}/>
          <Action icon="edit" onClick={this.handleEditSupplierClick}/>
          <Action icon="remove" onClick={this.handleDeleteSupplierClick}/>
        </PermissionRequired>
      </ListActions>
    )
  },

  handleEditSupplierClick() {
    ModalsAction.showSupplierForm(this.props.metadata.supplierFormModalId, this.props.rowData);
  },

  handleDeleteSupplierClick() {
    ModalsAction.show(this.props.metadata.supplierDeleteDialogId, {
      id:   this.props.rowData.id,
      name: this.props.rowData.name
    });
  }
});
