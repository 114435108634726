import React from 'react';

import {DefaultModal, Loader, NoItemHelper, Oops, PageTitle, YesNoDialog} from '../../components';
import {ModalsAction, SupplierAction} from '../../actions';

import {PageError, PageHelper, PageLifcycle, PageLoaded, PageLoading} from '../PageLifecycle';
import SuppliersList from './SuppliersList';
import SuppliersListActions from './SuppliersListActions';
import SupplierForm from '../supplier/SupplierForm';
import LogoList from './LogoList'

var _render = 0;

export default React.createClass({
  propTypes: {
    supplierDataForm:    React.PropTypes.object,
    supplierFormModalId: React.PropTypes.number
  },

  render() {
    _render++;

    return (
      <div className="data-table">
        <PageTitle icon="industry">List of suppliers</PageTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when page loading */}
          <PageLoading isDisplayed={this.props.isSuppliersLoading}>
            <Loader>
              Fetching suppliers from server, please wait.
            </Loader>
          </PageLoading>

          {/* Displaying when error */}
          <PageError isDisplayed={this.props.isSuppliersError}>
            <Oops errorCode={this.props.errCode}/>
          </PageError>

          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isSuppliersError && !this.props.isSuppliersLoading && this.props.suppliers.size === 0}>
            <NoItemHelper onNew={this.handleNewClick} buttonLabel="New supplier">
              <p>You are currently in the suppliers list. There is no supplier registered yet.</p>
              <p>To get you started, you can create a new supplier. You can add as many suppliers as you like.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!this.props.isSuppliersError && !this.props.isSuppliersLoading && !(this.props.suppliers.size === 0)}>
            <SuppliersList
              user={this.props.user}
              suppliers={this.props.suppliers}
              columnMetadata={this.getColumnMetadata()}
              columnNames={this.getColumnNames()}
              loadingStatus={this.props.loadingSuppliersStatus}
              isNewButton={true}
              onNew={this.handleNewClick}
            />
          </PageLoaded>
        </PageLifcycle>

        <DefaultModal id={this.props.supplierFormModalId} onHide={this.closeModal} title={this.getModalTitle()}>
          <SupplierForm
            onSave={this.handleSaveSupplier}
            onCancel={this.closeModal}
            data={this.props.supplierDataForm}/>
        </DefaultModal>

        <YesNoDialog
          id={this.props.supplierDeleteDialogId}
          title="Please confirm"
          onYes={this.handleConfirmDeleteClick}
          onNo={this.handleCancelDeleteClick}/>
      </div>
    )
  },

  getColumnMetadata() {
    return [{
      columnName:      "logo",
      displayName:     "Logo",
      cssClassName:    "col-logo",
      customComponent: LogoList,
      searchInput:     false
    }, {
      columnName:  "name",
      displayName: "Name",
      searchInput: false
    }, {
      columnName:  "email",
      displayName: "Email",
      searchInput: false
    }, {
      columnName:  'nbrAirports',
      displayName: 'Nbr. Airports',
      searchInput: false
    }, {
      columnName:             "id",
      displayName:            "",
      cssClassName:           "actionsColumn",
      supplierDeleteDialogId: this.props.supplierDeleteDialogId,
      supplierFormModalId:    this.props.supplierFormModalId,
      customComponent:        SuppliersListActions,
      searchInput:            "actions",
      user:                   this.props.user
    }];
  },

  getColumnNames() {
    return ['logo', 'name', 'email', 'nbrAirports', 'id'];
  },

  getModalTitle() {
    return this.props.supplierDataForm.get('id') && this.props.supplierDataForm.get('id').get('value') ? "Edit Supplier" : "New Supplier";
  },

  closeModal() {
    ModalsAction.hideSupplierForm(this.props.supplierFormModalId);
  },

  handleSaveSupplier(supplier) {
    if (supplier.id) {
      SupplierAction.update(supplier);
    } else {
      SupplierAction.create(supplier);
    }
  },

  handleConfirmDeleteClick(supplier) {
    SupplierAction.delete(supplier.get('id'))
  },

  handleCancelDeleteClick() {
    ModalsAction.hide(this.props.supplierDeleteDialogId);
  },

  handleNewClick() {
    ModalsAction.showSupplierForm(this.props.supplierFormModalId);
  }
});
