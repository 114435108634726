import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import moment from 'moment';
import { Map, Iterable } from 'immutable';
import _ from 'lodash';
import validator from '../utils/validator';
import formatter from '../utils/formatter';

const TaxFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    TaxFormStore.setState({
      id             : Map({value: null, isValid: null}),
      name           : Map({value: null, isValid: null}),
      type           : Map({value: 'airport-fee', isValid: null}),
      calculation    : Map({value: 'volume-based', isValid: null}),
      value          : Map({value: null, isValid: null}),
      currencyCode   : Map({value: 'EUR', isValid: null}),
      volumeUnitCode : Map({value: 'L', isValid: null}),
      validFrom      : Map({value: null, isValid: null}),
      validUntil     : Map({value: null, isValid: null}),
      isSavable      : true,
      error          : ''
    });
  },

  isValid(data){
    var isValid = false;
    switch(data.field) {
      case 'name':
        isValid = validator.required(data.value);
        break;
      case 'value':
        isValid = validator.isNumericValid(data.value) && validator.required(data.value);
        break;
      case 'validFrom':
      case 'validUntil':
        isValid = validator.isDateValid(data.value) || validator.notRequired(data.value);
        break;
      default:
        isValid = true;
        break
    }

    return isValid;
  },

  handleCalculationChange(calculation){
    switch(calculation) {
      case 'volume-based' :
        var nextState = {
          'currencyCode'    : Map({value: 'EUR', isValid: null}),
          'volumeUnitCode'  : Map({value: 'L', isValid: null})
        };
        break;
      case 'flat' :
        var nextState = {
          'currencyCode'    : Map({value: 'EUR', isValid: null}),
          'volumeUnitCode'  : Map({value: null, isValid: null})
        }
        break;
      case 'percentage-based' :
        var nextState = {
          'currencyCode'    : Map({value: null, isValid: null}),
          'volumeUnitCode'  : Map({value: null, isValid: null})
        }
        break;
    }
    TaxFormStore.setState(nextState);
  },

  dispatchToken: Dispatcher.register(function(payload) {
    let action = payload.action;
    let nextState = {};

    switch(action.actionType) {
      case Constants.ActionTaxes.UPDATE_TAX:
      case Constants.ActionTaxes.CREATE_TAX:
        TaxFormStore.setState({isSavable: false})
        TaxFormStore.emitChange();
        break;

      case Constants.ActionTaxes.CREATE_TAX_SUCCESS:
      case Constants.ActionTaxes.UPDATE_TAX_SUCCESS:
        TaxFormStore.setState({isSavable: true, error: false})
        TaxFormStore.emitChange();
        break;

      case Constants.ActionTaxes.UPDATE_TAX_ERROR:
      case Constants.ActionTaxes.CREATE_TAX_ERROR:
        nextState = {isSavable: true, error: action.err.responseJSON.message};

        _.each(TaxFormStore.state.toJS(), function(item, key){
          if(Iterable.isIterable(TaxFormStore.get(key)))
            nextState[key] = TaxFormStore.get(key).update('isValid', v => v = TaxFormStore.isValid({field: key, value: TaxFormStore.get(key).get('value') })  );
        })

        TaxFormStore.setState(nextState)
        TaxFormStore.emitChange();
        break;

      case Constants.ActionTaxForm.TAX_FORM_CHANGE:
        if(action.field === 'calculation')
          TaxFormStore.handleCalculationChange(action.value);

        let isValid  = TaxFormStore.isValid(action)

        nextState[action.field] = Map({ value: action.value, isValid: isValid });
        TaxFormStore.setState(nextState)
        TaxFormStore.emitChange();
        break;

      case Constants.ActionModals.SHOW_TAX_FORM_MODAL:
        TaxFormStore.initialize();
        if(action.tax) {
          _.each(TaxFormStore.state.toJS(), function(item, key){
            if(!Iterable.isIterable(TaxFormStore.get(key))) return;

            if(key === 'validFrom' || key === 'validUntil')
              action.tax[key] = formatter.format.date(action.tax[key]);

            nextState[key] = TaxFormStore.get(key).update('value', v => v = action.tax[key] ? action.tax[key] : null);
          })
          TaxFormStore.setState(nextState);
        }

        TaxFormStore.emitChange();
        break;

      case Constants.ActionModals.HIDE_TAX_FORM_MODAL:
        TaxFormStore.initialize()
        TaxFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default TaxFormStore;
