import Dispatcher from '../core/Dispatcher';
import { Constants } from '../constants';

//Token
let jwt = localStorage.getItem('jwt');

const VolumeUnitsApiUtils = {
  get: function(url) {
    Promise.resolve($.ajax({
        url: url,
        type: 'GET',
        beforeSend: (req) => {
          req.setRequestHeader("Authorization", "bearer " + localStorage.getItem('jwt'));
        }
      }))
      .then(function(data){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionVolumeUnits.LOAD_VOLUME_UNITS_SUCCESS,
          units: data
        });
      })
      .catch(function(err){
        Dispatcher.handleViewAction({
          actionType: Constants.ActionVolumeUnits.LOAD_VOLUME_UNITS_ERROR,
          err: err
        });
      })
  }
}

export default VolumeUnitsApiUtils
