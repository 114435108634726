import React from 'react';
import ReactDom from 'react-dom';
import { Map, List } from 'immutable';
import { ButtonGroup, Button } from 'react-bootstrap';
import { CalculatorAction } from '../../../actions'

import Palette from '../../../constants/Palette';

export default React.createClass({
  getInitialState(){
    return {
      leftIsFocus  : false,
      rightIsFocus : false
    }
  },

  getDefaultProps(){
    return {
      operationType: Map()
    }
  },

  handleClickRight(){
    this.setState({
      leftIsFocus  : false,
      rightIsFocus : true
    })

    CalculatorAction.operationFlightChanges('private');
  },

  handleClickLeft(){
    this.setState({
      leftIsFocus  : true,
      rightIsFocus : false
    })

    CalculatorAction.operationFlightChanges('commercial');
  },

  getRightStyle(){
    let backgroundColor = this.state.rightIsFocus ? Palette.PRIMARY : Palette.LIGHT_GREY
    let color           = this.state.rightIsFocus ? Palette.WHITE : Palette.MID_GREY
    
    return {
      backgroundColor: backgroundColor,
      color: color,
      width: '40%'
    }
  },

  getLeftStyle(){
    let backgroundColor = this.state.leftIsFocus ? Palette.PRIMARY : Palette.LIGHT_GREY
    let color = this.state.leftIsFocus ? Palette.WHITE : Palette.MID_GREY
    
    return {
      backgroundColor: backgroundColor,
      color: color,
      width: '40%'
    }
  },

  render() {
    return (
      <div style={{width: '70%', margin: 'auto', fontWeight: 400}}>
        <Button 
          onClick ={this.handleClickLeft}
          style   ={this.getLeftStyle()}>
          Commercial Flight
        </Button>
        <span style={{paddingLeft: 20, paddingRight: 20}}>Or</span>
        <Button 
          onClick ={this.handleClickRight} 
          style   ={this.getRightStyle()}>
          Private Flight
        </Button>
      </div>
    )
  }
});
