import React from 'react';
import {Map} from 'immutable';
import {PageHelper, PageLifcycle, PageLoaded} from '../PageLifecycle'
import {NoItemHelper, SubTitle} from '../../components'
import SupplierAirportList from './SupplierAirportList';

export default React.createClass({
  propTypes: {
    supplierAirports: React.PropTypes.object
  },

  getDefaultProps() {
    return {
      supplierAirports: Map()
    }
  },

  render() {
    return (
      <div>
        <SubTitle>Details</SubTitle>

        {/* Page wrapper */}
        <PageLifcycle>
          {/* Displaying when no items */}
          <PageHelper isDisplayed={!this.props.isSupplierAirportsError && !this.props.isSupplierAirportsLoading && this.props.supplierAirports.size === 0}>
            <NoItemHelper onNew={this.props.onAdd} buttonLabel="Add airport" onImport={this.props.onImport}>
              <p>You are currently in the <strong>supplier</strong> details. There is no registered airport yet for this supplier.
              </p>
              <p>To get you started, you can add an airport. You can add as many airport as you like.</p>
              <p>Every airport can have multiple mails coma separated.</p>
            </NoItemHelper>
          </PageHelper>

          {/* Displaying when page loaded */}
          <PageLoaded isDisplayed={!(this.props.supplierAirports.size === 0)}>
            <SupplierAirportList
              user={this.props.user}
              onNew={this.props.onAdd}
              supplierAirports={this.props.supplierAirports}
              supplier={this.props.supplier}
              searchParams={this.props.searchParams}
              airports={this.props.airports}
              onDelete={this.props.onDelete}
              onEdit={this.props.onEdit}
              onImport={this.props.onImport}
              supplierAirportFormModalId={this.props.supplierAirportFormModalId}
              supplierAirportDeleteDialogId={this.props.supplierAirportDeleteDialogId}
              supplierAirportImportDialogId={this.props.supplierAirportImportDialogId}
            />
          </PageLoaded>
        </PageLifcycle>
      </div>
    )
  }
});
