import Dispatcher from '../core/Dispatcher';
import {Constants} from '../constants';
import BaseStore from './BaseStore';
import assign from 'object-assign';
import _ from 'lodash';
import {fromJS, Iterable, Map} from 'immutable';
import validator from '../utils/validator';
import formatter from "../utils/formatter";

const ValueFormStore = assign({}, BaseStore, {
  initialize() {
    //Set some default params to the form
    ValueFormStore.setState({
      id:             Map({
        value:   null,
        isValid: null
      }),
      value:          Map({
        value:   null,
        isValid: null
      }),
      effectiveDate:  Map({
        value:   null,
        isValid: null
      }),
      validUntil:     Map({
        value:   null,
        isValid: null
      }),
      currencyCode:   Map({
        value:   'EUR',
        isValid: null
      }),
      volumeUnitCode: Map({
        value:   'L',
        isValid: null
      }),
      isSavable:      true,
      error:          ''
    })
  },

  isValid(data) {
    let isValid = false;
    switch (data.field) {
      case 'value':
        isValid = validator.isNumericValid(data.value) && validator.required(data.value);
        break;
      case 'effectiveDate':
        isValid = validator.isDateValid(data.value) && validator.required(data.value);
        break;
      case 'validUntil':
        isValid = validator.isDateValid(data.value);
        break;
      default:
        isValid = true;
        break
    }

    return isValid;
  },

  dispatchToken: Dispatcher.register(function (payload) {
    let action = payload.action;
    let nextState = {};

    switch (action.actionType) {
      case Constants.ActionValues.UPDATE_VALUE:
      case Constants.ActionValues.CREATE_VALUE:
        ValueFormStore.setState({isSavable: false});
        ValueFormStore.emitChange();
        break;

      case Constants.ActionValues.CREATE_VALUE_SUCCESS:
      case Constants.ActionValues.UPDATE_VALUE_SUCCESS:
        ValueFormStore.setState({
          isSavable: true,
          error:     false
        });
        ValueFormStore.emitChange();
        break;

      case Constants.ActionValues.UPDATE_VALUE_ERROR:
      case Constants.ActionValues.CREATE_VALUE_ERROR:
        nextState = {
          isSavable: true,
          error:     action.err.responseJSON.error.message
        };

        _.each(ValueFormStore.state.toJS(), function (item, key) {
          if (Iterable.isIterable(ValueFormStore.get(key))) {
            nextState[key] = ValueFormStore.get(key).update('isValid', v => v = ValueFormStore.isValid({
              field: key,
              value: ValueFormStore.get(key).get('value')
            }));
          }
        });

        ValueFormStore.setState(nextState);
        ValueFormStore.emitChange();
        break;

      case Constants.ActionValueForm.VALUE_FORM_CHANGE:
        let isValid = ValueFormStore.isValid(action);
        nextState[action.field] = fromJS({
          value:   action.value,
          isValid: isValid
        });
        ValueFormStore.setState(nextState);
        ValueFormStore.emitChange();
        break;

      case Constants.ActionModals.SHOW_VALUE_FORM_MODAL:
        ValueFormStore.initialize();
        console.log('SHOW_VALUE_FORM_MODAL');
        if (action.value) {
          let nextState = {};
          _.each(ValueFormStore.state.toJS(), function (item, key) {
            nextState[key] = ValueFormStore.get(key).update('value', v => v = action.value[key] ? fromJS(action.value[key]) : null);
          });
          ValueFormStore.setState(nextState);
        } else {
          if (action.platt) {
            let nextState = {};
            _.each(ValueFormStore.state.toJS(), function (item, key) {
              console.log('checking key ' + key);
              if (ValueFormStore.get(key) && action.platt[key]) {
                console.log(' key checked ' + key);
                if (key === 'effectiveDate' || key === 'validUntil') {
                  //nextState.effectiveDate = ContractAirportFormStore.get("effectiveDate").update('value', v => v = formatter.format.date(value.effectiveDate));
                  nextState[key] = ValueFormStore.get(key).update('value', v => v = action.platt[key] ? formatter.format.date(fromJS(action.platt[key])) : null);
                } else {
                  nextState[key] = ValueFormStore.get(key).update('value', v => v = action.platt[key] ? fromJS(action.platt[key]) : null);
                }
              }
            });
            ValueFormStore.setState(nextState);
          }
        }

        ValueFormStore.emitChange();
        break;

      case Constants.ActionModals.HIDE_VALUE_FORM_MODAL:
        ValueFormStore.initialize();
        ValueFormStore.emitChange();
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  })
});

export default ValueFormStore;
