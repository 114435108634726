import React from 'react';
import _ from 'lodash'
import {fromJS, List, Map} from 'immutable';
import Griddle from 'griddle-react'

import {PermissionRequired, TableSearch} from '../../components'
import {PlattAction} from '../../actions'
import {Activities} from '../../constants'

import {search} from '../../components/TableSearch/TableSearch';

export default React.createClass({
  propTypes: {
    platts:        React.PropTypes.object,
    searchParams:  React.PropTypes.object,
    loadingStatus: React.PropTypes.object
  },

  getDefaultProps: function () {
    return {
      platts:       List(),
      searchParams: Map()
    }
  },

  getInitialState() {
    return {
      platts: this.props.platts
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchParams.size > 0) {
      let filteredPlatts = search(this.props.platts.toJS(), nextProps.searchParams.toJS())
      this.setState({platts: fromJS(filteredPlatts)})
    } else {
      this.setState({platts: nextProps.platts})
    }
  },

  render() {
    return (
      <PermissionRequired requiredRoles={Activities.READ_PLATTS} user={this.props.user}>
        <TableSearch
          user={this.props.user}
          requiredRoles={Activities.WRITE_PLATTS}
          columnMetadata={this.props.columnMetadata}
          onSearch={this.handleSearch}
          isNewButton={this.props.isNewButton}
          newButtonText="New platts"
          onNew={this.props.onNew}
        />
        <Griddle
          initialSort="icao"
          results={this.getPlatts()}
          tableClassName="table middle-td table-platts"
          resultsPerPage={100}
          columnMetadata={this.props.columnMetadata}
          columns={this.props.columnNames}
          noDataMessage=""
        />
      </PermissionRequired>
    )

  },

  getPlatts() {
    return this.state.platts.toJS();
    // return _.map(this.state.platts.toJS(), (a) => {
    //   return a;
    // });
  },

  handleSearch(e) {
    let {name, value} = e.target;
    PlattAction.search(name, value);
  }
});
