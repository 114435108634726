import React, { PropTypes } from 'react';
import { Input } from 'react-bootstrap';
import _ from 'lodash'

export default React.createClass({
  propTypes: {
    keyName: PropTypes.string,
    data: PropTypes.object,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func
  },

  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps.data.toJS(), this.props.data.toJS())
      || nextProps.defaultValue !== this.props.defaultValue 
      || nextProps.value !== this.props.value 
  },

  render(){
    return (
      <Input
        {...this.props}
        type='select'
      >
        {this.props.data.map(item => {
          return <option key={item.get(this.props.keyName)} value={item.get(this.props.keyName)}>{item.get(this.props.labelName)}</option>
        }, this)}
      </Input>)
  }
});
