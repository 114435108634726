import React from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { PermissionRequired, Action } from '../../components';
import formatter from '../../utils/formatter';

export default class TaxItem extends React.Component {
  constructor(props) {
    super(props);

    //Bind context to functions
    this.formatValue       = this.formatValue.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleEditClick   = this.handleEditClick.bind(this)
  }

  render() {
    return (<Row>
      <Col xs={2}>{this.props.tax.name}</Col>
      <Col xs={4}>{this.formatValue()}</Col>
      <Col xs={2} className='text-center'>{this.formatDate(this.props.tax.validFrom)}</Col>
      <Col xs={2} className='text-center'>{this.formatDate(this.props.tax.validUntil)}</Col>
      <Col xs={2} className='text-center'>
        <PermissionRequired requiredRoles={this.props.requiredWriteRoles} user={this.props.user}>
          <Action icon="remove" onClick={this.handleDeleteClick} />
          <Action icon="edit" onClick={this.handleEditClick} />
        </PermissionRequired>
      </Col>
    </Row>)
  }

  formatValue() {
    switch(this.props.tax.calculation) {
      case 'flat':
          return this.props.tax.value + ' ' + this.props.tax.Currency.code;
        break;
      case 'percentage-based':
        return this.props.tax.value + ' %';
        break;
      case 'volume-based':
        return this.props.tax.value + ' ' + this.props.tax.Currency.code + ' per ' + this.props.tax.VolumeUnit.name;
        break;
    }
  }


  formatDate(date) {
    if(date) return formatter.format.date(date)
    else return '-'
  }

  handleDeleteClick(){
    this.props.onDelete(this.props.tax);
  }

  handleEditClick(){
    this.props.onEdit(this.props.tax)
  }
};